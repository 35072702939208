import { syncPresence } from '../../xmpp/client'

const XMPP_SET_STATUS = 'XMPP_SET_STATUS'
const XMPP_SET_PRESENCE = 'XMPP_SET_PRESENCE'

const initialState = {
  status: 'unknown',
  presence: {
    available: true, // Will be mappe to type=unavailable if false
    show: null, // Must be one of `away`, `chat`, `dnd` or `xa`
    status: null, // Human-readable status message
  },
}

export function reducer(state = initialState, action) {
  switch (action.type) {
    case XMPP_SET_STATUS:
      return {
        ...state,
        status: action.payload,
      }
    case XMPP_SET_PRESENCE:
      return {
        ...state,
        presence: action.payload,
      }
    default:
      return state
  }
}

/**
 * Create a redux action to update the XMPP status
 *
 * @param {string} state XMPP status
 */
export const setXmppStatus = (status) => ({
  type: XMPP_SET_STATUS,
  payload: status,
})

/**
 * Create a redux action to update the XMPP presence information.
 *
 * @param {bool} available Mark the user as available
 * @param {null|'away'|'chat'|'dnd'|'xa'} show Availability. See https://xmpp.org/rfcs/rfc3921.html#rfc.section.2.2.2
 * @param {undefined|string} status Status message to show other users.
 * @returns {Promise<void>}
 */
export function setXmppPresence(available, show = null, status) {
  return (dispatch) => {
    dispatch({
      type: XMPP_SET_PRESENCE,
      payload: { available, show, status },
    })
    return syncPresence()
  }
}
