import * as Sentry from '@sentry/core'
import uuid from 'uuid/v4'
import store from './store'
import { CryphoAPI } from './api'
import { createActionItem, publishToPubsubNode } from './infostream'
import { serverTime } from './utils'
import { pubsub } from './xmpp'

/**
 * Change the filename for a file
 *
 * @param {string} spaceId Space id where file must be downloaded from
 * @param {string} vaultItemId Pubsub item id for the vault data
 * @param {*} vaultItem  Pubsub item in the vault node
 * @param {string} name The new filename
 * @returns {Promise<bool>} Success indicator
 */
export async function setFileName(spaceId, vaultItemId, vaultItem, name) {
  const newVaultItem = { ...vaultItem, name, updated: serverTime() }
  try {
    await publishToPubsubNode(spaceId, `/spaces/${spaceId}/vault`, newVaultItem, vaultItemId, true)
  } catch (error) {
    return false
  }
  return true
}

/**
 * Change the title for a file
 *
 * @param {string} spaceId Space id where file must be downloaded from
 * @param {string} vaultItemId Pubsub item id for the vault data
 * @param {*} vaultItem  Pubsub item in the vault node
 * @param {string} title The new file title
 * @returns {Promise<bool>} Success indicator
 */
export async function setFileTitle(spaceId, vaultItemId, vaultItem, title) {
  const newVaultItem = { ...vaultItem, title, updated: serverTime() }
  try {
    await publishToPubsubNode(spaceId, `/spaces/${spaceId}/vault`, newVaultItem, vaultItemId, true)
  } catch (error) {
    return false
  }
  return true
}

/**
 * Delete a file
 *
 * @param {string} spaceId Space id where file is located
 * @param {*} item Infostream item or vault item
 * @returns {Promise<{result: boo, filename: string}>} Promise indicating if deletion was successful.
 */
export async function deleteFile(spaceId, itemId, item) {
  const state = store.getState()
  const xmppPubsub = state.config.xmppPubsub
  const api = new CryphoAPI(state.config.apiUrl)

  if (item.type === 'file') {
    itemId = item.content.vault_item
    item = state.pubsub.itemsById[itemId]
  }
  const filename = item.name

  try {
    await api.post(`/vault/${spaceId}/${item.uid}/delete`)
    if (item.thumbnail) {
      await api.post(`/vault/${spaceId}/${item.thumbnail.uid}/delete`)
    }
  } catch (error) {
    Sentry.captureException(error)
    return { filename, success: false }
  }

  try {
    await pubsub.retract(xmppPubsub, `/spaces/${spaceId}/vault`, itemId)
    await createActionItem({ title: filename }, 'fileDeleted', spaceId)
  } catch (error) {
    // eslint-disable-next-line no-console
    Sentry.captureException(error)
    return { filename, success: false }
  }
  return { filename, success: true }
}

/**
 * Mark an XMPP item as downloaded by the current user.
 *
 * @param {string} spaceId Unique identifier of the space
 * @param {string} itemId ID of the pubsub item in the `vault` node
 * @returns Promise<void>
 */
export async function markItemAsDownloaded(spaceId, itemId) {
  const state = store.getState()
  let vaultItem = state.pubsub.itemsById[itemId]
  const nodeId = `/spaces/${spaceId}/vault`

  let downloadedBy = vaultItem.downloadedBy
  const myId = state.identity.id

  if (downloadedBy) {
    if (downloadedBy.includes(myId)) {
      return
    }
    downloadedBy = [...downloadedBy, myId]
  } else {
    downloadedBy = [myId]
  }

  const newVaultItem = { ...vaultItem, downloadedBy, itemId, created: serverTime(), type: 'downloadedBy' }
  const newItemId = uuid()
  await publishToPubsubNode(spaceId, nodeId, newVaultItem, newItemId, true)
}
