import React from 'react'
import PropTypes from 'prop-types'
import classes from './FieldTitle.module.css'
import { Icon } from 'semantic-ui-react'

const FieldTitle = ({ title, isEditable = true, onEdit }) => {
  return (
    <div className={classes.header}>
      <span>{title}</span>
      {isEditable && (
        <div className={classes['edit-button']} onClick={onEdit}>
          <Icon className="edit" title={`Edit ${title}`} />
        </div>
      )}
    </div>
  )
}

FieldTitle.propTypes = {
  title: PropTypes.string,
  isEditable: PropTypes.bool,
  onEdit: PropTypes.func,
}

export default FieldTitle
