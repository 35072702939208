import dotProp from 'dot-prop-immutable'
import { FETCH_ITEMS, ADD_ITEM, DELETE_ITEM, CLEAR_PUBSUB } from './constants'
import {
  syncInfoStream,
  syncFileStreamItem,
  mergeDownloadedByById,
  filterDownloadedByItems,
  syncDownloadedByById,
} from './utils'

export const INITIAL_STATE = {
  itemsById: {},
  nodeIds: [],
  nodeItemIds: {},
  nodeRSM: {},
  downloadedByById: {},
}

export default function pubsub(state = INITIAL_STATE, action) {
  switch (action.type) {
    case FETCH_ITEMS: {
      const { items, nodeId, rsm, itemIds, downloadedByById } = filterDownloadedByItems(action)
      const spaceId = nodeId.split('/')[2]
      const updatedWithNodeRSM = dotProp.set(state, `nodeRSM.${nodeId}`, rsm)
      const updatedWithNodeList = dotProp.set(updatedWithNodeRSM, 'nodeIds', (nodeIds) =>
        nodeIds.includes(nodeId) ? nodeIds : nodeIds.concat(nodeId),
      )

      const updatedWithItemTable = dotProp.merge(updatedWithNodeList, 'itemsById', items)
      const updatedWithNodeItemList = dotProp.set(updatedWithItemTable, `nodeItemIds.${nodeId}`, (ids) =>
        // Always sort itemIds by creation date
        [...new Set([...((ids.length && ids) || []), ...itemIds])].sort(
          (a, b) => (updatedWithItemTable.itemsById[a].created < updatedWithItemTable.itemsById[b].created && 1) || -1,
        ),
      )

      const syncedInfoStream = syncInfoStream(spaceId, updatedWithNodeItemList)
      // merge the found downloaded by items to the state
      const mergedDownloadedByById = mergeDownloadedByById(syncedInfoStream.downloadedByById, downloadedByById)
      syncedInfoStream.downloadedByById = mergedDownloadedByById
      // merge downloaded by stored in state to the current vault items in the state
      syncDownloadedByById(syncedInfoStream)
      return syncedInfoStream
    }

    case ADD_ITEM: {
      const { item, itemId, nodeId } = action
      const [spaceId, nodeType] = nodeId.split('/').slice(2)

      // if new item is a downloaded by item then just update downloaded by if item exits
      if (item.type === 'downloadedBy') {
        let downloadedByById = { [item.itemId]: item.downloadedBy }
        downloadedByById = mergeDownloadedByById(state.downloadedByById, downloadedByById)
        let newState = dotProp.set(state, 'downloadedByById', downloadedByById)
        syncDownloadedByById(newState)
        return newState
      }

      // If we have not fetched the node just ignore
      // XXX: we should at least update unread when it's there.
      if (!state.nodeIds.includes(nodeId)) {
        return state
      }

      let newState = dotProp.set(state, 'nodeIds', (nodeIds) =>
        nodeIds.includes(nodeId) ? nodeIds : nodeIds.concat(nodeId),
      )
      newState = dotProp.set(newState, `itemsById.${itemId}`, item)

      // If item already exists just update it.
      if (!state.nodeItemIds[nodeId].includes(itemId)) {
        newState = dotProp.set(newState, `nodeItemIds.${nodeId}`, (ids) => (ids ? [itemId, ...ids] : [itemId]))
      }

      if (nodeType === 'vault') {
        // Since this is a filestream item we need to add an infostream item too.
        newState = syncFileStreamItem(item, itemId, spaceId, newState)
        // apply current downloaded by items
        syncDownloadedByById(newState)
      }
      return newState
    }
    case DELETE_ITEM: {
      const { itemId, nodeId } = action
      const nodeType = nodeId.split('/')[3]

      // If we have not fetched the node just ignore
      if (!state.nodeIds.includes(nodeId)) return state

      const updatedWithItemTable = dotProp.delete(state, `itemsById.${itemId}`)
      let itemIndex = state.nodeItemIds[nodeId].indexOf(itemId)
      const updatedWithNodeItemList =
        itemIndex > -1
          ? dotProp.delete(updatedWithItemTable, `nodeItemIds.${nodeId}.${itemIndex}`)
          : updatedWithItemTable

      // If the item is a file, delete the infostream message first
      if (nodeType === 'vault') {
        const infostreamNodeId = nodeId.replace('vault', 'infostream')
        const infostreamItemId = itemId + '-infostream'
        const updatedWithInfostreamItemTable = dotProp.delete(updatedWithNodeItemList, `itemsById.${infostreamItemId}`)
        itemIndex = state.nodeItemIds[infostreamNodeId].indexOf(infostreamItemId)
        const updatedWithInfostreamNodeItemList =
          itemIndex > -1
            ? dotProp.delete(updatedWithInfostreamItemTable, `nodeItemIds.${infostreamNodeId}.${itemIndex}`)
            : updatedWithInfostreamItemTable

        return updatedWithInfostreamNodeItemList
      }
      return updatedWithNodeItemList
    }

    case CLEAR_PUBSUB: {
      // Instead of completely clearing the pubsub store,
      // we merely clear the RSM values. On the next fetch MAX_RSM_ITEMS
      // will be fetched and replace the existing ones.
      // Any messages before the MAX_RSM_ITEMS will be deleted
      return {
        ...INITIAL_STATE,
      }
    }

    default:
      return state
  }
}
