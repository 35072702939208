import { husher } from './crypho.core'
import store from './crypho.core/store'
import { CryphoAPI } from './crypho.core/api'

let session

export const loadSession = () => {
  session = JSON.parse(sessionStorage.getItem('authSession'))
  global.husher.fromSession(session)
}

export const saveSession = () => {
  session = global.husher.toSession()
  sessionStorage.setItem('authSession', JSON.stringify(session))
  window.dispatchEvent(new CustomEvent('saveSession', { detail: session }))
}

export const hasSession = () => {
  return !!session
}

export function deleteSession() {
  sessionStorage.clear()
  session = null
  global.husher = new husher.Husher()
  window.dispatchEvent(new CustomEvent('removeSession'))

  const state = store.getState()
  const { apiUrl } = state.config
  const api = new CryphoAPI(apiUrl)
  return api.get('/logout')
}
