import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import { compose } from 'recompose'
import { Image, Icon, Popup, Button } from 'semantic-ui-react'
import { withRouter } from 'react-router-dom'
import PropTypes from 'prop-types'
import InviteContactForm from '../../scenes/invitations/invitecontact'
import { fetchUserVCard } from '../../crypho.core/store/modules/vcard'
import { getContactSpaceId } from '../../crypho.core/store/modules/space/selectors'
import { isFingerprintVerified, isExternallyVerified } from '../../crypho.core/store/modules/roster/selectors'

class RosterAvatar extends PureComponent {
  constructor(properties) {
    super(properties)
    this.inviteContactFormReference = React.createRef()
  }
  componentDidMount() {
    const { vcard, userJID, fetchUserVCard } = this.props
    if (!vcard) {
      fetchUserVCard(userJID)
    }
  }

  visitOrInvite = () => {
    const { history, contactSpaceId } = this.props
    history.push(`/contacts/${contactSpaceId}`)
  }

  render() {
    const { apiUrl, contactSpaceId, userId, vcard, noPopup, isExternallyVerified, isFingerPrintVerified } = this.props
    const inSpace = window.location.pathname.includes(contactSpaceId)
    const isVerified = isFingerPrintVerified || isExternallyVerified
    const avatar = <Image className="spaceAvatar" circular avatar src={`${apiUrl}/avatar/${userId}`} />

    if (noPopup) {
      return avatar
    }

    if (!vcard) return null
    const company = (vcard.ORG && vcard.ORG.ORGNAME) || ''

    return (
      <React.Fragment>
        <InviteContactForm email={vcard.EMAIL.USERID} ref={this.inviteContactFormReference} />

        <Popup trigger={avatar} hoverable flowing>
          <Popup.Header>
            {vcard.FN}
            {isVerified ? (
              <Icon className="verifiedIcon" title="Verified contact" />
            ) : (
              <Icon className="unverifiedIcon" title="Unverified contact" />
            )}
          </Popup.Header>

          <Popup.Content>
            {company ? <div className="description">{company}</div> : null}
            <p>{vcard.EMAIL.USERID}</p>

            {!inSpace && contactSpaceId ? (
              <Button className="primaryButton" onClick={this.visitOrInvite}>
                Private conversation
              </Button>
            ) : (
              <Button className="primaryButton" onClick={this.openContactForm}>
                Invite contact
              </Button>
            )}
          </Popup.Content>
        </Popup>
      </React.Fragment>
    )
  }
  openContactForm = () => this.inviteContactFormReference.current.open()
}

RosterAvatar.propTypes = {
  apiUrl: PropTypes.string,
  contactSpaceId: PropTypes.string,
  fetchUserVCard: PropTypes.func.isRequired,
  isExternallyVerified: PropTypes.bool.isRequired,
  isFingerPrintVerified: PropTypes.bool.isRequired,
  history: PropTypes.shape(),
  isVerified: PropTypes.bool,
  userId: PropTypes.string,
  userJID: PropTypes.string,
  vcard: PropTypes.shape(),
  noPopup: PropTypes.bool,
}

const mapStateToProperties = (state, ownProperties) => {
  const { vcards } = state
  const { userId } = ownProperties
  let vcard = vcards.byId[userId]
  const { xmppDomain, apiUrl } = state.config
  const userJID = `${userId}@${xmppDomain}`

  return {
    userJID,
    apiUrl,
    vcard,
    contactSpaceId: getContactSpaceId(state, { contactId: userId }),
    isExternallyVerified: isExternallyVerified(state, { userId }),
    isFingerPrintVerified: isFingerprintVerified(state, { userId }),
    isVerified: !!(state.roster.byId[userId] && isFingerprintVerified(state, { userId })),
  }
}

const mapDispatchToProperties = {
  fetchUserVCard,
}

const connector = (container) => compose(withRouter, connect(mapStateToProperties, mapDispatchToProperties))(container)

export default connector(RosterAvatar)
