import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Table } from 'semantic-ui-react'
import FileStreamItem from './FileStreamItem'
import { Visibility, Loader } from 'semantic-ui-react'
import { useDispatch } from 'react-redux'
import { fetchPubsubItemsFromServer } from '../../../../crypho.core/store/modules/pubsub'

function FileList({ spaceId, itemIds }) {
  const [refreshing, setRefreshing] = useState(false)
  const dispatch = useDispatch()

  const refresh = async () => {
    if (refreshing) return
    setRefreshing(true)
    await dispatch(fetchPubsubItemsFromServer(`/spaces/${spaceId}/vault`, spaceId, true))
    setRefreshing(false)
  }

  return (
    <div className="filestreamNode">
      {itemIds.length > 0 ? (
        <Table>
          <Table.Body>
            {itemIds.map((id) => (
              <FileStreamItem key={id} id={id} spaceId={spaceId} />
            ))}
            <Table.Row>
              <Table.Cell>
                <Visibility
                  updateOn="repaint"
                  once={false}
                  onOnScreen={() => {
                    refresh()
                  }}
                >
                  <Loader active={refreshing} />
                </Visibility>
              </Table.Cell>
            </Table.Row>
          </Table.Body>
        </Table>
      ) : (
        <div className="pageInfo">
          <span className="empty">No files.</span>
        </div>
      )}
    </div>
  )
}

FileList.propTypes = {
  spaceId: PropTypes.string.isRequired,
  itemIds: PropTypes.arrayOf(PropTypes.string).isRequired,
}

export default FileList
