import React, { useRef } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { List, Button, ListHeader, ListDescription, Image } from 'semantic-ui-react'

import SystemAdminRemoveConfirm from './SystemAdminRemoveConfirm'

const SystemAdminListItem = ({ admin, onDelete, apiUrl, isSelf }) => {
  const removeConfirmRef = useRef()
  const avatar = <Image className="spaceAvatar" circular avatar src={`${apiUrl}/avatar/${admin.id}`} />

  const deleteAdminHandler = () => {
    removeConfirmRef.current.close()
    onDelete()
  }

  const cancelDeleteHandler = () => {
    removeConfirmRef.current.close()
  }

  return (
    <List.Item key={admin.id}>
      {avatar}
      <List.Content>
        <ListHeader>{admin.fullName}</ListHeader>
        <ListDescription>{admin.email}</ListDescription>
      </List.Content>
      <List.Content floated="right">
        {!isSelf && [
          <SystemAdminRemoveConfirm
            ref={removeConfirmRef}
            onDelete={deleteAdminHandler}
            onCancel={cancelDeleteHandler}
            key="RemoveSystemAdminConfirm"
          />,
          <Button
            color="red"
            circular
            icon="remove"
            title="Remove admin"
            key="removeSystemAdminButton"
            onClick={() => removeConfirmRef.current.open(admin.fullName)}
          />,
        ]}
      </List.Content>
    </List.Item>
  )
}

SystemAdminListItem.propTypes = {
  admin: PropTypes.object,
  onDelete: PropTypes.func,
  apiUrl: PropTypes.string,
  isSelf: PropTypes.bool,
}

const mapStateToProperties = (state, ownProps) => {
  const apiUrl = state.config.apiUrl
  return {
    admin: ownProps.admin,
    onDelete: ownProps.onDelete,
    apiUrl,
    isSelf: state.identity.id === ownProps.admin.id,
  }
}

const mapDispatchToProperties = {}

const connector = (container) => connect(mapStateToProperties, mapDispatchToProperties)(container)

export default connector(SystemAdminListItem)
