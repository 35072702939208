import createCachedSelector from 're-reselect'
import max from 'lodash/max'
import memoize from 'lodash/memoize'
import { getContacts, alphaSortedRosterIds } from '../roster/selectors'
import { getSpaceKey } from '../space'
import { getReceivedInvitationCount } from '../invitations/selectors'
import store from '../../../store'

const spaces = (state) => state.spaces.byId
const spaceIds = (state) => state.spaces.allIds
const vcards = (state) => state.vcards.byId
const space = (state, props) => props.space || (props.spaceId && state.spaces.byId[props.spaceId])
const roster = (state) => state.roster.byId
const spaceId = (state, props) => props.spaceId || props.space.id
const onlineIds = (state) => state.online.allIds
const unread = (state) => state.spaces.unread
const contactId = (state, props) => props.contactId
const spaceKeys = (state, props) => state.spaces.keys[props.spaceId]

export const getSpaceMembersUserIds = createCachedSelector(
  [spaceId, roster, alphaSortedRosterIds],
  (spaceId, roster, alphaSortedRosterIds) =>
    alphaSortedRosterIds.filter((userId) => roster[userId].groups.includes(spaceId)),
)(spaceId)

export const spaceTitle = createCachedSelector([spaceId, space], (spaceId, space) => space.title)(spaceId)

const memoizedGetSpaceKey = memoize(
  (spaceId, id) => ({
    id,
    key: getSpaceKey(spaceId, id),
  }),
  (spaceId, id) => `${spaceId}-${id}`,
)

export const getSpaceCurrentKey = createCachedSelector([spaceKeys, spaceId], (spaceKeys, spaceId) => {
  if (!spaceKeys) return { id: null, key: null }
  const id = max(Object.keys(spaceKeys).map((k) => parseInt(k, 10))).toString()
  return memoizedGetSpaceKey(spaceId, id)
})(spaceId)

export const getSpaceNonMembersUserIds = createCachedSelector([spaceId, getContacts], (spaceId, contacts) =>
  contacts.filter(({ groups }) => !groups.includes(spaceId)).map(({ jid }) => jid.split('@')[0]),
)(spaceId)

export const getSpaceOnlineMembersUserIds = createCachedSelector(
  [spaceId, getSpaceMembersUserIds, onlineIds],
  (spaceId, memberIds, onlineIds) => memberIds.filter((id) => onlineIds.includes(id)),
)(spaceId)

export const getContactSpaceIds = createCachedSelector([spaceIds], (spaceIds) => {
  const spaces = store.getState().spaces.byId
  return spaceIds.filter((spaceId) => spaces[spaceId] && spaces[spaceId].type === 'contact')
})(() => 'contactSpaceIds')

export const getContactSpaces = createCachedSelector([spaces, getContactSpaceIds], (spaces, spaceIds) =>
  spaceIds.map((spaceId) => spaces[spaceId]),
)(() => 'contactSpaces')

export const getGroupSpaceIds = createCachedSelector([spaceIds], (spaceIds) => {
  const spaces = store.getState().spaces.byId
  return spaceIds.filter((spaceId) => spaces[spaceId] && spaces[spaceId].type === 'group')
})(() => 'groupSpaceIds')

export const getGroupSpaces = createCachedSelector([spaces, getGroupSpaceIds], (spaces, spaceIds) =>
  spaceIds.map((spaceId) => spaces[spaceId]),
)(() => 'groupSpaces')

export const getSpaceMemberVCards = createCachedSelector(
  [spaceId, getSpaceMembersUserIds, vcards],
  (spaceId, spaceMembersUserIds, vcards) =>
    spaceMembersUserIds.reduce((spaceVCards, id) => {
      spaceVCards[id] = vcards[id]
      return spaceVCards
    }, {}),
)(spaceId)

export const getSpaceTitle = createCachedSelector(
  [spaceId, spaceTitle, getSpaceMemberVCards],
  (spaceId, title, vcards) => {
    if (title) return title
    const displayedParticipants = Object.keys(vcards).map((userId) => {
      try {
        return vcards[userId].FN
      } catch (error) {
        return ''
      }
    })
    if (displayedParticipants.length === 1) {
      return displayedParticipants[0]
    } else if (displayedParticipants.length > 3) {
      return `${displayedParticipants.slice(0, 3).join(', ')} and ${displayedParticipants.length - 3} more`
    } else if (displayedParticipants.length === 0) {
      return 'No title'
    } else {
      return displayedParticipants.join(', ')
    }
  },
)(spaceId)

export const getSpaceCompany = createCachedSelector(
  [space, getSpaceMembersUserIds, vcards],
  (space, spaceMembersUserIds, vcards) => {
    if (space.type === 'contact') {
      const vcard = vcards[spaceMembersUserIds[0]] || { ORG: null }
      const { ORG } = vcard
      if (ORG && typeof ORG.ORGNAME === 'string') {
        return ORG.ORGNAME
      }
    }
    return ''
  },
)((state, props) => props.space.id)

export const getUnreadInContacts = createCachedSelector([getContactSpaceIds, unread], (spaceIds, unread) => {
  return spaceIds.reduce((sum, spaceId) => sum + unread[spaceId], 0)
})(() => 'unreadContacts')

export const getUnreadInGroups = createCachedSelector([getGroupSpaceIds, unread], (spaceIds, unread) => {
  return spaceIds.reduce((sum, spaceId) => sum + unread[spaceId], 0)
})(() => 'unreadGroups')

export const getUnread = createCachedSelector(
  [getUnreadInContacts, getUnreadInGroups, getReceivedInvitationCount],
  (unreadInContacts, unreadInGroups, invitationCount) => {
    return unreadInContacts + unreadInGroups + invitationCount
  },
)(() => 'unread')

export const getContactSpacesFullnameValueLabelPairs = createCachedSelector(
  [getContactSpaceIds, vcards],
  (contactSpacesIds) => {
    const state = store.getState()
    return contactSpacesIds
      .map((spaceId) => ({
        value: spaceId,
        label: getSpaceTitle(state, { spaceId }),
      }))
      .sort((kvA, kvB) => {
        if (kvA.label > kvB.label) return 1
        return -1
      })
  },
)(() => 'alphaContactsSortedSpaceFullnameValueLabels')

export const getGroupSpacesFullnameValueLabelPairs = createCachedSelector([getGroupSpaces, vcards], (groupSpaces) => {
  const state = store.getState()
  return groupSpaces
    .map((space) => ({
      value: space.id,
      label: getSpaceTitle(state, { space }),
    }))
    .sort((kvA, kvB) => {
      if (kvA.label > kvB.label) return 1
      return -1
    })
})(() => 'alphaGroupsSortedSpaceFullnameValueLabels')

export const getContactSpaceId = createCachedSelector(
  [contactId, getContactSpaceIds, roster],
  (contactId, contactSpaceIds, roster) => {
    const contact = roster[contactId]
    if (!contact) return null
    return contact.groups.find((spaceId) => contactSpaceIds.includes(spaceId)) || null
  },
)((state, props) => props.contactId)
