import React from 'react'
import { Header } from 'semantic-ui-react'
import { Link } from 'react-router-dom'
import { withRouter } from 'react-router-dom'

function DeletedAccount() {
  return (
    <div className="darkContainer">
      <div className="cardContainer">
        <div className="cryphoLogotype" />
        <div className="card">
          <div className="redHeader">
            <div className="cardLogo" />
            <Header as="h1">You have successfully deleted your account</Header>
          </div>
          <div className="form">
            <Header as="h4">
              {
                'All your conversations and files you have shared with your contacts have been deleted. All the groups you owned and files within have been deleted. You have been removed from all the groups owned by others, but messages and files you have shared within those groups remain. It is impossible to recover any of the content lost.'
              }
            </Header>
          </div>
        </div>
        <Link className="whiteTextLink top20" name="signup" to="/signup">
          Sign up with new account?
        </Link>
      </div>
    </div>
  )
}

export default withRouter(DeletedAccount)
