import * as Sentry from '@sentry/core'
import dotProp from 'dot-prop-immutable'
import * as XMPP from '../../../xmpp'

const SET_API_URL = 'SET_API_URL'
const SET_EJABBERD_VERSION = 'SET_EJABBERD_VERSION'
const UPDATE_CONFIGURATION = 'UPDATE_CONFIGURATION'

const runtimeConfig = window._RUNTIME || {}

const getCfg = (name, dflt) => runtimeConfig[name] || process.env[`REACT_APP_${name}`] || dflt
const isDevel = process.env.NODE_ENV == 'development' || __DEV__
const apiUrl = getCfg('API_URL', isDevel ? 'https://api.local.crypho.dev:8080' : 'https://app.crypho.com')
const siteUrl = getCfg('SITE_URL', isDevel ? 'https://app.local.crypho.dev:8080' : 'https://app.crypho.com')
const xmppDomain = getCfg('XMPP_DOMAIN', isDevel ? 'localhost' : 'crypho.com')

const buildState = (apiUrl, siteUrl, xmppDomain) => ({
  apiUrl,
  siteUrl,
  appUrl: apiUrl, // For backwards compatibility
  xmppDomain,
  xmppPubsub: `pubsub.${xmppDomain}`,
  xmppCrypho: `crypho.${xmppDomain}`,
  xmppWebsocketUrl: `${apiUrl}/xmpp`,
  ejabberdVersion: '',
})

const initialState = buildState(apiUrl, siteUrl, xmppDomain)

export default function config(state = initialState, action) {
  switch (action.type) {
    case SET_API_URL: {
      const { apiUrl } = action
      Sentry.configureScope((scope) => {
        scope.setExtra('api_url', apiUrl)
      })
      const xmppDomain = apiUrl === 'https://app.crypho.com' ? 'crypho.com' : 'localhost'
      return buildState(apiUrl, state.siteUrl, xmppDomain)
    }

    case SET_EJABBERD_VERSION: {
      const { version } = action
      return dotProp.set(state, 'ejabberdVersion', version)
    }

    case UPDATE_CONFIGURATION: {
      Sentry.configureScope((scope) => {
        scope.setExtra('api_url', action.payload.apiUrl)
      })
      const newState = {
        ...state,
        ...action.payload,
        appUrl: action.payload.siteUrl,
        xmppPubsub: `pubsub.${action.payload.xmppDomain}`,
        xmppCrypho: `crypho.${action.payload.xmppDomain}`,
        xmppWebsocketUrl: action.payload.xmppWebsocketUrl,
      }
      if (
        XMPP.client &&
        (newState.xmppDomain !== XMPP.client.options.domain ||
          newState.xmppWebsocketUrl !== XMPP.client.options.service)
      ) {
        const prevStatus = XMPP.client.status
        XMPP.reconfigure(newState)
        if (prevStatus !== 'offline') {
          XMPP.start()
        }
      }
      return newState
    }

    default:
      return state
  }
}

export const setApiUrl = (apiUrl) => ({
  type: SET_API_URL,
  apiUrl,
})

// Keep old name for backwards compatibility
export const setAppUrl = setApiUrl

export const setEjabberdVersion = (version) => ({
  type: SET_EJABBERD_VERSION,
  version,
})

/**
 * Update the Crypho instance configuration
 *
 * @param {{apiUrl: string, siteUrl: string, xmppDomain: string, xmppWebsocketUrl: string}} cfg Site configuration
 */
export const updateConfiguration = (cfg) => ({
  type: UPDATE_CONFIGURATION,
  payload: cfg,
})
