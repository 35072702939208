import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

import { NotificationManager } from 'react-notifications'
import { CallContext, STATE_IDLE } from '../../crypho.core/rtc'
import { Button } from 'semantic-ui-react'
import { getCallerName } from './utils'

class InitiateCall extends React.Component {
  initiateCall = async (media) => {
    const { ring } = this.context
    const { userId, spaceId } = this.props
    const answer = await ring(userId, spaceId, media)
    switch (answer) {
      case 'no-reply':
        NotificationManager.info('No reply')
        break
      case 'refused':
        NotificationManager.info('Your call was refused')
        break
      case 'accepted':
        break
      case 'media-access-denied':
        NotificationManager.error(
          'Could not access a camera/microphone. You need to allow Crypho access in order to make calls.',
        )
        break
      case 'offline':
        NotificationManager.info(
          `${getCallerName(userId)} is not online with a supported device. They have been notified of your call`,
        )
        break
    }
  }

  render() {
    const { callState, getDevicesByType } = this.context
    const disabled = !(this.props.canCall && callState === STATE_IDLE)

    const hasCamera = getDevicesByType('videoinput').length > 0
    const hasMicrophone = getDevicesByType('audioinput').length > 0

    return (
      <React.Fragment>
        <Button
          id="audio-call"
          onClick={() => this.initiateCall('audio')}
          circular
          floated="right"
          color={!disabled ? 'blue' : undefined}
          className="spaceCallButton"
          icon="phone"
          data-tooltip="Start audio call"
          data-position="bottom right"
          disabled={disabled || !hasMicrophone}
        />
        <Button
          id="video-call"
          onClick={() => this.initiateCall('video')}
          circular
          floated="right"
          color={!disabled ? 'blue' : undefined}
          className="spaceCallButton"
          icon="video"
          data-tooltip="Start video call"
          data-position="bottom right"
          disabled={disabled || !(hasCamera && hasMicrophone)}
        />
      </React.Fragment>
    )
  }
}

InitiateCall.contextType = CallContext

InitiateCall.propTypes = {
  userId: PropTypes.string.isRequired,
  spaceId: PropTypes.string.isRequired,
  canCall: PropTypes.bool.isRequired,
}

const mapStateToProperties = () => {
  // Corona virus PR dictates free video.
  //const canCall = !['free', 'beta'].includes(state.account.plan.name)
  return {
    canCall: true,
  }
}

const connector = (container) => connect(mapStateToProperties, {})(container)

export default withRouter(connector(InitiateCall))
