import React from 'react'
import PropTypes from 'prop-types'
import { Visibility, Loader, Button, Icon } from 'semantic-ui-react'
import { connect } from 'react-redux'

import InfoStreamItem from './InfoStreamItem'
import store from '../../../crypho.core/store'
import { fetchStreams } from '../../../crypho.core/store/modules/pubsub'
import { client, jid } from '../../../crypho.core/xmpp'

export const COMPOSING_IDLE_TIMEOUT = 5000

class InfoStreamNode extends React.Component {
  constructor(properties) {
    super(properties)
    this.containerReference = React.createRef()
    this.state = {
      composing: {},
      nearBottom: true,
      nearTop: false,
      refreshing: false,
      newMessages: false,
    }
  }

  componentDidMount() {
    client.on('stanza', this.onChatStateMessage)
    this.scrollToBottom(true)
    const { containerReference } = this
    this.scrollObserver = new MutationObserver(() => {
      this.scrollToBottom()
    })
    this.scrollObserver.observe(containerReference.current, {
      childList: true,
      subtree: true,
    })
    window.addEventListener('focus', this.clearComposing)
  }

  componentDidUpdate(previousProperties) {
    const { nearBottom } = this.state
    if (previousProperties.nodeId !== this.props.nodeId) {
      this.setState({
        composing: {},
        nearBottom: true,
        nearTop: false,
        refreshing: false,
        newMessages: false,
      })
      this.scrollToBottom(true)
    } else if (previousProperties.itemIds !== this.props.itemIds) {
      if (nearBottom) {
        this.scrollToBottom()
      } else {
        if (previousProperties.itemIds[0] !== this.props.itemIds[0]) this.setState({ newMessages: true })
      }
    }
  }

  componentWillUnmount() {
    this.scrollObserver.disconnect()
    client.removeListener('stanza', this.onChatStateMessage)
    const { composing } = this.state
    Object.keys(composing).forEach((key) => clearTimeout(composing[key].timeout))
    window.removeEventListener('focus', this.clearComposing)
  }

  onComposingEnd = (userId) => {
    const composing = { ...this.state.composing }
    if (composing[userId]) {
      clearTimeout(composing[userId].timeout)
      delete composing[userId]
      this.setState({ composing })
    }
  }

  clearComposing = () => {
    const composing = { ...this.state.composing }
    const now = Date.now()
    const toClean = Object.keys(composing).filter((user) => now - composing[user].time > COMPOSING_IDLE_TIMEOUT)
    toClean.forEach((composer) => {
      clearTimeout(composing[composer].timeout)
      delete composing[composer]
    })
    this.setState({ composing })
  }

  onChatStateMessage = (stanza) => {
    const { spaceId } = this.props
    const { composing } = this.state
    if (!stanza.is('message')) return
    if (stanza.attrs.type !== 'chat') return
    if (stanza.getChildText('thread') !== spaceId) return
    const from = jid(stanza.attrs.from).local
    if (stanza.getChild('composing')) {
      if (!composing[from]) {
        const timeout = setTimeout(() => {
          this.onComposingEnd(from)
        }, COMPOSING_IDLE_TIMEOUT)
        const time = Date.now()
        composing[from] = { timeout, time }
      }
    } else {
      this.onComposingEnd(from)
    }
    this.setState({ composing })
  }

  onScroll = async () => {
    const { nearTop, refreshing } = this.state
    if (!nearTop) return
    if (refreshing) return
    this.setState({ refreshing: true })
    await this.refresh()
  }

  refresh = async () => {
    const { spaceId } = this.props
    await store.dispatch(fetchStreams(spaceId, true))
    this.setState({ refreshing: false })
  }

  scrollToBottom = (force = false) => {
    requestAnimationFrame(() => {
      const container = this.containerReference.current
      const { nearBottom } = this.state
      if ((force || nearBottom) && container) container.scrollTop = container.scrollHeight
    })
  }

  _renderComposing = () => {
    const composing = Object.keys(this.state.composing)
    const { vcards } = this.props
    if (composing.length === 0) return null
    else if (composing.length === 1) {
      return (
        <div className="typingIndicator" key="typingIndicator">
          <span>{`${vcards[composing[0]].FN} is typing…`}</span>
        </div>
      )
    } else {
      const names = composing.map((userId) => vcards[userId].FN)
      return (
        <div className="typingIndicator" key="typingIndicator">
          <span>{`${names.join(', ')} are typing…`}</span>
        </div>
      )
    }
  }

  render() {
    const { itemIds, nodeId, spaceId, spaceType } = this.props
    const { refreshing, newMessages } = this.state
    return (
      <div ref={this.containerReference} onScroll={this.onScroll} className="infostreamNode">
        <Visibility
          context={this.containerReference.current}
          once={false}
          onOnScreen={() => {
            this.setState({ nearTop: true })
          }}
          onOffScreen={() => {
            this.setState({ nearTop: false })
          }}
        />
        <Loader active={refreshing} />

        <div>
          {[
            [...itemIds]
              .reverse()
              .map((id) => <InfoStreamItem key={id} id={id} nodeId={nodeId} spaceId={spaceId} spaceType={spaceType} />),
            this._renderComposing(),
          ]}
        </div>
        <Visibility
          context={this.containerReference.current}
          once={false}
          fireOnMount={true}
          onOnScreen={() => {
            this.setState({ nearBottom: true, newMessages: false })
          }}
          onOffScreen={() => {
            this.setState({ nearBottom: false })
          }}
        />
        {newMessages ? (
          <Button
            basic
            id="new-messages"
            onClick={() => {
              this.scrollToBottom(true)
            }}
          >
            <span>
              <Icon name="chevron down" className="top" />
              <Icon name="chevron down" className="bottom" />
              New messages
            </span>
          </Button>
        ) : null}
      </div>
    )
  }
}

InfoStreamNode.propTypes = {
  nodeId: PropTypes.string.isRequired,
  itemIds: PropTypes.array.isRequired,
  spaceId: PropTypes.string.isRequired,
  spaceType: PropTypes.string.isRequired,
  vcards: PropTypes.shape(),
}

const mapStateToProperties = (state, ownProperties) => {
  const { pubsub } = state
  const itemIds = pubsub.nodeItemIds[ownProperties.nodeId] || []
  return {
    itemIds,
    vcards: state.vcards.byId,
  }
}

const mapDispatchToProperties = {}

const connector = (container) =>
  connect(
    mapStateToProperties,
    mapDispatchToProperties,
    null,
    // eslint-disable-next-line unicorn/prevent-abbreviations
    { forwardRef: true },
  )(container)

export default connector(InfoStreamNode)
