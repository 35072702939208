import React, { useState } from 'react'
import { Form } from 'formsy-semantic-ui-react'
import { Segment, Header, Button } from 'semantic-ui-react'
import PropTypes from 'prop-types'
import { NotificationManager } from 'react-notifications'
import { connect } from 'react-redux'

import { errorLabel } from '../../../UI/forms'
import { sendAnnouncement } from '../api'

import classes from './AnnouncementForm.module.css'

const AnnouncementForm = ({ apiUrl }) => {
  const [sending, setSending] = useState(false)

  const submitHandler = async (formData) => {
    try {
      setSending(true)
      await sendAnnouncement(apiUrl, formData)
      NotificationManager.info('Announcement sent successfully')
    } catch (error) {
      NotificationManager.error(error?.data?.message ?? 'Error occurred', '')
    }
    setSending(false)
  }

  return (
    <Segment className={classes.formContainer}>
      {sending && (
        <div className={classes.loader}>
          <div className="ui active large inverted text loader">Sending announcement</div>
        </div>
      )}
      <Header as="h3">Send announcement</Header>
      <Form onValidSubmit={submitHandler}>
        <Form.Input
          label="type"
          type="text"
          id="type"
          name="type"
          className={`${classes.formInput} ${classes.type}`}
          required
          validationErrors={{ isDefaultRequiredValue: 'type is required' }}
          errorLabel={errorLabel}
        />
        <Form.TextArea
          label="body"
          type="text"
          id="body"
          name="body"
          className={classes.formInput}
          rows={3}
          required
          validationErrors={{ isDefaultRequiredValue: 'body is required' }}
          errorLabel={errorLabel}
        />
        <Button name="save" className={`settingsButton ${classes.sendButton}`} type="submit">
          Send
        </Button>
      </Form>
    </Segment>
  )
}

AnnouncementForm.propTypes = {
  apiUrl: PropTypes.string,
}

const mapStateToProperties = (state) => {
  const apiUrl = state.config.apiUrl
  return {
    apiUrl,
  }
}

const connector = (container) => connect(mapStateToProperties)(container)
export default connector(AnnouncementForm)
