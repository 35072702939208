import React from 'react'
import { NavLink, Route } from 'react-router-dom'
import { Tab } from 'semantic-ui-react'

import AdminUsers from './AdminUsers'
import Topbar from '../../UI/Topbar'
import SystemAdmins from './SystemAdmins'
import Plans from './Plans'
import Communications from './communications/Communications'

function usersPane() {
  return (
    <Tab.Pane>
      <AdminUsers />
    </Tab.Pane>
  )
}

function adminPane() {
  return (
    <Tab.Pane>
      <SystemAdmins />
    </Tab.Pane>
  )
}

function planPane() {
  return (
    <Tab.Pane>
      <Plans />
    </Tab.Pane>
  )
}

function communicationPane() {
  return (
    <Tab.Pane>
      <Communications />
    </Tab.Pane>
  )
}

const tabPanes = [
  {
    menuItem: {
      as: NavLink,
      id: 'users-tab',
      content: 'Users',
      to: '/admin',
      exact: true,
      key: 'users',
    },
    pane: <Route key="users" path="/admin" exact={true} render={usersPane} />,
  },
  {
    menuItem: {
      as: NavLink,
      id: 'system-admins-tab',
      content: 'System Admins',
      to: '/admin/system-admins',
      exact: true,
      key: 'system-admins',
    },
    pane: <Route key="system-admins" path="/admin/system-admins" exact={true} render={adminPane} />,
  },
  {
    menuItem: {
      as: NavLink,
      id: 'plans-tab',
      content: 'Plans',
      to: '/admin/plans',
      exact: true,
      key: 'plans',
    },
    pane: <Route key="plans" path="/admin/plans" exact={true} render={planPane} />,
  },
  {
    menuItem: {
      as: NavLink,
      id: 'communications-tab',
      content: 'Communications',
      to: '/admin/communications',
      exact: true,
      key: 'communications',
    },
    pane: <Route key="communications" path="/admin/communications" exact={true} render={communicationPane} />,
  },
]

export default function AdminScene() {
  return (
    <div id="admin-scene" className="authenticatedScene">
      <Topbar />
      <Tab as={React.Fragment} renderActiveOnly={false} activeIndex={-1} panes={tabPanes} />
    </div>
  )
}
