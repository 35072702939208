const generateGoogleRecaptchaScript = (enterprise) => {
  return `https://www.google.com/recaptcha/${enterprise ? 'enterprise.js' : 'api.js'}`
}

const isScriptInjected = (scriptId) => !!document.querySelector(`#${scriptId}`)

const cleanGstaticRecaptchaScript = () => {
  const script = document.querySelector('script[src^="https://www.gstatic.com/recaptcha/releases"]')

  if (script) {
    script.remove()
  }
}

const removeDefaultBadge = () => {
  const nodeBadge = document.querySelector('.grecaptcha-badge')
  if (nodeBadge && nodeBadge.parentNode) {
    document.body.removeChild(nodeBadge.parentNode)
  }
}

const cleanCustomBadge = (customBadge) => {
  if (!customBadge) {
    return
  }

  while (customBadge.lastChild) {
    customBadge.lastChild.remove()
  }
}

const cleanBadge = (container) => {
  if (!container) {
    removeDefaultBadge()

    return
  }

  const customBadge = typeof container === 'string' ? document.querySelector(`#${container}`) : container

  cleanCustomBadge(customBadge)
}

/**
 * Injects google recaptcha v3 script to document
 * @param {
 *  render: string;
 *  onLoadCallbackName: string;
 *  useEnterprise: boolean;
 *  onLoad: () => void;
 *  language?: string;
 *  scriptProps?: {
 *    nonce?: string;
 *    defer?: boolean;
 *    async?: boolean;
 *    appendTo?: 'head' | 'body';
 *    id?: string;
 *  };
 * }
 * @returns void
 */

export const injectGoogleRecaptchaScript = ({
  render,
  onLoadCallbackName,
  language,
  onLoad,
  useEnterprise,
  scriptProps: { nonce = '', defer = false, async = false, id = '', appendTo } = {},
}) => {
  const scriptId = id || 'google-recaptcha-v3'

  if (isScriptInjected(scriptId)) {
    onLoad()
    return
  }

  const googleRecaptchaScript = generateGoogleRecaptchaScript(useEnterprise)
  const documentScript = document.createElement('script')
  documentScript.id = scriptId
  documentScript.src = `${googleRecaptchaScript}?render=${render}${
    render === 'explicit' ? `&onload=${onLoadCallbackName}` : ''
  }${language ? `&hl=${language}` : ''}`

  if (nonce) {
    documentScript.nonce = nonce
  }

  documentScript.defer = !!defer
  documentScript.async = !!async
  documentScript.addEventListener('load', onLoad)
  const targetElement = appendTo === 'body' ? document.body : document.querySelectorAll('head')[0]
  targetElement.append(documentScript)
}

export const cleanGoogleRecaptcha = (scriptId, container) => {
  // remove badge
  cleanBadge(container)

  // remove old config from window
  window.___grecaptcha_cfg = undefined

  // remove script
  const script = document.querySelector(`#${scriptId}`)
  if (script) {
    script.remove()
  }

  cleanGstaticRecaptchaScript()
}
