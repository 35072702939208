import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Button, Modal, Loader } from 'semantic-ui-react'
import { Form } from 'formsy-semantic-ui-react'
import { NotificationManager } from 'react-notifications'

import { crypho } from '../../../crypho.core/xmpp'
import { getContactFullnameValueLabelPairs } from '../../../crypho.core/store/modules/roster/selectors'
import { fetchSpaceMetadata } from '../../../crypho.core/store/modules/space'
import store from '../../../crypho.core/store'
import { createActionItem } from '../../../crypho.core/infostream'
import { MultiSelect, errorLabel } from '../../../UI/forms'

class CreateGroupForm extends Component {
  state = {
    open: false,
    title: '',
    members: [],
    processing: false,
    contactValueLabels: [],
  }

  open = () => {
    const state = store.getState()
    const contactValueLabels = getContactFullnameValueLabelPairs(state)
    this.setState({ open: true, contactValueLabels })
  }

  close = () => this.setState({ open: false })

  create = async () => {
    const { title, members, processing } = this.state
    const { history } = this.props
    if (processing) return
    this.setState({ processing: true })
    const memberIds = members.map((member) => member.value)
    let spaceId
    try {
      spaceId = await crypho.createGroupSpace(memberIds, title)
    } catch (error) {
      this.setState({ processing: false })
      // eslint-disable-next-line no-console
      console.error(error)
      NotificationManager.error('You have reached the group limit of your account. Contact Crypho to upgrade.', '')
      this.close()
      return
    }

    const spaceCreated = async () => {
      // Fetch space meta and the space to create the action item.
      await fetchSpaceMetadata(spaceId)
      createActionItem({}, 'spaceCreated', spaceId)

      this.setState({ processing: false, open: false, title: '', members: [] })
      history.push(`/groups`)
    }

    if (store.getState().spaces.allIds.includes(spaceId)) {
      spaceCreated()
    } else {
      // subscribe to the store in case we received the headline message before the iq result
      const unsubscribe = store.subscribe(() => {
        const state = store.getState()
        if (state.spaces.allIds.includes(spaceId) && state.spaces.byId[spaceId]) {
          unsubscribe()
          spaceCreated()
        }
      })
    }
  }

  render() {
    const { open, members, processing, contactValueLabels } = this.state

    return (
      <Modal closeIcon open={open} closeOnEscape={true} closeOnRootNodeClick={false} onClose={this.close}>
        <Modal.Header>Create group</Modal.Header>
        <Modal.Content>
          <p>
            Share and communicate privately in groups. Groups are only visible to group members. Only the group members
            have the encryption keys for the group. Adding a member instantly adds them to the group.
          </p>

          <Loader disabled={!processing} />

          <Form onValidSubmit={this.create}>
            <Form.Input
              name="title"
              onChange={(event_) => {
                this.setState({ title: event_.target.value })
              }}
              placeholder="Title"
              rows={1}
              label="Group title"
            />
            <MultiSelect
              label="Group members"
              value={members}
              name="members"
              isMulti
              placeholder="Select"
              onChange={(value) => this.setState({ members: value })}
              options={contactValueLabels}
              errorLabel={errorLabel}
              validations="isRequiredMultiSelect"
              validationErrors={{
                isRequiredMultiSelect: 'You need to specify converation members',
              }}
              className="c-groupMembers"
            />
            <Modal.Actions>
              <Button floated="right" name="create-group" disabled={processing}>
                Create group
              </Button>
            </Modal.Actions>
          </Form>
        </Modal.Content>
      </Modal>
    )
  }
}

CreateGroupForm.propTypes = {
  history: PropTypes.object.isRequired,
}

export default CreateGroupForm
