import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { Menu, Dropdown, Image, Icon, Label } from 'semantic-ui-react'
import { NavLink, Link, withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { compose } from 'recompose'
import store from '../crypho.core/store'
import { getUnreadInContacts, getUnreadInGroups } from '../crypho.core/store/modules/space/selectors'
import { getReceivedInvitationCount } from '../crypho.core/store/modules/invitations/selectors'
import { deleteSession } from '../session'
import * as XMPP from '../crypho.core/xmpp'

const options = [
  {
    key: 'profile',
    text: 'My Profile',
    as: Link,
    to: '/profile',
  },
  {
    key: 'devices',
    text: 'Connected Devices',
    as: Link,
    to: '/devices',
  },
  {
    key: 'passphrase',
    text: 'Change Passphrase',
    as: Link,
    to: '/passphrase',
  },
  {
    key: 'recovery-key',
    text: 'Recovery key',
    as: Link,
    to: '/recovery-key',
  },
  {
    key: 'verification',
    text: 'Verification',
    as: Link,
    to: '/verification',
  },
  {
    key: 'settings',
    text: 'Settings',
    as: Link,
    to: '/settings',
  },
]

class Topbar extends PureComponent {
  logout = async () => {
    const { history } = this.props
    await store.dispatch(XMPP.setXmppPresence(false)).catch(() => {})
    await XMPP.stop()
    await deleteSession()
    history.push('/')
  }

  render() {
    const { apiUrl, userId, fullname, unreadInContacts, unreadInGroups, receivedInvitationCount } = this.props

    const trigger = (
      <div>
        <span className="accountOwner">{fullname}</span>
        {userId ? <Image avatar src={`${apiUrl}/avatar/${userId}`} /> : null}
      </div>
    )
    return (
      <div id="topBar">
        <Menu secondary>
          <Menu.Item name="home" as={NavLink} to="/contacts">
            <div className="topLogo" title="CRYPHO" />
            <div className="topLogotype" />
          </Menu.Item>
          <Menu.Menu className="centeredMenu">
            <Menu.Item name="contacts" as={NavLink} to="/contacts">
              {unreadInContacts > 0 ? <Label circular floating /> : null}
              <Icon name="user" title="Contacts" />
              <span>Contacts</span>
              {unreadInContacts > 0 ? <Label>{unreadInContacts}</Label> : null}
            </Menu.Item>
            <Menu.Item name="groups" as={NavLink} to="/groups">
              {unreadInGroups > 0 ? <Label circular floating /> : null}
              <Icon name="users" title="Groups" />
              <span> Groups</span>
              {unreadInGroups > 0 ? <Label>{unreadInGroups}</Label> : null}
            </Menu.Item>
            <Menu.Item name="company" as={NavLink} to="/company">
              <Icon name="building" title="Company" />
              <span> Company</span>
            </Menu.Item>
          </Menu.Menu>
          <Menu.Item position="right">
            <Dropdown id="myAccount" trigger={trigger} icon={null}>
              <Dropdown.Menu>
                <Menu.Header name="myaccount">My account</Menu.Header>
                <Dropdown.Divider />
                <Menu.Item as={NavLink} name="invitations" to="/invitations">
                  <Icon name="mail" color="grey" />
                  Invitations
                  {receivedInvitationCount > 0 ? <Label>{receivedInvitationCount}</Label> : null}
                </Menu.Item>

                {options.map((options) => (
                  <Dropdown.Item key={options.text} {...options} />
                ))}
                <Menu.Item as={NavLink} name="changelog" to="/changelog" className="menuBackground">
                  Changelog
                </Menu.Item>
                <Menu.Item as={NavLink} name="support" to="/support" className="menuBackground">
                  Help and Support
                </Menu.Item>
                <Menu.Item onClick={() => this.logout()} name="Logout">
                  <Icon name="log out" />
                  Log out
                </Menu.Item>
              </Dropdown.Menu>
            </Dropdown>
          </Menu.Item>
        </Menu>
      </div>
    )
  }
}

Topbar.propTypes = {
  apiUrl: PropTypes.string,
  fullname: PropTypes.string,
  history: PropTypes.shape(),
  receivedInvitationCount: PropTypes.number,
  unreadInContacts: PropTypes.number,
  unreadInGroups: PropTypes.number,
  userId: PropTypes.string,
}

const mapStateToProperties = (state) => {
  const { identity } = state
  const { id } = identity
  const vcard = state.vcards.byId[id]

  if (!vcard) return {}
  return {
    apiUrl: state.config.apiUrl,
    userId: id,
    fullname: vcard.FN,
    unreadInContacts: getUnreadInContacts(state),
    unreadInGroups: getUnreadInGroups(state),
    receivedInvitationCount: getReceivedInvitationCount(state),
  }
}

const mapDispatchToProperties = {}

const connector = (container) => compose(withRouter, connect(mapStateToProperties, mapDispatchToProperties))(container)

export default connector(Topbar)
