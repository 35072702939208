import moment from 'moment'

/**
 * Return a hex representation of a buffer.
 * This function assumes the data length is a multiple of 4 bytes.
 *
 * @param {ArrayBuffer} buffer
 * @return {string} HEX representation
 *
 */
export function hex(buffer) {
  const hexCodes = []
  const view = new DataView(buffer)
  for (let i = 0; i < view.byteLength; i += 4) {
    const stringValue = view.getUint32(i).toString(16)
    hexCodes.push(stringValue.padStart(8, '0'))
  }
  return hexCodes.join('')
}

/**
 * Calculate a SHA-256 hash.
 *
 * @param {ArrayBuffer} data data to hash
 * @return {string}
 */
export async function sha256(data) {
  const h = await crypto.subtle.digest('SHA-256', data)
  return hex(h)
}

/**
 * UTF-8 encode a string and convert it to an ArrayBuffer.
 *
 * This can be useful when needing to encrypt or hash strings.
 *
 * @param {string} input Input string to encode
 * @return {ArrayBuffer}
 */
export function stringToBuffer(input) {
  const utf8 = new TextEncoder().encode(input)
  return utf8.buffer
}

/**
 * Promise wrapper for readFile
 *
 * This is a minimal wrapper around FileReader.readAsArrayBuffer which
 * return a promise for the resulting data.
 *
 * NOTE: this function only runs in browsers. NodeJS does not support Blob or FileReader.
 *
 * @typedef {(ev: ProgressEvent) => any} ProgressEventHandler
 * @param {Blob} blob data source to read from
 * @param {null | ProgressEventHandler} onProgress progresss event handler
 * @return {Promise<ArrayBuffer>}
 *
 */
export function readFile(blob, onProgress = null) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    if (onProgress) {
      reader.addEventListener('progress', onProgress)
    }
    reader.onloadend = () => {
      resolve(reader.result)
    }
    reader.addEventListener('error', () => reject())
    reader.readAsArrayBuffer(blob)
  })
}

/**
 * Combine multiple array buffers
 *
 * @param  {...ArrayBuffer} buffers
 */
export function combineBuffers(...buffers) {
  const totalSize = buffers.reduce((a, b) => a + b.byteLength, 0)
  const buffer = new Uint8Array(totalSize)
  let offset = 0
  let b
  for (b of buffers) {
    buffer.set(new Uint8Array(b), offset)
    offset += b.byteLength
  }
  return buffer.buffer
}

/**
  Determine if a link may be converted to a link the user can
  click on.

  This function should *always* be used as validator when
  using linkify.

  ```html
  <Linkify options={{validate: allowLink}}>
    ...
  </Linkify>
  ```
  @param {string} url
  @param {'email'|'url'} urlType
  @returns {boolean}
  */
export function allowLink(url, urlType) {
  return urlType === 'email' || (urlType === 'url' && /^(https?|ftp|sftp):/.test(url))
}

const TODAY = moment(new Date()).format('YYYYMMDD')

/**
 * Format a timestamp for display
 *
 * @param {any} ts Timestamp to format
 * @returns {string}
 */
export function formatTimestamp(ts) {
  const m = moment(ts)
  if (m.format('YYYYMMDD') === TODAY) {
    return m.format('HH:mm')
  }
  if (moment().year() === m.year()) {
    return m.format('MMM DD, HH:mm')
  }
  return m.format('MMM DD YYYY, HH:mm')
}
