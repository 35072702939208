import React from 'react'
import { Link } from 'react-router-dom'
import { Header } from 'semantic-ui-react'

const NotFoundScene = () => (
  <div className="notFound">
    <div className="cryphoLogo" />
    <Header as="h2">404 - Page not found</Header>
    <p>Something went wrong. We could not find the page you were looking for.</p>
    <Link to="/contacts"> Back to Crypho</Link>
  </div>
)

export default NotFoundScene
