import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Item, Header, Message, Image, Button } from 'semantic-ui-react'
import moment from 'moment'
import PropTypes from 'prop-types'

import { NotificationManager } from 'react-notifications'
import { crypho } from '../../crypho.core/xmpp'
import { fetchDevicesFromServer } from '../../crypho.core/store/modules/identity'
import Topbar from '../../UI/Topbar'

const Device = ({ device }) => {
  const [processing, setProcessing] = useState(false)
  const dispatch = useDispatch()

  const removeDevice = async () => {
    if (processing) return
    setProcessing(true)

    try {
      await crypho.unlinkDevice(device.device_id)
      await dispatch(fetchDevicesFromServer())
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error)
      NotificationManager.error('There was an error unlinking the device', '')
    }
  }

  const lastActivity = moment(device.updated_at).format('MMM DD YYYY')
  return (
    <Item key={device}>
      <Item.Content className="deviceModel">{device.model}</Item.Content>
      <Item.Description>
        last access on {lastActivity}
        <Button color="red" circular icon="remove" className="unlinkDevice" onClick={() => removeDevice()} />
      </Item.Description>
    </Item>
  )
}

Device.propTypes = {
  device: PropTypes.shape(),
}

const Devices = () => {
  const [twoFactorData, setTwoFactorData] = useState({})
  const devices = useSelector((state) => state.identity.devices)
  const dispatch = useDispatch()

  useEffect(() => {
    ;(async () => {
      try {
        await dispatch(fetchDevicesFromServer())
        const twoFactorData = await crypho.getTwoFactorData()
        setTwoFactorData(twoFactorData)
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error)
      }
    })()
  }, [])

  const qrCode = twoFactorData && twoFactorData.qr_code
  return (
    <div className="authenticatedScene">
      <Topbar />
      <div className="accountOptions">
        <Header as="h3">Add a new phone</Header>
        <p className="description">
          To link Crypho on a new phone, install the Crypho app, then scan the QR code below with the Crypho app to link
          it to your account.
        </p>
        <Image src={qrCode} className="addDeviceQR" />

        {devices.length > 0 ? (
          <div>
            <Header as="h3">Linked devices</Header>

            <Message negative compact>
              Click the icon next to your phone to unlink it and stop receiving notifications to it.
              <p>
                If your phone has been lost or stolen, you <strong>need</strong> to{' '}
                <a href="/passphrase">change your passphrase</a> immediately.
              </p>
            </Message>

            <Item.Group divided>
              {devices.map((device) => (
                <Device device={device} key={device.device_id} />
              ))}
            </Item.Group>
          </div>
        ) : null}
      </div>
    </div>
  )
}

Devices.propTypes = {
  devices: PropTypes.array,
}

export default Devices
