import * as Sentry from '@sentry/core'
import store from './store'
import { setApiUrl, updateConfiguration } from './store/modules/config'
import { CryphoAPI } from './api'

async function fetchConfiguration(apiUrl) {
  const API = new CryphoAPI(apiUrl)

  try {
    const cfg = await API.get('/config')
    store.dispatch(
      updateConfiguration({
        apiUrl: cfg.api_url,
        siteUrl: cfg.site_url,
        xmppDomain: cfg.xmpp_domain,
        xmppWebsocketUrl: cfg.xmpp_websocket_url,
        salt: cfg.salt ?? '',
        recaptchaWebSiteKey: cfg.recaptcha_web_sitekey,
        recaptchaAndroidSiteKey: cfg.recaptcha_ios_sitekey,
        recaptchaIOSSiteKey: cfg.recaptcha_android_sitekey,
        recaptchaEnabled: cfg.recaptcha_enabled,
      }),
    )
  } catch (error) {
    Sentry.captureException(error)
    // At least try to set some sane defaults
    store.dispatch(setApiUrl(apiUrl))
    // eslint-disable-next-line no-console
    console.error('Error loading configuration', error)
  }
}

/**
 * Request API URL from the Electron settings, and use that to fetch the
 * Crypho instance configuration and update the redux store.
 */
export const configureDomainsFromDesktop = () => {
  const ipcRenderer = window.require('electron').ipcRenderer
  return new Promise((resolve) => {
    ipcRenderer.on('desktop:getAppDomain', (ev, apiUrl) => {
      fetchConfiguration(apiUrl)
        .then(resolve)
        .catch(() => {})
    })
    ipcRenderer.send('desktop:getAppDomain')
  })
}

/**
 * Load the Crypho instance configuration from the API, and update the
 * configuration in the redux store.
 */
export function loadConfiguration(apiUrl) {
  if (!apiUrl) {
    apiUrl = store.getState().config.apiUrl
  }
  return fetchConfiguration(apiUrl)
}
