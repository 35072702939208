import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { Button, Grid, Header, Popup } from 'semantic-ui-react'

import { getGroupSpaces } from '../../../crypho.core/store/modules/space/selectors'
import SpaceList from '../spacelist'
import SpaceScene from '../SpaceScene'
import CreateGroupForm from './creategroup'
import { ColorFilter } from '../../../UI/widgets/color'
import SpaceSearch from '../../../UI/widgets/spacesearch'
import Topbar from '../../../UI/Topbar'

class GroupsScene extends PureComponent {
  state = {
    filter: -1,
  }

  constructor(properties) {
    super(properties)
    this.createGroupFormReference = React.createRef()
  }
  componentDidUpdate() {
    // We want to replace the history url if we are going to switch to the latest space
    const { spaceId, history } = this.props
    let { spaces } = this.props
    const { filter } = this.state

    if (filter !== -1) spaces = spaces.filter((space) => space.color === filter)

    if (spaces.length > 0 && !spaceId) {
      history.replace(`/groups/${spaces[0].id}`)
    }
  }

  onFilterUpdate = (color) => {
    const { history } = this.props
    const { filter } = this.state
    if (filter === color) this.setState({ filter: -1 })
    else this.setState({ filter: color })
    history.push('/groups')
  }

  render() {
    const { spaceId, contactValueLabels, history } = this.props
    let { spaces } = this.props
    const totalCount = spaces.length
    const { filter } = this.state
    if (filter !== -1) {
      spaces = spaces.filter((space) => space.color === filter)
    }
    const count = spaces.length

    return (
      <div className="authenticatedScene">
        <Topbar />
        <div id="group-scene">
          <div className={count === 0 ? 'fullWidth' : 'list-container'}>
            <Grid className={count === 0 ? 'fullWidth spacelistHeader' : 'spacelistHeader'}>
              <Grid.Column width={filter === -1 ? 10 : 16}>
                <div className="helpContainer">
                  <Popup
                    trigger={
                      <Header as="h3">{filter !== -1 ? `${count} of ${totalCount} groups` : `${count} groups`} </Header>
                    }
                    wide="very"
                  >
                    A group is a persistent chat room with a shared file folder. Only invited members have access to the
                    group conversation, know of its existence, and see who else is a member.
                  </Popup>
                </div>
                <ColorFilter selected={filter} onUpdate={this.onFilterUpdate} />
              </Grid.Column>

              {filter === -1 ? (
                <Grid.Column width={6} className="buttonContainer">
                  <span>Create</span>
                  <CreateGroupForm ref={this.createGroupFormReference} history={history} options={contactValueLabels} />
                  <Button
                    color="blue"
                    circular
                    icon="plus"
                    className="spacelistHeaderButton"
                    title="Create group"
                    onClick={this.openCreateGroupForm}
                  />
                </Grid.Column>
              ) : null}
              <Grid.Column width={16} className={spaces.length === 0 ? 'fullWidth spaceSearch' : 'spaceSearch'}>
                <SpaceSearch type="groups" />
              </Grid.Column>
            </Grid>
            {spaces.length !== 0 ? (
              <SpaceList spaces={spaces} />
            ) : (
              <div className="descriptionContainer">
                {filter === -1 ? (
                  [
                    <h3 key="info-title">You have no groups.</h3>,
                    <p className="description" key="info-description">
                      Click on the plus button to create groups.
                      <br />
                      If you want to invite people to the group that are not your contacts, invite them to be your
                      contacts first.
                    </p>,
                  ]
                ) : (
                  <p className="description">There are no groups with this color.</p>
                )}
              </div>
            )}
          </div>
          {spaceId && spaces.length > 0 ? (
            <SpaceScene spaceId={spaceId} />
          ) : (
            (spaces.length && <SpaceScene spaceId={spaces[0].id} />) || null
          )}
        </div>
      </div>
    )
  }

  openCreateGroupForm = () => this.createGroupFormReference.current.open()
}

GroupsScene.propTypes = {
  contactValueLabels: PropTypes.array,
  history: PropTypes.shape(),
  space: PropTypes.shape(),
  spaceId: PropTypes.string,
  spaces: PropTypes.array,
}

const mapStateToProperties = (state, ownProperties) => {
  const spaceId = ownProperties.match.params.spaceId
  return {
    spaces: getGroupSpaces(state),
    spaceId,
  }
}

const mapDispatchToProperties = {}

const connector = (container) => connect(mapStateToProperties, mapDispatchToProperties)(container)

export default connector(GroupsScene)
