import React, { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { Grid, Icon, Image, Form, Popup, Label } from 'semantic-ui-react'
import filesize from 'filesize'
import { basename, extname } from 'path'
import * as Sentry from '@sentry/browser'

import { formatTimestamp } from '../../../../utils'
import { getObjectURLForFile, deleteFile, downloadAndSaveFile, setFileName } from '../../../../file-handling'
import ImageViewer from '../../../../UI/imageviewer'
import RosterAvatar from '../../../../UI/widgets/rosteravatar'
import { mimeTypeToIcon } from '../../../../UI/utils'

function GalleryItem({ id, spaceId }) {
  const vaultItem = useSelector((state) => state.pubsub.itemsById[id])
  const authorId = vaultItem.author.split('@')[0]
  const myJID = useSelector((state) => state.identity.jid)
  const vcards = useSelector((state) => state.vcards).byId
  const extension = extname(vaultItem.name)
  const [filename, setFilename] = useState(basename(vaultItem.name, extension))
  const [isRenaming, setIsRenaming] = useState(false)
  const [isDeleting, setIsDeleting] = useState(false)
  const [thumbnail, setThumbnail] = useState()
  const imageViewerReference = useRef()
  const canOperateOnFile = vaultItem.author === myJID
  const handleDownload = () => downloadAndSaveFile(spaceId, id, vaultItem)
  const handleOpen = vaultItem.type.startsWith('image/') ? () => imageViewerReference.current.open() : handleDownload
  const handleDelete = async () => {
    await deleteFile(spaceId, id, vaultItem)
    setIsDeleting(false)
  }
  const handleRename = async () => {
    await setFileName(spaceId, id, vaultItem, `${filename}${extension}`)
    setIsRenaming(false)
  }
  const handleKeyDown = (evt) => {
    if (evt.key === 'Enter') {
      return handleRename()
    }
  }
  useEffect(() => {
    if (vaultItem.thumbnail) {
      getObjectURLForFile(spaceId, vaultItem.fkid, vaultItem.thumbnail)
        .then(setThumbnail)
        .catch(Sentry.captureException)
    }
  }, [spaceId, vaultItem])

  const downloadedByText =
    vaultItem.downloadedBy && vaultItem.downloadedBy.length > 0
      ? vaultItem.downloadedBy
          .map((userId) => (vcards[userId] && vcards[userId].FN) || '')
          .sort()
          .join(', ')
      : ''

  return (
    <React.Fragment>
      <ImageViewer spaceId={spaceId} item={vaultItem} itemId={id} ref={imageViewerReference} />
      <Grid.Column className={!isDeleting ? null : 'destroyBubble'}>
        <div className={`galleryItem ${thumbnail ? '' : 'loading'}`} onClick={handleOpen}>
          {thumbnail ? (
            <Image draggable={false} className="thumbnail" src={thumbnail} />
          ) : (
            <Icon link className={`${mimeTypeToIcon(vaultItem.type)} fileIcons `} onClick={handleOpen} />
          )}
        </div>
        <div className="revealedInfo">
          {isDeleting ? (
            <Label as="a" onClick={handleDelete} className="deleteImage">
              Delete <Icon color="red" name="trash" />
            </Label>
          ) : null}
          <Grid.Column floated="right" className="fileOptions">
            {!isDeleting ? <Icon className="download" onClick={handleDownload} title="Download file" /> : null}
            {downloadedByText && !isDeleting ? (
              <Popup
                hoverable={true}
                on="click"
                trigger={
                  <Icon
                    id="c-seen"
                    name="eye"
                    title={vaultItem.type.includes('image') ? `Viewed by` : `Downloaded by`}
                  />
                }
                content={
                  vaultItem.type.includes('image')
                    ? `Viewed by : ${downloadedByText}`
                    : `Downloaded by: ${downloadedByText}`
                }
                basic
              />
            ) : null}
            {canOperateOnFile && !isDeleting ? (
              <div key="renameAction">
                {isRenaming ? (
                  <Icon name="remove" onClick={() => setIsRenaming(false)} />
                ) : (
                  <Icon
                    key="file-rename"
                    className="edit c-rename-file"
                    title="Rename file"
                    onClick={() => setIsRenaming(true)}
                  />
                )}
              </div>
            ) : null}
            {canOperateOnFile ? (
              <div key="deleteAction" onClick={() => setIsDeleting(!isDeleting)}>
                {isDeleting ? (
                  <Icon name="remove" />
                ) : (
                  <Icon key="file-delete" className="c-delete-file" id="c-delete" name="trash" title="Delete file" />
                )}
              </div>
            ) : null}
          </Grid.Column>
        </div>
        <Grid.Row>
          {isRenaming ? (
            <Form.Input
              icon={<Icon name="check" color="green" link onClick={handleRename} />}
              name="rename"
              type="text"
              placeholder="New file name"
              required
              value={filename}
              onChange={(event) => setFilename(event.target.value)}
              onKeyDown={handleKeyDown}
            />
          ) : (
            <Grid className="galleryItemInfo">
              <Grid.Column width={12}>
                <span onClick={handleOpen}> {vaultItem.name}</span>
              </Grid.Column>
              <Grid.Column width={4}>
                <RosterAvatar userId={authorId} className="galleryInfoAvatar" />
              </Grid.Column>
            </Grid>
          )}
        </Grid.Row>
        <Grid.Column>
          <span className="galleryUpdatedOn">{formatTimestamp(vaultItem.updated)}</span>
          <span className="filesize"> {filesize(vaultItem.size)}</span>
        </Grid.Column>
      </Grid.Column>
    </React.Fragment>
  )
}

GalleryItem.propTypes = {
  spaceId: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
}

export default GalleryItem
