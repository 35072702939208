import husher from './husher'
import store from './store'
import { CryphoAPI } from './api'

export async function authenticate(
  email,
  password,
  token,
  scryptSalt,
  recaptcha_token = '',
  platform = 'web',
  remember = false,
) {
  const state = store.getState()
  const { apiUrl } = state.config
  const api = new CryphoAPI(apiUrl)
  const strengthened = await husher._strengthenScrypt(password, {
    salt: husher._b64.toBits(scryptSalt),
  })
  const authHash = husher._b64.fromBits(husher._hash(strengthened.key2))

  const response = await api.post('/auth/authenticate', {
    email,
    token,
    authHash,
    remember,
    recaptcha_token,
    platform,
    version: '1',
  })

  response.scrypt = { scryptSalt }
  await global.husher.fromJSON(password, response)
  return response
}
