import React, { forwardRef, useState, useImperativeHandle, useRef } from 'react'
import { Button, Confirm } from 'semantic-ui-react'
import { Form } from 'formsy-semantic-ui-react'
import PropTypes from 'prop-types'
import EmailList from './EmailList'
import { errorLabel } from '../../UI/forms'

const EmailsEditor = ({ onSave, onCancel }, ref) => {
  const [emails, setEmails] = useState([])
  const [primaryEmail, setPrimaryEmail] = useState('')
  const [open, setOpen] = useState(false)
  const formRef = useRef(null)

  useImperativeHandle(ref, () => ({
    open: (emails, primaryEmail) => {
      setEmails(emails)
      setPrimaryEmail(primaryEmail)
      setOpen(true)
    },
    close: () => setOpen(false),
  }))

  const emailDoesNotExist = (email) => {
    return !emails.includes(email.email)
  }

  const addEmailHandler = (data) => {
    const { email } = data
    setEmails((emails) => {
      return [email, ...emails]
    })
    formRef.current.reset()
  }

  const deleteEmailHandler = (email) => {
    setEmails((emails) => {
      return emails.filter((e) => e !== email)
    })
  }

  return (
    <div id="system-admin-container">
      <Confirm
        size="small"
        className="input"
        closeIcon
        open={open}
        closeOnRootNodeClick={false}
        content={[
          <>
            <Form ref={formRef} onValidSubmit={addEmailHandler}>
              <Form.Input
                name="email"
                type="email"
                placeholder="Email"
                required
                validations={{ isEmail: true, emailDoesNotExist }}
                errorLabel={errorLabel}
                validationErrors={{
                  emailDoesNotExist: 'Email already exists in the list',
                  isEmail: 'This is not a valid email',
                  isDefaultRequiredValue: 'Email is required',
                }}
              />
              <Button type="submit"> Add Email </Button>
            </Form>
            <EmailList emails={emails} onDelete={(e) => deleteEmailHandler(e)} primaryEmail={primaryEmail} />
          </>,
        ]}
        header="Edit emails"
        confirmButton="Save"
        cancelButton={null}
        onCancel={onCancel}
        onConfirm={() => onSave(emails)}
      />
    </div>
  )
}

EmailsEditor.propTypes = {
  onSave: PropTypes.func,
  onCancel: PropTypes.func,
}

export default forwardRef(EmailsEditor)
