import React from 'react'
import { Header, Icon, Button, Grid, Message, Form, GridColumn } from 'semantic-ui-react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { NotificationManager } from 'react-notifications'

import { crypho } from '../../crypho.core/xmpp'
import { fetchAccount } from '../../crypho.core/store/modules/account'
import CompanyMemberList from './companymemberlist'
import CompanyInvitationList from './companyinvitationlist'
import AddMemberForm from './addaccountmember'
import Topbar from '../../UI/Topbar'

class CompanyScene extends React.Component {
  state = {
    renamingCompany: false,
    newTitle: '',
  }

  constructor(properties) {
    super(properties)
    this.addMemberFormReference = React.createRef()
  }

  onRename = async () => {
    const { newTitle, processing } = this.state
    const { account, fetchAccount } = this.props
    const { title } = account

    if (title === newTitle || newTitle === '') return
    if (processing) return
    this.setState({ processing: true })
    try {
      await crypho.updateAccount({ title: newTitle })
      await fetchAccount()
      await NotificationManager.success('Your company has been renamed', '')
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error)
      NotificationManager.error('There was an error renaming the company', '')
    }
    this.setState({ newTitle: '' })
    this.setState({ processing: false })
  }

  respondToAccountInvitation = async (response) => {
    const { processing } = this.state
    const { invitation } = this.props
    if (processing) return
    this.setState({ processing: true })
    try {
      await crypho.respondToAccountInvitation(invitation.accountId, response)
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error)
      NotificationManager.error('There was an error responding to the invitation', '')
    }
    this.setState({ processing: false })
  }

  render() {
    const { account, userId, ownerVCard, memberCount, invitation } = this.props
    const { title, plan } = account
    const { newTitle } = this.state
    const isAdmin = account.admins.includes(userId)

    if (!account || !ownerVCard) return null
    const isFull = account.plan.memberLimit === memberCount
    const isFree = ['free', 'beta'].includes(account.plan.name)
    const isPersonal = account.plan.memberLimit === 1
    return (
      <div id="company-scene" className="authenticatedScene">
        <Topbar />
        <div id="company-container">
          <div>
            <Grid className="companyHeader">
              <Grid.Column width={!isFree && isAdmin ? 12 : 16}>
                <Header as="h3">
                  {!isFree && isAdmin
                    ? [
                        <div className="renameCompany" key="rename-company">
                          {this.state.renamingCompany ? (
                            <Icon
                              link
                              name="remove"
                              onClick={() => {
                                this.setState({
                                  renamingCompany: false,
                                  newTitle: '',
                                })
                              }}
                            />
                          ) : (
                            <Icon
                              title="Edit company name"
                              className="edit"
                              size="large"
                              key="renameAccountIcon"
                              onClick={() => {
                                this.setState({
                                  newTitle: title,
                                  renamingCompany: true,
                                })
                              }}
                            />
                          )}
                        </div>,
                      ]
                    : null}
                  {this.state.renamingCompany ? (
                    <Form.Input
                      icon={
                        <Icon
                          name="check"
                          color="green"
                          link
                          onClick={() => {
                            this.onRename()
                            this.setState({ renamingCompany: false })
                          }}
                        />
                      }
                      className="inlineInput"
                      name="company-title"
                      type="text"
                      required
                      value={newTitle ? newTitle : 'Edit company name'}
                      onChange={(event_) => this.setState({ newTitle: event_.target.value })}
                    />
                  ) : (
                    <span> {isFree ? account.plan.title : newTitle || title}</span>
                  )}
                </Header>
                <Header.Subheader key="subscriptionPlan">
                  {`Subscription type: ${plan.title}`}
                  <br />
                  {plan.description}
                  <br />
                  {`Licences used: ${memberCount} of ${account.plan.memberLimit}`}
                </Header.Subheader>
                <Header as="h5">{`This account is managed by ${ownerVCard.FN}.`}</Header>
                {!isFree && isFull && !isPersonal ? (
                  <Message negative compact>
                    This account has reached its member limit
                  </Message>
                ) : null}
              </Grid.Column>

              <Grid.Column width={4} className="buttonContainer">
                {!isFree && isAdmin && !isFull
                  ? [
                      <AddMemberForm key="addMemberForm" ref={this.addMemberFormReference} />,
                      <Button
                        key="addMember"
                        id="c-add-account-member"
                        className="companyHeaderButton"
                        title="Add to company"
                        onClick={() => this.addMemberFormReference.current.open()}
                      >
                        Invite company member
                      </Button>,
                    ]
                  : null}
              </Grid.Column>
            </Grid>
          </div>
          {invitation ? (
            <Grid className="companyHeader">
              <GridColumn width={10}>
                <Header as="h3"> You have a pending invitation to join the account of {invitation.title}</Header>
                <Header.Subheader>
                  A business account in Crypho offers additional functionality. With a Crypho business user account,
                  your administrator has the ability to delete your account (typically when you leave your job),
                  including all your contacts and groups you own. Your administrator can never read what you share in
                  Crypho.
                </Header.Subheader>
              </GridColumn>
              <Grid.Column width={6} className="buttonContainer">
                <Button
                  id="c-accept-account-invitation"
                  className="companyHeaderButton"
                  title="Accept invitation"
                  onClick={() => this.respondToAccountInvitation(true)}
                >
                  Accept invitation
                </Button>
                <Button
                  id="c-reject-account-invitation"
                  className="companyHeaderButton negative"
                  title="Reject invitation"
                  onClick={() => this.respondToAccountInvitation(false)}
                >
                  Reject invitation
                </Button>
              </Grid.Column>
            </Grid>
          ) : null}

          {!isFree ? <CompanyMemberList /> : null}
          {!isFree ? <CompanyInvitationList /> : null}
        </div>
      </div>
    )
  }
}

CompanyScene.propTypes = {
  account: PropTypes.shape(),
  memberCount: PropTypes.number,
  ownerVCard: PropTypes.shape(),
  userId: PropTypes.string,
  fetchAccount: PropTypes.func.isRequired,
  xmppDomain: PropTypes.string.isRequired,
  invitation: PropTypes.shape(),
}

const mapStateToProperties = (state) => {
  const { account, identity } = state
  const userId = identity.id

  return {
    account,
    memberCount: account.members.length + ((account.invitations && account.invitations.length) || 0),
    ownerVCard: state.vcards.byId[account.owner],
    userId,
    xmppDomain: state.config.xmppDomain,
    invitation: state.account.invitationsToAccount,
  }
}

const mapDispatchToProperties = {
  fetchAccount,
}

const connector = (container) => connect(mapStateToProperties, mapDispatchToProperties)(container)

export default connector(CompanyScene)
