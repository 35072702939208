import React from 'react'
import PropTypes from 'prop-types'
import { Item } from 'semantic-ui-react'
import { Visibility } from 'semantic-ui-react'

import SpaceListItem from './spacelistitem'
import './space.css'

const _spaceBatchSize = 50

export default class SpaceList extends React.PureComponent {
  constructor(...arguments_) {
    super(...arguments_)
    this.containerReference = React.createRef()
    this.state = {
      spacesShown: _spaceBatchSize,
    }
  }

  onScroll = async () => {
    const container = this.containerReference.current
    if (container.scrollHeight - container.offsetHeight - container.scrollTop !== 0) {
      return
    }
    const { spaces } = this.props
    const { spacesShown } = this.state
    if (spacesShown >= spaces.length) return
    this.setState({ spacesShown: spacesShown + _spaceBatchSize })
  }

  render() {
    const { spacesShown } = this.state
    const { spaces } = this.props
    const spacesToRender = spaces.slice(0, spacesShown)

    return (
      <div className="spaceList" ref={this.containerReference} onScroll={this.onScroll}>
        <Item.Group divided>
          {spacesToRender.map((space) => {
            return <SpaceListItem key={space.id} space={space} />
          })}
          <Visibility
            style={{ height: '1px' }}
            context={this.containerReference.current}
            once={false}
            onOnScreen={() => {
              this.setState({ nearBottom: true })
            }}
            onOffScreen={() => {
              this.setState({ nearBottom: false })
            }}
          />
        </Item.Group>
      </div>
    )
  }
}

SpaceList.propTypes = {
  spaces: PropTypes.array,
}
