import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Modal, Icon, Loader } from 'semantic-ui-react'
import { NotificationManager } from 'react-notifications'
import { saveAs } from 'file-saver'
import { CryphoAPI } from '../crypho.core/api'
import { getKeyById } from '../crypho.core/space'
import { markItemAsDownloaded } from '../crypho.core/vault'
import CryptoHandler from '../crypto'
import { downloadAsBlob } from '../api'

const dragingTransformDuration = 0
const normalTransformDuration = 0.3
const minimumScale = 1.25
const maximumScale = 5

class ImageViewer extends React.Component {
  constructor(properties) {
    super(properties)
    this.state = {
      open: false,
      rotation: 0,
      scale: 1,
      file: null,
      source: '',
      panX: 0,
      panY: 0,
    }
  }

  openFile = async () => {
    const { item } = this.props
    const { file } = this.state
    if (file) {
      saveAs(file, item.name)
    }
  }

  open = async () => {
    const { apiUrl, item, itemId, spaceId, myJID } = this.props
    try {
      this.setState({ open: true })

      const key = getKeyById(spaceId, item.fkid)
      const api = new CryphoAPI(apiUrl)
      const { task: decryptTask } = await new CryptoHandler(key, myJID).startDecryption(item.params)

      const file = await downloadAsBlob(api, spaceId, item, decryptTask)
      const reader = new FileReader()
      reader.addEventListener('load', (event) => {
        this.setState({ source: event.target.result, file })
        markItemAsDownloaded(spaceId, itemId)
      })
      reader.readAsDataURL(file)
    } catch (error) {
      NotificationManager.error('There was an error downloading the image.')
      return
    }
  }

  close = () => {
    window.removeEventListener('resize', this.onWindowResize)
    this.setState({
      open: false,
      rotation: 0,
      scale: 1,
      panX: 0,
      panY: 0,
    })
  }

  rotateAntiClockwise = () => {
    const { rotation } = this.state
    this.setState({
      rotation: rotation - 90,
    })
  }

  rotateClockwise = () => {
    const { rotation } = this.state
    this.setState({
      rotation: rotation + 90,
    })
  }

  onMouseWheel = (ev) => {
    if (ev.deltaY > 0) {
      this.zoomIn()
    } else {
      this.zoomOut()
    }
  }

  zoomIn = () => {
    const { scale } = this.state
    if (scale <= maximumScale) {
      this.setState({ scale: scale * 1.25 })
    }
  }

  zoomOut = () => {
    const { scale } = this.state
    if (scale >= minimumScale) {
      if (this.state.panX !== 0 && this.state.panY !== 0) {
        this.setState({ panX: 0, panY: 0 })
      }
      this.setState({ scale: scale * 0.8 })
    }
  }

  handleMouseDown = (event) => {
    this.dragging = true
    this.coords = {
      x: event.pageX,
      y: event.pageY,
    }
  }

  handleMouseUp = () => {
    this.dragging = false
    this.coords = {}
  }

  handleMouseMove = (event) => {
    //If we are dragging
    if (this.dragging) {
      event.preventDefault()
      //Get mouse change differential
      const xDiff = this.coords.x - event.pageX
      const yDiff = this.coords.y - event.pageY

      //Adjust our x,y based upon the x/y diff from before
      const newPanX = this.state.panX - xDiff
      const newPanY = this.state.panY - yDiff
      //Update to our new coordinates
      this.coords.x = event.pageX
      this.coords.y = event.pageY

      const currentImgWidth = (this.state.rotation % 180 !== 0 ? this.imageHeight : this.imageWidth) * this.state.scale
      const currentImgHeight = (this.state.rotation % 180 !== 0 ? this.imageWidth : this.imageHeight) * this.state.scale

      if (currentImgWidth > this.imageViewerWidth || currentImgHeight > this.imageViewerHeight) {
        const limitX = currentImgWidth / 2 - this.imageViewerWidth / 2
        const limitY = currentImgHeight / 2 - this.imageViewerHeight / 2
        if ((limitX > 0 && Math.abs(newPanX) > limitX) || (limitY > 0 && Math.abs(newPanY) > limitY)) {
          return
        }
        this.setState({
          panX: limitX > 0 ? newPanX : this.state.panX,
          panY: limitY > 0 ? newPanY : this.state.panY,
        })
      }
    }
  }

  onImgLoad = ({ target: img }) => {
    const imageViewer = document.querySelector('div.image.content')
    this.imageWidth = img.clientWidth
    this.imageHeight = img.clientHeight
    this.imageViewerWidth = imageViewer.clientWidth
    this.imageViewerHeight = imageViewer.clientHeight
    window.addEventListener('resize', this.onWindowResize)
  }

  onWindowResize = () => {
    const imageViewer = document.querySelector('div.image.content')
    this.imageViewerWidth = imageViewer.clientWidth
    this.imageViewerHeight = imageViewer.clientHeight
  }

  render() {
    const { scale, rotation, open, source, panX, panY } = this.state
    const { item } = this.props
    const title = item.name
    return (
      <Modal
        closeIcon
        open={open}
        closeOnEscape={true}
        closeOnRootNodeClick={false}
        onClose={this.close}
        className="imageViewer"
      >
        <Modal.Header>{title}</Modal.Header>
        <Modal.Content image>
          {!source ? (
            <Loader active />
          ) : (
            <div
              onMouseDown={this.handleMouseDown}
              onMouseUp={this.handleMouseUp}
              onMouseMove={this.handleMouseMove}
              onWheel={this.onMouseWheel}
            >
              <img
                onLoad={this.onImgLoad}
                alt=""
                style={{
                  transform: `translateX(${panX}px) translateY(${panY}px) rotate(${rotation}deg) scale(${scale})`,
                  transition: this.dragging
                    ? `transform ${dragingTransformDuration}s`
                    : `transform ${normalTransformDuration}s`,
                }}
                src={source}
              />
            </div>
          )}
        </Modal.Content>
        <Modal.Actions className="noPrint">
          <Icon name="zoom in" onClick={this.zoomIn} title="Zoom in" />
          <Icon name="zoom out" onClick={this.zoomOut} title="Zoom out" />
          <Icon flipped="horizontally" name="redo alternate" onClick={this.rotateAntiClockwise} title="Rotate left" />
          <Icon name="redo alternate" onClick={this.rotateClockwise} title="Rotate right" />
          <Icon name="download" onClick={this.openFile} title="Download" />
        </Modal.Actions>
      </Modal>
    )
  }
}

ImageViewer.propTypes = {
  myJID: PropTypes.string.isRequired,
  apiUrl: PropTypes.string.isRequired,
  item: PropTypes.shape({
    fkid: PropTypes.string.isRequired,
    params: PropTypes.shape().isRequired,
    name: PropTypes.string.isRequired,
    size: PropTypes.number.isRequired,
    type: PropTypes.string.isRequired,
  }).isRequired,
  itemId: PropTypes.string.isRequired,
  spaceId: PropTypes.string.isRequired,
}

const mapStateToProperties = (state) => ({
  myJID: state.identity.jid,
  apiUrl: state.config.apiUrl,
})

const connector = (container) => connect(mapStateToProperties, null, null, { forwardRef: true })(container)

export default connector(ImageViewer)
