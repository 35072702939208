import streamSaver from 'streamsaver'
import { saveAs } from 'file-saver'
import { sanitizeFilename, decryptStream, responseToBlob, MUST_STREAM_FROM_SIZE } from './generic'

/** @typedef {import('../../crypto/sjcl').Task} Task */
/** @typedef {import('.generic/FileItem} FileItem */

/**
 * Check if the current browser is able to download this file.
 *
 * @param {FileItem} item Item to download
 * @returns {boolean}
 */
export function canDownload(item) {
  return item.size < MUST_STREAM_FROM_SIZE || streamSaver.supported
}

let _ponyPromise = null

async function _applyPonies() {
  let ponyReadableStream = false
  let ponyWritableStream = !streamSaver.WritableStream

  try {
    new ReadableStream()
  } catch (error) {
    // This will fail on MS Edge with a `Function expected` exception
    ponyReadableStream = true
  }

  if (ponyReadableStream || ponyWritableStream) {
    const pony = await import('web-streams-polyfill/ponyfill')

    if (ponyReadableStream) {
      // eslint-disable-next-line require-atomic-updates
      streamSaver.ReadableStream = pony.ReadableStream
    }

    if (ponyWritableStream) {
      // eslint-disable-next-line require-atomic-updates
      streamSaver.WritableStream = pony.WritableStream
    }
  }
}

function applyPonies() {
  if (_ponyPromise === null) {
    _ponyPromise = _applyPonies()
  }
  return _ponyPromise
}

/**
 * Download and save a file
 *
 * @param {CryphoAPI} api CryphoAPI instance
 * @param {string} siteUrl Base URL for the website
 * @param {string} spaceId Space id where file must be downloaded from
 * @param {FileItem} item Item to download
 * @param {Task} decryptTask Decryption task
 * @returns {Promise<void>}
 */
export async function downloadFile(api, siteUrl, spaceId, item, decryptTask) {
  const response = await fetch(`${api.baseUrl}/vault/${spaceId}/${item.uid}`, {
    credentials: 'include',
  })

  const name = sanitizeFilename(item.name)

  if (item.size < MUST_STREAM_FROM_SIZE || !streamSaver.supported) {
    const file = await responseToBlob(response, item, decryptTask)
    saveAs(file, name)
    return
  }
  streamSaver.mitm = `${siteUrl}/mitm/mitm.html`

  await applyPonies()

  const fileStream = streamSaver.createWriteStream(name, { size: item.size })
  const readStream = decryptStream(response.body, decryptTask, streamSaver.ReadableStream)

  const writer = fileStream.getWriter()
  const reader = readStream.getReader()
  const pump = async () => {
    const { value, done } = await reader.read()
    if (done) {
      writer.close()
    } else {
      await writer.write(value)
      return pump()
    }
  }

  return pump()
}
