import React, { Component } from 'react'
import { Confirm } from 'semantic-ui-react'
import { NotificationManager } from 'react-notifications'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { crypho } from '../../crypho.core/xmpp'
import { fetchAccount } from '../../crypho.core/store/modules/account'

class RemoveCompanyMember extends Component {
  state = { open: false }
  open = () => this.setState({ open: true })
  close = () => this.setState({ open: false })

  delete = async () => {
    const { xmppDomain, vCard, userId, fetchAccount } = this.props
    try {
      await crypho.removeAccountMember(`${userId}@${xmppDomain}`)
      this.close()
      await fetchAccount()
      NotificationManager.success(`${vCard.FN} has been deleted`, '')
    } catch (error) {
      this.close()
      NotificationManager.error('There was an error deleting the user', '')
    }
  }

  render() {
    const { vCard } = this.props
    const { open } = this.state
    return (
      <Confirm
        size="mini"
        className="destructive"
        closeIcon
        open={open}
        closeOnRootNodeClick={false}
        content={[
          <p key="p1">Deleting {`${vCard.FN}`} will unrevokably delete their user account.</p>,
          <p key="p2">All their conversations and files they have shared with their contacts will be deleted.</p>,
          <p key="p4">All the groups they own and files within will be deleted.</p>,
          <p key="p3">
            They will be removed from all the groups owned by others, but messages and files they have shared within
            those groups will remain.
          </p>,
        ]}
        header={`Delete ${vCard.FN}?`}
        confirmButton="Delete"
        cancelButton={null}
        onCancel={() => {
          this.close()
        }}
        onConfirm={() => {
          this.delete()
        }}
      />
    )
  }
}

RemoveCompanyMember.propTypes = {
  xmppDomain: PropTypes.string.isRequired,
  userId: PropTypes.string.isRequired,
  fetchAccount: PropTypes.func.isRequired,
  vCard: PropTypes.shape(),
}

const mapStateToProperties = (state) => ({
  xmppDomain: state.config.xmppDomain,
})

const mapDispatchToProperties = {
  fetchAccount,
}

const connector = (container) =>
  connect(
    mapStateToProperties,
    mapDispatchToProperties,
    null,
    // eslint-disable-next-line unicorn/prevent-abbreviations
    { forwardRef: true },
  )(container)

export default connector(RemoveCompanyMember)
