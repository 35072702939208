import without from 'lodash/without'
import dotProp from 'dot-prop-immutable'

import * as xmpp from '../../../xmpp'
import { fetchUserVCard } from '../vcard'

const ADD_USER = 'ADD_USER'
const UPDATE_USER = 'UPDATE_USER'
const DELETE_USER = 'DELETE_USER'
const SET_ROSTER = 'SET_ROSTER'
const LOAD_ROSTER_FROM_CACHE = 'LOAD_ROSTER_FROM_CACHE'
const UPDATE_VERIFICATIONS = 'UPDATE_VERIFICATIONS'

export default function roster(
  state = {
    byId: {},
    allIds: [],
    version: '',
  },
  action,
) {
  switch (action.type) {
    case SET_ROSTER: {
      const { byId, allIds, version } = action

      delete byId['admin']
      const adminIndex = allIds.indexOf('admin')
      if (adminIndex > -1) allIds.splice(adminIndex, 1)
      return {
        byId,
        allIds,
        version,
      }
    }

    case ADD_USER: {
      const { user } = action
      const { jid } = user
      const id = jid.split('@')[0]
      const updatedWithRosterTable = dotProp.set(state, `byId.${id}`, user)
      const updatedWithRosterList = dotProp.set(updatedWithRosterTable, 'allIds', (allIds) =>
        allIds.includes(id) ? allIds : allIds.concat(id),
      )
      return updatedWithRosterList
    }

    case UPDATE_USER: {
      const { id, data } = action
      const updatedWithRosterTable = dotProp.merge(state, `byId.${id}`, data)
      return updatedWithRosterTable
    }

    case DELETE_USER: {
      const { id } = action
      const updatedWithRosterTable = dotProp.delete(state, `byId.${id}`)
      const updatedWithRosterList = dotProp.set(updatedWithRosterTable, 'allIds', (allIds) => without(allIds, id))
      return updatedWithRosterList
    }

    case UPDATE_VERIFICATIONS: {
      const { data } = action
      let newState = state
      Object.keys(data).forEach((id) => {
        if (newState.byId[id].verified !== data[id])
          newState = dotProp.merge(newState, `byId.${id}`, {
            verified: data[id],
          })
      })
      return newState
    }

    case LOAD_ROSTER_FROM_CACHE: {
      const { data } = action
      return data
    }

    default:
      return state
  }
}

export const addUser = (user) => ({
  type: ADD_USER,
  user,
})

export const updateUser = (id, data) => ({
  type: UPDATE_USER,
  id,
  data,
})

export const deleteUser = (id) => ({
  type: DELETE_USER,
  id,
})

const updateVerifications = (data) => ({
  type: UPDATE_VERIFICATIONS,
  data,
})

const setRoster = (allIds, byId, version) => ({
  type: SET_ROSTER,
  allIds,
  byId,
  version,
})

export const addUserAndFetchVCard = (user) => {
  return function (dispatch) {
    dispatch(addUser(user))
    dispatch(fetchUserVCard(user.jid))
  }
}

export const fetchRosterFromServer = () => {
  return async function (dispatch, getState) {
    const { roster } = getState()
    const { version } = roster

    const newRoster = await xmpp.roster.get(version || '')
    if (newRoster) {
      const [userIds, updatedRoster] = newRoster.items.reduce(
        ([userIds, roster], user) => {
          user.jid = user.jid.toString()
          const id = user.jid.split('@')[0]
          userIds.push(id)
          roster[id] = { jid: user.jid, groups: user.groups }
          return [userIds, roster]
        },
        [[], {}],
      )
      dispatch(setRoster(userIds, updatedRoster, newRoster.version))
    }
    return !!newRoster
  }
}

export const fetchUserVerificationsFromServer = () => {
  return async function (dispatch) {
    return dispatch(updateVerifications(await xmpp.crypho.getVerifiedUsers()))
  }
}

export const loadRosterFromCache = (data) => ({
  type: LOAD_ROSTER_FROM_CACHE,
  data,
})
