import React, { Component } from 'react'
import { Popup, Header } from 'semantic-ui-react'
import PropTypes from 'prop-types'

export default class GroupStatus extends Component {
  constructor() {
    super()
  }

  render() {
    const { status } = this.props
    if (!status || (status && status.includes('adata'))) return null
    return (
      <Popup
        className="statusMessagePopup"
        on="click"
        position="bottom left"
        trigger={<Header.Subheader className="statusMessage">{status}</Header.Subheader>}
        wide="very"
      >
        {status}
      </Popup>
    )
  }
}

GroupStatus.propTypes = {
  status: PropTypes.string,
}
