import xml from '@xmpp/xml'
import xmppJid from '@xmpp/jid'
import * as Sentry from '@sentry/core'

const xmlns = 'http://crypho.com/ns/crypho/webrtc'

const mediaConstraintsToMedia = (constraints) => (constraints.video ? 'video' : 'audio')

class RTCPlugin {
  constructor(client, xmppCrypho, xmppDomain) {
    this.client = client
    this.xmppCrypho = xmppCrypho
    this.xmppDomain = xmppDomain
  }

  start() {
    this.waiting = false
  }

  stop() {
    this.stopWaitingForCalls()
  }

  /**
   * Set the encryption key to use
   * @param {{id: number, key: string}} key Encryption key to use
   */
  setEncryptionKey(key) {
    this.encryptionKey = key
  }

  /**
   * Clear encryption key data
   */
  clearEncryptionKey() {
    this.encryptionKey = null
  }

  /**
   * Start listening for incoming RTC events
   *
   * @param {(string, string, string, {audio: boolean, video: boolean}) => Promise<{answer: boolean, encryptionKey?: {id: number, key: string}, media?: {audio: boolean, video: boolean}}>} onRingRequest Handler for incoming call requests
   * @param {(string, {audio: boolean, video: boolean}|undefined) => void} onRingAnswer Handler for incoming ring answer requests
   * @param {string => Promise<void>} onRingCancel Handle ring cancellation request
   * @param {string => Promise<void>} onHangup Handle hangup request
   * @param {(string, RTCSessionDescriptionInit) => Promise<RTCSessionDescriptionInit>} onNewOffer Handler for new session description offers
   * @param {(string, RTCIceCandidateInit[]) => void} onIceCandidates Handler for new ICE candidates
   */
  startWaitingForCalls(
    onRingRequest,
    onRingAnswer,
    onRingCancel,
    onHangup,
    onNewOffer,
    onIceCandidates,
    handleMuteStateChange,
  ) {
    this.handlers = {
      onRingRequest,
      onRingAnswer,
      onRingCancel,
      onHangup,
      onNewOffer,
      onIceCandidates,
      handleMuteStateChange,
    }
    if (this.waiting) {
      return
    }
    this.waiting = true
  }

  /**
   * Stop listening for incoming RTC events
   */
  stopWaitingForCalls() {
    this.waiting = false
    this.handlers = {}
  }

  /**
   * Send a push notification to a user to tell them we are calling them.
   *
   * @param {string} bareJid (Bare) XMPP JID of the person we are calling
   * @param {string} spaceId ID of the space in which the call is made
   * @param {{audio: boolean, video: boolean}} mediaConstraints Requested media for the call
   * @returns {Promise<void>}
   */
  async notifyUserOfRing(bareJid, spaceId, mediaConstraints = { video: true, audio: true }) {
    const media = mediaConstraintsToMedia(mediaConstraints)
    const { iqCaller } = this.client
    await iqCaller.request(
      xml(
        'iq',
        { type: 'set', to: this.xmppCrypho },
        xml(
          'webrtc',
          { xmlns },
          xml('ring', { media, space: spaceId, key: this.encryptionKey.id }, xml('to', { jid: bareJid })),
        ),
      ),
    )
  }

  /**
   * Send a push notification to a user to tell them we are no longer calling them.
   *
   * @param {string} bareJid (Bare) XMPP JID of the person we are calling
   * @param {string} spaceId ID of the space in which the call is made
   * @returns {Promise<void>}
   */
  async notifyUserOfRingCancel(bareJid, spaceId) {
    const { iqCaller } = this.client
    await iqCaller.request(
      xml(
        'iq',
        { type: 'set', to: this.xmppCrypho },
        xml('webrtc', { xmlns }, xml('ring-cancel', { space: spaceId }, xml('to', { jid: bareJid }))),
      ),
    )
  }

  /**
   * Ring other user asking them if they want to accept a call from us
   *
   * @param {string} jid Full XMPP JID of the person we are calling
   * @param {string} spaceId ID of the space in which the call is made
   * @param {{audio: boolean, video: boolean}} mediaConstraints Media used for the call
   * @returns {Promise<void>}
   */
  async ring(jid, spaceId, mediaConstraints = { video: true, audio: true }) {
    const media = mediaConstraintsToMedia(mediaConstraints)
    const { iqCaller } = this.client
    await iqCaller.request(
      xml(
        'iq',
        { type: 'set', to: jid },
        xml('ring-request', { xmlns, space: spaceId, key: this.encryptionKey.id, media }),
      ),
    )
  }

  answerRing(fullJid, accept, mediaConstraints) {
    const { iqCaller } = this.client

    const attrs = accept
      ? {
          answer: 'accept',
          media: mediaConstraintsToMedia(mediaConstraints),
        }
      : { answer: 'reject' }
    return iqCaller.request(xml('iq', { type: 'set', to: fullJid }, xml('ring-answer', { xmlns, ...attrs })))
  }

  /**
   * Signal a device that we are going to disconnect the RTC connection.
   *
   * @param {string} jid Full XMPP jid id of the device that we are disconnecting from
   * @returns {Promise<void>}
   */
  async hangup(jid) {
    this.clearEncryptionKey()
    const { iqCaller } = this.client
    await iqCaller.request(xml('iq', { type: 'set', to: jid }, xml('hangup', { xmlns })))
  }

  encryptData(data) {
    if (!this.encryptionKey) {
      throw new Error('No encryption key set')
    }
    return global.husher.encrypt(JSON.stringify(data), this.encryptionKey.key, this.client.entity.jid.getLocal())
  }

  decryptData(jid, encryptedData) {
    if (!this.encryptionKey) {
      throw new Error('No encryption key set')
    }
    const adata = xmppJid.parse(jid).getLocal()
    const data = global.husher.decrypt(encryptedData, this.encryptionKey.key, adata)
    return JSON.parse(data)
  }

  async sendWebRTCSignal(jid, type, payload) {
    const encryptedSignal = this.encryptData({ type, payload })
    const req = xml('webrtc', { xmlns }, encryptedSignal)
    const { iqCaller } = this.client

    let response = await iqCaller.request(xml('iq', { type: 'set', to: jid }, req))
    let encryptedReply = response.getChildText('webrtc')
    return encryptedReply ? this.decryptData(jid, encryptedReply) : null
  }

  /**
   *
   * @param {string} jid Full XMPP JIDof the person we are calling
   * @param {RTCSessionDescription} description RTC session description
   * @returns {Promise<RTCSessionDescriptionInit>} RTC session description for the other side
   */
  sendSessionDescription(jid, description) {
    return this.sendWebRTCSignal(jid, 'session-description', description)
  }

  /**
   * Send an ICE candidate to the other party
   *
   * @param {string} jid Full XMPP JID of the person we are calling
   * @param {RTCIceCandidate[]} candidates session description
   * @returns {Promise<void>} Promise indicating remote party received the candidate
   */
  sendIceCandidates(jid, candidates) {
    return this.sendWebRTCSignal(jid, 'ice-candidates', candidates)
  }

  /**
   * Send track mute state  to the other party
   *
   * @param {string} jid Full XMPP JID ID of the person we are calling
   * @param {{audio: boolean, video: boolean}} mutes state of mute on each track
   * @returns {Promise<void>} Promise indicating remote party received the candidate
   */
  async sendMuteState(jid, mutes) {
    const { iqCaller } = this.client
    await iqCaller.request(xml('iq', { type: 'set', to: jid }, xml('mute-state', { xmlns, ...mutes })))
  }

  /**
   * Handle incoming iq ring-request messages
   *
   * @param {*} context XMPP event context
   */
  handleRingRequest(context) {
    if (!this.waiting) {
      return false
    }

    const iq = context.stanza
    const req = iq.getChild('ring-request')

    this.handlers
      .onRingRequest(iq.attrs.from, req.attrs.space, req.attrs.key, {
        audio: true,
        video: req.attrs.media === 'video',
      })
      .then(({ encryptionKey, answer, media: mediaConstraints }) => {
        if (answer) {
          this.setEncryptionKey(encryptionKey)
        }
        return this.answerRing(iq.attrs.from, answer, encryptionKey, mediaConstraints)
      })
      .catch(Sentry.captureException)
    return true
  }

  /**
   * Handle incoming iq ring-answer messages
   *
   * @param {*} context XMPP event context
   */
  handleRingAnswer(context) {
    if (!this.waiting) {
      return false
    }

    const iq = context.stanza
    const answer = iq.getChild('ring-answer')
    const media = answer.attrs.answer === 'accept' ? { audio: true, video: answer.attrs.media === 'video' } : undefined
    this.handlers.onRingAnswer(iq.attrs.from, answer.attrs.answer, media)
    return true
  }

  /**
   * Handle incoming iq ring-cancel messages
   *
   * @param {*} context XMPP event context
   */
  handleRingCancel(context) {
    if (!this.waiting) {
      return false
    }

    const iq = context.stanza
    this.handlers.onRingCancel(iq.attrs.from).catch(Sentry.captureException)
    return true
  }

  /**
   * Handle incoming iq hangup messages
   *
   * @param {*} context XMPP event context
   */
  async handleHangup(context) {
    if (!this.waiting) {
      return false
    }

    const iq = context.stanza
    this.clearEncryptionKey()
    await this.handlers.onHangup(iq.attrs.from)
    return true
  }

  /**
   * Handle incoming iq webrtc messages
   *
   * @param {*} context XMPP event context
   * @returns {Promise<boolean>} indicator if we handled the message and service is available
   */
  async handleWebrtcIq(context) {
    if (!this.waiting) {
      return false
    }

    const iq = context.stanza
    const req = iq.getChild('webrtc')

    if (!this.encryptionKey) {
      // eslint-disable-next-line no-console
      console.error('Received a WebRTC signal, but there is no encryption key')
      return false
    }

    let signal
    try {
      signal = this.decryptData(iq.attrs.from, req.getText())
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('Can not decrypt WebRTC signal', error)
      return false
    }
    switch (signal.type) {
      case 'session-description': {
        const sdp = await this.handlers.onNewOffer(iq.attrs.from, signal.payload)
        return xml('webrtc', { xmlns }, this.encryptData(sdp))
      }
      case 'ice-candidates': {
        this.handlers.onIceCandidates(iq.attrs.from, signal.payload)
        return true
      }
      default:
        // eslint-disable-next-line no-console
        console.error('Unknown WebRTC signal', signal)
        throw new Error('Unknown WebRTC signal')
    }
  }

  /**
   * Handle incoming iq mute-state messages
   *
   * @param {*} context XMPP event context
   */
  handleMuteStateIq(context) {
    if (!this.waiting) {
      return
    }

    const iq = context.stanza
    const req = iq.getChild('mute-state')
    this.handlers.handleMuteStateChange(iq.attrs.from, {
      audio: req.attrs.audio === 'true',
      video: req.attrs.video === 'true',
    })
    return true
  }

  /**
   * Signal user devices that they should stop ringing. This can happen because
   * the caller hung up, or the user accepted or refused the call on another device.
   *
   * @param {string} jid Full XMPP JIDfor the devices that should stop ringing
   * @returns {Promise<void>}
   */
  cancelRing(jid) {
    const { iqCaller } = this.client
    return iqCaller.request(xml('iq', { type: 'set', to: jid }, xml('ring-cancel', { xmlns })))
  }
}

export default function setupRTC(client, xmppCrypho, xmppDomain) {
  const plugin = new RTCPlugin(client, xmppCrypho, xmppDomain)
  const { iqCallee } = client

  iqCallee.set(xmlns, 'ring-request', plugin.handleRingRequest.bind(plugin))
  iqCallee.set(xmlns, 'ring-answer', plugin.handleRingAnswer.bind(plugin))
  iqCallee.set(xmlns, 'ring-cancel', plugin.handleRingCancel.bind(plugin))
  iqCallee.set(xmlns, 'hangup', plugin.handleHangup.bind(plugin))
  iqCallee.set(xmlns, 'webrtc', plugin.handleWebrtcIq.bind(plugin))
  iqCallee.set(xmlns, 'mute-state', plugin.handleMuteStateIq.bind(plugin))
  plugin.start()
  return plugin
}
