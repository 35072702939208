import * as Sentry from '@sentry/core'
import dotProp from 'dot-prop-immutable'

import { privateStorage, crypho } from '../../../xmpp'

const SET_EMAIL_NOTIFICATIONS = 'SET_EMAIL_NOTIFICATIONS'
const SET_INACTIVITY_TIMEOUT = 'SET_INACTIVITY_TIMEOUT'

export default function settings(
  state = {
    inactivityAwayTimeout: 5 * 60000,
    emailNotifications: true,
  },
  action,
) {
  switch (action.type) {
    case SET_EMAIL_NOTIFICATIONS: {
      return dotProp.set(state, 'emailNotifications', action.payload)
    }

    case SET_INACTIVITY_TIMEOUT: {
      return dotProp.set(state, 'inactivityAwayTimeout', action.payload)
    }

    default:
      return state
  }
}

const setInactivityTimeout = (timeout) => ({
  type: SET_INACTIVITY_TIMEOUT,
  payload: timeout,
})

const setEmailNotifications = (active) => ({
  type: SET_EMAIL_NOTIFICATIONS,
  payload: active,
})

export const saveInactivityTimeout = (timeout) => {
  return async (dispatch) => {
    await privateStorage.set('settings', JSON.stringify({ inactivityAwayTimeout: timeout }))
    dispatch(setInactivityTimeout(timeout))
  }
}

export const saveEmailNotifications = (active) => {
  return async (dispatch) => {
    await crypho.setEmailNotifications(active)
    dispatch(setEmailNotifications(active))
  }
}

export const fetchUserSettings = () => {
  return async (dispatch) => {
    let settings = await privateStorage.get('settings')
    if (settings) {
      try {
        settings = JSON.parse(settings)
        if (settings.inactivityAwayTimeout !== undefined) {
          dispatch(setInactivityTimeout(settings.inactivityAwayTimeout))
        }
      } catch (error) {
        Sentry.captureException(error)
        // eslint-disable-next-line no-console
        console.error('Unable to parse user settings')
      }
    } else settings = {}
    const emailNotifications = await crypho.getEmailNotifications()
    dispatch(setEmailNotifications(emailNotifications))
  }
}
