import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useDispatch } from 'react-redux'
import { Modal, Form, Radio, Button, Divider } from 'semantic-ui-react'
import * as Sentry from '@sentry/browser'
import { NotificationManager } from 'react-notifications'
import { setSpaceDefaultTtl } from 'crypho.core/store/modules/space'
import { COMMON_CONTENT_EXPIRY_OPTIONS } from 'crypho.core/infostream'

function ExpirySelector({ space, onClose }) {
  const [value, setValue] = useState(space.defaultExpiry)
  const [isSaving, setIsSaving] = useState(false)
  const dispatch = useDispatch()

  const setExpiry = async () => {
    setIsSaving(true)
    try {
      await dispatch(setSpaceDefaultTtl(space.id, value))
      NotificationManager.success('Default expiry time has been updated.')
      onClose()
    } catch (error) {
      Sentry.captureException(error)
      NotificationManager.error('There was an error updating the default expiry time.')
    }
    setIsSaving(false)
  }

  return (
    <Modal
      size="tiny"
      className="info"
      closeIcon
      open={true}
      closeOnEscape={true}
      closeOnRootNodeClick={false}
      onClose={onClose}
    >
      <Modal.Header>Content expiry</Modal.Header>
      <Modal.Content>
        <p>
          Set default expiry time for new messages and files in this conversation. The new default will apply only to
          new messages. You can override the expiry time on individual messages and files. Messages will automatically
          disappear when they reach the expiry time.
        </p>
        <Divider />
        <Form>
          {COMMON_CONTENT_EXPIRY_OPTIONS.map((option) => (
            <Form.Field key={option.value}>
              <Radio
                label={option.label}
                name="radioGroup"
                value={option.value}
                checked={value === option.value}
                onChange={() => setValue(option.value)}
              />
            </Form.Field>
          ))}
          <Modal.Actions>
            <Button floated="right" name="set-expiry" onClick={setExpiry} disabled={isSaving}>
              Save changes
            </Button>
          </Modal.Actions>
        </Form>
      </Modal.Content>
    </Modal>
  )
}

ExpirySelector.propTypes = {
  onClose: PropTypes.func.isRequired,
  space: PropTypes.shape().isRequired,
}

export default ExpirySelector
