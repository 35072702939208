import React from 'react'
import { Button, Modal } from 'semantic-ui-react'
import { Form } from 'formsy-semantic-ui-react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { NotificationManager } from 'react-notifications'
import Burry from 'burry'
import { husher } from '../../crypho.core'
import store from '../../crypho.core/store'
import * as XMPP from '../../crypho.core/xmpp'
import { deleteSession } from '../../session'
import { errorLabel } from '../../UI/forms'

class DeleteAccountForm extends React.Component {
  state = { open: false, passphrase: '' }
  open = () => this.setState({ open: true })
  close = () => this.setState({ open: false })

  deleteAccount = async () => {
    const { scryptSalt } = this.props
    const { passphrase } = this.state
    const strengthened = await husher._strengthenScrypt(passphrase, {
      salt: scryptSalt,
    })
    const authHash = husher._b64.fromBits(husher._hash(strengthened.key2))
    try {
      await XMPP.crypho.deleteAccount(authHash)
    } catch (error) {
      if (error.condition === 'invalid-passphrase') NotificationManager.error('Invalid passphrase', '')
      if (error.condition === 'account-owner') {
        NotificationManager.error('Users who own an account cannot be deleted. Please contact technical support.', '')
        this.close()
      }
      return
    }
    this.close()
    await store.dispatch(XMPP.setXmppPresence(false)).catch(() => {})
    await XMPP.stop()
    await deleteSession()
    const authTokenStore = new Burry.Store('auth.token', 1209600)
    authTokenStore.flush()
    window.location = '/deleted'
  }

  render() {
    const { open } = this.state
    return (
      <Modal
        size="tiny"
        className="destructive"
        closeIcon
        open={open}
        closeOnEscape={true}
        closeOnRootNodeClick={false}
        onClose={this.close}
      >
        <Modal.Header>Delete your account</Modal.Header>
        <Modal.Content>
          <p>Enter your passphrase to confirm your identity.</p>

          <Form onValidSubmit={this.deleteAccount}>
            <Form.Input
              name="passphrase"
              type="password"
              autoComplete="current-password"
              required
              onChange={(event_) => {
                this.setState({ passphrase: event_.target.value })
              }}
              errorLabel={errorLabel}
              validationErrors={{
                isDefaultRequiredValue: 'Passphrase is required',
              }}
            />

            <Modal.Actions className="noPrint">
              <Button negative floated="right" name="confirmDeleteAccount" type="submit">
                Delete
              </Button>
            </Modal.Actions>
          </Form>
        </Modal.Content>
      </Modal>
    )
  }
}

DeleteAccountForm.propTypes = {
  scryptSalt: PropTypes.array.isRequired,
}

const mapStateToProperties = (state) => ({
  scryptSalt: husher._b64.toBits(state.identity.scryptSalt),
})

const connector = (container) =>
  connect(
    mapStateToProperties,
    {},
    null,
    // eslint-disable-next-line unicorn/prevent-abbreviations
    { forwardRef: true },
  )(container)

export default connector(DeleteAccountForm)
