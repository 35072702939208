import { crypho } from '../../../xmpp'
import store from '../..'
import {
  SET_SPACES,
  ADD_SPACE,
  UPDATE_SPACE,
  UPDATE_SPACES,
  DELETE_SPACE,
  UPDATE_SPACE_KEY,
  UPDATE_SPACE_ORDER,
  SET_UNREAD,
} from './constants'

export const setSpaces = (spaces) => ({
  type: SET_SPACES,
  spaces,
})

export const addSpace = (space) => ({
  type: ADD_SPACE,
  space,
})

export const updateSpace = (id, data) => ({
  type: UPDATE_SPACE,
  id,
  data,
})

const updateSpaces = (data) => ({
  type: UPDATE_SPACES,
  data,
})

export const deleteSpace = (id) => {
  return async function (dispatch) {
    dispatch({
      type: DELETE_SPACE,
      id,
    })
  }
}

export const updateSpaceKey = (spaceId, keyId, key) => ({
  type: UPDATE_SPACE_KEY,
  spaceId,
  keyId,
  key,
})

export const fetchSpaceMetadata = (spaceId) => {
  return async function (dispatch) {
    const meta = await crypho.getSpaceMeta(spaceId)
    dispatch(updateSpace(spaceId, meta))
    return meta
  }
}

export const fetchSpacesFromServer = () => {
  return async function (dispatch, getState) {
    const spaces = await crypho.getSpaces()
    const state = getState()
    if (state.spaces.allIds.length === 0) {
      dispatch(setSpaces(spaces))
    } else {
      let spaceIds = []
      let toUpdate = {}
      let space
      for (space of spaces) {
        spaceIds.push(space.id)
        if (state.spaces.allIds.includes(space.id)) toUpdate[space.id] = space
        else dispatch(addSpace(space))
      }
      dispatch(updateSpaces(toUpdate))
      const toDelete = state.spaces.allIds.filter((spaceId) => !spaceIds.includes(spaceId))
      toDelete.forEach((spaceId) => {
        dispatch(deleteSpace(spaceId))
      })
    }
  }
}

export const fetchSpaceFromServer = (spaceId) => {
  return async function (dispatch, getState) {
    const space = await crypho.getSpace(spaceId)
    const state = getState()
    if (state.spaces.allIds.includes(space.id)) dispatch(updateSpace(space.id, space))
    else dispatch(addSpace(space))
  }
}

/**
 * Set the default expiry time for a group.
 *
 * @param {string} spaceId Identifier for the group space
 * @param {number|null} ttl Expiry time in seconds, or null if no default expiry
 */
export function setSpaceDefaultTtl(spaceId, ttl) {
  return async function setSpaceDefaultTtlAction(dispatch) {
    await crypho.setGroupDefaultTtl(spaceId, ttl)
    dispatch(updateSpace(spaceId, { defaultExpiry: ttl }))
  }
}

export const updateSpaceOrder = () => ({
  type: UPDATE_SPACE_ORDER,
})

export const getSpaceKey = (spaceId, keyId) => {
  const state = store.getState()
  const keys = state.spaces.keys[spaceId]
  if (!keys) return null
  let key = keys[keyId]
  if (!key) return null
  if (key.includes('adata')) {
    key = global.husher.decrypt(key)
    store.dispatch(updateSpaceKey(spaceId, keyId, key))
  }
  return key
}

export const setUnread = (unread) => ({
  type: SET_UNREAD,
  unread,
})

export const getUnreadMessageCount = () => {
  return async function (dispatch) {
    const count = await crypho.getUnreadMessageCount()
    dispatch(setUnread(count))
  }
}
