import localforage from 'localforage'
import { CallHandler, CallManager } from './crypho.core/rtc'

export class WebCallManager extends CallManager {
  /**
   * Return the media streams to be used.
   *
   * @param {{audio: boolean, video: boolean}} media Requested media for the call
   * @returns {Promise<MediaStream>}
   */
  async createMediaStream(constraints) {
    let media = { ...constraints }
    const preferredAudioInput = await localforage.getItem('preferredAudioInput')
    const preferredVideoInput = await localforage.getItem('preferredVideoInput')

    if (media.video) {
      media.video = this.devices.filter((device) => device.kind === 'videoinput').length > 0 && media.video
      if (preferredVideoInput && !media.video.deviceId) {
        media.video = { deviceId: preferredVideoInput }
      }
    }
    if (media.audio) {
      media.audio = this.devices.filter((device) => device.kind === 'audioinput').length > 0 && media.audio
      if (preferredAudioInput && !media.audio.deviceId) {
        media.audio = { deviceId: preferredAudioInput }
      }
    }
    return navigator.mediaDevices.getUserMedia(media)
  }
}

export class WebCallHandler extends CallHandler {
  handleAddTrack(source, stream) {
    const interval = setInterval(() => {
      const ref = source === 'local' ? this.localVideoRef : this.remoteVideoRef
      if (source === 'remote') {
        this.updateRemoteStreamCache(stream)
      } else {
        this.updateLocalStreamCache(stream)
      }

      if (ref && ref.current) {
        const el = ref.current
        el.srcObject = stream
        stream.addEventListener('removetrack', () => (el.srcObject = null), { once: true })
        clearInterval(interval)
      }
    }, 100)
  }

  //////////////////////////////////////////////
  // Public interface
  /**
   * Register a function that can create media streams and connect them to the UI.
   *
   * @param {React.RefObject<video>} localVideoRef Reference to <video> element for local video
   * @param {React.RefObject<video>} remoteVideoRef Reference to <video> element for local video
   */
  setVideoReference = (localVideoRef, remoteVideoRef) => {
    this.localVideoRef = localVideoRef
    this.remoteVideoRef = remoteVideoRef
  }

  handleStopTracks() {
    function stop(video) {
      if (video.srcObject) {
        video.srcObject.getTracks().forEach((track) => track.stop())
        video.removeAttribute('src')
        video.removeAttribute('srcObject')
      }
    }

    if (this.localVideoRef && this.localVideoRef.current) {
      stop(this.localVideoRef.current)
    }

    if (this.remoteVideoRef && this.remoteVideoRef.current) {
      stop(this.remoteVideoRef.current)
    }

    const { localStreamCache, remoteStreamCache } = this.state
    if (localStreamCache) {
      localStreamCache.getTracks().forEach((track) => track.stop())
    }

    if (remoteStreamCache) {
      remoteStreamCache.getTracks().forEach((track) => track.stop())
    }
    this.resetState()
  }

  enumerateDevices() {
    return navigator.mediaDevices.enumerateDevices()
  }

  registerDeviceChangeHandler(callback) {
    navigator.mediaDevices.addEventListener('devicechange', callback)
  }

  unregisterDeviceChangeHandler(callback) {
    navigator.mediaDevices.removeEventListener('devicechange', callback)
  }

  async setOutputDevice(deviceId) {
    if (!this.remoteVideoRef) throw new Error('No video element when setting webrtc output device')
    const el = this.remoteVideoRef.current
    if (el.sinkId === undefined) throw new Error('sinkId not supported')
    return el.setSinkId(deviceId)
  }
}
