import React from 'react'
import { Message, Button, Form, Modal, Divider, Header } from 'semantic-ui-react'
import Mnemonic from 'mnemonic.js'
import moment from 'moment'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

import { husher } from '../../crypho.core'
import { fetchScryptSaltFromServer } from '../../crypho.core/store/modules/identity'
import { crypho } from '../../crypho.core/xmpp'
import Topbar from '../../UI/Topbar'
const TODAY = moment(new Date()).format('YYYYMMDD')
class ShowRecoveryKey extends React.Component {
  state = { open: false }
  open = () => this.setState({ open: true })
  close = () => this.setState({ open: false })

  print() {
    window.print()
  }

  updatedOn() {
    let { updated } = this.props
    updated = moment(updated)
    if (updated.format('YYYYMMDD') === TODAY) return updated.format('YYYY-MM-DD')
  }

  render() {
    const { open } = this.state
    const { words } = this.props
    return (
      <Modal
        size="tiny"
        className="info"
        closeIcon
        open={open}
        closeOnEscape={true}
        closeOnRootNodeClick={false}
        onClose={this.close}
      >
        <p className="printOnly">Created on: {this.updatedOn()}</p>
        <Modal.Header className="noPrint">Your Crypho recovery key</Modal.Header>
        <Modal.Content>
          <p className="noPrint">
            This is your Crypho recovery key. It can be used to recover your account should you forget your passphrase.
            Store it somewhere safe where you can retrieve it only when needed, for example in an envelope in a safe
            deposit box.
          </p>

          <Divider className="noPrint" />

          <p id="c-recovery-key">{words}</p>

          <Modal.Actions className="noPrint">
            <Button floated="right" name="print-key" onClick={this.print}>
              Print
            </Button>
          </Modal.Actions>
        </Modal.Content>
      </Modal>
    )
  }
}

ShowRecoveryKey.propTypes = {
  updated: PropTypes.bool,
  words: PropTypes.string,
}

class RecoveryKey extends React.Component {
  constructor(properties) {
    super(properties)
    this.showRecoveryKeyReference = React.createRef()
    this.state = {
      processing: false,
      words: '',
    }
  }

  generateKey = async () => {
    const { fetchScryptSaltFromServer, identity } = this.props

    this.setState({ processing: true })
    try {
      await fetchScryptSaltFromServer(identity.email)
      const m = new Mnemonic(256)
      const session = JSON.stringify(global.husher.toSession())
      const key = husher._hex.toBits(m.toHex())
      const strengthened = await husher._strengthenScrypt(key, {
        salt: identity.scryptSalt,
      })
      const ct = global.husher.encrypt(session, strengthened.key, identity.id)
      const authHash = husher._b64.fromBits(strengthened.key2)
      await crypho.setRecoveryKey(ct, authHash)

      this.setState({ words: m.toWords().join(' ') })
      this.showRecoveryKeyReference.current.open()
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(error)
    }

    this.setState({ processing: false })
  }

  render() {
    const { processing, words } = this.state
    return (
      <div className="authenticatedScene">
        <Topbar />
        <div className="accountOptions">
          <Header as="h3">Recovery key</Header>
          <p>
            You can generate a recovery key that allows you to reset your passphrase in case you forget it. Generating a
            new recovery key will wipe your previous recovery key.
          </p>
          <Message negative compact>
            Keep this key in a safe place that only you have access to. Should another person obtain the key, they will
            gain control of your account.
          </Message>
          <a
            rel="noopener noreferrer"
            target="_blank"
            className="documentation"
            name="signup"
            href="https://www.crypho.com/documentation/recovery-keys/"
          >
            Documentation about recovery key
          </a>

          <Form>
            <Button
              id="generate-key"
              onClick={() => this.generateKey()}
              name="generate-key"
              className="settingsButton"
              type="submit"
              disabled={processing}
              loading={processing}
            >
              Generate recovery key
            </Button>
          </Form>
          <ShowRecoveryKey words={words} ref={this.showRecoveryKeyReference} />
        </div>
      </div>
    )
  }
}

RecoveryKey.propTypes = {
  fetchScryptSaltFromServer: PropTypes.func.isRequired,
  identity: PropTypes.shape().isRequired,
}

const mapStateToProperties = (state) => ({
  identity: state.identity,
})

const mapDispatchToProperties = {
  fetchScryptSaltFromServer,
}

const connector = (container) => connect(mapStateToProperties, mapDispatchToProperties)(container)

export default connector(RecoveryKey)
