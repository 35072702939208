import React from 'react'
import PropTypes from 'prop-types'
import ActionWrapper from './ActionWrapper'

function FileDeletedItem({ authorVCard, content }) {
  return (
    <ActionWrapper>
      <div className="deleted">
        <p className="authorName">
          {`${authorVCard.FN} deleted `}
          <span className="fileInfo">{content.title}</span>
        </p>
      </div>
    </ActionWrapper>
  )
}

FileDeletedItem.propTypes = {
  authorVCard: PropTypes.shape({
    FN: PropTypes.string.isRequired,
  }).isRequired,
  content: PropTypes.shape({
    title: PropTypes.string.isRequired,
  }).isRequired,
}

export default FileDeletedItem
