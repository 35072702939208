import React, { forwardRef, useState, useImperativeHandle } from 'react'
import { Modal, Button } from 'semantic-ui-react'
import { Form } from 'formsy-semantic-ui-react'
import PropTypes from 'prop-types'
import { errorLabel } from '../../UI/forms'
import classes from './AddAccountAdmin.module.css'

const AddAccountDomain = ({ onAdd }, ref) => {
  const [open, setOpen] = useState(false)

  useImperativeHandle(ref, () => ({
    open: () => {
      setOpen(true)
    },
    close: () => setOpen(false),
  }))

  const closeHandler = () => {
    setOpen(false)
  }

  const submitHandler = (formData) => {
    const { domain } = formData
    onAdd(domain)
  }

  return (
    <div id="add-account-domain-container">
      <Modal
        size="mini"
        className="input"
        closeIcon
        open={open}
        closeOnRootNodeClick={false}
        content={[
          <Form key="form" onValidSubmit={submitHandler} className={classes.form}>
            <Form.Input
              name="domain"
              type="text"
              placeholder="Domain name"
              required
              errorLabel={errorLabel}
              validationErrors={{
                isDefaultRequiredValue: 'Domain name is required',
              }}
            />
            <Button name="save" className="settingsButton" type="submit">
              Save
            </Button>
          </Form>,
        ]}
        header="Add domain"
        onClose={closeHandler}
      />
    </div>
  )
}

AddAccountDomain.propTypes = {
  onAdd: PropTypes.func,
}

export default forwardRef(AddAccountDomain)
