import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Button, Loader, Header } from 'semantic-ui-react'
import { Form } from 'formsy-semantic-ui-react'
import Mnemonic from 'mnemonic.js'
import { NotificationManager } from 'react-notifications'
import { withRouter, Link } from 'react-router-dom'
import { connect } from 'react-redux'
import { compose } from 'recompose'

import { husher } from '../../crypho.core'
import {
  setIdentityEmail,
  setTFASecret,
  setPhone,
  fetchScryptSaltFromServer,
} from '../../crypho.core/store/modules/identity'
import * as XMPP from '../../crypho.core/xmpp'
import { saveSession } from '../../session'
import { saveIdentity } from '../../store/identity'
import { errorLabel } from '../../UI/forms'

class RecoverAccountScene extends Component {
  constructor(properties) {
    super(properties)
    this.state = {
      processing: false,
      email: '',
      recoveryKey: '',
    }
  }

  recoverCredentials = async () => {
    this.setState({ processing: true })
    try {
      const { email, recoveryKey } = this.state
      const {
        apiUrl,
        fetchScryptSaltFromServer,
        setIdentityEmail,
        setTFASecret,
        setPhone,
        history,
        saveIdentity,
      } = this.props
      const mnemonic = Mnemonic.fromWords(recoveryKey.split(/\s+/))
      const key = husher._hex.toBits(mnemonic.toHex())
      await setIdentityEmail(email)
      await fetchScryptSaltFromServer(email)
      const { identity } = this.props
      const strengthened = await husher._strengthenScrypt(key, {
        salt: identity.scryptSalt,
      })

      const response = await fetch(`${apiUrl}/auth/key-recovery`, {
        method: 'POST',
        credentials: 'include',
        body: JSON.stringify({
          email,
          authHash: husher._b64.fromBits(strengthened.key2),
        }),
      })
      if (!response.ok) throw new Error('No match')
      let session = await response.json()
      session = global.husher.decrypt(JSON.stringify(session), strengthened.key, email)
      global.husher.fromSession(JSON.parse(session))
      saveSession()

      await XMPP.reloadCredentials()

      const twoFactorData = await XMPP.crypho.getTwoFactorData()
      await setTFASecret(twoFactorData.secret)
      await setPhone(twoFactorData.local, twoFactorData.country)
      await saveIdentity()
      history.push('/passphrase')
      NotificationManager.success('You have successfully recovered your account. Please set your passphrase')
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(error)
      NotificationManager.error('The recovery key does not match')
      this.setState({ processing: false })
    }
  }

  render() {
    const { processing } = this.state
    return (
      <div className="darkContainer">
        <div className="cardContainer">
          <div className="cryphoLogotype" />
          <div className="card">
            <div className="formHeader">
              <div className="cardLogo" />
              <Header as="h1">Key recovery</Header>
            </div>
            <Loader disabled={!processing} />
            <Form onValidSubmit={this.recoverCredentials}>
              <Link className="blueTextLink top20" name="reset" to="/reset">
                I do not have a recovery key
              </Link>
              <p>To reset your passphrase, enter your primary email address and recovery key.</p>
              <Form.Input
                name="email"
                type="email"
                placeholder="Email"
                required
                validations="isEmail"
                onChange={(event_) => this.setState({ email: event_.target.value })}
                errorLabel={errorLabel}
                validationErrors={{
                  isEmail: 'This is not a valid email',
                  isDefaultRequiredValue: 'Email is required',
                }}
              />
              <Form.TextArea
                name="recovery-key"
                placeholder="Recovery key"
                rows={3}
                autoHeight
                style={{ maxHeight: 200 }}
                required
                errorLabel={errorLabel}
                onChange={(event_) => this.setState({ recoveryKey: event_.target.value })}
                validationErrors={{
                  isDefaultRequiredValue: 'Recovery key is required',
                }}
              />

              <Button name="requestReset" className="primaryButton" fluid type="submit" disabled={processing}>
                Request passphrase reset
              </Button>
              <Link className="blueTextLink top20" name="login" to="/login">
                Go back to Login page
              </Link>
            </Form>
          </div>

          <a className="whiteTextLink" target="blank" href="https://www.crypho.com/contactsupport/">
            Problems? Click here for help and support.
          </a>
        </div>
      </div>
    )
  }
}

RecoverAccountScene.propTypes = {
  apiUrl: PropTypes.string.isRequired,
  identity: PropTypes.shape().isRequired,
  history: PropTypes.shape().isRequired,
  setIdentityEmail: PropTypes.func.isRequired,
  fetchScryptSaltFromServer: PropTypes.func.isRequired,
  setTFASecret: PropTypes.func.isRequired,
  setPhone: PropTypes.func.isRequired,
  saveIdentity: PropTypes.func.isRequired,
}

const mapStateToProperties = (state) => ({
  identity: state.identity,
  apiUrl: state.config.apiUrl,
})

const mapDispatchToProperties = {
  saveIdentity,
  fetchScryptSaltFromServer,
  setIdentityEmail,
  setTFASecret,
  setPhone,
}

const connector = (container) => compose(withRouter, connect(mapStateToProperties, mapDispatchToProperties))(container)

export default connector(RecoverAccountScene)
