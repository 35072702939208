import React from 'react'
import PropTypes from 'prop-types'
import { List } from 'semantic-ui-react'
import SystemAdminListItem from './SystemAdminListItem'

const SystemAdminList = ({ admins, loading, onDelete }) => {
  return (
    <List divided verticalAlign="middle">
      <span></span>
      {admins.length === 0 && !loading ? (
        <div className="pageInfo">
          <span className="empty">There is no system admin.</span>
        </div>
      ) : null}
      {loading && (
        <div className="admin-list-loader">
          <div className="ui active large inverted text loader">Loading</div>
        </div>
      )}
      {admins.map((admin) => (
        <SystemAdminListItem key={admin.id} admin={admin} onDelete={() => onDelete(admin)} />
      ))}
    </List>
  )
}

SystemAdminList.propTypes = {
  admins: PropTypes.array,
  loading: PropTypes.bool,
  onDelete: PropTypes.func,
}

export default SystemAdminList
