import React, { Component, useState } from 'react'
import PropTypes from 'prop-types'
import { Button, Icon, Message, Header, Loader, Transition, Segment } from 'semantic-ui-react'
import { Form } from 'formsy-semantic-ui-react'
import Mnemonic from 'mnemonic.js'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { compose } from 'recompose'

import { setIdentityEmail, fetchScryptSaltFromServer } from '../../crypho.core/store/modules/identity'
import { husher } from '../../crypho.core'
import * as XMPP from '../../crypho.core/xmpp'
import { deleteSession, saveSession } from '../../session'
import { saveIdentity } from '../../store/identity'
import { setTFASecret, setPhone } from '../../crypho.core/store/modules/identity'
import { authenticate } from '../../crypho.core/auth'
import PassphraseStrengthMeter from '../../UI/widgets/passphrasestrength'
import { GoogleReCaptchaContext, GoogleRecaptchaProvider } from '../../components/google-recaptcha'

import { errorLabel } from '../../UI/forms'
import { CryphoAPI } from 'crypho.core/api'

class ResetPassphraseScene extends Component {
  constructor(properties) {
    super(properties)
    const mnemonic = new Mnemonic(32)
    this.state = {
      email: '',
      password: '',
      passwordConfirmation: '',
      processing: false,
      statusMessage: '',
      statusMessageType: null,
      examplePassphrase: mnemonic.toWords().join(' '),
    }
  }

  async componentDidMount() {
    const { token, fetchScryptSaltFromServer, setIdentityEmail, history, apiUrl } = this.props
    try {
      this.setState({ processing: true })
      await deleteSession()
      const response = await fetch(`${apiUrl}/reset-passphrase/email-from-token/${token}`)
      const { email } = await response.json()
      this.setState({ email })
      await setIdentityEmail(email)
      await fetchScryptSaltFromServer(email)
      this.setState({ processing: false })
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error)
      history.push('/')
    }
  }

  setStatusMessage = (statusMessage, statusMessageType = 'info') => {
    this.setState({ statusMessage: '', statusMessageType })
    this.setState({ statusMessage, statusMessageType })
  }

  resetExamplePassphrase() {
    const mnemonic = new Mnemonic(32)
    this.setState({ examplePassphrase: mnemonic.toWords().join(' ') })
  }

  onRegister = async () => {
    const { email, password, tfaToken } = this.state
    const { history, token, setIdentityEmail, setTFASecret, setPhone, saveIdentity, identity, apiUrl } = this.props

    this.setState({ processing: true })
    let recaptcha_token
    try {
      recaptcha_token = await this.context.executeRecaptcha('LOGIN')
    } catch (error) {
      this.setState({ processing: false })
      this.setStatusMessage('Failed to load Recaptcha', 'warning')
      return
    }
    if (!recaptcha_token) {
      this.setState({ processing: false })
      this.setStatusMessage(`Please confirm 'I'm not a robot'`, 'warning')
      return
    }
    const h = new husher.Husher()
    await h.generate(password, email)
    const authData = h.toJSON(email)

    const response = await fetch(`${apiUrl}/reset-passphrase/${token}`, {
      method: 'POST',
      credentials: 'include',
      body: JSON.stringify({ authData, token: tfaToken }),
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    })
    this.setState({ processing: false })
    if (response.status === 200) {
      global.husher = h
      await setIdentityEmail(email)
      saveSession()
      await authenticate(email, password, tfaToken, identity.scryptSalt, recaptcha_token, 'web', false)
      await XMPP.reloadCredentials()
      const twoFactorData = await XMPP.crypho.getTwoFactorData()
      await setTFASecret(twoFactorData.secret)
      await setPhone(twoFactorData.local, twoFactorData.country)
      saveIdentity()
      history.push(`/`)
    } else {
      this.setStatusMessage('There was an error resetting your passphrase', 'warning')
    }
  }

  sendSecurityToken = async (push) => {
    const { apiUrl } = this.props
    const { email } = this.state
    const api = new CryphoAPI(apiUrl)
    this.setState({ tfaToken: '' })

    try {
      const recaptcha_token = await this.context.executeRecaptcha('LOGIN')
      if (!recaptcha_token) {
        this.setStatusMessage(`Please confirm 'I'm not a robot'`, 'warning')
        return
      }
      await api.post('/auth/sendtoken', { email, push, recaptcha_token, platform: 'web' })
      this.setStatusMessage('We have sent you a security code. It is valid for 60 seconds.')
    } catch (error) {
      this.setStatusMessage('We could not send you the security code.', 'warning')
    }
  }

  render() {
    const {
      password,
      email,
      processing,
      examplePassphrase,
      statusMessage,
      statusMessageType,
      rememberToken,
    } = this.state

    return (
      <div className="darkContainer">
        <div className="cardContainer">
          <div className="cryphoLogotype" />
          <div className="card">
            <div className="formHeader">
              <div className="cardLogo" />
              <Header as="h1">Passphrase reset</Header>
            </div>
            <Loader disabled={!processing} />
            <Form onValidSubmit={this.onRegister}>
              <Transition animation="scale" duration={statusMessage ? 1000 : 0} visible={!!statusMessage}>
                <Message className={statusMessageType} content={statusMessage || ''} />
              </Transition>
              <Header as="h4">{`Passphrase reset for ${email}`}</Header>
              <div className="changePasswordExample">
                <h4 className="examplePassphrase">Example</h4>
                <span>{examplePassphrase}</span>
                <Icon onClick={() => this.resetExamplePassphrase()} className="refresh" />
              </div>
              <Form.Input
                action={
                  <Icon
                    disabled
                    name="eye"
                    title={this.state.showPassword ? 'Hide passphrase' : 'Show passphrase'}
                    onClick={() =>
                      this.setState({
                        showPassword: !this.state.showPassword,
                      })
                    }
                  />
                }
                name="password"
                type={this.state.showPassword ? 'text' : 'password'}
                autoComplete="new-password"
                placeholder="Passphrase"
                required
                value={this.state.password}
                onChange={(event_) => this.setState({ password: event_.target.value })}
                errorLabel={errorLabel}
                validations="isStrongPassphrase"
                validationErrors={{
                  isDefaultRequiredValue: 'Your passphrase is required',
                }}
              />
              <Form.Input
                name="passwordConfirmation"
                type={this.state.showPassword ? 'text' : 'password'}
                autoComplete="new-password"
                placeholder="Repeat passphrase"
                validations="equalsField:password"
                required={true}
                onChange={(event_) => this.setState({ passwordConfirmation: event_.target.value })}
                errorLabel={errorLabel}
                validationErrors={{
                  isDefaultRequiredValue: 'Please repeat your passphrase',
                  equalsField: 'Passphrases do not match',
                }}
              />
              <PassphraseStrengthMeter passphrase={password} className="changePassProgress" />
              <div id="g-recaptcha"></div>
              <Segment basic>
                <Form.Input
                  name="token"
                  type="number"
                  placeholder="Security code"
                  tabIndex={3}
                  width={6}
                  className="inlineInput"
                  value={this.state.tfaToken}
                  required={!rememberToken}
                  errorLabel={errorLabel}
                  validationErrors={{
                    isDefaultRequiredValue: 'The security code is required',
                  }}
                  onChange={(event_) => this.setState({ tfaToken: event_.target.value })}
                />
                <Button
                  type="button"
                  className="securityTokenButton"
                  formNoValidate="formNoValidate"
                  onClick={() => this.sendSecurityToken(false)}
                  id="send-sms"
                  disabled={!this.props.captchaReady}
                >
                  Send SMS
                </Button>
                <Button
                  type="button"
                  className="securityTokenButton"
                  formNoValidate="formNoValidate"
                  onClick={() => this.sendSecurityToken(true)}
                  id="send-push"
                  disabled={!this.props.captchaReady}
                >
                  Send to app
                </Button>
              </Segment>
              <Button
                name="reset-passphrase"
                className="primaryButton"
                fluid
                type="submit"
                disabled={processing || !this.props.captchaReady}
              >
                Reset passphrase
              </Button>
            </Form>
          </div>
          <a className="whiteTextLink" target="blank" href="https://www.crypho.com/contactsupport/">
            Problems? Click here for help and support.
          </a>
        </div>
      </div>
    )
  }
}

ResetPassphraseScene.contextType = GoogleReCaptchaContext

ResetPassphraseScene.propTypes = {
  token: PropTypes.string.isRequired,
  identity: PropTypes.shape().isRequired,
  history: PropTypes.object.isRequired,
  apiUrl: PropTypes.string.isRequired,
  fetchScryptSaltFromServer: PropTypes.func.isRequired,
  setIdentityEmail: PropTypes.func.isRequired,
  setTFASecret: PropTypes.func.isRequired,
  setPhone: PropTypes.func.isRequired,
  saveIdentity: PropTypes.func.isRequired,
  captchaReady: PropTypes.bool,
}

const mapStateToProperties = (state, ownProperties) => {
  const token = ownProperties.match.params.token
  return {
    token,
    identity: state.identity,
    apiUrl: state.config.apiUrl,
    recaptchaWebSiteKey: state.config.recaptchaWebSiteKey,
    recaptchaEnabled: state.config.recaptchaEnabled,
  }
}

const mapDispatchToProperties = {
  fetchScryptSaltFromServer,
  setIdentityEmail,
  setTFASecret,
  setPhone,
  saveIdentity,
}

const connector = (container) => compose(withRouter, connect(mapStateToProperties, mapDispatchToProperties))(container)

const resetPassphraseSceneWithRecaptchaProvider = (props) => {
  const { recaptchaEnabled, recaptchaWebSiteKey } = props
  const [ready, setReady] = useState(false)
  const readyHandler = () => {
    setReady(true)
  }
  return (
    <GoogleRecaptchaProvider
      reCaptchaKey={recaptchaWebSiteKey}
      useEnterprise={true}
      disabled={!recaptchaEnabled}
      readyCallBack={readyHandler}
      container={{ element: 'g-recaptcha', parameters: { size: 'normal', action: 'LOGIN' } }}
    >
      <ResetPassphraseScene captchaReady={ready} {...props} />
    </GoogleRecaptchaProvider>
  )
}

resetPassphraseSceneWithRecaptchaProvider.propTypes = {
  recaptchaWebSiteKey: PropTypes.string,
  recaptchaEnabled: PropTypes.bool,
}

export default connector(resetPassphraseSceneWithRecaptchaProvider)
