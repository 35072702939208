import React from 'react'
import { List } from 'semantic-ui-react'
import { NavLink } from 'react-router-dom'
import localforage from 'localforage'

import { NotificationManager } from 'react-notifications'
import changelog from '../../changelog'

const showLatestChanges = async () => {
  const lastShownVersion = await localforage.getItem('lastShownChangelog')
  const { version, changes } = changelog[0]
  if (version === lastShownVersion) return
  await localforage.setItem('lastShownChangelog', version)
  const title = `Crypho v${version}`
  const entry = (
    <React.Fragment>
      <List>
        {changes.map((change, i) => (
          <List.Item key={i}>{change}</List.Item>
        ))}
      </List>
      <NavLink to="/changelog">Full changelog</NavLink>
    </React.Fragment>
  )
  NotificationManager.info(entry, title, 10000)
}
export default showLatestChanges
