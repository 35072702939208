const UPLOAD_START = 'UPLOAD_START'
const UPLOAD_SET_PROGRESS = 'UPLOAD_SET_PROGRESS'
const UPLOAD_COMPLETE = 'UPLOAD_COMPLETE'

/**
 * @typedef {"uploading" | "complete" | "failed"} UploadState
 */

/** @type {{uploads: FileState[]}} */
const initialState = {
  uploads: [],
}

export function reducer(state = initialState, action) {
  switch (action.type) {
    case UPLOAD_START:
      return {
        ...state,
        uploads: [...state.uploads, action.payload],
      }
    case UPLOAD_SET_PROGRESS:
      return {
        ...state,
        uploads: state.uploads.map((s) =>
          s.id === action.payload.id
            ? {
                ...s,
                state: action.payload.state,
                progress: action.payload.progress,
              }
            : s,
        ),
      }
    case UPLOAD_COMPLETE:
      return {
        ...state,
        uploads: state.uploads.map((s) => (s.id === action.payload ? { ...s, state: 'complete', progress: 100 } : s)),
      }
    default:
      return state
  }
}

/**
 * Create a redux action for a newly started upload
 *
 * @param {string} id Unique identifier for the upload.
 *   Must be unique globally, not per space.
 * @param {string} space
 *   Identifier for the space where the file will be located.
 * @param {string} name Filename (without path)
 */
export const startUpload = (id, space, name) => ({
  type: UPLOAD_START,
  payload: {
    id,
    space,
    name,
    state: 'uploading',
    progress: 0,
  },
})

/**
 * Create a redux action to update progress for a file upload
 *
 * @param {string} id
 * @param {number} progress
 * @param {UploadState} state
 */
export const updateUploadProgress = (id, progress, state = 'uploading') => ({
  type: UPLOAD_SET_PROGRESS,
  payload: {
    id,
    progress,
    state,
  },
})

/**
 * Create a redux action indication an upload completed successfully.
 *
 * @param {string} id
 */
export const uploadComplete = (id) => ({
  type: UPLOAD_COMPLETE,
  payload: id,
})
