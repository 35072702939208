import queryString from 'querystring'
import store from './store'
import { calculateMD5 } from './hashUtils'

/**
 * @typedef {Object} UploadSpec
 * @property {string} id
 * @property {string} method
 * @property {string[]} urls
 */

/**
 * @typedef {{chunks: number[]}} UploadInvalidChecksumData
 */

/**
 * @typedef {"uploading" | "complete" | "failed"} UploadState
 */

/**
 * @typedef {(status: UploadState, percentage: number) => any} ProgressHandler
 */

/**
 * API error data.
 *
 * This error type is used for runtime errors generated by the API service.
 */
export class ApiError extends Error {
  /**
   * @param {string} message
   * @param {any} data
   */
  constructor(message, data = {}) {
    // const trueProto = new.target.prototype
    super(message)
    // Object.setPrototypeOf(this, trueProto)
    const { error, ...other } = data
    this.error = error
    this.data = other
    this.apiError = true
  }
}

/** @typedef {{[key: string]: string | number | null}} QueryParameters */

/**
 * This is a basic wrapper around the Crypho API.
 *
 * It handles a few basic things:
 * - it exposes a Promise API for all interactions
 * - errors are also raised as ApiError exceptions
 * - it will automatically detect and decode JSON responses
 */
export class CryphoAPI {
  /**
   *
   * @param {string} baseUrl Root URL for the API
   */
  constructor(baseUrl) {
    this.baseUrl = baseUrl
    this.salt = store.getState().config?.salt
  }

  /**
   * Send DELETE request to the API
   *
   * @param {string} path URL resource path (relative to API root)
   * @param {QueryParameters} params Query parameters
   */
  delete(path, params = {}) {
    return this.query('DELETE', path, params)
  }

  /**
   * GET data from the API
   *
   * @param {string} path URL resource path (relative to API root)
   * @param {QueryParameters} params Query parameters
   * @param {any} headers Request headers.
   */
  get(path, params = {}, headers = null) {
    return this.query('GET', path, params, undefined, headers)
  }

  /**
   * Send PATCH request with JSON data to the API, and return response data
   *
   * @param {string} path URL resource path (relative to API root)
   * @param {any} data Data to pass to the server.
   * @param {QueryParameters} params Query parameters
   * @param {any} headers Request headers.
   */
  patch(path, data, params = {}, headers = null) {
    return this.query('PATCH', path, params, data, headers)
  }

  /**
   * Post JSON data to the API, and return response data
   *
   * @param {string} path URL resource path (relative to API root)
   * @param {any} data Data to pass to the server.
   * @param {QueryParameters} params Query parameters
   */
  post(path, data, params = {}, headers = null) {
    return this.query('POST', path, params, data, headers)
  }

  /**
   * PUT JSON data to the API, and return response data
   *
   * @param {string} path URL resource path (relative to API root)
   * @param {any} data Data to pass to the server.
   * @param {QueryParameters} params Query parameters
   * @param {any} headers Request headers.
   */
  put(path, data, params = {}, headers = null) {
    return this.query('PUT', path, params, data, headers)
  }

  /**
   * Low-level method to send request to API.
   *
   * This method should normally not be used by callers. Instead use
   * one of the get/post/put/delete/patch methods.
   *
   * @param {'GET'|'POST'|'DELETE'|'PUT'|'PATCH} method HTTP method
   * @param {string} path URL resource path (relative to API root)
   * @param {QueryParameters} params Query parameters
   * @param {any} data Data to pass to the server.
   * @param {any} headers Request headers.
   */

  async query(method, path, params, data, headers = null) {
    let url = `${this.baseUrl}${path}`
    const qs = queryString.stringify(params)
    let custom_header
    if (qs.length > 0) {
      url += `?${qs}`
      if (method === 'GET' && this.salt) {
        custom_header = calculateMD5(this.salt + qs)
      }
    }
    const options = {
      credentials: 'include',
      method,
      headers: headers ?? {},
    }

    if (data) {
      options.headers = { ...options.headers, 'Content-Type': 'application/json' }
      options.body = JSON.stringify(data)
    }
    if ((method === 'POST' || method === 'PUT') && this.salt) {
      custom_header = calculateMD5(this.salt + options.body)
    }

    if (custom_header) {
      options.headers['X-Custom'] = custom_header
    }

    const r = await fetch(url, options)
    if (r.status === 204) {
      return null
    }

    let response
    if (r.headers.get('Content-Type') === 'application/json') {
      response = await r.json()
    } else {
      response = await r.text()
    }
    if (!r.ok) {
      throw new ApiError(r.statusText, response)
    }
    return response
  }
}
