import React from 'react'
import PropTypes from 'prop-types'
import { Icon, Item } from 'semantic-ui-react'
import ActionWrapper from './ActionWrapper'
import { deleteMessage } from './utils'

function GeoLocationItem({ isDeleting, authorVCard, id, item, spaceId, content }) {
  return (
    <ActionWrapper onDelete={() => deleteMessage(spaceId, id, item)} isDeleting={isDeleting}>
      <Item.Group>
        <Item
          target="_blank"
          rel="noopener noreferrer"
          href={`https://maps.google.com/maps?daddr=${content.latitude},${content.longitude}`}
        >
          <Icon className="locationIcon" />
          <Item.Content>
            <Item className="authorName">{`${authorVCard.FN} is near `}</Item>
            <Item>
              <span className="locationInfo">{`${content.address}`}</span>
            </Item>
          </Item.Content>
        </Item>
      </Item.Group>
    </ActionWrapper>
  )
}

GeoLocationItem.propTypes = {
  id: PropTypes.string.isRequired,
  isDeleting: PropTypes.bool.isRequired,
  spaceId: PropTypes.string.isRequired,
  item: PropTypes.shape({
    author: PropTypes.string.isRequired,
    created: PropTypes.any.isRequired,
  }),
  authorVCard: PropTypes.shape({
    FN: PropTypes.string.isRequired,
  }).isRequired,
  content: PropTypes.shape({
    longitude: PropTypes.number.isRequired,
    latitude: PropTypes.number.isRequired,
    address: PropTypes.string.isRequired,
  }).isRequired,
}

export default GeoLocationItem
