import * as xmpp from '../../../xmpp'
import { fetchSpaceMetadata, getSpaceKey, updateSpace, updateSpaceOrder } from '../space'
import { FETCH_ITEMS, ADD_ITEM, DELETE_ITEM, CLEAR_PUBSUB } from './constants'

const MAX_RSM_ITEMS = 20
const NODE_FULLY_LOADED_MARKER = 'NODE_FULLY_LOADED_MARKER'

const fetchItems = (items, itemIds, nodeId, rsm) => ({
  type: FETCH_ITEMS,
  items,
  itemIds,
  nodeId,
  rsm,
})

const addItem = (item, itemId, nodeId) => ({
  type: ADD_ITEM,
  item,
  itemId,
  nodeId,
})

export const deletePubsubItem = (itemId, nodeId) => ({
  type: DELETE_ITEM,
  itemId,
  nodeId,
})

export const addPubsubItem = (item) => {
  return async function (dispatch, getState) {
    const state = getState()
    const { node, entry } = item
    const spaceId = node.split('/')[2]

    const { kid, payload } = JSON.parse(entry.text())
    const unread = state.spaces.unread[spaceId]
    const away = state.app.away

    // If we have already fetched the pubsub node decrypt the item and add.
    let key = getSpaceKey(spaceId, kid)
    let pt
    if (key === null) {
      await dispatch(fetchSpaceMetadata(spaceId))
      key = getSpaceKey(spaceId, kid)
    }
    try {
      pt = JSON.parse(global.husher.decrypt(payload, key))
      dispatch(addItem(pt, item.id, node))
    } catch (error) {
      /* eslint-disable no-console */
      console.error(`Error decrypting item with id ${item.id}`)
      console.error(error)
      throw error
    }
    const isUpdate = item.entry.attrs.updated !== undefined
    let inSpace = false

    // To determine whether we are seeing the space,
    // for native we check state.navigation,
    // for web window.location
    if (state.navigation) {
      const { params } = state.navigation
      inSpace = params && params.spaceId && params.spaceId === spaceId
    } else {
      inSpace = window.location.pathname.includes(spaceId)
    }

    if (isUpdate || (!away && inSpace)) {
      dispatch(
        updateSpace(spaceId, {
          lastActivity: new Date().toISOString(),
        }),
      )
    } else {
      dispatch(
        updateSpace(spaceId, {
          lastActivity: new Date().toISOString(),
          unread: unread + 1,
        }),
      )
    }
    dispatch(updateSpaceOrder())
  }
}

/**
 * Decrypt and unpack a pubsub item.
 *
 * This will return the pubsub item id and the unpacked pubsub item itself.
 *
 * @param {string} spaceId
 * @param {*} item
 * @returns {[string, object]}
 */
function decodeItem(spaceId, item) {
  const { kid, payload } = JSON.parse(item.children[0].text())
  const key = getSpaceKey(spaceId, kid)
  try {
    const pt = global.husher.decrypt(payload, key)
    return [item.attrs.id, JSON.parse(pt)]
  } catch (error) {
    /* eslint-disable no-console */
    console.error(`Error decrypting item with id ${item.id}`, error)
  }
  return [null, null]
}

export function fetchPubsubItemsFromServer(nodeId, spaceId, fetchMore = false, max = MAX_RSM_ITEMS) {
  return async (dispatch, getState) => {
    let { spaces, pubsub, config } = getState()
    const savedRSM = pubsub.nodeRSM[nodeId]
    const { xmppPubsub } = config

    if (savedRSM && !fetchMore) return
    if (fetchMore && savedRSM === NODE_FULLY_LOADED_MARKER) return []
    let rsm = { max }
    // if we haven't saved rsm before then set the rsm.before to null to start from the beginning
    rsm.before = savedRSM?.last ?? null

    if (!spaces.keys[spaceId] || Object.keys(spaces.keys[spaceId]).length === 0) {
      await dispatch(fetchSpaceMetadata(spaceId))
    }

    let { items, rsm: newRSM } = await xmpp.pubsub.items(xmppPubsub, nodeId, rsm)
    const itemIds = []
    const itemPayloads = {}

    items.forEach((item) => {
      const [itemId, itemPayload] = decodeItem(spaceId, item)
      if (itemId !== null) {
        itemIds.push(itemId)
        itemPayloads[itemId] = itemPayload
      }
    })

    // If we have no RSM saved, might be because we are resuming.
    // Check if we have items that were fetched before resetting RSM and
    // delete them
    // Check if we have items that were not there and insert them
    if (!savedRSM && pubsub.nodeItemIds[nodeId]) {
      const toDelete = pubsub.nodeItemIds[nodeId].filter((id) => !itemIds.includes(id))
      const toInsert = itemIds.filter((id) => !pubsub.nodeItemIds[nodeId].includes(id))
      toDelete.forEach((itemId) => {
        dispatch(deletePubsubItem(itemId, nodeId))
      })
      toInsert.reverse().forEach((itemId) => {
        dispatch(addItem(itemPayloads[itemId], itemId, nodeId))
      })
    }
    if (newRSM.count && !newRSM.first) newRSM = NODE_FULLY_LOADED_MARKER
    await dispatch(fetchItems(itemPayloads, itemIds, nodeId, newRSM))
    return itemIds
  }
}

export function fetchStreams(spaceId, fetchMore = false) {
  return async (dispatch, getState) => {
    const infostreamNodeId = `/spaces/${spaceId}/infostream`
    const filestreamNodeId = `/spaces/${spaceId}/vault`
    const infoItemIds = await dispatch(fetchPubsubItemsFromServer(infostreamNodeId, spaceId, fetchMore))
    if (!infoItemIds || infoItemIds.length === 0) return
    // We have infostream items, now fetch enough items from the filestream until we
    // the oldest filestream item is older than the oldest infostream item.
    let fileItemIds, oldestFileItemCreatedOn
    let state = getState()
    const oldestInfoItemCreatedOn = state.pubsub.itemsById[infoItemIds[infoItemIds.length - 1]].created
    do {
      fileItemIds = await dispatch(fetchPubsubItemsFromServer(filestreamNodeId, spaceId, true))
      if (!fileItemIds || fileItemIds.length === 0) break
      state = getState()
      // here as we may have items which are the type of 'downloadedBy' and are not added to store
      // we check the oldest item which is really in store
      // if none of fileItemIds were not found in store it means all loaded items are 'downloadedBy'
      // then we should load more items to get the real file item
      const fileItemId = fileItemIds.reverse().find((fileItemId) => state.pubsub.itemsById[fileItemId]?.created)
      oldestFileItemCreatedOn = fileItemId ? state.pubsub.itemsById[fileItemId].created : undefined
    } while (!oldestFileItemCreatedOn || oldestFileItemCreatedOn > oldestInfoItemCreatedOn)
  }
}

/**
 * Fetch all items in a pubsub node.
 *
 * This should be used with care since it may retrieve a *lot* of data.
 *
 * @param {string} nodeId Pubsub node ID, for example `/spaces/123123/vault`
 * @param {string} spaceId
 * @returns Promise<void>
 */
export function fetchAllPubsubItems(nodeId, spaceId) {
  return async (dispatch, getState) => {
    let { spaces, config } = getState()
    const { xmppPubsub } = config

    if (!spaces.keys[spaceId] || Object.keys(spaces.keys[spaceId]).length === 0) {
      await dispatch(fetchSpaceMetadata(spaceId))
    }

    let rsm = { max: MAX_RSM_ITEMS, before: null }
    let pubsubItems = []

    do {
      const result = await xmpp.pubsub.items(xmppPubsub, nodeId, rsm)
      rsm.before = result.rsm.last
      pubsubItems = result.items
      const items = {}
      result.items
        .map((i) => decodeItem(spaceId, i))
        .filter((i) => !!i[0])
        .forEach(([itemId, payload]) => {
          items[itemId] = payload
        })

      dispatch(fetchItems(items, Object.keys(items), nodeId, result.rsm))
    } while (pubsubItems.length > 0)
  }
}

export const clearPubSub = () => ({
  type: CLEAR_PUBSUB,
})
