import PropTypes from 'prop-types'
import React, { useState, useEffect } from 'react'
import { Message } from 'semantic-ui-react'
import { Dropdown, Button } from 'semantic-ui-react'
import { Form } from 'formsy-semantic-ui-react'
import { getPlans } from './api'
import { connect } from 'react-redux'
import moment from 'moment'
import classes from './EditPlan.module.css'
import { errorLabel } from 'UI/forms'
import { NotificationManager } from 'react-notifications'

const convertToEpoch = (date) => {
  const dateObj = moment(date)
  return dateObj.unix()
}

const EditPlan = ({ onCancel, onSave, defaultPlan, apiUrl }) => {
  const [plans, setPlans] = useState([])
  const [loading, setLoading] = useState(false)
  const [selectedOption, setSelectedOption] = useState(defaultPlan)
  const options = plans.map((plan) => ({
    key: plan.name,
    text: plan.name,
    value: plan.name,
  }))

  useEffect(() => {
    ;(async () => {
      setLoading(true)
      try {
        const plans = await getPlans(apiUrl)
        setPlans(plans)
      } catch (error) {
        NotificationManager.error(error?.data?.message ?? 'Error occurred', '')
      }
      setLoading(false)
    })()
  }, [])

  const changPlanHandler = (_event, data) => {
    setSelectedOption(data.value)
  }

  const submitHandler = (data) => {
    const { validUntil, title } = data
    var formData = { plan_id: selectedOption }
    if (validUntil) {
      const unixTimestamp = convertToEpoch(validUntil)
      formData = { ...formData, valid_until: unixTimestamp }
    }
    if (title) {
      formData = { ...formData, title }
    }
    onSave(formData)
  }

  return (
    <Form onValidSubmit={submitHandler} className={classes.form}>
      {loading && (
        <div className={classes.loader}>
          <div className="ui active large inverted text loader">Loading</div>
        </div>
      )}
      <Message warning visible>
        <p>Warning: Downgrading to free plans will delete all related users and data.</p>
      </Message>
      <Form.Field
        control={Dropdown}
        label="Plan"
        placeholder="Select plan"
        required
        fluid
        selection
        options={options}
        value={selectedOption}
        onChange={changPlanHandler}
        className={classes.planInput}
      />
      <Form.Input
        label="Title"
        type="text"
        id="title"
        name="title"
        className={classes.titleInput}
        required
        validationErrors={{ isDefaultRequiredValue: 'Title is required' }}
        errorLabel={errorLabel}
      />
      <Form.Input
        label="Valid Until"
        name="validUntil"
        type="date"
        id="validUntil"
        className={classes.dateInput}
        required
        validationErrors={{
          isDefaultRequiredValue: 'Valid Until is required',
          isValidDate: 'Valid Until must be in the future',
        }}
        validations={{
          isValidDate: (_values, value) => {
            return moment(value).isAfter(moment(), 'day')
          },
        }}
        errorLabel={errorLabel}
      />
      <Button name="save" className="settingsButton" type="submit">
        Save
      </Button>
      <Button name="cancel" className={`settingsButton ${classes['cancel-button']}`} onClick={onCancel}>
        Cancel
      </Button>
    </Form>
  )
}

EditPlan.propTypes = {
  onCancel: PropTypes.func,
  onSave: PropTypes.func,
  defaultPlan: PropTypes.string,
  apiUrl: PropTypes.string,
}

const mapStateToProperties = (state) => {
  const apiUrl = state.config.apiUrl
  return {
    apiUrl,
  }
}

const connector = (container) => connect(mapStateToProperties)(container)

export default connector(EditPlan)
