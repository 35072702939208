import { NotificationManager } from 'react-notifications'
import matchAll from 'string.prototype.matchall'
import { deleteInfostreamMessage } from '../../../../crypho.core/infostream'

export async function deleteMessage(spaceId, itemId, item) {
  try {
    await deleteInfostreamMessage(spaceId, itemId, item)
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error)
    NotificationManager.error('There was an error deleting the message please try again.')
    return
  }
}

/**
 * Split a message into parts with different formats
 *
 * @param {string} msg Input message to split
 * @returns {[{type: 'code'|'plain', syntax?: string, content: string}]} Message components
 */
export function splitMessage(msg) {
  let lastIndex = 0
  const parts = []
  let match
  for (match of matchAll(msg, /^```([a-z]+)?\s*$(.*?)^```\s*$/gms)) {
    if (match.index !== lastIndex) {
      parts.push({ type: 'plain', content: msg.slice(lastIndex, match.index).trim() })
    }
    lastIndex = match.index + match[0].length
    const syntax = match[1]
    const content = match[2].trim()
    if (content !== '') {
      parts.push({ type: 'code', syntax, content })
    }
  }

  if (lastIndex < msg.length) {
    parts.push({ type: 'plain', content: msg.slice(lastIndex).trim() })
  }
  return parts
}
