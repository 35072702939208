import { OnetimeLock } from '../../../utils'
import { LOAD_VCARD, LOAD_VCARD_COMPLETE, ADD_VCARD, DELETE_VCARD, UPDATE_VCARDS } from './constants'

const VcardLock = OnetimeLock()

const loadVcard = (jid) => ({
  type: LOAD_VCARD,
  payload: jid,
})

const loadVcardComplete = (jid) => ({
  type: LOAD_VCARD_COMPLETE,
  payload: jid,
})

export const addVCard = (jid, vCard) => ({
  type: ADD_VCARD,
  jid,
  vCard,
})

export const deleteVCard = (id) => ({
  type: DELETE_VCARD,
  id,
})

export const updateVCards = (data) => ({
  type: UPDATE_VCARDS,
  data,
})

export const fetchUserVCard = function (jid, force = false) {
  const username = jid.split('@', 1)[0]
  return async function (dispatch, getState) {
    await VcardLock
    const state = getState()
    const { apiUrl } = state.config
    if (!state.vcards.loading[username] && (force || !state.vcards.byId[username])) {
      dispatch(loadVcard(username))
      const r = await fetch(`${apiUrl}/vcard/${username}`, {
        method: 'GET',
        credentials: 'include',
      })
      const vcard = await r.json()
      dispatch(addVCard(jid, vcard))
      dispatch(loadVcardComplete(username))
    }
  }
}

export const fetchUserVCards = () => {
  return async (dispatch, getState) => {
    const state = getState()
    const { apiUrl } = state.config
    const r = await fetch(`${apiUrl}/vcards`, {
      method: 'GET',
      credentials: 'include',
    })
    const vcards = await r.json()
    dispatch(updateVCards(vcards))
    VcardLock.unlock()
  }
}
