import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Button, Modal } from 'semantic-ui-react'
import { Form } from 'formsy-semantic-ui-react'
import { NotificationManager } from 'react-notifications'
import { connect } from 'react-redux'

import { errorLabel } from '../../UI/forms'
import { crypho } from '../../crypho.core/xmpp'
import { fetchAccount } from '../../crypho.core/store/modules/account'

class AddAccountMemberForm extends Component {
  state = {
    processing: false,
    open: false,
    email: '',
  }

  open = () => this.setState({ open: true })
  close = () => this.setState({ open: false })

  invite = async () => {
    const { fetchAccount } = this.props
    const { processing } = this.state
    if (processing) return
    this.setState({ processing: true })
    try {
      const { email } = this.state
      await crypho.addAccountMember(email)
      await fetchAccount()
      this.setState({ processing: false })
      this.close()
      NotificationManager.success(`${email} has been invited`, '')
    } catch (error) {
      const { email } = this.state
      switch (error.condition) {
        case 'in-foreign-account':
          NotificationManager.error(`${email} is already a member of another account.`)
          break
        case 'already-member':
          NotificationManager.error(`${email} is already a member of the account.`)
          break
        case 'invited-to-foreign-account':
          NotificationManager.error(`${email} is already invited to another account.`)
          break
        default:
          NotificationManager.error(`There was an error inviting ${email} to the account.`)
          break
      }
      this.setState({ processing: false })
      // eslint-disable-next-line no-console
      console.error(error)
    }
  }

  render() {
    const { open, processing } = this.state
    return (
      <Modal closeIcon open={open} closeOnEscape={true} closeOnRootNodeClick={false} onClose={this.close} size="small">
        <Modal.Header>Add company member</Modal.Header>
        <Modal.Content>
          <p>
            Invite people to your company account by entering their email address. They will receive an invitation email
            to join. Members of your company get a name badge indicating their company, and they get access to
            enterprise features from Crypho. You can not add members who already have an account on Crypho.
          </p>

          <Form onValidSubmit={this.invite}>
            <Form.Input
              name="email"
              type="email"
              placeholder="Email"
              required
              validations="isEmail"
              onChange={(event_) => this.setState({ email: event_.target.value })}
              errorLabel={errorLabel}
              validationErrors={{
                isEmail: 'This is not a valid email',
                isDefaultRequiredValue: 'Email is required',
              }}
            />

            <Modal.Actions>
              <Button floated="right" name="add-member" disabled={processing}>
                Add member
              </Button>
            </Modal.Actions>
          </Form>
        </Modal.Content>
      </Modal>
    )
  }
}

AddAccountMemberForm.propTypes = {
  fetchAccount: PropTypes.func.isRequired,
}

const mapDispatchToProperties = {
  fetchAccount,
}

const connector = (container) =>
  connect(
    null,
    mapDispatchToProperties,
    null,
    // eslint-disable-next-line unicorn/prevent-abbreviations
    { forwardRef: true },
  )(container)

export default connector(AddAccountMemberForm)
