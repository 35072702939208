import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { errorLabel } from '../../../UI/forms'
import { Form } from 'formsy-semantic-ui-react'

const validateEmail = (email) => {
  const re = /\S+@\S+\.\S+/
  return re.test(email)
}

const EmailInput = ({ ...props }) => {
  const [error, setError] = useState('')

  const validateAllEmails = (emails) => {
    const { to } = emails
    const emailList = to?.split(';') ?? []
    const invalidEmails = emailList.filter((email) => email && !validateEmail(email.trim()))
    if (invalidEmails.length > 0) {
      setError(`Invalid email address(es): ${invalidEmails.join(', ')}`)
      return false
    } else {
      setError('')
      return true
    }
  }

  return (
    <Form.Input
      type="text"
      validations={{ validateAllEmails }}
      errorLabel={errorLabel}
      placeholder="Enter multiple emails separated by semicolon"
      validationErrors={{
        validateAllEmails: error,
      }}
      {...props}
    />
  )
}

EmailInput.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
}

export default EmailInput
