import React, { Component, useState } from 'react'
import PropTypes from 'prop-types'
import { Button, Header, Transition, Message, Loader } from 'semantic-ui-react'
import { Form } from 'formsy-semantic-ui-react'
import { withRouter } from 'react-router-dom'
import { PhoneInput } from '../../UI/forms'
import { parsePhoneNumberFromString } from 'libphonenumber-js'
import { connect } from 'react-redux'
import { compose } from 'recompose'

import { errorLabel } from '../../UI/forms'
import * as XMPP from '../../crypho.core/xmpp'
import { loadSession } from '../../session'
import { saveIdentity } from '../../store/identity'
import { setTFASecret, setPhone } from '../../crypho.core/store/modules/identity'
import { crypho } from '../../crypho.core/xmpp'
import { CryphoAPI } from 'crypho.core/api'
import { GoogleReCaptchaContext, GoogleRecaptchaProvider } from '../../components/google-recaptcha'

class Register2FAScene extends Component {
  state = {
    email: '',
    country: 'NO',
    phoneNumber: '',
    tfaToken: '',
    statusMessage: '',
    statusMessageType: null,
    processing: false,
  }

  async componentDidMount() {
    const { apiUrl, token } = this.props
    const api = new CryphoAPI(apiUrl)
    try {
      const response = await api.get(
        `/register/twofactor/${token}`,
        { getdata: 1 },
        {
          Accept: 'application/json',
        },
      )
      let { country, email } = response
      this.setState({ country, email })
    } catch (error) {
      return
    }
  }

  setStatusMessage = (statusMessage, statusMessageType = 'info') => {
    this.setState({ statusMessage: '', statusMessageType })
    this.setState({ statusMessage, statusMessageType })
  }

  onSendSMS = async () => {
    const { apiUrl, token } = this.props

    const phoneNumber = parsePhoneNumberFromString(this.state.phoneNumber)
    if (!phoneNumber || !phoneNumber.isValid()) {
      return
    }
    const type = phoneNumber.getType()
    if (type !== 'MOBILE' && type !== undefined) {
      return false
    }
    const api = new CryphoAPI(apiUrl)
    try {
      const recaptcha_token = await this.context.executeRecaptcha('LOGIN')
      if (!recaptcha_token) {
        this.setStatusMessage(`Please confirm 'I'm not a robot'`, 'warning')
        return
      }
      await api.post(
        `/register/twofactor/${token}`,
        {
          send_sms: 1,
          mobile: phoneNumber.nationalNumber,
          country: phoneNumber.country,
          recaptcha_token,
          platform: 'web',
        },
        {},
        {
          Accept: 'application/json',
        },
      )
      this.setStatusMessage('We have sent you a security code')
    } catch {
      this.setStatusMessage('We could not send you an sms', 'warning')
    }
  }

  onRegisterPhone = async () => {
    const { apiUrl, token, history, setTFASecret, setPhone } = this.props
    const { tfaToken } = this.state
    this.setState({ processing: true })
    const api = new CryphoAPI(apiUrl)
    try {
      const response = await api.post(
        `/register/twofactor/${token}`,
        {
          code: tfaToken,
          platform: 'web',
        },
        {},
        {
          Accept: 'application/json',
          'content-type': 'application/json',
        },
      )

      if (!response?.valid) {
        this.setStatusMessage('Your security code is incorrect.', 'error')
        this.setState({ processing: false })
        return
      }

      await loadSession()
      await XMPP.reloadCredentials()

      const twoFactorData = await crypho.getTwoFactorData()
      await setTFASecret(twoFactorData.secret)
      await setPhone(twoFactorData.local, twoFactorData.country)
      await saveIdentity()

      history.push('/')
    } catch (error) {
      console.error(error)
    } finally {
      this.setState({ processing: false })
    }
  }

  render() {
    const { email, country, statusMessage, statusMessageType, processing } = this.state
    return (
      <div className="darkContainer">
        <div className="cardContainer">
          <div className="cryphoLogotype" />
          <div className="card">
            <div className="formHeader">
              <div className="cardLogo" />
              <Header as="h1">Register 2/2</Header>
            </div>
            <Loader disabled={!processing} />
            <Form onValidSubmit={this.onRegisterPhone}>
              <Transition animation="scale" duration={statusMessage ? 1000 : 0} visible={!!statusMessage}>
                <Message className={statusMessageType} content={statusMessage || ''} />
              </Transition>
              <Header as="h4">{`Registering account for ${email}`}</Header>
              <p className="description">
                {
                  'Crypho protects your data with 2 factor authentication. Get started by receiving a security code to your phone in a text message.'
                }
              </p>
              <PhoneInput
                width={10}
                name="phone"
                defaultCountry={country}
                className="inlineInput"
                onChange={(value) => this.setState({ phoneNumber: value })}
                required
                autoComplete="tel"
                errorLabel={errorLabel}
                validations="isMobileNumber"
                validationErrors={{
                  isDefaultRequiredValue: 'You need a phone number for Crypho to send security codes',
                  isMobileNumber: 'You need a valid mobile number',
                }}
              />
              <div id="g-recaptcha"></div>
              <Button
                className="securityTokenButton"
                formNoValidate="formNoValidate"
                type="button"
                onClick={() => this.onSendSMS(false)}
                id="send-sms"
                disabled={!this.props.captchaReady}
              >
                Send code
              </Button>

              <Header as="h4">{'Enter the security code received in SMS.'}</Header>
              <Form.Input
                width={6}
                name="token"
                type="number"
                placeholder="Security code"
                required
                onChange={(event_) => this.setState({ tfaToken: event_.target.value })}
                validationErrors={{
                  isDefaultRequiredValue: 'You need a valid security code',
                }}
              />

              <Button name="registerPhone" className="primaryButton" fluid type="submit" disabled={processing}>
                Complete registration
              </Button>
            </Form>
          </div>
          <a className="whiteTextLink" target="blank" href="https://www.crypho.com/contactsupport/">
            Problems? Click here for help and support.
          </a>
        </div>
      </div>
    )
  }
}

Register2FAScene.propTypes = {
  apiUrl: PropTypes.string.isRequired,
  token: PropTypes.string.isRequired,
  history: PropTypes.shape().isRequired,
  setTFASecret: PropTypes.func.isRequired,
  setPhone: PropTypes.func.isRequired,
  captchaReady: PropTypes.bool,
}

Register2FAScene.contextType = GoogleReCaptchaContext

const mapStateToProperties = (state, ownProperties) => {
  const token = ownProperties.match.params.token
  return {
    token,
    apiUrl: state.config.apiUrl,
    recaptchaWebSiteKey: state.config.recaptchaWebSiteKey,
    recaptchaEnabled: state.config.recaptchaEnabled,
  }
}

const mapDispatchToProperties = {
  setTFASecret,
  setPhone,
  saveIdentity,
}

const connector = (container) => compose(withRouter, connect(mapStateToProperties, mapDispatchToProperties))(container)

const register2FASceneWithRecaptchaProvider = (props) => {
  const { recaptchaEnabled, recaptchaWebSiteKey } = props
  const [ready, setReady] = useState(false)
  const readyHandler = () => {
    setReady(true)
  }
  return (
    <GoogleRecaptchaProvider
      reCaptchaKey={recaptchaWebSiteKey}
      useEnterprise={true}
      disabled={!recaptchaEnabled}
      readyCallBack={readyHandler}
      container={{ element: 'g-recaptcha', parameters: { size: 'normal', action: 'LOGIN' } }}
    >
      <Register2FAScene captchaReady={ready} {...props} />
    </GoogleRecaptchaProvider>
  )
}

register2FASceneWithRecaptchaProvider.propTypes = {
  recaptchaWebSiteKey: PropTypes.string,
  recaptchaEnabled: PropTypes.bool,
}

export default connector(register2FASceneWithRecaptchaProvider)
