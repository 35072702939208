import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Grid, Visibility, Loader } from 'semantic-ui-react'
import GalleryItem from './GalleryItem'
import { useDispatch } from 'react-redux'
import { fetchPubsubItemsFromServer } from '../../../../crypho.core/store/modules/pubsub'

function Gallery({ spaceId, itemIds }) {
  const [refreshing, setRefreshing] = useState(false)
  const dispatch = useDispatch()

  const refresh = async () => {
    if (refreshing) return
    setRefreshing(true)
    await dispatch(fetchPubsubItemsFromServer(`/spaces/${spaceId}/vault`, spaceId, true))
    setRefreshing(false)
  }
  return (
    <div className="filestreamNode">
      {itemIds.length > 0 ? (
        <Grid doubling columns={6}>
          {itemIds.map((id) => (
            <GalleryItem key={id} id={id} spaceId={spaceId} />
          ))}
          <Visibility
            updateOn="repaint"
            once={false}
            onOnScreen={() => {
              refresh()
            }}
          >
            <Loader active={refreshing} />
          </Visibility>
        </Grid>
      ) : (
        <div className="pageInfo">
          <span className="empty">No images.</span>
        </div>
      )}
    </div>
  )
}

Gallery.propTypes = {
  spaceId: PropTypes.string.isRequired,
  itemIds: PropTypes.arrayOf(PropTypes.string).isRequired,
}

export default Gallery
