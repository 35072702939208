import React from 'react'
import PropTypes from 'prop-types'
import ActionWrapper from './ActionWrapper'

function MessageDeletedItem({ authorVCard }) {
  return (
    <ActionWrapper>
      <p className="authorName">{authorVCard.FN} deleted this message</p>
    </ActionWrapper>
  )
}

MessageDeletedItem.propTypes = {
  authorVCard: PropTypes.shape({
    FN: PropTypes.string.isRequired,
  }).isRequired,
}

export default MessageDeletedItem
