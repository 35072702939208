import React, { useState } from 'react'
import { Form } from 'formsy-semantic-ui-react'
import { Segment, Header, Button } from 'semantic-ui-react'
import PropTypes from 'prop-types'
import { NotificationManager } from 'react-notifications'
import { connect } from 'react-redux'

import { PhoneInput, errorLabel } from '../../../UI/forms'
import { sendSMS } from '../api'

import classes from './SMSForm.module.css'

const SMSForm = ({ apiUrl }) => {
  const [sending, setSending] = useState(false)

  const submitHandler = async (formData) => {
    try {
      setSending(true)
      await sendSMS(apiUrl, formData)
      NotificationManager.info('sms sent successfully')
    } catch (error) {
      NotificationManager.error(error?.data?.message ?? 'Error occurred', '')
    }
    setSending(false)
  }

  return (
    <Segment className={classes.formContainer}>
      {sending && (
        <div className={classes.loader}>
          <div className="ui active large inverted text loader">Sending sms</div>
        </div>
      )}
      <Header as="h3">Send sms</Header>
      <Form onValidSubmit={submitHandler}>
        <PhoneInput
          name="mobile"
          required
          errorLabel={errorLabel}
          validations="isMobileNumber"
          validationErrors={{
            isDefaultRequiredValue: 'You need a mobile number',
            isMobileNumber: 'You need a valid mobile number',
          }}
        />
        <Form.TextArea
          label="body"
          type="text"
          id="text"
          name="text"
          className={classes.formInput}
          rows={3}
          required
          validationErrors={{ isDefaultRequiredValue: 'body is required' }}
          errorLabel={errorLabel}
        />
        <Button name="save" className={`settingsButton ${classes.sendButton}`} type="submit">
          Send
        </Button>
      </Form>
    </Segment>
  )
}

SMSForm.propTypes = {
  apiUrl: PropTypes.string,
}

const mapStateToProperties = (state) => {
  const apiUrl = state.config.apiUrl
  return {
    apiUrl,
  }
}

const connector = (container) => connect(mapStateToProperties)(container)
export default connector(SMSForm)
