import React, { Component } from 'react'
import { Button, Modal } from 'semantic-ui-react'
import { Form } from 'formsy-semantic-ui-react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { crypho } from '../../../crypho.core/xmpp'
import { NotificationManager } from 'react-notifications'
import { getSpaceCurrentKey } from '../../../crypho.core/store/modules/space/selectors'

class EditGroupStatus extends Component {
  state = { open: false, status: '' }
  open = () => this.setState({ open: true, status: this.props.space.status })
  close = () => this.setState({ open: false })

  onChangeStatus = async () => {
    const { space } = this.props
    const { status } = this.state
    if (status === space.status) {
      this.close()
      return
    }

    try {
      const { currentKey, jid } = this.props
      const encryptedStatus = global.husher.encrypt(status, currentKey.key, jid)
      await crypho.updateSpace(space.id, { status: JSON.stringify({ kid: currentKey.id, payload: encryptedStatus }) })
      await NotificationManager.success('The group status has been updated', '')
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error)
      NotificationManager.error('There was an error updating the group status', '')
    }
    this.close()
  }

  encryptData = (data) => {
    const { currentKey, jid } = this.props
    return global.husher.encrypt(data, currentKey.key, jid)
  }

  render() {
    const { space } = this.props
    const { open } = this.state
    return (
      <Modal
        size="tiny"
        className="info"
        closeIcon
        open={open}
        closeOnEscape={true}
        closeOnRootNodeClick={false}
        onClose={this.close}
      >
        <Modal.Header>Edit group status</Modal.Header>
        <Modal.Content>
          <p>
            The groups status is displayed at the top of the conversation and is visible to all members. Owner and
            operators can edit group status.
          </p>
          <Form>
            <Form.TextArea
              name="changeStatus"
              type="text"
              rows="6"
              required
              value={space.status}
              onChange={(event_) => this.setState({ status: event_.target.value })}
            />
            <Modal.Actions>
              <Button floated="right" name="changeStatus" onClick={this.onChangeStatus}>
                Save
              </Button>
            </Modal.Actions>
          </Form>
        </Modal.Content>
      </Modal>
    )
  }
}

EditGroupStatus.propTypes = {
  space: PropTypes.shape(),
  currentKey: PropTypes.shape(),
  jid: PropTypes.string,
}

const mapStateToProperties = (state, ownProperties) => {
  const space = ownProperties.space
  if (!space) return {}

  return {
    currentKey: getSpaceCurrentKey(state, { spaceId: space.id }),
    jid: state.identity.jid,
  }
}

const connector = (container) =>
  connect(
    mapStateToProperties,
    {},
    null,
    // eslint-disable-next-line unicorn/prevent-abbreviations
    { forwardRef: true },
  )(container)

export default connector(EditGroupStatus)
