import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { Dropdown } from 'semantic-ui-react'
import * as Sentry from '@sentry/browser'
import moment from 'moment'
import { NotificationManager } from 'react-notifications'
import { COMMON_CONTENT_EXPIRY_OPTIONS, setPubsubItemTtl } from 'crypho.core/infostream'

function ItemExpirySelector({ nodeId, spaceId, itemId, item }) {
  const [isSaving, setIsSaving] = useState(false)
  const space = useSelector((state) => state.spaces.byId[spaceId])
  const resetText = space.defaultExpiry
    ? `use default of ${moment.duration(space.defaultExpiry, 'seconds').humanize()}`
    : 'use default'
  const setExpiry = async (ttl) => {
    setIsSaving(true)
    try {
      await setPubsubItemTtl(spaceId, nodeId, itemId, item, ttl)
      NotificationManager.success('Expiry time has been updated.')
    } catch (error) {
      Sentry.captureException(error)
      NotificationManager.error('There was an error updating the expiry time.')
    }
    setIsSaving(false)
  }

  return (
    <Dropdown icon="clock outline" direction="left" disabled={isSaving} loading={isSaving}>
      <Dropdown.Menu>
        <Dropdown.Item>
          {item.expires ? `Message expires ${moment(item.expires).fromNow()}` : 'This message does not expire'}
        </Dropdown.Item>
        <Dropdown.Divider />
        {COMMON_CONTENT_EXPIRY_OPTIONS.map((option) => (
          <Dropdown.Item key={option.value} text={option.label} onClick={() => setExpiry(option.value)} />
        ))}
        {item.expires ? (
          <>
            <Dropdown.Divider />
            <Dropdown.Item text={resetText} onClick={() => setExpiry(null)} />
          </>
        ) : null}
      </Dropdown.Menu>
    </Dropdown>
  )
}

ItemExpirySelector.propTypes = {
  nodeId: PropTypes.string.isRequired,
  spaceId: PropTypes.string.isRequired,
  itemId: PropTypes.string.isRequired,
  item: PropTypes.shape().isRequired,
}

export default ItemExpirySelector
