import React, { Component } from 'react'
import { Header } from 'semantic-ui-react'
import { withRouter } from 'react-router-dom'

class SignedUpScene extends Component {
  render() {
    return (
      <div className="darkContainer">
        <div className="cardContainer">
          <div className="cryphoLogotype" />
          <div className="card">
            <div className="formHeader">
              <div className="cardLogo" />
              <Header as="h1">Verify your email</Header>
            </div>
            <div className="form">
              <Header as="h4">
                {
                  'We have sent you an email with a link to verify your address ownership. Check your email and click the link to complete your registration. '
                }
              </Header>
              <p className="description">{'If you do not receive an email, please check your spam folder.'}</p>
            </div>
          </div>
          <a className="whiteTextLink" target="blank" href="https://www.crypho.com/contactsupport/">
            Problems? Click here for help and support.
          </a>
        </div>
      </div>
    )
  }
}

export default withRouter(SignedUpScene)
