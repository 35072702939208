/* eslint-disable unicorn/no-nested-ternary */
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect, Provider, ReactReduxContext } from 'react-redux'
import { __RouterContext as RouterContext } from 'react-router'
import { Button, Modal, Divider, Image, Header } from 'semantic-ui-react'
import { NotificationManager } from 'react-notifications'

import { getSpaceTitle, getSpaceMembersUserIds } from '../../crypho.core/store/modules/space/selectors'
import { crypho } from '../../crypho.core/xmpp'
import RosterAvatar from '../../UI/widgets/rosteravatar'

class DeleteOrLeaveSpaceForm extends Component {
  state = { open: false }
  open = () => this.setState({ open: true })
  close = () => this.setState({ open: false })

  deleteOrLeave = async () => {
    const { space, userId } = this.props
    const isContact = space.type === 'contact'
    const isOwner = !!(space.roles && space.roles[userId] && space.roles[userId].includes('owner'))

    try {
      this.close()
      if (isContact || isOwner) {
        await crypho.deleteSpace(space.id)
      } else {
        await crypho.leaveSpace(space.id)
        return
      }
      NotificationManager.success(isContact ? 'The contact has been deleted' : 'The conversation has been deleted', '')
    } catch (error) {
      NotificationManager.error(
        isContact
          ? 'There was an error deleting the contact'
          : isOwner
          ? 'There was an error deleting the conversation'
          : 'There was an error leaving the conversation',
        '',
      )
    }
  }

  render() {
    const { open } = this.state
    const { apiUrl, space, title, spaceMembersUserIds, userId } = this.props
    const avatarUrl =
      space.type === 'contact' ? `${apiUrl}/avatar/${spaceMembersUserIds[0]}` : `${apiUrl}/identicon/${space.id}`

    const isContact = space.type === 'contact'
    const isOwner = !!(space.roles && space.roles[userId] && space.roles[userId].includes('owner'))

    return (
      <RouterContext.Consumer>
        {((routeCtx) => (
          <ReactReduxContext.Consumer>
            {((ctx) => (
              <Modal
                className="destructive"
                size="tiny"
                closeIcon
                open={open}
                closeOnEscape={true}
                closeOnRootNodeClick={false}
                onClose={this.close}
              >
                <RouterContext.Provider value={routeCtx}>
                  <Provider store={ctx.store}>
                    <Modal.Header>
                      {isContact ? 'Delete contact' : isOwner ? 'Delete group' : 'Leave group'}
                    </Modal.Header>
                    <Modal.Content>
                      <Header>
                        {isContact ? (
                          <RosterAvatar noPopup userId={this.props.spaceMembersUserIds[0]} />
                        ) : (
                          <Image circular avatar src={avatarUrl} />
                        )}
                        {title}
                      </Header>

                      <Divider />
                      <p>
                        {isContact
                          ? 'The contact and conversation history will be deleted.'
                          : isOwner
                          ? 'The conversation history will be deleted.'
                          : 'You will loose access to this conversation.'}
                      </p>

                      <Modal.Actions>
                        <Button onClick={this.deleteOrLeave} floated="right" name="delete-contact">
                          {isContact ? 'Delete contact' : isOwner ? 'Delete group' : 'Leave group'}
                        </Button>
                      </Modal.Actions>
                    </Modal.Content>
                  </Provider>
                </RouterContext.Provider>
              </Modal>
            )).bind(this)}
          </ReactReduxContext.Consumer>
        )).bind(this)}
      </RouterContext.Consumer>
    )
  }
}

DeleteOrLeaveSpaceForm.propTypes = {
  apiUrl: PropTypes.string,
  space: PropTypes.shape(),
  spaceMembersUserIds: PropTypes.array,
  title: PropTypes.string,
  userId: PropTypes.string,
}

const mapStateToProperties = (state, ownProperties) => {
  const space = ownProperties.space
  return {
    apiUrl: state.config.apiUrl,
    title: getSpaceTitle(state, { space }),
    spaceMembersUserIds: getSpaceMembersUserIds(state, ownProperties),
    userId: state.identity.id,
  }
}

const connector = (container) =>
  connect(
    mapStateToProperties,
    null,
    null,
    // eslint-disable-next-line unicorn/prevent-abbreviations
    { forwardRef: true },
  )(container)

export default connector(DeleteOrLeaveSpaceForm)
