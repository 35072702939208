export { downloadAsBlob } from './generic'
import store from '../../crypho.core/store'
import { startDownload, downloadComplete } from '../../crypho.core/store/modules/downloads'
import { ApiError } from '../../crypho.core/api'
import { FileTooLarge } from './generic'

const impl = process.env.REACT_APP_DESKTOP ? require('./electron') : require('./browser')

/** @typedef {import('../../crypto/sjcl').Task} Task */
/** @typedef {import('.generic/FileItem} FileItem */

/**
 * Download and save a file.
 *
 * @param {CryphoAPI} api CryphoAPI instance
 * @param {string} siteUrl Base URL for the website
 * @param {string} spaceId Space id where file must be downloaded from
 * @param {FileItem} item Item to download
 * @param {Task} decryptTask Decryption task
 * @returns Promise<void>
 */
export async function downloadFile(api, siteUrl, spaceId, item, decryptTask) {
  if (!canDownload(item)) {
    throw new FileTooLarge('no-stream-support')
  }

  const state = store.getState()
  const downloads = state.downloads.downloadsInProgress
  const downloadUID = `${spaceId}-${item.uid}`

  if (downloads.includes(downloadUID)) {
    throw new ApiError('download-in-progress')
  }
  store.dispatch(startDownload(downloadUID))

  try {
    return await impl.downloadFile(api, siteUrl, spaceId, item, decryptTask)
  } finally {
    store.dispatch(downloadComplete(downloadUID))
  }
}

export function canDownload(item) {
  if (process.env.REACT_APP_DESKTOP) {
    return true
  } else {
    return impl.canDownload(item)
  }
}
