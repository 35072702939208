import { backoff } from '../crypho.core/utils'
import { ApiError } from '../crypho.core/api'
import { readFile, sha256, combineBuffers } from '../utils'

/** @typedef {import('../crypto/sjcl').Task} Task */

// Upload in 5Mb chunks
const CHUNK_SIZE = 2 ** 20 * 5

/**
 *
 * @param {UploadSpec} spec
 * @param {Blob} blob
 * @param {number} chunk
 * @param {Task} encryptTask Encryption task
 * @param {(i: number) => void} bumpProgress
 * @param {boolean} final Flag indication if this is the final task
 * @returns {Promise<string>} SHA-256 hash for uploaded chunk
 */
export async function uploadChunk(spec, blob, chunk, encryptTask, final, bumpProgress) {
  const offset = chunk * CHUNK_SIZE
  const chunkData = blob.slice(offset, offset + CHUNK_SIZE)
  const buffer = await readFile(chunkData)
  bumpProgress(1)
  let encryptedData = await encryptTask.update(buffer)
  if (final) {
    const finalData = await encryptTask.finish()
    encryptedData = combineBuffers(encryptedData, finalData)
  }
  bumpProgress(1)
  const h = await sha256(encryptedData)
  await fetch(spec.urls[chunk], {
    method: 'POST',
    body: encryptedData,
    credentials: 'include',
    headers: { 'Content-Type': 'application/octet-stream' },
  })
  bumpProgress(1)
  return h
}

/**
 * Encrypt and upload a file in chunks
 *
 * @param {CryphoAPI} api CryphoAPI instance
 * @param {string} spaceId Space id where file must be uplaoded to
 * @param {Blob} blob File to upload
 * @param {Task} encrypt Encryption task
 * @param {ProgressHandler} progressHandler
 *   Optional event handler to receive progress information
 */
export async function uploadFile(api, spaceId, blob, encryptTask, progressHandler = null) {
  const chunks = Math.ceil(blob.size / CHUNK_SIZE)
  const progressSteps = 1 + chunks * 3 + 1
  let progress = 0

  const bumpProgress = (increment, status = 'uploading') => {
    progress += increment
    if (progressHandler) {
      progressHandler(status, Math.ceil((100 * progress) / progressSteps))
    }
  }

  bumpProgress(0)
  const request = await api.post(`/vault/${spaceId}/upload/prepare`, {
    chunks,
    size: blob.size,
    methods: ['post'],
    hash_algorithm: ['sha256'],
  })
  bumpProgress(1)

  const hashes = new Array(chunks)
  let todo = new Array(chunks).fill(1).map((x, y) => y)
  let attempt = 0

  // eslint-disable-next-line no-constant-condition
  while (true) {
    const chunk = todo.shift()
    if (chunk !== undefined) {
      const final = todo.length === 0
      // eslint-disable-next-line no-await-in-loop
      hashes[chunk] = await uploadChunk(request, blob, chunk, encryptTask, final, bumpProgress)
    } else {
      try {
        // eslint-disable-next-line no-await-in-loop
        const r = await api.post(`/vault/${spaceId}/upload/verify`, {
          id: request.id,
          checksums: hashes,
        })
        if (progressHandler) {
          progressHandler('complete', 100)
        }
        return r.id
      } catch (error) {
        if (error instanceof ApiError) {
          if (error.error === 'invalid-checksum') {
            const { chunks: badChunks } = error.data
            todo = badChunks
            // eslint-disable-next-line no-console
            console.error('Upload verification failed, re-uploading chunks', badChunks)

            // Reset progress for chunks we need to re-upload
            bumpProgress(-3 * badChunks.length)
          } else {
            // In other case we have an unexpected API error. Since a retry is
            // not likely to help here we just abort.
            throw new Error('Upload failed')
          }
        } else {
          // If that was not an ApiError we got an unexpected server error. We should
          // report this via Sentry. We'll try to deal with it by doing a retry with
          // backoff.
        }

        attempt += 1
        if (attempt > 5) {
          if (progressHandler) {
            bumpProgress(0, 'failed')
          }
          throw new Error('Upload failed')
        }
        // eslint-disable-next-line no-await-in-loop
        await backoff(attempt)
      }
    }
  }
}
