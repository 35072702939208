import React from 'react'
import * as Sentry from '@sentry/browser'
import PropTypes from 'prop-types'
import { Container, Icon, Message } from 'semantic-ui-react'

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props)
    this.state = { eventId: 123123 } // null }
  }

  static getDerivedStateFromError() {
    return { hasError: true }
  }

  componentDidCatch(error, errorInfo) {
    Sentry.withScope((scope) => {
      scope.setExtras(errorInfo)
      const eventId = Sentry.captureException(error)
      this.setState({ eventId })
    })
  }

  render() {
    if (this.state.hasError) {
      //render fallback UI
      const { eventId } = this.state
      return (
        <Container text>
          <Message icon negative>
            <Icon name="warning sign" />
            <Message.Content>
              <Message.Header>An unexpected error occured</Message.Header>
              This error has been logged with event id <code>{eventId}</code>.
            </Message.Content>
          </Message>
        </Container>
      )
    }

    return this.props.children
  }
}

ErrorBoundary.propTypes = {
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.arrayOf(PropTypes.element)]),
}

export default ErrorBoundary
