import React from 'react'
import PropTypes from 'prop-types'
import { Button, Container, Image } from 'semantic-ui-react'

import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { CallContext } from '../../crypho.core/rtc'
import Draggable from 'react-draggable'
import { getCallerName } from './utils'
import ringSoundMp3 from './ring.mp3'
import './call.css'
// The audio instance must be global otherwise the webpack url will go wrong for electron app
const audio = new Audio(ringSoundMp3)
audio.loop = true

class InComingCall extends React.Component {
  constructor(props) {
    super(props)
    this.localVideoReference = React.createRef()
  }

  componentDidMount() {
    if (this.context.requestedMedia.video) {
      this.context.setVideoReference(this.localVideoReference, null)
    }
    audio.play()
  }

  async componentWillUnmount() {
    if (this.context.requestedMedia.video) {
      this.context.setVideoReference(null, null)
    }
    audio.pause()
  }

  renderIncomingCallButtons() {
    const { acceptIncomingCall, refuseIncomingCall } = this.context
    return (
      <div className="callControls">
        <Button
          id="accept-call"
          circular
          icon="call"
          onClick={() => {
            acceptIncomingCall()
          }}
          color="green"
        />
        <Button id="reject-call" circular icon="call" onClick={() => refuseIncomingCall()} color="red" />
      </div>
    )
  }

  renderAudioView() {
    const { remoteJid } = this.context
    const { apiUrl } = this.props

    return (
      <Draggable bounds="parent">
        <Container
          id="wrtc"
          style={{
            ...this.props.containerStyle,
            backgroundColor: 'white',
          }}
        >
          {this.renderIncomingCallButtons()}
          <div className="callHeader">
            Incoming audio call from <p className="contactsName">{getCallerName(remoteJid)}…</p>
          </div>
          <Image
            className="audioCallerAvatar"
            size="small"
            avatar
            src={`${apiUrl}/avatar/${remoteJid.split('@')[0]}`}
          />
        </Container>
      </Draggable>
    )
  }

  renderVideoView() {
    const { remoteJid } = this.context
    return (
      <Draggable bounds="parent">
        <Container id="wrtc" style={this.props.containerStyle}>
          {this.renderIncomingCallButtons()}
          <div className="callHeader">
            Incoming video call from <p className="inlineName">{getCallerName(remoteJid)}…</p>
          </div>
          <video id="remote-video" className="mirrored" ref={this.localVideoReference} autoPlay muted />
        </Container>
      </Draggable>
    )
  }

  render() {
    const { requestedMedia } = this.context
    if (requestedMedia.video) {
      return this.renderVideoView()
    }
    return this.renderAudioView()
  }
}

InComingCall.contextType = CallContext

InComingCall.propTypes = {
  containerStyle: PropTypes.shape(),
  apiUrl: PropTypes.string.isRequired,
}

const mapStateToProperties = (state) => ({
  apiUrl: state.config.apiUrl,
})

const connector = (container) => connect(mapStateToProperties, null)(container)

export default withRouter(connector(InComingCall))
