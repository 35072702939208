import React, { Component } from 'react'

class Timer extends Component {
  constructor(props) {
    super(props)
    this.state = {
      secElapsed: 0,
    }
  }

  componentDidMount() {
    this.interval = setInterval(() => {
      let { secElapsed } = this.state
      secElapsed++
      this.setState({ secElapsed })
    }, 1000)
  }

  componentWillUnmount() {
    clearInterval(this.interval)
  }

  pad(n) {
    return String('0' + n).slice(-2)
  }

  render() {
    const { secElapsed } = this.state
    const minutes = this.pad(Math.floor(secElapsed / 60))
    const seconds = this.pad(secElapsed % 60)

    return (
      <span className="callHeader">
        {minutes}:{seconds}
      </span>
    )
  }
}
export default Timer
