import sjcl from './sjcl'
import omit from 'lodash/omit'
import defaults from 'lodash/defaults'
import isString from 'lodash/isString'

const _OCB2Slice = 2048

class Husher {
  constructor() {
    this.encryptionKey = null // El Gamal ECC keypair
    this.signingKey = null // ECDSA keypair
    this.macKey = null // a key form which the AES keys that encrypt the private keys are generated
    this.authKey = null // the key used (hashed) for authentication
    this.scryptSalt = null // the salt used by the scrypt KDF, only applicable for version 1
    this.pN = null // scrypt N
    this.pr = null // scrypt r
    this.pp = null // scrypt p
  }

  encrypt(pt, key, adata) {
    let defaultParams = husher._versions[husher._currVersion]
    let params
    let ct
    params = Object.assign({}, defaultParams)
    if (key && !(key instanceof sjcl.ecc.elGamal.publicKey) && !adata) {
      throw new Error('Only authenticated CCM supported')
    }

    key = key || this.encryptionKey.pub
    if (adata) {
      params.adata = adata
    }
    ct = JSON.parse(sjcl.encrypt(key, pt, params))
    ct = omit(ct, Object.keys(defaultParams))
    ct.v = husher._currVersion
    return JSON.stringify(ct)
  }

  decrypt(ct, key) {
    key = key || this.encryptionKey.sec
    ct = JSON.parse(ct)

    if (ct.v) {
      ct = defaults(ct, husher._versions[ct.v])
    }

    try {
      return sjcl.decrypt(key, JSON.stringify(ct))
    } catch (e) {
      try {
        ct.adata = husher._b64.fromBits(husher._utf8.toBits(ct.adata))
      } catch (e) {
        //
      }

      return sjcl.decrypt(key, JSON.stringify(ct))
    }
  }

  async encryptBinary(pt, key, adata) {
    if (typeof key === 'string') {
      key = husher._b64.toBits(key)
    }
    if (typeof adata === 'string') {
      try {
        adata = husher._b64.toBits(adata)
      } catch (error) {
        adata = husher._utf8.toBits(adata)
      }
    }

    const iv = husher._getRandomWords(4)
    let index = 0
    let ct = []
    let prp = new sjcl.cipher.aes(key)
    let encryptor = sjcl.mode.ocb2progressive.createEncryptor(prp, iv, adata)
    while (index < pt.length) {
      ct.push(...encryptor.process(pt.slice(index, index + _OCB2Slice)))
      index += _OCB2Slice
    }
    ct.push(...encryptor.finalize())

    return new Promise((resolve) =>
      resolve({
        ct,
        params: {
          ocb2: true,
          iv: sjcl.codec.base64.fromBits(iv),
          adata: sjcl.codec.base64.fromBits(adata),
        },
      }),
    )
  }

  async decryptBinaryProgressive(ct, key, iv, adata) {
    if (typeof key === 'string') {
      key = husher._b64.toBits(key)
    }
    if (typeof iv === 'string') {
      iv = husher._b64.toBits(iv)
    }
    if (typeof adata === 'string') {
      try {
        adata = husher._b64.toBits(adata)
      } catch (error) {
        adata = husher._utf8.toBits(adata)
      }
    }
    let index = 0
    let pt = []
    let prp = new sjcl.cipher.aes(key)
    let decryptor = sjcl.mode.ocb2progressive.createDecryptor(prp, iv, adata)
    while (index < ct.length) {
      pt.push(...decryptor.process(ct.slice(index, index + _OCB2Slice)))
      index += _OCB2Slice
    }
    pt.push(...decryptor.finalize())
    return pt
  }

  sign(data) {
    let hash = husher._hash(data)
    return husher._b64.fromBits(this.signingKey.sec.sign(hash))
  }

  verify(data, signature, publicKey) {
    let hash = husher._hash(data)
    signature = husher._b64.toBits(signature)
    publicKey = publicKey || this.signingKey.pub
    if (isString(publicKey)) {
      publicKey = husher.buildPublicKey(publicKey, 'ecdsa')
    }

    try {
      return publicKey.verify(hash, signature)
    } catch (error) {
      return false
    }
  }

  authHash() {
    return husher._b64.fromBits(husher._hash(this.authKey || ''))
  }

  generate(password, email) {
    let _this = this
    let scryptSalt

    email = email.trim().toLowerCase()

    // Use an email-derived salt
    scryptSalt = husher._hash(`${email}-crypho.com`).slice(0, 2)
    return husher
      ._strengthenScrypt(password, {
        salt: scryptSalt,
      })
      .then(function (strengthened) {
        _this.encryptionKey = sjcl.ecc.elGamal.generateKeys(husher._CURVE)
        _this.signingKey = sjcl.ecc.ecdsa.generateKeys(husher._CURVE)
        _this.macKey = strengthened.key // The strengthened key used to encrypt the private El Gamal keys
        _this.authKey = strengthened.key2 // The strengthened key used (hashed) for authentication
        _this.scryptSalt = strengthened.salt
        _this.pN = strengthened.N
        _this.pr = strengthened.r
        _this.pp = strengthened.p
        return _this
      })
  }

  // Return the user's public fingerprint, which is derived by hashing together the two public keys,
  // then using the first 16 hexadecimal characters.
  fingerprint() {
    let encryptionPublic = this.encryptionKey.pub._point.toBits()
    let signingPublic = this.signingKey.pub._point.toBits()
    return husher._hash(encryptionPublic.concat(signingPublic))
  }

  generateKeyAndEncryptToPublicKeys(userPublicKeys) {
    const key = husher.randomKey()

    const keys = Object.entries(userPublicKeys).reduce((dict, [userId, pKey]) => {
      if (!(pKey instanceof sjcl.ecc.elGamal.publicKey)) pKey = husher.buildPublicKey(pKey)
      dict[userId] = this.encrypt(key, pKey)
      return dict
    }, {})

    return {
      keys,
      signature: this.sign(key),
    }
  }

  toJSON(email) {
    let aesOptions
    let encryptedEncryptionPrivate
    let encryptedSigningPrivate
    let encrKey
    let encrSalt
    let signingKey
    let signSalt
    let mac

    email = email.trim().toLowerCase()
    aesOptions = defaults(
      {
        adata: email,
      },
      husher._versions['1'],
    )

    mac = new sjcl.misc.hmac(this.macKey)
    encrSalt = husher.randomKey()
    signSalt = husher.randomKey()
    encrKey = mac.mac(husher._b64.toBits(encrSalt))
    signingKey = mac.mac(husher._b64.toBits(signSalt))

    encryptedEncryptionPrivate = JSON.parse(
      sjcl.encrypt(encrKey, husher._b64.fromBits(this.encryptionKey.sec._exponent.toBits()), aesOptions),
    )

    encryptedSigningPrivate = JSON.parse(
      sjcl.encrypt(signingKey, husher._b64.fromBits(this.signingKey.sec._exponent.toBits()), aesOptions),
    )

    return {
      scrypt: {
        scryptSalt: husher._b64.fromBits(this.scryptSalt),
        pN: this.pN,
        pr: this.pr,
        pp: this.pp,
      },

      encKey: {
        macSalt: encrSalt,
        pub: husher._b64.fromBits(this.encryptionKey.pub._point.toBits()),
        sec: {
          macSalt: encrSalt,
          iv: encryptedEncryptionPrivate.iv,
          ct: encryptedEncryptionPrivate.ct,
          adata: encryptedEncryptionPrivate.adata,
        },
      },

      signingKey: {
        pub: husher._b64.fromBits(this.signingKey.pub._point.toBits()),
        sec: {
          macSalt: signSalt,
          iv: encryptedSigningPrivate.iv,
          ct: encryptedSigningPrivate.ct,
          adata: encryptedEncryptionPrivate.adata,
        },
      },

      authHash: this.authHash(),

      version: 2,
    }
  }

  fromJSON(passwd, json) {
    let exp
    let data
    // Regenerate key from password
    this.scryptSalt = husher._b64.toBits(json.scrypt.scryptSalt)
    return husher
      ._strengthenScrypt(passwd, {
        salt: this.scryptSalt,
      })
      .then((strengthened) => {
        let mac
        let macSalt
        let encKey
        this.macKey = strengthened.key
        this.authKey = strengthened.key2

        try {
          mac = new sjcl.misc.hmac(this.macKey)

          // First decrypt the private encryption key
          data = defaults(json.encKey.sec, husher._versions['1'])
          macSalt = json.encKey.sec.macSalt
          encKey = mac.mac(husher._b64.toBits(macSalt))

          // Calculate the curve's exponent
          exp = sjcl.bn.fromBits(husher._b64.toBits(this.decrypt(JSON.stringify(data), encKey)))

          this.encryptionKey = {
            sec: new sjcl.ecc.elGamal.secretKey(husher._CURVE, exp),
            pub: husher.buildPublicKey(json.encKey.pub),
          }

          // Then decrypt the private signing key
          data = defaults(json.signingKey.sec, husher._versions['1'])
          macSalt = json.signingKey.sec.macSalt
          encKey = mac.mac(husher._b64.toBits(macSalt))

          // Calculate the curve's exponent
          exp = sjcl.bn.fromBits(husher._b64.toBits(this.decrypt(JSON.stringify(data), encKey)))

          this.signingKey = {
            sec: new sjcl.ecc.ecdsa.secretKey(husher._CURVE, exp),
            pub: husher.buildPublicKey(json.signingKey.pub, 'ecdsa'),
          }
          return null
        } catch (error) {
          this.encryptionKey = null
          this.macKey = null
          this.scryptSalt = null
          throw error
        }
      })
  }

  toSession() {
    return {
      encryptionKey: {
        pub: husher._b64.fromBits(this.encryptionKey.pub._point.toBits()),
        sec: husher._b64.fromBits(this.encryptionKey.sec._exponent.toBits()),
      },
      signingKey: {
        pub: husher._b64.fromBits(this.signingKey.pub._point.toBits()),
        sec: husher._b64.fromBits(this.signingKey.sec._exponent.toBits()),
      },
      authKey: this.authKey,
    }
  }

  fromSession(json) {
    let exp = sjcl.bn.fromBits(husher._b64.toBits(json.encryptionKey.sec))
    this.encryptionKey = {
      sec: new sjcl.ecc.elGamal.secretKey(husher._CURVE, exp),
      pub: husher.buildPublicKey(json.encryptionKey.pub),
    }
    exp = sjcl.bn.fromBits(husher._b64.toBits(json.signingKey.sec))
    this.signingKey = {
      sec: new sjcl.ecc.ecdsa.secretKey(husher._CURVE, exp),
      pub: husher.buildPublicKey(json.signingKey.pub, 'ecdsa'),
    }
    this.authKey = json.authKey
  }

  isInitialized() {
    return this.encryptionKey && this.signingKey && this.authKey
  }
}
const husher = {
  _CURVE: sjcl.ecc.curves.c384,
  _b64: sjcl.codec.base64,
  _utf8: sjcl.codec.utf8String,
  _hex: sjcl.codec.hex,
  _bytes: sjcl.codec.bytes,
  _hash: sjcl.hash.sha256.hash,

  _versions: {
    1: {
      v: 1,
      iter: 1000,
      ks: 256,
      ts: 128,
      mode: 'ccm',
      cipher: 'aes',
    },
  },

  _currVersion: 1,

  Husher,

  _getRandomWords(count) {
    return sjcl.random.randomWords(count)
  },

  _generateKeyPair() {
    return sjcl.ecc.elGamal.generateKeys(husher._CURVE)
  },

  _generateSigningKeyPair() {
    return sjcl.ecc.ecdsa.generateKeys(husher._CURVE)
  },

  scrypt(passwd, options) {
    return sjcl.misc.scrypt(passwd, options.salt, options.N, options.r, options.p, options.dkLen * 8)
  },

  async _strengthenScrypt(passwd, options = {}) {
    options = Object.assign(
      {
        N: 16384,
        r: 8,
        p: 1,
        dkLen: 64,
        salt: husher._getRandomWords(2),
      },
      options,
    )

    const key = await this.scrypt(passwd, options)
    // eslint-disable-next-line require-atomic-updates
    options.key = key.splice(0, 8)
    // eslint-disable-next-line require-atomic-updates
    options.key2 = key

    return options
  },

  randomKey() {
    // 8 words, for a 256 bit key.
    return sjcl.codec.base64.fromBits(husher._getRandomWords(8))
  },

  randomId() {
    return sjcl.codec.hex.fromBits(husher._getRandomWords(2))
  },

  buildPublicKey(key, family) {
    if (family === 'ecdsa') {
      return new sjcl.ecc.ecdsa.publicKey(husher._CURVE, husher._b64.toBits(key))
    }

    return new sjcl.ecc.elGamal.publicKey(husher._CURVE, husher._b64.toBits(key))
  },

  progress() {
    return sjcl.random.getProgress()
  },
}

global.husher = new Husher()

// Patch sjcl random.
// Set default paranoia
sjcl.random.setDefaultParanoia(8)

export default husher
