import * as Sentry from '@sentry/core'
import { Severity as SentrySeverity } from '@sentry/types'
import uuid from 'uuid/v4'
import xml from '@xmpp/xml'
import { client as NewClient } from '@xmpp/client/browser'
import setupPubsub from '@xmpp-plugins/pubsub'
import setupRoster from '@xmpp-plugins/roster'
import setupCrypho from './crypho'
import setupRTC from './webrtc'
import setupPrivateStorage from './private'
import store from '../store'
import { setXmppStatus } from '../store/modules/xmpp'
import { CryphoAPI } from '../api'
import { stop as stopXmpp } from '.'

export let xmppClient = null
export let pubsubPlugin = null
export let rosterPlugin = null
export let cryphoPlugin = null
export let pingPlugin = null
export let rtcPlugin = null
export let privatePlugin = null

let bindResource = uuid()
if (process.env.REACT_APP_DESKTOP || (window && window.process && window.process.type))
  bindResource = `desktop-${bindResource}`
else if (typeof document === 'undefined') bindResource = `mobile-${bindResource}`
else bindResource = `web-${bindResource}`

/**
 * Retrieve the JID for the current user.
 *
 * @param {string} authHash
 * @returns {Promise<string>} JID for the user
 */
const getJid = async (authHash) => {
  const state = store.getState()
  const { apiUrl } = state.config
  const api = new CryphoAPI(apiUrl)
  const connectionInfo = await api.post('/auth/wss', {
    authHash,
    platform: 'react',
    version: '1',
  })
  return connectionInfo.jid
}

/**
 * Authentication handler for XMPP.js
 *
 * See https://github.com/xmppjs/xmpp.js/tree/master/packages/client for details
 *
 * @param {{username: string, password:string} => Promise<void>} auth xmpp.js authentication handler
 */
async function authenticate(auth) {
  const authHash = global.husher.authHash()
  let jid
  try {
    jid = await getJid(authHash)
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error('🗣 Authhash is not valid')
    stopXmpp()
    throw error
  }

  try {
    await auth({ username: jid, password: authHash })
  } catch (error) {
    Sentry.addBreadcrumb({
      category: 'xmpp',
      message: 'Authentication failed',
      level: SentrySeverity.Info,
    })
    // eslint-disable-next-line no-console
    console.error('🗣 XMPP authentication failed', error)
    throw error
  }
}

/**
 * Create the XMPP client. This should only be called once
 *
 * @returns {createXmppClient} New client instance
 */
export function createXmppClient() {
  if (xmppClient) {
    xmppClient.removeAllListeners('online')
    xmppClient.removeAllListeners('status')
    xmppClient.removeAllListeners('ready')
    xmppClient.removeAllListeners('disconnect')
    xmppClient.removeAllListeners('stanza')
    xmppClient.reconnect.removeAllListeners('reconnected')
    // We do not need to wait for client to finish disconnecting
    xmppClient.stop().catch(() => {})
  }

  const { config } = store.getState()
  xmppClient = NewClient({
    resource: bindResource,
    service: config.xmppWebsocketUrl,
    domain: config.xmppDomain,
    credentials: authenticate,
  })
  pubsubPlugin = setupPubsub(xmppClient)
  rosterPlugin = setupRoster(xmppClient)
  cryphoPlugin = setupCrypho(xmppClient, config.xmppCrypho, config.xmppDomain)
  rtcPlugin = setupRTC(xmppClient, config.xmppCrypho, config.xmppDomain)
  privatePlugin = setupPrivateStorage(xmppClient)

  // Ignore XMPP client errors here, since we already handle them through
  // the XMPP status. We need the event handler so error
  // events do not result in an uncaught exception.
  xmppClient.on('error', () => {})
  xmppClient.on('status', (status) => {
    Sentry.addBreadcrumb({
      category: 'xmpp',
      message: `XMPP changed status to ${status}`,
      level: SentrySeverity.Info,
    })
    // eslint-disable-next-line no-console
    console.log(`🗣 XMPP changed status to ${status}`)
    store.dispatch(setXmppStatus(status))
  })
  return xmppClient
}

/**
 * Start a XMPP client. This should only be called once
 *
 * @returns {Promise<void>}
 */
export function startClient() {
  if (!xmppClient) {
    throw new Error('You must call createClient first')
  }

  // Make sure the reconnect logic is running, without duplicate events.
  xmppClient.reconnect.stop()
  xmppClient.reconnect.start()

  if (xmppClient.status === 'offline' || xmppClient.status === 'unknown') {
    Sentry.addBreadcrumb({
      category: 'xmpp',
      message: 'Starting XMPP client',
      level: SentrySeverity.Info,
    })

    return xmppClient.start()
  }
}

/**
 * Synchronise the desired presence as stored in Redux with the server.
 *
 * @params {string|undefined} to JID to send presence information to.
 * @returns {Promise<void>}
 */
export async function syncPresence(to) {
  if (xmppClient.status !== 'online') {
    // We only send the presence if the client is online. In other cases
    // the presence will be send automatically when the client comes online.
    return
  }
  const { available, show, status } = store.getState().xmpp.presence
  const stanza = xml('presence', { to, type: available ? undefined : 'unavailable' })
  if (show) {
    stanza.append(xml('show', {}, show))
  }
  if (status) {
    stanza.append(xml('status', {}, status))
  }
  await xmppClient.send(stanza)
}
