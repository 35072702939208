import { crypho, pubsub, xml } from './xmpp'
import { serverTime } from './utils'
import store from './store'
import { getSpaceCurrentKey } from './store/modules/space/selectors'

// Number of seconds in a day
const DAY = 86400

// List of allowed ttl values for content expiry
export const COMMON_CONTENT_EXPIRY_OPTIONS = [
  { value: null, label: 'never expire' },
  { value: 1 * DAY, label: 'a day' },
  { value: 7 * DAY, label: 'a week' },
  { value: 30 * DAY, label: 'a month' },
  { value: 180 * DAY, label: 'half a year' },
  { value: 365 * DAY, label: 'a year' },
]

/**
 * Determine the public data for vault items
 *
 * @param {*} vaultItem  Pubsub item in the vault node
 * @returns {{vault_items: []string}}
 */
function publicDataForVaultItem(vaultItem) {
  const vault_items = [vaultItem.uid]
  if (vaultItem.thumbnail?.uid) {
    vault_items.push(vaultItem.thumbnail.uid)
  }
  return { vault_items }
}

/**
 * Create a pubsub item object
 *
 * This creates an object that uses the standard item structure used
 * in all pubsub nodes.
 *
 * @param {string} type Item type
 * @param {item} content
 */
export function createPubsubItem(type, content) {
  const state = store.getState()
  const now = serverTime()
  const myJID = state.identity.jid

  return {
    type,
    content,
    author: myJID,
    created: now,
    updated: now,
  }
}

/**
 * Publish an item to a pubsub node. This will encrypt the item as well.
 *
 * @param {string} spaceId The ID for the space
 * @param {string} nodeId
 * @param {item} item
 * @param {string|undefined} pubsubItemId The desired id of the pubsub item
 * @returns {Promise<string>} The pubsub item id
 */
export function publishToPubsubNode(spaceId, nodeId, item, pubsubItemId, updated = false) {
  const state = store.getState()
  const spaceKey = getSpaceCurrentKey(state, { spaceId })
  const xmppPubsub = state.config.xmppPubsub
  const publicData = nodeId.endsWith('/vault') ? publicDataForVaultItem(item) : {}

  // If no expiry is set, apply the group default. Note that we explicitly allow
  // expires=null to opt out of expiry.
  if (item.expires === undefined) {
    const { defaultExpiry } = state.spaces.byId[spaceId]
    if (defaultExpiry) {
      item.expires = serverTime(defaultExpiry)
    }
  }

  const entry = xml(
    'item',
    { id: pubsubItemId },
    xml(
      'entry',
      { updated: updated ? '' : undefined },
      JSON.stringify({
        ...publicData,
        kid: spaceKey.id,
        payload: global.husher.encrypt(JSON.stringify(item), spaceKey.key, store.getState().identity.jid),
      }),
    ),
  )
  return pubsub.publish(xmppPubsub, nodeId, entry)
}

/**
 * Create **and publish** an item in a space's infostream.
 *
 * @param {any} data The item content
 * @param {string} type The Crypho item type (`chatMessage`, `file`, etc.)
 * @param {string} spaceId The ID for the space
 * @returns {Promise<string>} The pubsub item ID
 */
export function createActionItem(data, type, spaceId, pubsubItemId) {
  const item = createPubsubItem(type, data)
  return publishToPubsubNode(spaceId, `/spaces/${spaceId}/infostream`, item, pubsubItemId)
}

/**
 * Delete a message from an infostream
 *
 * @param {string} spaceId The ID for the space
 * @param {string} itemId The pubsub item id for the message to delete
 * @param {any} item The message to delete
 * @returns {Promise<string>} The pubsub item ID that was updated
 */
export function deleteInfostreamMessage(spaceId, itemId, item) {
  const newItem = {
    ...createPubsubItem('messageDeleted', undefined),
    author: item.author,
    created: item.created,
  }

  return publishToPubsubNode(spaceId, `/spaces/${spaceId}/infostream`, newItem, itemId)
}

/**
 * Set the expiry time for a single pubsub item.
 *
 * @param {string} spaceId The ID for the space
 * @param {string} node Pubsub node id (for example `/spaces/19su2k1289109/infostream`)
 * @param {string} itemId The pubsub item id to update
 * @param {any} item The pubsub item to update
 * @param {number|null} ttl Expiry time in seconds, or null if no default expiry
 * @returns {Promise<string>} The pubsub item ID that was updated
 */

export async function setPubsubItemTtl(spaceId, nodeId, itemId, item, ttl) {
  const now = serverTime()

  if (item.type === 'file' && item.content.vault_item) {
    const state = store.getState()
    itemId = item.content.vault_item
    nodeId = item.content.node_id
    item = state.pubsub.itemsById[itemId]
  }

  const expires = ttl ? serverTime(ttl) : null
  const newItem = {
    ...item,
    expires,
    updated: now,
  }
  await crypho.setPubsubItemTtl(nodeId, itemId, ttl)
  return publishToPubsubNode(spaceId, nodeId, newItem, itemId, true)
}
