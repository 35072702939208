import React from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { InfostreamFileContentPropType, deleteFile } from '../../../../../file-handling'
import ActionWrapper from '../ActionWrapper'
import FileWithoutThumbnail from './FileWithoutThumbnail'
import FileWithThumbnail from './FileWithThumbnail'

function FileItem({ isDeleting, spaceId, showFullName, authorVCard, content }) {
  const vaultItem = useSelector((state) => state.pubsub.itemsById[content.vault_item])
  const handleDelete = () => deleteFile(spaceId, content.vault_item, vaultItem)

  if (!vaultItem) {
    return null
  }

  return (
    <ActionWrapper onDelete={handleDelete} isDeleting={isDeleting}>
      {vaultItem.thumbnail ? (
        <FileWithThumbnail
          spaceId={spaceId}
          showFullName={showFullName}
          authorVCard={authorVCard}
          itemId={content.vault_item}
          item={vaultItem}
        />
      ) : (
        <FileWithoutThumbnail
          spaceId={spaceId}
          showFullName={showFullName}
          authorVCard={authorVCard}
          itemId={content.vault_item}
          item={vaultItem}
        />
      )}
    </ActionWrapper>
  )
}

FileItem.propTypes = {
  isDeleting: PropTypes.bool.isRequired,
  showFullName: PropTypes.bool.isRequired,
  authorVCard: PropTypes.object.isRequired,
  spaceId: PropTypes.string.isRequired,
  itemId: PropTypes.string.isRequired,
  content: InfostreamFileContentPropType.isRequired,
}

export default FileItem
