import React from 'react'
import PropTypes from 'prop-types'
import * as Sentry from '@sentry/core'
import { Container, Dropdown, Button, Image, Icon } from 'semantic-ui-react'
import localforage from 'localforage'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { CallContext } from '../../crypho.core/rtc'
import Draggable from 'react-draggable'

import Timer from './timer'
import { getCallerName } from './utils'
import './call.css'

class ActiveCall extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      screenSharing: false,
      localAudioMuted: false,
      localVideoMuted: false,
      remoteMuteState: { audio: false, video: false },
    }

    this.localVideoReference = React.createRef()
    this.remoteVideoReference = React.createRef()
  }

  async componentDidMount() {
    const { manager } = this.context
    this.context.setVideoReference(this.localVideoReference, this.remoteVideoReference)
    const preferredAudioOutput = await localforage.getItem('preferredAudioOutput')
    if (preferredAudioOutput) {
      try {
        await this.context.setOutputDevice(preferredAudioOutput)
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error('Output device not present')
      }
    }

    manager.events.addListener('remote-mute', (remoteMuteState) => this.setState({ remoteMuteState }))
  }

  toggleFullscreen() {
    let video = document.querySelector('#wrtc')

    if (!document.fullscreenElement) {
      video.requestFullscreen().catch((error) => {
        alert(`Error attempting to enable full-screen mode: ${error.message} (${error.name})`)
      })
    } else {
      document.exitFullscreen()
    }
  }

  async componentWillUnmount() {
    const { manager } = this.context
    this.context.setVideoReference(null, null)
    manager.events.removeAllListeners('remote-mute')
  }

  onMuteVideoClick = () => {
    const { localStreamCache } = this.context
    let { localVideoMuted, localAudioMuted } = this.state
    const videoTrack = localStreamCache.getVideoTracks()[0]
    localVideoMuted = !localVideoMuted
    videoTrack.enabled = !localVideoMuted
    this.context.sendMuteState({ audio: localAudioMuted, video: localVideoMuted })
    this.setState({ localVideoMuted: localVideoMuted })
  }

  onMuteMicrophoneClick = () => {
    const { localStreamCache } = this.context
    let { localVideoMuted, localAudioMuted } = this.state
    const audioTrack = localStreamCache.getAudioTracks()[0]
    localAudioMuted = !localAudioMuted
    audioTrack.enabled = !localAudioMuted
    this.context.sendMuteState({ audio: localAudioMuted, video: localVideoMuted })
    this.setState({ localAudioMuted: localAudioMuted })
  }

  startScreenSharing = async () => {
    const { peerConnection, localStreamCache } = this.context
    try {
      let stream
      if (process.env.REACT_APP_DESKTOP) {
        const { desktopCapturer } = await import('electron')
        const sources = await desktopCapturer.getSources({ types: ['screen'] })
        if (!sources) {
          Sentry.captureException('Desktop capturer did not find any sources')
          return
        }
        stream = await navigator.mediaDevices.getUserMedia({
          audio: false,
          video: {
            mandatory: {
              chromeMediaSource: 'desktop',
              chromeMediaSourceId: sources[0].id,
            },
          },
        })
      } else {
        stream = await navigator.mediaDevices.getDisplayMedia({ video: true })
      }
      const newTrack = stream.getVideoTracks()[0]
      const sender = peerConnection.getSenders().find((s) => s.track && s.track.kind == 'video')
      await sender.replaceTrack(newTrack)
      const oldTrack = localStreamCache.getVideoTracks()[0]
      oldTrack.stop()
      localStreamCache.removeTrack(oldTrack)
      localStreamCache.addTrack(newTrack)
      this.setState({ screenSharing: true })
    } catch (error) {
      // Either getDisplayMedia does not exist or there was a cancel.
      // eslint-disable-next-line no-console
      console.error('Could not share screen, or user cancelled')
    }
  }

  stopScreenSharing = async () => {
    const { peerConnection, localStreamCache, createMediaStream } = this.context
    const stream = await createMediaStream({ video: true })
    const newTrack = stream.getVideoTracks()[0]
    const sender = peerConnection.getSenders().find((s) => s.track && s.track.kind == 'video')
    await sender.replaceTrack(newTrack)
    const oldTrack = localStreamCache.getVideoTracks()[0]
    oldTrack.stop()
    localStreamCache.removeTrack(oldTrack)
    localStreamCache.addTrack(newTrack)
    this.setState({ screenSharing: false })
  }

  async onDeviceChange(type, deviceId) {
    const { peerConnection, localStreamCache, createMediaStream } = this.context
    const constraints = { [type]: { deviceId: { exact: deviceId } } }
    const stream = await createMediaStream(constraints)
    const newTrack = type === 'audio' ? stream.getAudioTracks()[0] : stream.getVideoTracks()[0]
    const sender = peerConnection.getSenders().find((s) => s.track && s.track.kind == type)
    await sender.replaceTrack(newTrack)
    const oldTrack = type === 'audio' ? localStreamCache.getAudioTracks()[0] : localStreamCache.getVideoTracks()[0]
    oldTrack.stop()
    localStreamCache.removeTrack(oldTrack)
    localStreamCache.addTrack(newTrack)
    await localforage.setItem(type === 'audio' ? 'preferredAudioInput' : 'preferredVideoInput', deviceId)
  }

  async onSpeakerChange(deviceId) {
    const { setOutputDevice } = this.context
    try {
      await setOutputDevice(deviceId)
      await localforage.setItem('preferredAudioOutput', deviceId)
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('Setting output device not supported')
    }
  }

  callControls = () => {
    const { hangup, requestedMedia, remoteJid, getDevicesByType, localStreamCache, callState } = this.context
    const { remoteMuteState } = this.state

    if (callState === 'connecting')
      return (
        <React.Fragment>
          <span className="callHeader">Connecting…</span>
        </React.Fragment>
      )

    const { screenSharing, localAudioMuted, localVideoMuted } = this.state
    const callerName = getCallerName(remoteJid)
    const isVideoCall = requestedMedia.video
    const selectedAudioInputLabel = localStreamCache && localStreamCache.getAudioTracks()[0].label
    const selectedVideoInputLabel = isVideoCall && localStreamCache && localStreamCache.getVideoTracks()[0].label
    const mutedText =
      // eslint-disable-next-line unicorn/no-nested-ternary
      remoteMuteState.audio && remoteMuteState.video
        ? [
            <p key="muted" className="contactsName">
              {callerName}
            </p>,
            `has disabled video and audio`,
          ]
        : remoteMuteState.audio
        ? [
            <p key="muted" className="contactsName">
              {callerName}
            </p>,
            `has muted audio`,
          ]
        : // eslint-disable-next-line unicorn/no-nested-ternary
        remoteMuteState.video
        ? [
            <p key="muted" className="contactsName">
              {callerName}
            </p>,
            `has disabled video`,
          ]
        : null

    const microphones = getDevicesByType('audioinput')
      .filter((device) => device.label)
      .map((device) => ({
        key: device.deviceId,
        value: device.deviceId,
        text: device.label,
        active: device.label === selectedAudioInputLabel,
        selected: device.label === selectedAudioInputLabel,
      }))
    const cameras = getDevicesByType('videoinput')
      .filter((device) => device.label)
      .map((device) => ({
        key: device.deviceId,
        value: device.deviceId,
        text: device.label,
        active: device.label === selectedVideoInputLabel,
        selected: device.label === selectedVideoInputLabel,
      }))
    const speakers = getDevicesByType('audiooutput')
      .filter((device) => device.label)
      .map((device) => ({ key: device.deviceId, value: device.deviceId, text: device.label }))
    const settings = microphones.length > 0 && cameras.length > 0 && speakers.length > 0

    return (
      <React.Fragment>
        <Timer />
        {isVideoCall && (
          <Icon
            link
            className="screenSize"
            onClick={this.toggleFullscreen}
            name={document.fullscreenElement ? 'compress' : 'expand'}
          />
        )}
        {settings ? (
          <Dropdown icon="setting" className="callSettings" direction="left">
            <Dropdown.Menu>
              {isVideoCall && cameras.length > 0 ? (
                <React.Fragment>
                  <Dropdown
                    text="Camera"
                    pointing="left"
                    direction="left"
                    position="right"
                    className="link item"
                    onChange={(ev, { value }) => {
                      this.onDeviceChange('video', value)
                    }}
                    options={cameras}
                  />
                  <Dropdown.Divider />
                </React.Fragment>
              ) : null}
              {microphones.length > 0 ? (
                <React.Fragment>
                  <Dropdown
                    text="Microphone"
                    pointing="left"
                    direction="left"
                    position="right"
                    className="link item"
                    onChange={(ev, { value }) => {
                      this.onDeviceChange('audio', value)
                    }}
                    options={microphones}
                  />
                  <Dropdown.Divider />
                </React.Fragment>
              ) : null}
              {speakers.length > 0 ? (
                <Dropdown
                  text="Speaker"
                  pointing="left"
                  direction="left"
                  position="right"
                  className="link item"
                  onChange={(ev, { value }) => {
                    this.onSpeakerChange(value)
                  }}
                  options={speakers}
                />
              ) : null}
            </Dropdown.Menu>
          </Dropdown>
        ) : null}
        <div className="callControls">
          {mutedText && <div className="muteTextWrapper">{mutedText}</div>}
          {isVideoCall && (
            <Button
              id="share-screen"
              circular
              icon="tv"
              onClick={() => {
                screenSharing ? this.stopScreenSharing() : this.startScreenSharing()
              }}
              color={screenSharing ? undefined : 'blue'}
            />
          )}

          {isVideoCall && (
            <Button
              id="mute-video"
              circular
              icon="video"
              onClick={this.onMuteVideoClick}
              color={localVideoMuted ? 'blue' : undefined}
            />
          )}
          <Button
            id="mute-audio"
            circular
            icon="microphone"
            onClick={this.onMuteMicrophoneClick}
            color={localAudioMuted ? 'blue' : undefined}
          />
          <Button id="hangup-call" circular icon="call" onClick={() => hangup()} color="red" />
        </div>
      </React.Fragment>
    )
  }

  renderAudioView() {
    const { remoteJid } = this.context
    const { apiUrl } = this.props

    return (
      <Draggable bounds="parent">
        <Container
          id="wrtc"
          style={{
            ...this.props.containerStyle,
            backgroundColor: 'white',
          }}
        >
          {this.callControls()}
          <div className="audioCallerAvatar">
            <Image size="small" avatar src={`${apiUrl}/avatar/${remoteJid.split('@')[0]}`} />
            <p className="contactsName">{getCallerName(remoteJid)}</p>
          </div>
          <video id="local-video" hidden muted ref={this.localVideoReference} autoPlay />
          <video id="remote-video" hidden ref={this.remoteVideoReference} autoPlay />
        </Container>
      </Draggable>
    )
  }

  renderVideoView() {
    const { screenSharing } = this.state
    return (
      <Draggable bounds="parent">
        <Container id="wrtc" style={this.props.containerStyle}>
          {this.callControls()}
          <video
            id="local-video"
            className={screenSharing ? '' : 'mirrored'}
            muted
            ref={this.localVideoReference}
            autoPlay
          />
          <video id="remote-video" ref={this.remoteVideoReference} autoPlay />
        </Container>
      </Draggable>
    )
  }

  render() {
    const { requestedMedia } = this.context
    if (requestedMedia.video) {
      return this.renderVideoView()
    }
    return this.renderAudioView()
  }
}

ActiveCall.contextType = CallContext

ActiveCall.propTypes = {
  containerStyle: PropTypes.shape(),
  apiUrl: PropTypes.string.isRequired,
}

const mapStateToProperties = (state) => ({
  apiUrl: state.config.apiUrl,
})

const connector = (container) => connect(mapStateToProperties, null)(container)

export default withRouter(connector(ActiveCall))
