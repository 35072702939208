import React, { PureComponent } from 'react'
import { Progress } from 'semantic-ui-react'
import PropTypes from 'prop-types'
import zxcvbn from 'zxcvbn'
import { MIN_PASSWORD_ENTROPY } from '../forms'

const strengthColors = {
  0: 'grey',
  1: 'red',
  2: 'orange',
  3: 'yellow',
  4: 'green',
}

class PassphraseStrengthMeter extends PureComponent {
  render() {
    const { passphrase } = this.props
    const strength = zxcvbn(passphrase)
    const minPasswordEntropy = window.minPasswordEntropy ? window.minPasswordEntropy : MIN_PASSWORD_ENTROPY
    const timeEstimate = strength.crack_times_display.offline_slow_hashing_1e4_per_second

    return (
      <React.Fragment>
        <Progress
          total={minPasswordEntropy}
          value={strength.score}
          label={
            strength.score
              ? strength.score > 3
                ? `Takes ${timeEstimate} to crack`
                : `Can be cracked in ${timeEstimate}`
              : null
          }
          color={strengthColors[strength.score]}
          size="small"
        />
      </React.Fragment>
    )
  }
}

PassphraseStrengthMeter.propTypes = {
  passphrase: PropTypes.string.isRequired,
}

export default PassphraseStrengthMeter
