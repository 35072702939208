import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import moment from 'moment'
import { Grid, Icon, Menu, Popup, Dropdown } from 'semantic-ui-react'
import { fetchUserVCard } from 'crypho.core/store/modules/vcard'
import { crypho } from 'crypho.core/xmpp'
import RosterAvatar from '../../../../UI/widgets/rosteravatar'
import SpaceCreatedItem from './SpaceCreatedItem'
import FileDeletedItem from './FileDeletedItem'
import MessageDeletedItem from './MessageDeletedItem'
import GeoLocationItem from './GeoLocationItem'
import ChatMessageItem from './ChatMessageItem'
import UnsuportedItem from './UnsuportedItem'
import FileItem from './FileItem'
import ItemExpirySelector from './ItemExpirySelector'

const TODAY = moment(new Date()).format('YYYYMMDD')

class InfoStreamItem extends React.Component {
  constructor(properties) {
    super(properties)
    this.state = {
      isDeleting: false,
      seenBy: '',
    }
  }

  componentDidMount = () => {
    const { authorVCard, fetchUserVCard, authorJID } = this.props
    if (!authorVCard) {
      fetchUserVCard(authorJID)
    }
  }

  updatedOn() {
    const updated = moment(this.props.item.updated)
    if (updated.format('YYYYMMDD') === TODAY) return updated.format('HH:mm')
    else if (moment().year() === updated.year()) return updated.format('MMM DD, HH:mm')
    return updated.format('MMM DD YYYY, HH:mm')
  }

  updateSeenBy = async () => {
    const { item, vcards } = this.props
    const { updated } = item
    const lastRead = await crypho.getSpaceLastSeen(this.props.spaceId)
    const seenBy = Object.keys(lastRead)
      .reduce((seenBy, uid) => {
        if (lastRead[uid] > updated) seenBy.push(uid)
        return seenBy
      }, [])
      .map((uid) => vcards[uid.split('@')[0]].FN)
      .join(', ')
    this.setState({ seenBy })
  }

  renderContent() {
    const { id, item, authorVCard, myJID, spaceType, spaceId } = this.props
    if (!authorVCard) return null
    let { content } = item
    const showFullName = spaceType === 'group' && item.author !== myJID
    switch (item.type) {
      case 'spaceCreated':
        return <SpaceCreatedItem authorVCard={authorVCard} />
      case 'file':
        return (
          <FileItem
            showFullName={showFullName}
            authorVCard={authorVCard}
            spaceId={spaceId}
            itemId={id}
            content={content}
            isDeleting={this.state.isDeleting}
          />
        )
      case 'fileDeleted':
        return <FileDeletedItem authorVCard={authorVCard} content={content} />
      case 'messageDeleted':
        return <MessageDeletedItem authorVCard={authorVCard} />
      case 'geolocation':
        return (
          <GeoLocationItem
            authorVCard={authorVCard}
            id={id}
            spaceId={spaceId}
            isDeleting={this.state.isDeleting}
            item={item}
            content={content}
          />
        )

      default:
        if (item.type === 'chatMessage' || !item.type) {
          return (
            <ChatMessageItem
              id={id}
              spaceId={spaceId}
              isDeleting={this.state.isDeleting}
              showFullName={showFullName}
              authorVCard={authorVCard}
              item={item}
              content={content}
            />
          )
        } else {
          console.error(`Unsupported item type: ${item.type}`)
          return <UnsuportedItem id={id} showFullName={showFullName} authorVCard={authorVCard} />
        }
    }
  }

  renderActionMenu() {
    const { spaceId, nodeId, id, item, myJID, vcards, vaultItem } = this.props

    const isMe = item.author === myJID
    const isChatMessage = !item.type || item.type === 'chatMessage'
    const isLocationMessage = item.type === 'geolocation'
    const isFile = item.type === 'file'
    const canDelete = isMe && (isChatMessage || isLocationMessage || isFile) // file items
    const showExpires = item.type !== 'spaceCreated' && item.type !== 'messageDeleted' && item.type !== 'fileDeleted'

    let accessByTitle = ''
    let accessByContent = ''
    let accessByAction = () => {}
    let accessByMenuItem = null

    if (isFile && vaultItem.downloadedBy) {
      accessByContent = vaultItem.downloadedBy
        .map((userId) => (vcards[userId] && vcards[userId].FN) || '')
        .sort()
        .join(', ')
      accessByTitle = vaultItem.type.startsWith('image/') ? 'Viewed by' : 'Downloaded by'
      accessByContent = `${accessByTitle}: ${accessByContent}`
    } else if (isChatMessage || isLocationMessage) {
      accessByTitle = 'Seen by'
      accessByContent = `Seen by: ${this.state.seenBy}`
      accessByAction = this.updateSeenBy
    }

    if (accessByTitle) {
      accessByMenuItem = (
        <Menu.Item as="a">
          <Popup
            hoverable={true}
            on="click"
            trigger={<Icon id="c-seen" name="eye" title={accessByTitle} onClick={accessByAction} />}
            content={accessByContent}
            basic
          />
        </Menu.Item>
      )
    }

    return (
      <Menu compact>
        {accessByMenuItem}
        {canDelete ? (
          <Menu.Item as="a">
            <div
              onClick={() => {
                this.setState({ isDeleting: !this.state.isDeleting })
              }}
            >
              {this.state.isDeleting ? (
                <Icon name="remove" />
              ) : (
                <Icon id="c-delete" name="trash" title="Delete message" />
              )}
            </div>
          </Menu.Item>
        ) : null}
        {showExpires ? (
          isMe ? (
            <Menu.Item>
              <ItemExpirySelector nodeId={nodeId} spaceId={spaceId} itemId={id} item={item} />
            </Menu.Item>
          ) : (
            <Menu.Item>
              <Popup
                basic
                on="click"
                content={
                  item.expires ? `Message expires ${moment(item.expires).fromNow()}` : 'This message does not expire'
                }
                trigger={<Dropdown icon="clock outline" />}
              />
            </Menu.Item>
          )
        ) : null}
      </Menu>
    )
  }

  render() {
    const { item, myJID, authorId, spaceType } = this.props
    const isMe = item.author === myJID
    const showAvatar = !isMe && spaceType === 'group'
    if (item.type === 'fileUploaded') return null

    return (
      <Grid columns={2}>
        <Grid.Row className={`infostreamitem ${isMe ? 'out' : 'in'}`}>
          {showAvatar ? <RosterAvatar userId={authorId} /> : null}
          <Grid.Column width={14}>
            {this.renderContent()}
            <Grid.Row className={`infostreamitemUpdated ${isMe ? 'out' : 'in'}`}>
              {this.updatedOn()}
              {this.renderActionMenu()}
            </Grid.Row>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    )
  }
}

InfoStreamItem.propTypes = {
  myJID: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  authorId: PropTypes.string.isRequired,
  authorJID: PropTypes.string.isRequired,
  authorVCard: PropTypes.shape({
    FN: PropTypes.string.isRequired,
  }),
  item: PropTypes.shape({
    author: PropTypes.string.isRequired,
    created: PropTypes.any.isRequired,
    updated: PropTypes.any.isRequired,
    expires: PropTypes.any,
    type: PropTypes.string,
    content: PropTypes.any.isRequired,
    seenBy: PropTypes.arrayOf(PropTypes.string),
  }),
  vaultItem: PropTypes.shape({
    name: PropTypes.string.isRequired,
    size: PropTypes.number.isRequired,
    type: PropTypes.string.isRequired,
    downloadedBy: PropTypes.arrayOf(PropTypes.string),
  }),
  nodeId: PropTypes.string.isRequired,
  spaceId: PropTypes.string.isRequired,
  spaceType: PropTypes.string.isRequired,
  vcards: PropTypes.any.isRequired,
  fetchUserVCard: PropTypes.func.isRequired,
}

const mapStateToProperties = (state, ownProperties) => {
  const { pubsub, vcards } = state
  const item = pubsub.itemsById[ownProperties.id]
  if (!item) return {}
  const { author } = item
  const authorId = author ? author.split('@')[0] : ''
  let vcard = vcards.byId[authorId]
  const vaultItem = item.content && item.content.vault_item && state.pubsub.itemsById[item.content.vault_item]
  return {
    item,
    myJID: state.identity.jid,
    authorJID: author,
    authorId,
    authorVCard: vcard,
    vcards: state.vcards.byId,
    vaultItem,
  }
}
const mapDispatchToProperties = {
  fetchUserVCard,
}
const connector = (container) => connect(mapStateToProperties, mapDispatchToProperties)(container)

export default connector(InfoStreamItem)
