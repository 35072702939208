/* eslint-disable unicorn/no-nested-ternary */
import React from 'react'
import PropTypes from 'prop-types'
import { Container, Header, Icon, Tab, Dropdown, Popup } from 'semantic-ui-react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { NotificationManager } from 'react-notifications'
import { CallContext } from '../../../crypho.core/rtc'
import { client, crypho } from '../../../crypho.core/xmpp'
import { fetchStreams } from '../../../crypho.core/store/modules/pubsub'
import { getSpaceOnlineMembersUserIds, getSpaceTitle } from '../../../crypho.core/store/modules/space/selectors'
import { chatToText } from '../../../crypho.core/space'
import { fetchSpaceMetadata, updateSpace } from '../../../crypho.core/store/modules/space'
import { getFingerprintVerifiedContacts } from '../../../crypho.core/store/modules/roster/selectors'
import { getSpaceMembersUserIds } from '../../../crypho.core/store/modules/space/selectors'
import ErrorBoundary from '../../../components/ErrorBoundary'
import InfoStream from '../pubsub/infostream'
import RenameGroup from '../groups/renamegroup'
import EditGroupStatus from '../groups/editgroupstatus'
import DeleteOrLeaveSpaceForm from '../deletespace'
import VerificationForm from '../contacts/verification'
import GroupMembersList from '../groups/groupmembers'
import ExpirySelector from '../ExpirySelector'
import ChangeSpaceColor from '../changecolor'
import Uploader from '../Uploader'
import VerifiedIcon from '../../../UI/widgets/verifiedicon'
import InitiateCall from '../../../UI/webrtc/InitiateCall'
import GroupStatus from '../groups/groupstatus'
import FileStreamContainer from '../pubsub/FileStreamContainer'
import FileList from '../pubsub/FileList'
import Gallery from '../pubsub/Gallery'
import OnlineMembers from './OnlineMembers'
import moment from 'moment'

const colorPallette = ['#EC5f67', '#F99157', '#FAC863', '#99C794', '#5FB3B3', '#6699CC', '#C594C5', '#AB7967']

class SpaceScene extends React.Component {
  state = {
    showExpirySelector: false,
  }

  constructor(props) {
    super(props)
    this.changeSpaceColorReference = React.createRef()
    this.verificationFormReference = React.createRef()
    this.groupMembersListReference = React.createRef()
    this.renameGroupReference = React.createRef()
    this.groupStatusReference = React.createRef()
    this.deleteFormReference = React.createRef()
  }

  componentDidMount() {
    client.on('ready', this.fetch)
    crypho.on('space-deleted', this.onSpaceDeleted)
    this.fetch()
  }

  componentDidUpdate(previousProperties) {
    const oldId = previousProperties.spaceId
    const newId = this.props.spaceId
    if (newId === oldId) return
    this.fetch()
  }

  async componentWillUnmount() {
    client.removeListener('ready', this.fetch)
    crypho.removeListener('space-deleted', this.onSpaceDeleted)
    clearTimeout(this.typingTimeout)
  }

  fetch = async () => {
    const { space, fetchSpaceMetadata, fetchStreams } = this.props
    if (!space || !space.id) return
    await fetchSpaceMetadata(space.id)
    fetchStreams(space.id)
    // eslint-disable-next-line no-console
    crypho.ping(space.id).catch(console.error)
  }

  onSpaceDeleted = (spaceId) => {
    const { space, history } = this.props
    if ((!space || space.id === spaceId) && history) {
      if (history.location.pathname.includes('contacts')) {
        history.replace('/contacts')
      } else if (history.location.pathname.includes('groups')) {
        history.replace('/groups')
      }
    }
  }

  downloadTranscript = async () => {
    const { space, title } = this.props
    const txt = await chatToText(space.id)
    const blob = new Blob([txt], { type: 'text/plain' })
    let anchor = document.createElement('a')
    anchor.href = window.URL.createObjectURL(blob)
    anchor.download = `${title}.txt`
    document.body.append(anchor)
    anchor.click()
    setTimeout(() => {
      document.body.removeChild(anchor)
    }, 0)
  }

  renderPanes(openUploadDialog) {
    const { space } = this.props

    return [
      {
        menuItem: { key: 'chat', content: 'Chat' },
        render: () => (
          <Tab.Pane>
            <ErrorBoundary>
              <InfoStream
                spaceId={space.id}
                nodeId={`/spaces/${space.id}/infostream`}
                openUploadDialog={openUploadDialog}
              />
            </ErrorBoundary>
          </Tab.Pane>
        ),
      },
      {
        menuItem: { key: 'files', content: 'Files' },
        render: () => (
          <Tab.Pane>
            <ErrorBoundary>
              <FileStreamContainer spaceId={space.id} openUploadDialog={openUploadDialog}>
                {(itemIds) => <FileList spaceId={space.id} itemIds={itemIds} />}
              </FileStreamContainer>
            </ErrorBoundary>
          </Tab.Pane>
        ),
      },
      {
        menuItem: { key: 'gallery', content: 'Gallery' },
        render: () => (
          <Tab.Pane>
            <ErrorBoundary>
              <FileStreamContainer spaceId={space.id} openUploadDialog={openUploadDialog}>
                {(itemIds) => <Gallery spaceId={space.id} itemIds={itemIds} />}
              </FileStreamContainer>
            </ErrorBoundary>
          </Tab.Pane>
        ),
      },
    ]
  }

  setMuteSettings = async (settings) => {
    const { space, updateSpace } = this.props
    try {
      await crypho.setSpaceNotificationSettings(space.id, settings)
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error)
      NotificationManager.error('There was an error, please try again.')
    }
    updateSpace(space.id, { notifications: settings })
  }

  render() {
    const {
      title,
      space,
      members,
      onlineMemberIds,
      fingerprintVerifiedContacts,
      userId,
      accountPlan,
      spaceType,
      groupStatus,
      isXmppOnline,
    } = this.props
    if (!space) {
      return null
    }
    const color = colorPallette[space.color === null ? 3 : space.color]
    const isMuted = space.notifications && space.notifications.includes('mute')
    const isContact = space.type === 'contact'
    const canVerify = isContact && !fingerprintVerifiedContacts[members[0]]
    const isOwner = !!(space.roles && space.roles[userId] && space.roles[userId].includes('owner'))
    const isOperator = !!(space.roles && space.roles[userId] && space.roles[userId].includes('op'))
    const defaultExpiryDuration = moment.duration(space.defaultExpiry, 'seconds').humanize()
    const defaultExpiryText = `Content in this conversation expires in ${defaultExpiryDuration} by default`

    return (
      <div className="spaceContainer">
        <Container fluid className="spaceHeader">
          <Header>
            <span title={title}>{title}</span>
          </Header>
          {isContact ? <VerifiedIcon userId={members[0]} /> : null}

          {space.defaultExpiry ? (
            <Popup
              on="click"
              position="bottom left"
              trigger={
                <span className="expiryHeader">
                  <Icon name="clock outline" />
                  <span> {defaultExpiryDuration}</span>
                </span>
              }
              wide="very"
            >
              {defaultExpiryText}
            </Popup>
          ) : null}
          {isMuted ? <Icon className="bell slash" title="This conversation is muted" /> : null}
          <OnlineMembers
            space={space}
            onlineMemberIds={onlineMemberIds}
            members={members}
            onOpenGroupMemberList={() => this.groupMembersListReference.current.open()}
          />
          {spaceType === 'group' && <GroupStatus status={groupStatus} />}
          <Dropdown
            id="space-drawer"
            title="Conversation options"
            direction="left"
            position="right"
            icon="ellipsis vertical"
          >
            <Dropdown.Menu>
              <Dropdown.Header content="Conversation options" />
              <Dropdown.Divider />
              <Dropdown.Item
                disabled={['free', 'beta'].includes(accountPlan)}
                onClick={() => this.downloadTranscript()}
                title=""
              >
                <Icon className="download" />
                Download transcript
              </Dropdown.Item>
              <Dropdown.Divider />
              <Dropdown.Item onClick={this.openChangeSpaceColorReference} title="">
                <Icon style={{ color }} name="square" />
                Change color
              </Dropdown.Item>
              <ChangeSpaceColor space={space} ref={this.changeSpaceColorReference} />
              {canVerify
                ? [
                    <Dropdown.Item
                      key="verifyDropDownItem"
                      onClick={() => this.verificationFormReference.current.open()}
                      title=""
                    >
                      <Icon className="fingerprintsymbol" />
                      Verify contact
                    </Dropdown.Item>,
                    <VerificationForm key="verifyForm" space={space} ref={this.verificationFormReference} />,
                  ]
                : null}

              <Dropdown.Item
                onClick={() => {
                  this.setMuteSettings(isMuted ? null : 'mute')
                }}
                title={
                  isMuted
                    ? 'Turn on mobile notifications for this conversation'
                    : 'Turn off mobile notifications for this conversation'
                }
              >
                {isMuted ? <Icon className="bell slash" /> : <Icon className="bell" />}
                {isMuted ? 'Unmute' : 'Mute'}
              </Dropdown.Item>

              <Dropdown.Divider />

              {!isContact
                ? [
                    <Dropdown.Item
                      key="groupMemberItem"
                      onClick={() => this.groupMembersListReference.current.open()}
                      title=""
                    >
                      <Icon className="membersIcon" />
                      Group members
                    </Dropdown.Item>,
                    <GroupMembersList key="groupMembersForm" space={space} ref={this.groupMembersListReference} />,
                  ]
                : null}
              {space.type === 'contact' || isOwner || isOperator ? (
                <React.Fragment>
                  <Dropdown.Item onClick={() => this.setState({ showExpirySelector: true })}>
                    <Icon name="clock outline" />
                    Content expiry
                  </Dropdown.Item>
                  {this.state.showExpirySelector ? (
                    <ExpirySelector space={space} onClose={() => this.setState({ showExpirySelector: false })} />
                  ) : null}
                </React.Fragment>
              ) : null}

              {isOwner
                ? [
                    <Dropdown.Item
                      key="renameGroupItem"
                      onClick={() => this.renameGroupReference.current.open()}
                      title=""
                    >
                      <Icon className="edit" />
                      Rename group
                    </Dropdown.Item>,
                    <RenameGroup key="renameGroupForm" space={space} ref={this.renameGroupReference} />,
                  ]
                : null}

              {space.type === 'group' && (isOwner || isOperator)
                ? [
                    <Dropdown.Item
                      key="groupStatusItem"
                      onClick={() => this.groupStatusReference.current.open()}
                      title=""
                    >
                      <Icon name="info" />
                      Edit group status
                    </Dropdown.Item>,
                    <EditGroupStatus key="groupStatusForm" space={space} ref={this.groupStatusReference} />,
                  ]
                : null}
              <Dropdown.Divider />
              <Dropdown.Item onClick={() => this.deleteFormReference.current.open()} title="">
                <Icon className="close" />
                {isContact ? 'Delete contact' : isOwner ? 'Delete group' : 'Leave group'}
              </Dropdown.Item>
              <DeleteOrLeaveSpaceForm space={space} ref={this.deleteFormReference} />
            </Dropdown.Menu>
          </Dropdown>
          {space.type === 'contact' ? <InitiateCall userId={members[0]} spaceId={space.id} /> : null}
        </Container>

        <Uploader
          spaceId={space.id}
          className="spaceTabs"
          disabled={!isXmppOnline}
          dragNotifcation={
            <div className="dragNotification">
              <Icon name="cloud upload" size="massive" />
              <p>Drop files here to share with {title}</p>
            </div>
          }
        >
          {({ open }) => <Tab as={React.Fragment} panes={this.renderPanes(open)} />}
        </Uploader>
      </div>
    )
  }

  openChangeSpaceColorReference = () => this.changeSpaceColorReference.current.open()
}

SpaceScene.contextType = CallContext

SpaceScene.propTypes = {
  accountPlan: PropTypes.string,
  fetchSpaceMetadata: PropTypes.func.isRequired,
  fetchStreams: PropTypes.func.isRequired,
  history: PropTypes.shape(),
  members: PropTypes.array,
  onlineMemberIds: PropTypes.array,
  space: PropTypes.shape(),
  spaceId: PropTypes.string,
  title: PropTypes.string,
  updateSpace: PropTypes.func.isRequired,
  userId: PropTypes.string,
  fingerprintVerifiedContacts: PropTypes.shape(),
  xmppDomain: PropTypes.string.isRequired,
  isXmppOnline: PropTypes.bool.isRequired,
  online: PropTypes.object,
  spaceType: PropTypes.string,
  groupStatus: PropTypes.string,
}

const mapStateToProperties = (state, ownProperties) => {
  const space = state.spaces.byId[ownProperties.spaceId]
  if (!space)
    return {
      space: undefined,
      userId: state.identity.id,
      onlineMemberIds: [],
      title: '',
      fingerprintVerifiedContacts: {},
      members: [],
      accountPlan: state.account.plan.name,
      xmppDomain: state.config.xmppDomain,
      online: state.online,
      spaceType: '',
      groupStatus: '',
      isXmppOnline: state.xmpp.status === 'online',
    }

  return {
    space,
    userId: state.identity.id,
    onlineMemberIds: getSpaceOnlineMembersUserIds(state, { space }),
    title: getSpaceTitle(state, { space }),
    fingerprintVerifiedContacts: getFingerprintVerifiedContacts(state),
    members: getSpaceMembersUserIds(state, { spaceId: space.id }),
    accountPlan: state.account.plan.name,
    xmppDomain: state.config.xmppDomain,
    online: state.online,
    spaceType: space.type,
    groupStatus: space.status,
    isXmppOnline: state.xmpp.status === 'online',
  }
}

const mapDispatchToProperties = {
  fetchStreams,
  fetchSpaceMetadata,
  updateSpace,
}

const connector = (container) => connect(mapStateToProperties, mapDispatchToProperties)(container)

export default withRouter(connector(SpaceScene))
