import husher from '../crypho.core/husher'
import Worker from 'workerize-loader!./sjcl.worker'
import { combineBuffers } from '../utils'

const sjclWorker = new Worker()

const BLOCK_SIZE = 512

/**
 * Interface to an encryption or decryption task.
 */
export class Task {
  /**
   *
   * @param {number} taskId Crypto worker task ID
   */
  constructor(taskId) {
    this.taskId = taskId
    this.pending = undefined
  }

  /**
   * Feed extra data to the task.
   *
   * @param {ArrayBuffer} data to process
   * @returns Promise<Uint8Array>
   */
  async update(data) {
    if (this.pending) {
      data = combineBuffers(this.pending, data)
      this.pending = undefined
    }
    const extraBytes = data.byteLength % BLOCK_SIZE
    if (extraBytes) {
      const offset = data.byteLength - extraBytes
      this.pending = data.slice(offset)
      data = data.slice(0, offset)
    }

    return this._update(data)
  }

  /**
   * Indicate no more data needs to be processed.
   * @returns Promise<Uint8Array>
   */
  async finish() {
    let pendingResult = null
    if (this.pending) {
      pendingResult = await this._update(this.pending)
      this.pending = undefined
    }

    const result = await sjclWorker.finishTask(this.taskId)
    const encryptedBits = husher._bytes.fromBits(result)
    let data = new Uint8Array(encryptedBits)

    if (pendingResult) {
      data = new Uint8Array(combineBuffers(pendingResult.buffer, data.buffer))
    }
    return data
  }

  async _update(data) {
    const byteArray = new Uint8Array(data, 0, data.byteLength)
    const bitArray = husher._bytes.toBits(byteArray)
    const result = await sjclWorker.updateTask(this.taskId, bitArray)
    const encryptedBits = husher._bytes.fromBits(result)
    return new Uint8Array(encryptedBits)
  }
}

/**
 * SJCL implementation of the CryptoHandler interface
 */
export default class CryptoHandler {
  constructor(key, jid) {
    this.key = key
    this.jid = jid
  }
  /**
   * Start the new encryption process
   *
   * @returns {Promise<{task: Task, params: any}>}
   */
  async startEncryption() {
    const [taskId, params] = await sjclWorker.startProgressiveEncryption(
      this.key,
      husher._getRandomWords(4), // iv
      husher._utf8.toBits(this.jid), // additional data (for AES)
    )
    return {
      task: new Task(taskId),
      key: this.key,
      params,
    }
  }

  async startDecryption(params) {
    const taskId = await sjclWorker.startProgressiveDecryption(
      this.key, // key
      husher._b64.toBits(params.iv), // iv
      husher._b64.toBits(params.adata), // additional data (for AES)
    )
    return {
      task: new Task(taskId),
    }
  }
}
