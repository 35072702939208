import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { Grid, Label, Icon } from 'semantic-ui-react'

function ActionWrapper({ className, onDelete, isDeleting, children }) {
  const cls = classNames({ [className]: !isDeleting, destroyBubble: isDeleting })
  return (
    <Grid.Row className={cls}>
      {isDeleting ? (
        <Label as="a" floating onClick={onDelete}>
          Delete <Icon color="red" name="trash" />
        </Label>
      ) : null}

      {children}
    </Grid.Row>
  )
}

ActionWrapper.propTypes = {
  className: PropTypes.string.isRequired,
  onDelete: PropTypes.func,
  isDeleting: PropTypes.bool.isRequired,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
}

ActionWrapper.defaultProps = {
  className: 'actionBubble',
  isDeleting: false,
  isFile: false,
}

export default ActionWrapper
