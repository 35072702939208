import PropTypes from 'prop-types'
import React, { useState } from 'react'
import { Dropdown, Button } from 'semantic-ui-react'
import { Form } from 'formsy-semantic-ui-react'
import classes from './EditEmails.module.css'

const EditEmail = ({ onCancel, onSave, emails, defaultValue }) => {
  const [selectedOption, setSelectedOption] = useState(defaultValue)
  const options = emails.map((email) => ({
    key: email,
    text: email,
    value: email,
  }))

  const changEmailHandler = (_event, data) => {
    setSelectedOption(data.value)
  }

  const submitHandler = () => {
    onSave(selectedOption)
  }

  return (
    <Form onValidSubmit={submitHandler} className={classes.form}>
      <Dropdown
        placeholder="Select email"
        fluid
        selection
        options={options}
        value={selectedOption}
        onChange={changEmailHandler}
      />
      <Button name="save" className="settingsButton" type="submit">
        Save
      </Button>
      <Button name="cancel" className={`settingsButton ${classes['cancel-button']}`} onClick={onCancel}>
        Cancel
      </Button>
    </Form>
  )
}

EditEmail.propTypes = {
  onCancel: PropTypes.func,
  onSave: PropTypes.func,
  emails: PropTypes.array,
  defaultValue: PropTypes.string,
}

export default EditEmail
