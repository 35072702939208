import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { compose } from 'recompose'
import { withRouter } from 'react-router-dom'
import { Button } from 'semantic-ui-react'

import store from './crypho.core/store'
import { setAwayStatus } from './crypho.core/store/modules/app'
import { loadSession, deleteSession } from './session'
import * as XMPP from './crypho.core/xmpp'
import { getUnread } from './crypho.core/store/modules/space/selectors'
import { getSpaceTitle } from './crypho.core/store/modules/space/selectors'
import { fetchIdentity } from './store/identity'

window.addEventListener('error', function (error, url, line) {
  ipcRenderer.send('error', `${error} ${url}:${line}`)
})

// XXX: We want to require electron during runtime from the nodejs environment
// provided at the runtime rather than the nodejs environment used during
// compilation by webpack. By default, globals are bound to window and webpack
// compilation ignores the window global - hence window.require works.

const ipcRenderer = window.require('electron').ipcRenderer

class DesktopBridge extends Component {
  constructor(properties) {
    super(properties)
    this.state = {
      showNotifications: false,
      desktopNotifications: [],
      hasDesktopUpdate: false,
    }
  }

  componentDidMount() {
    const { xmppPubsub, fetchIdentity, history } = this.props
    window.Notification.requestPermission(() => {})

    window.addEventListener('saveSession', (event) => {
      const { identity } = this.props
      ipcRenderer.send('desktop:saveSession', event.detail)
      ipcRenderer.send('desktop:saveIdentity', identity)
    })

    window.addEventListener('removeSession', () => {
      ipcRenderer.send('desktop:removeSession')
    })

    ipcRenderer.on('desktop:flagPath', (sender, flagPath) => {
      window._FLAGS_PATH = flagPath
    })

    ipcRenderer.on('tray:showNotifications', (sender, showNotifications) => {
      this.setState({
        showNotifications,
      })
    })

    ipcRenderer.on('desktop:restoreSession', async (sender, session) => {
      sessionStorage.setItem('authSession', JSON.stringify(session))
      global.husher.fromSession(session)
      ipcRenderer.send('desktop:restoreIdentity')
    })

    ipcRenderer.on('desktop:restoreIdentity', async (sender, identity) => {
      sessionStorage.setItem('identity', JSON.stringify(identity))

      try {
        loadSession()
        await fetchIdentity()
      } catch (error) {
        await deleteSession()
        // eslint-disable-next-line no-console
        console.error(error)
        return
      }
      try {
        store.dispatch(XMPP.setXmppPresence(true))
        await XMPP.start()
        await XMPP.onceOnline()
        history.replace('/')
        ipcRenderer.send('desktop:splashEnd')
      } catch (error) {
        await deleteSession()
        // eslint-disable-next-line no-console
        console.error(error)
      }
    })

    ipcRenderer.send('desktop:restoreSession')
    XMPP.pubsub.on(`item-published:${xmppPubsub}`, ({ node, entry }) => {
      this.onNewMessageReceived(node, entry)
    })

    ipcRenderer.on('desktop:sendAway', () => {
      store.dispatch(setAwayStatus(true))
      store.dispatch(XMPP.setXmppPresence(true, 'away'))
    })

    ipcRenderer.on('desktop:sendOnline', async () => {
      if (history.location.pathname.includes('contacts') || history.location.pathname.includes('groups')) {
        const spaceId = history.location.pathname.split('/')[2]
        XMPP.crypho.ping(spaceId).catch((error) => {
          // eslint-disable-next-line no-console
          console.error('Error pinging space', error)
        })
      }

      store.dispatch(setAwayStatus(false))
      await store.dispatch(XMPP.setXmppPresence(true))
      await XMPP.onceOnline()
    })

    ipcRenderer.on('desktop:updateDownloaded', () => {
      this.setState({ hasDesktopUpdate: true })
    })
  }

  componentDidUpdate(previousProperties) {
    const { unread } = this.props
    if (unread !== previousProperties.unread) {
      ipcRenderer.send('desktop:change:unread', unread)
    }
  }

  onNewMessageReceived(node, entry) {
    const { showNotifications, desktopNotifications } = this.state
    const spaceId = node.split('/')[2]

    // Ignore "updated" messages
    if (entry.attrs.updated !== undefined) return

    if (showNotifications) {
      const { history } = this.props
      const state = store.getState()

      // If the user is online on another device just return.
      // The desktop should be set to away so it does not count.
      const id = state.identity.id
      if (state.online.byId[id].length > 0) return

      const space = state.spaces.byId[spaceId]
      if (space.notifications === 'mute') return

      const title = getSpaceTitle(state, { space })
      const body = space.type === 'contact' ? `Message from ${title}` : `Message in "${title}"`
      const url = space.type === 'contact' ? `/contacts/${spaceId}` : `/groups/${spaceId}`
      let notification
      while (desktopNotifications.length) {
        notification = desktopNotifications.pop()
        notification.close()
      }
      notification = new window.Notification('Crypho', {
        body,
      })
      notification.addEventListener('click', function () {
        history.push(url)
        ipcRenderer.send('tray:openFromMessageNotification')
      })
      this.setState({ desktopNotifications: [notification] })
    }
  }

  quitAndInstall = () => {
    ipcRenderer.send('desktop:quitAndInstall')
  }

  render() {
    const { hasDesktopUpdate } = this.state
    if (hasDesktopUpdate) {
      return (
        <div className="newVersion">
          <span>There is a new version of Crypho available</span>
          <Button className="primaryButton" onClick={this.quitAndInstall}>
            Update & restart
          </Button>
        </div>
      )
    } else return null
  }
}

DesktopBridge.propTypes = {
  fetchIdentity: PropTypes.func,
  history: PropTypes.shape(),
  identity: PropTypes.shape(),
  unread: PropTypes.number,
  xmppPubsub: PropTypes.string,
}

const mapStateToProperties = (state) => ({
  xmppPubsub: state.config.xmppPubsub,
  identity: state.identity,
  unread: getUnread(state),
})

const mapDispatchToProperties = {
  fetchIdentity,
}
const connector = (container) => compose(withRouter, connect(mapStateToProperties, mapDispatchToProperties))(container)

const connectedBridge = connector(DesktopBridge)

export { connectedBridge }
