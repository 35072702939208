import * as Sentry from '@sentry/core'
import { crypho } from '../../../xmpp'

const SET_IDENTITY_USER_ID = 'SET_IDENTITY_USER_ID'
const SET_IDENTITY_USER_JID = 'SET_IDENTITY_USER_JID'
const SET_IDENTITY_EMAIL = 'SET_IDENTITY_EMAIL'
const SET_IDENTITY_TFA_SECRET = 'SET_IDENTITY_TFA_SECRET'
const SET_IDENTITY_SCRYPT_SALT = 'SET_IDENTITY_SCRYPT_SALT'
const SET_IDENTITY_PHONE = 'SET_IDENTITY_PHONE'
const SET_DEVICES = 'SET_DEVICES'

export default function identity(
  state = {
    id: '',
    jid: '',
    email: '',
    tfaSecret: '',
    scryptSalt: '',
    phone: { local: '', country: '' },
    devices: [],
  },
  action,
) {
  switch (action.type) {
    case SET_IDENTITY_USER_ID:
      return Object.assign({}, state, { id: action.id })
    case SET_IDENTITY_USER_JID:
      Sentry.configureScope((scope) => {
        scope.setUser({ id: action.jid, username: action.jid })
      })
      return Object.assign({}, state, { jid: action.jid })
    case SET_IDENTITY_EMAIL:
      return Object.assign({}, state, { email: action.email })
    case SET_IDENTITY_SCRYPT_SALT:
      return Object.assign({}, state, { scryptSalt: action.scryptSalt })
    case SET_IDENTITY_TFA_SECRET:
      return Object.assign({}, state, { tfaSecret: action.tfaSecret })
    case SET_IDENTITY_PHONE:
      return Object.assign({}, state, {
        phone: { local: action.local, country: action.country },
      })
    case SET_DEVICES:
      return Object.assign({}, state, {
        devices: action.devices,
      })

    default:
      return state
  }
}
export const setIdentityUserId = (id) => ({
  type: SET_IDENTITY_USER_ID,
  id,
})

export const setIdentityUserJID = (jid) => ({
  type: SET_IDENTITY_USER_JID,
  jid,
})

export const setIdentityEmail = (email) => ({
  type: SET_IDENTITY_EMAIL,
  email,
})

export const setTFASecret = (tfaSecret) => ({
  type: SET_IDENTITY_TFA_SECRET,
  tfaSecret,
})

export const setScryptSalt = (scryptSalt) => ({
  type: SET_IDENTITY_SCRYPT_SALT,
  scryptSalt,
})

export const setPhone = (local, country) => ({
  type: SET_IDENTITY_PHONE,
  local,
  country,
})

export const setDevices = (devices) => ({
  type: SET_DEVICES,
  devices,
})

export const fetchScryptSaltFromServer = (email) => {
  return (dispatch, getState) => {
    const state = getState()
    const { apiUrl } = state.config
    return fetch(`${apiUrl}/auth/scrypt-salt`, {
      method: 'POST',
      credentials: 'include',
      body: JSON.stringify({ email }),
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    })
      .then((response) => response.json())
      .then((json) => dispatch(setScryptSalt(json.salt)))
  }
}

export const fetchDevicesFromServer = () => {
  return (dispatch) => {
    return crypho.getDevices().then((json) => dispatch(setDevices(json)))
  }
}
