import React, { Component } from 'react'
import AsyncSelect from 'react-select/async'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { CryphoAPI } from '../../crypho.core/api'
import moment from 'moment'
import { PhoneInput, errorLabel } from '../../UI/forms'
import { parsePhoneNumberFromString } from 'libphonenumber-js'
import { Form } from 'formsy-semantic-ui-react'
import { List, Card, Table, Button } from 'semantic-ui-react'
import classes from './AdminUsers.module.css'
import {
  deleteUser,
  updateEmails,
  updatePrimaryEmail,
  updatePlan,
  addAccountAdmin,
  addAccountDomain,
  removeSpace,
} from './api'
import { NotificationManager } from 'react-notifications'
import UserInfoHeader from './UserInfoHeader'
import EmailsEditor from './EmailsEditor'
import FieldTitle from './FieldTitle'
import EditEmail from './EditEmail'
import EditPlan from './EditPlan'
import AddAccountAdmin from './AddAccountAdmin'
import AccountInfoHeader from './AccountInfoHeader'
import SpacesTable from './spaces/SpaceTable'

export class UserLink extends Component {
  render() {
    const { fullname, username, isGroupOwner } = this.props
    return (
      <a
        className={isGroupOwner ? 'emphasized' : null}
        onClick={(ev) => {
          ev.preventDefault()
          this.props.callback({ value: username })
        }}
      >
        {fullname}
      </a>
    )
  }
}

UserLink.propTypes = {
  fullname: PropTypes.string,
  username: PropTypes.string,
  callback: PropTypes.func,
  isGroupOwner: PropTypes.bool,
}

class AdminUsers extends Component {
  state = {
    username: '',
    account: {},
    basic: {},
    devices: [],
    fullnames: {},
    contactSpaces: [],
    groupSpaces: [],
    plan: {},
    editMode: {},
    loading: false,
  }

  constructor(props) {
    super(props)
    this.searchInputRef = React.createRef()
    this.emailEditorRef = React.createRef()
    this.onSearchTermChanged = (searchTerm) => this.searchUsers(searchTerm)
  }

  postToAdminAPI = async (payLoad) => {
    const { apiUrl } = this.props
    const api = new CryphoAPI(apiUrl)
    return await api.post('/admin', payLoad)
  }

  searchUsers = async (searchTerm) => {
    if (searchTerm.length < 3) {
      return []
    }

    const data = await this.postToAdminAPI({ searchTerm })
    return data.map((item) => ({
      value: item.username,
      label: `${item.fullname} ${item.email} ${item.username}`,
    }))
  }

  loadUserData = async (username) => {
    const data = await this.postToAdminAPI({ username })

    const { account, basic, devices, fullnames, spaces, plan } = data
    const contactSpaces = (spaces && spaces.filter((space) => space.type === 'contact')) || []
    const groupSpaces = (spaces && spaces.filter((space) => space.type === 'group')) || []
    this.setState({
      username,
      account,
      basic,
      devices,
      fullnames,
      contactSpaces,
      groupSpaces,
      plan,
    })
  }

  onSelectUser = async (user) => {
    if (!user) {
      this.setState({
        username: '',
      })
      return
    }
    const username = user.value

    try {
      await this.loadUserData(username)
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('Failed to select a user', error)
    }
  }

  onChangeDisplayedUser = (user) => {
    this.onSelectUser(user)
  }

  clearSearchInput = () => {
    this.searchInputRef.current.select.select.clearValue()
  }

  onEditFormSubmit = async (formData) => {
    const { mobile } = formData
    const mobileNr = parsePhoneNumberFromString(mobile)

    try {
      const data = await this.postToAdminAPI({
        editView: {
          mobile: mobileNr.nationalNumber,
          country: mobileNr.country,
          uid: this.state.username,
        },
      })

      const { account, basic, devices, fullnames, spaces, plan } = data
      const contactSpaces = (spaces && spaces.filter((space) => space.type === 'contact')) || []
      const groupSpaces = (spaces && spaces.filter((space) => space.type === 'group')) || []
      this.setState({
        account,
        basic,
        devices,
        fullnames,
        contactSpaces,
        groupSpaces,
        plan,
        editMode: {
          mobile: false,
          primaryEmail: false,
        },
      })
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('Failed to update user mobile', error)
    }
  }

  renderMobileEditForm = () => {
    const { basic } = this.state
    return (
      <Form onValidSubmit={this.onEditFormSubmit}>
        <PhoneInput
          name="mobile"
          value={basic.mobile}
          required
          errorLabel={errorLabel}
          validations="isMobileNumber"
          validationErrors={{
            isDefaultRequiredValue: 'You need a phone number',
            isMobileNumber: 'You need a valid mobile number',
          }}
        />
        <Button name="save" className="settingsButton" type="submit">
          Save
        </Button>
        <Button name="cancel" className="settingsButton cancelMobile" onClick={this.onMobileCancel}>
          Cancel
        </Button>
      </Form>
    )
  }

  editMobileHandler = () => {
    this.setState({
      editMode: { mobile: true },
    })
  }

  onMobileCancel = () => {
    this.setState({
      editMode: { mobile: false },
    })
  }

  editPrimaryEmailHandler = () => {
    this.setState({
      editMode: { primaryEmail: true },
    })
  }

  editAccountAdminHandler = () => {
    this.setState({
      editMode: { admins: true },
    })
  }

  cancelAccountAdminHandler = () => {
    this.setState({
      editMode: { admins: false },
    })
  }

  addAccountAdminHandler = async (admin) => {
    const { apiUrl } = this.props
    try {
      this.setState({
        loading: true,
      })
      await addAccountAdmin(apiUrl, admin)
      await this.loadUserData(this.state.username)
      this.setState({
        editMode: { admins: false },
      })
    } catch (error) {
      NotificationManager.error(error?.data?.message ?? 'Error occurred', '')
    }
    this.setState({
      loading: false,
    })
  }

  addAccountDomainHandler = async (domain) => {
    const { apiUrl } = this.props
    try {
      this.setState({
        loading: true,
      })
      await addAccountDomain(apiUrl, this.state.account.account_id, domain)
      await this.loadUserData(this.state.username)
      this.setState({
        editMode: { admins: false },
      })
    } catch (error) {
      NotificationManager.error(error?.data?.message ?? 'Error occurred', '')
    }
    this.setState({
      loading: false,
    })
  }

  cancelPrimaryEmailHandler = () => {
    this.setState({
      editMode: { primaryEmail: false },
    })
  }

  savePrimaryEmailHandler = async (email) => {
    const { apiUrl } = this.props
    try {
      this.setState({
        loading: true,
      })
      const result = await updatePrimaryEmail(apiUrl, this.state.username, email)
      this.setState((previousState) => {
        return { basic: { ...previousState.basic, email: result.email } }
      })
      this.setState({
        editMode: { primaryEmail: false },
      })
    } catch (error) {
      NotificationManager.error(error.data.message, '')
    }
    this.setState({
      loading: false,
    })
  }

  editEmailsConfirmHandler = async (emails) => {
    this.emailEditorRef.current.close()
    const { apiUrl } = this.props
    try {
      this.setState({
        loading: true,
      })
      await updateEmails(apiUrl, this.state.username, emails)
      NotificationManager.info('emails updated successfully.')
    } catch (error) {
      NotificationManager.error(error.data.message, '')
    }
    this.setState({
      loading: false,
    })
    await this.loadUserData(this.state.username)
  }

  editEmailsCancelHandler = () => {
    this.emailEditorRef.current.close()
  }

  deleteUserHandler = async () => {
    const { apiUrl } = this.props
    try {
      this.setState({
        loading: true,
      })
      await deleteUser(apiUrl, this.state.username)
      NotificationManager.info('user deleted successfully.')
      this.clearSearchInput()
    } catch (error) {
      NotificationManager.error(error.data.message, '')
    }
    this.setState({
      loading: false,
    })
  }

  editPlanHandler = () => {
    this.setState({
      editMode: { plan: true },
    })
  }

  savePlanHandler = async (plan) => {
    const { apiUrl } = this.props
    try {
      this.setState({
        loading: true,
      })
      const result = await updatePlan(apiUrl, this.state.username, plan)
      this.setState({
        plan: result.plan,
      })
      NotificationManager.info('plan updated successfully.')
      this.setState({
        editMode: { plan: false },
      })
    } catch (error) {
      NotificationManager.error(error?.data?.message ?? 'Error occurred', '')
    }
    this.setState({
      loading: false,
    })
  }

  cancelPlanHandler = () => {
    this.setState({
      editMode: { plan: false },
    })
  }

  removeSpaceHandler = async (spaceId) => {
    const { apiUrl } = this.props
    try {
      this.setState({
        loading: true,
      })
      await removeSpace(apiUrl, spaceId)
      await this.loadUserData(this.state.username)
    } catch (error) {
      NotificationManager.error(error?.data?.message ?? 'Error occurred', '')
    }
    this.setState({
      loading: false,
    })
  }

  render() {
    const {
      account,
      basic,
      contactSpaces,
      devices,
      fullnames,
      groupSpaces,
      username,
      plan,
      editMode,
      loading,
    } = this.state
    const createdAt = moment(basic.created_at).format('YYYY-MM-DD HH:MM')

    return (
      <div className="adminScene">
        <div className="adminHeader">
          <AsyncSelect
            placeholder="Search..."
            icon="search"
            cacheOptions
            onChange={this.onSelectUser}
            loadOptions={this.onSearchTermChanged}
            ref={this.searchInputRef}
            theme={(theme) => ({
              ...theme,
              colors: {
                ...theme.colors,
                primary: '#ccc',
              },
            })}
          />
        </div>
        {username ? (
          <div className="adminContent">
            {loading && (
              <div className={classes.loader}>
                <div className="ui active large inverted text loader">Loading</div>
              </div>
            )}
            <Card.Group itemsPerRow={2}>
              <Card>
                <UserInfoHeader onDelete={this.deleteUserHandler} fullName={this.state.fullnames[basic.username]} />
                <List divided verticalAlign="middle">
                  <List.Item className={classes.listItem}>
                    <List.Header className={classes.listItemHeader}>Username</List.Header>
                    <List.Content verticalAlign="middle">{basic.username}</List.Content>
                  </List.Item>
                  <List.Item className={classes.listItem}>
                    <List.Header className={classes.listItemHeader}> Full Name </List.Header>
                    <List.Content verticalAlign="middle">{fullnames[basic.username]}</List.Content>
                  </List.Item>
                  <List.Item className={classes.listItem}>
                    <List.Header className={classes.listItemHeader}>
                      <FieldTitle title="Mobile" isEditable={true} onEdit={this.editMobileHandler} />
                    </List.Header>
                    <List.Content> {editMode.mobile ? this.renderMobileEditForm() : <> {basic.mobile}</>}</List.Content>
                  </List.Item>
                  <List.Item className={classes.listItem}>
                    <List.Header className={classes.listItemHeader}>
                      <FieldTitle title="Primary Email" isEditable={true} onEdit={this.editPrimaryEmailHandler} />
                    </List.Header>
                    <List.Content>
                      {editMode.primaryEmail ? (
                        <EditEmail
                          emails={basic.emails}
                          onSave={this.savePrimaryEmailHandler}
                          onCancel={this.cancelPrimaryEmailHandler}
                          defaultValue={basic.email}
                        />
                      ) : (
                        basic.email
                      )}
                    </List.Content>
                  </List.Item>
                  <List.Item className={classes.listItem}>
                    <List.Header className={classes.listItemHeader}>
                      <FieldTitle
                        title="Emails"
                        isEditable={true}
                        onEdit={() => this.emailEditorRef.current.open(basic.emails, basic.email)}
                      />
                    </List.Header>
                    <List.Content>
                      <>
                        {basic.emails.join(', ')}
                        <EmailsEditor
                          ref={this.emailEditorRef}
                          onCancel={this.editEmailsCancelHandler}
                          onSave={this.editEmailsConfirmHandler}
                        />
                      </>
                    </List.Content>
                  </List.Item>
                  <List.Item className={classes.listItem}>
                    <List.Header className={classes.listItemHeader}>Registered on</List.Header>
                    <List.Content>{createdAt}</List.Content>
                  </List.Item>
                  <List.Item className={classes.listItem}>
                    <List.Header className={classes.listItemHeader}>Verified 2FA</List.Header>
                    <List.Content>{basic.verified2fa.toString()}</List.Content>
                  </List.Item>
                </List>
              </Card>
              <Card>
                <AccountInfoHeader onAddDomain={this.addAccountDomainHandler} />
                <List divided verticalAlign="middle">
                  <List.Item className={classes.listItem}>
                    <List.Header className={classes.listItemHeader}>Title Email</List.Header>
                    <List.Content>{account.title}</List.Content>
                  </List.Item>
                  <List.Item className={classes.listItem}>
                    <List.Header className={classes.listItemHeader}>
                      <FieldTitle title="Plan" isEditable onEdit={this.editPlanHandler} />
                    </List.Header>
                    <List.Content>
                      {editMode.plan ? (
                        <EditPlan
                          onSave={this.savePlanHandler}
                          onCancel={this.cancelPlanHandler}
                          defaultPlan={plan.name}
                        />
                      ) : (
                        plan.name
                      )}
                    </List.Content>
                  </List.Item>
                  <List.Item className={classes.listItem}>
                    <List.Header className={classes.listItemHeader}>Plan description</List.Header>
                    <List.Content>{plan.description}</List.Content>
                  </List.Item>
                  <List.Item className={classes.listItem}>
                    <List.Header className={classes.listItemHeader}>Account ID</List.Header>
                    <List.Content>{account.account_id}</List.Content>
                  </List.Item>
                  <List.Item className={classes.listItem}>
                    <List.Header className={classes.listItemHeader}>Valid until ID</List.Header>
                    <List.Content>{account.valid_until}</List.Content>
                  </List.Item>
                  <List.Item className={classes.listItem}>
                    <List.Header className={classes.listItemHeader}>
                      <FieldTitle title="Admins" isEditable={true} onEdit={this.editAccountAdminHandler} />
                    </List.Header>
                    {editMode.admins ? (
                      <AddAccountAdmin onCancel={this.cancelAccountAdminHandler} onSave={this.addAccountAdminHandler} />
                    ) : (
                      <List.Content>
                        {account.admins.map((admin) => (
                          <React.Fragment key={`admins-${admin}`}>
                            <UserLink
                              username={admin}
                              fullname={fullnames[admin]}
                              callback={this.onChangeDisplayedUser}
                            />
                            {account.admins.length > 1 ? ', ' : ''}
                          </React.Fragment>
                        ))}
                      </List.Content>
                    )}
                  </List.Item>
                  <List.Item className={classes.listItem}>
                    <List.Header className={classes.listItemHeader}>Members ({account.members.length})</List.Header>
                    <List.Content>
                      {account.members.map((member) => (
                        <React.Fragment key={`members-${member}`}>
                          <UserLink
                            username={member}
                            fullname={fullnames[member]}
                            callback={this.onChangeDisplayedUser}
                          />
                          {', '}
                        </React.Fragment>
                      ))}
                    </List.Content>
                  </List.Item>
                </List>
              </Card>
            </Card.Group>
            <h3>Devices</h3>
            <Table celled>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell>Device Id</Table.HeaderCell>
                  <Table.HeaderCell>Platform</Table.HeaderCell>
                  <Table.HeaderCell>Data</Table.HeaderCell>
                  <Table.HeaderCell>App version</Table.HeaderCell>
                  <Table.HeaderCell>Registered on</Table.HeaderCell>
                  <Table.HeaderCell>Last access</Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {devices.map((device) => (
                  <Table.Row key={`devices-${device.device_id}`}>
                    <Table.Cell>{device.device_id}</Table.Cell>
                    <Table.Cell>{device.platform}</Table.Cell>
                    <Table.Cell>{JSON.stringify(device.data)}</Table.Cell>
                    <Table.Cell>{device.app_version}</Table.Cell>
                    <Table.Cell>{device.created_at}</Table.Cell>
                    <Table.Cell>{device.updated_at}</Table.Cell>
                  </Table.Row>
                ))}
              </Table.Body>
            </Table>
            <h3>Contacts</h3>
            <Table celled>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell>Space Id</Table.HeaderCell>
                  <Table.HeaderCell>Fullname</Table.HeaderCell>
                  <Table.HeaderCell>Last activity</Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {contactSpaces.map((space) => (
                  <Table.Row key={`space-${space.id}`}>
                    <Table.Cell>{space.id}</Table.Cell>
                    <Table.Cell>
                      <UserLink
                        key={`members-${space.id}`}
                        username={space.members.filter((member) => member !== username)[0]}
                        fullname={fullnames[space.members.filter((member) => member !== username)[0]]}
                        callback={this.onChangeDisplayedUser}
                      />
                    </Table.Cell>
                    <Table.Cell>{moment(space.lastActivity).format('YYYY-MM-DD HH:MM')}</Table.Cell>
                  </Table.Row>
                ))}
              </Table.Body>
            </Table>
            <SpacesTable
              groupSpaces={groupSpaces}
              fullNames={fullnames}
              onMembersLinkClick={this.onChangeDisplayedUser}
              onRemove={this.removeSpaceHandler}
            />
          </div>
        ) : null}
      </div>
    )
  }
}

AdminUsers.propTypes = {
  apiUrl: PropTypes.string,
}

const mapStateToProperties = (state) => {
  const apiUrl = state.config.apiUrl
  return {
    apiUrl,
  }
}

const connector = (container) => connect(mapStateToProperties)(container)

export default connector(AdminUsers)
