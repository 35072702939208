/* eslint-disable no-unused-vars */
export function filterSuirElementPropperties(properties) {
  const {
    as,
    instantValidation,
    error,
    defaultChecked,
    defaultSelected,
    rootClassName,
    rootStyle,
    defaultValue,
    rootElement,
    errorLabel,
    formRadioGroup,
    getValue,
    isPristine,
    isValid,
    getErrorMessage,
    setValidations,
    setValue,
    resetValue,
    hasValue,
    getErrorMessages,
    isFormDisabled,
    isFormSubmitted,
    isRequired,
    showRequired,
    showError,
    isValidValue,
    validations,
    validationError,
    validationErrors,
    width,
    passRequiredToField,
    inputAs,
    // eslint-disable-next-line unicorn/prevent-abbreviations
    innerRef,
    ...suirProperties
  } = properties

  return suirProperties
}
