import 'react-app-polyfill/stable'
import './poly'
import React from 'react'
import * as Sentry from '@sentry/browser'
import * as Integrations from '@sentry/integrations'
import { render } from 'react-dom'
import PropTypes from 'prop-types'
import { Provider } from 'react-redux'
import { BrowserRouter as Router } from 'react-router-dom'
import App from './app'
import { connect } from 'react-redux'
import 'semantic-ui-css/semantic.css'
import store from './crypho.core/store'
import { createClient } from './crypho.core/xmpp'
import { initCacheStorage } from './store/storage'
import 'typeface-roboto'
import './index.css'
import { configureDomainsFromDesktop, loadConfiguration } from './crypho.core/config'

if (process.env.NODE_ENV !== 'development') {
  Sentry.init({
    dsn: 'https://1d9a65bf80334ddeaeadfdf5489cf063@sentry.io/1394770',
    release: `crypho.web@${process.env.REACT_APP_VERSION}`,
    environment: window._RUNTIME.SENTRY_ENVIRONMENT || 'development',
    integrations: [
      new Integrations.ExtraErrorData(),
      new Integrations.ReportingObserver({ types: ['crash', 'intervention'] }),
    ],
  })
}

initCacheStorage()

const Root = ({ store }) => (
  <Provider store={store}>
    <Router>
      <App />
    </Router>
  </Provider>
)

Root.propTypes = {
  store: PropTypes.object.isRequired,
}

const mapStateToProperties = (state) => ({
  identity: state.identity,
})

const mapDispatchToProperties = {}

const connector = (container) => connect(mapStateToProperties, mapDispatchToProperties)(container)

const ConnectedRoot = connector(Root)

const loader =
  process.env.REACT_APP_DESKTOP || (window && window.process && window.process.type)
    ? configureDomainsFromDesktop()
    : loadConfiguration()

loader
  .then(() => createClient())
  .then(() => render(<ConnectedRoot store={store} />, document.querySelector('#root')))
  .catch((error) => {
    // eslint-disable-next-line no-console
    console.error('Error configuring domains', error)
  })

// Register a require function to the browser so that we can manipulate things in tests.
if (process.env.NODE_ENV === 'development' || (window._RUNTIME || {}).CI) {
  import('./debugging')
    .then((debugging) => debugging.setupDebugging())
    .catch((error) => console.error('Failed to load debugging tools', error))
}
