import without from 'lodash/without'
import dotProp from 'dot-prop-immutable'

const USER_ONLINE = 'USER_ONLINE'
const USER_OFFLINE = 'USER_OFFLINE'
const RESET_ONLINE_STATE = 'RESET_ONLINE_STATE'

const initState = {
  byId: {},
  allIds: [],
}

export default function online(state = initState, action) {
  switch (action.type) {
    case USER_ONLINE: {
      const { jid } = action
      const id = jid.split('@')[0]

      const updatedWithOnlineTable = dotProp.set(state, `byId.${id}`, (jids) => {
        if (!state.byId[id]) return [jid]
        if (jids.includes(jid)) return jids
        return jids.concat(jid)
      })

      const updatedWithOnlineList = dotProp.set(updatedWithOnlineTable, 'allIds', (allIds) =>
        allIds.includes(id) ? allIds : allIds.concat(id),
      )
      return updatedWithOnlineList
    }

    case USER_OFFLINE: {
      const { jid } = action
      const id = jid.split('@')[0]

      const updatedWithOnlineTable = dotProp.set(state, `byId.${id}`, (jids) =>
        jids.length > 0 ? without(jids, jid) : [],
      )

      const updatedWithOnlineList = dotProp.set(updatedWithOnlineTable, 'allIds', (allIds) =>
        without(state.byId[id] || [], jid).length > 0 ? allIds : without(allIds, id),
      )
      return updatedWithOnlineList
    }

    case RESET_ONLINE_STATE: {
      return initState
    }

    default:
      return state
  }
}

export const userOnline = (jid) => ({
  type: USER_ONLINE,
  jid,
})

export const userOffline = (jid) => ({
  type: USER_OFFLINE,
  jid,
})

export const resetOnlineState = () => ({
  type: RESET_ONLINE_STATE,
})
