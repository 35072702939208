import * as Sentry from '@sentry/core'
import xmppJid from '@xmpp/jid'
import EventEmitter from 'events'
import { CryphoAPI } from '../api'
import store from '../store'
import { fetchSpaceMetadata, getSpaceKey } from '../store/modules/space'
import { getSpaceCurrentKey } from '../store/modules/space/selectors'
import { webrtc } from '../xmpp'
import { pause } from '../utils'

export const STATE_IDLE = 'idle'
export const STATE_RINGING = 'ringing'
export const STATE_RECEIVING_RING = 'received-ring'
export const STATE_READY_TO_CALL = 'ready-to-call'
export const STATE_WAITING_FOR_FIRST_OFFER = 'waiting-for-first-offer'
export const STATE_CONNECTING = 'connecting'
export const STATE_CONNECTED = 'connected'
export const STATE_CANCEL = 'cancel'

// Time in milliseconds we are willing to wait for a ring answer
const RING_RESPONSE_TIMEOUT = 40 * 1000
// Maximum amount of time to wait until call setup has finished
const CALL_SETUP_TIMEOUT = 10 * 1000

const dummyCallback = () => false

export class CallError extends Error {
  constructor(error) {
    super(error)
    this.reason = error
  }
}

const resolveNothing = () => {}

export class CallManager {
  constructor() {
    this.state = STATE_IDLE
    // resolveRingRequest is resolve function for the call promise
    this.resolveRingRequest = resolveNothing
    // XXX: remoteMuteState is necessary as react-native-webrtc does not support
    // onmute, onunmute on AudioTracks.
    this.remoteMuteState = { audio: false, video: false }
    // remoteFullJid is the full jid of the party we are calling
    this.remoteFullJid = null
    this.pc = null
    this.pendingIceCandidates = []
    // CallManager does not keep track of devices itself.
    // CallHandler must call setDevices to set them.
    this.devices = []
    this.pcReadyForCandidates = false
    this.onIncomingRing = dummyCallback
    this.onStateChange = dummyCallback
    this.onAddTrack = dummyCallback
    this.onStopTracks = dummyCallback
    this.createMediaStream = this.createMediaStream.bind(this)
    this.handleMuteStateChange = (jid, state) => {
      this.remoteMuteState = state
      this.events.emit('remote-mute', state)
    }
    this.events = new EventEmitter()
  }

  /////////////////////////////////////////////////////////
  // Public API

  /**
   * Enable processing of RTC signals. This must be called before any attempt to call
   * another user.
   */
  start() {
    webrtc.startWaitingForCalls(
      this.handleRingRequest,
      this.handleRingAnswer,
      this.handleRingCancel,
      this.handleHangup,
      this.handleNewOffer,
      this.handleIceCandidates,
      this.handleMuteStateChange,
    )
  }

  /**
   * Stop processing of RTC signals
   */
  stop() {
    if (this.state !== STATE_IDLE) {
      // eslint-disable-next-line no-console
      console.error('You must not stop the CallManager while a call is in progress!')
      Sentry.captureMessage(`CallManager.stop called while in state ${this.state}`)
    }
    webrtc.stopWaitingForCalls()
  }

  /**
   * Ring a user
   *
   * @param {string} userId Crypho user id of the user we are calling
   * @param {string} spaceId Id of the space in which the call is made
   * @param {{audio: boolean, video: boolean}} mediaConstraints Requested media for the call
   * @returns {Promise<'no-reply'|'refused'|'accepted'|'offline'>}
   */
  async ring(userId, spaceId, mediaConstraints) {
    if (this.state !== STATE_IDLE) {
      Sentry.captureMessage(`CallManager.ring called while in state ${this.state}`)
      throw new Error(`Can not ring another user while in state ${this.state}`)
    }
    this.spaceId = spaceId
    this.requestedMedia = mediaConstraints
    this.remoteUserId = userId
    this.remoteBareJid = xmppJid(userId, store.getState().config.xmppDomain).bare().toString()

    const state = store.getState()
    const to = state.online.byId[userId] || []
    const encryptionKey = await this.getEncryptionKey(spaceId)
    webrtc.setEncryptionKey(encryptionKey)

    // Send a push notification through the backend
    webrtc.notifyUserOfRing(this.remoteBareJid, spaceId, mediaConstraints).catch((error) => {
      // eslint-disable-next-line no-console
      console.error('WebRTC error notifying peer about call', error)
    })

    try {
      await this.setupLocalMedia(mediaConstraints)
    } catch (error) {
      return 'media-access-denied'
    }
    this.setConnectionState(STATE_RINGING)

    const answerPromise = new Promise((resolve) => {
      this.resolveRingRequest = resolve
    })

    // Ring all online clients
    to.forEach((jid) => {
      webrtc.ring(jid, spaceId, mediaConstraints).catch((error) => {
        Sentry.captureException(error)
        return { jid, accept: 'error', error }
      })
    })

    const timeout = pause(RING_RESPONSE_TIMEOUT, { accept: 'timeout' })
    const cancel = new Promise((resolve) => {
      this._cancelPromise = () => resolve({ accept: 'cancel' })
    })
    const stopRinging = () => {
      this.sendRingCancel(userId)
      this._cancelPromise = null
    }

    // eslint-disable-next-line no-constant-condition
    while (true) {
      const promises = [timeout, cancel, answerPromise]
      const { jid, accept, error } = await Promise.race(promises)

      switch (accept) {
        case 'timeout':
          stopRinging()
          this.disconnectStreams()
          this.setConnectionState(STATE_IDLE)
          return 'no-reply'

        case 'error':
          // eslint-disable-next-line no-console
          console.error(`Error ringing ${jid}`, error)
          break

        case 'cancel':
          stopRinging()
          this.disconnectStreams()
          this.setConnectionState(STATE_IDLE)
          return 'cancelled'

        case 'reject':
          this.remoteFullJid = jid
          stopRinging()
          this.disconnectStreams()
          this.setConnectionState(STATE_IDLE)
          return 'refused'

        case 'accept':
          this.remoteFullJid = jid
          stopRinging()
          this.setConnectionState(STATE_READY_TO_CALL)
          this.startCall()
          return 'accepted'
      }
    }
  }

  /**
   * Answer a ring from another party. This method should only be used when
   * answering a ring that was received via a push notification.
   *
   * @param {string} fullJid Full JID for the entity initiating the call
   * @param {boolean} accept Flag indicating if the call is accepted or refused
   * @param {string} spaceId Unique identifier for the space used for the call
   * @param {string|undefined} keyId Encryption key id to use
   * @param {{audio: boolean, video: boolean}} mediaConstraints Media constraints to use for the call
   */
  async answerRing(fullJid, accept, spaceId, keyId, mediaConstraints) {
    if (this.state !== STATE_RECEIVING_RING) {
      Sentry.captureMessage(`CallManager.answerRing called while in state ${this.state}`)
      throw new Error(`Can not answer a ring while in state ${this.state}`)
    }
    this.remoteFullJid = fullJid
    this.requestedMedia = mediaConstraints
    this.remoteMuteState = { audio: false, video: false }
    this.remoteBareJid = xmppJid(fullJid).bare().toString()
    if (accept) {
      const encryptionKey = await this.getEncryptionKey(spaceId, keyId)
      webrtc.setEncryptionKey(encryptionKey)
      this.setConnectionState(STATE_WAITING_FOR_FIRST_OFFER)
    } else {
      this.setConnectionState(STATE_IDLE)
    }
    webrtc.answerRing(fullJid, accept, mediaConstraints)
  }

  /**
   * Terminate a call that is being setup or already in progress.
   */
  hangup() {
    if (this.state !== STATE_CONNECTED) {
      // eslint-disable-next-line no-console
      console.error('You must not hangup the CallManager while a call is not in progress!')
      return
    }
    webrtc.hangup(this.remoteFullJid)
    this.endCall()
  }

  /**
   * Cancel an active ring to another party.
   *
   * @param {string} userId Userid for the user that we are ringing.
   */
  cancelRing(userId) {
    if (this.state !== STATE_RINGING) {
      return
    }
    if (this._cancelPromise) {
      this._cancelPromise()
      this._cancelPromise = null
    }

    this.sendRingCancel(userId)
    this.disconnectStreams()
    this.setConnectionState(STATE_IDLE)
  }

  /**
   * Notify users of tracks being muted.
   * Necessary because react-native-webrtc does not handle mutes on the tracks.
   *
   * @param {{audio: boolean, video: boolean}} mute video/audio mute state
   * @returns {Promise<void>}
   */
  async sendMuteState(state) {
    await webrtc.sendMuteState(this.remoteFullJid, state)
  }

  /////////////////////////////////////////////////////////
  // Internal API

  sendRingCancel(userId) {
    // Send a push notification through the backend to stop offline devices from ringing
    webrtc.notifyUserOfRingCancel(this.remoteBareJid, this.spaceId).catch((error) => {
      // eslint-disable-next-line no-console
      console.error('WebRTC error notifying peer about call', error)
    })
    // We need to get a new list of online devices since a device may have come
    // online later after a ring push notification.
    const state = store.getState()
    const to = state.online.byId[userId]
    if (to) {
      to.forEach((jid) => webrtc.cancelRing(jid).catch(() => {}))
    }
  }

  /**
   * Return the encryption key to use when talking to another party
   *
   * @param {string} spaceId ID of the person we are signalling
   * @param {number} keyId ID of the encryption key to use
   * @returns {Promise<{id: number, key: string}>} Encryption key
   */
  async getEncryptionKey(spaceId, keyId) {
    const getKey = keyId
      ? () => ({ id: keyId, key: getSpaceKey(spaceId, keyId) })
      : () => getSpaceCurrentKey(store.getState(), { spaceId })

    let key = getKey()
    if (!key || !key.key) {
      await store.dispatch(fetchSpaceMetadata(spaceId))
      key = getKey()
    }
    return key
  }

  // Rename this method since it does a bit more now
  disconnectStreams() {
    webrtc.clearEncryptionKey()
    this.stream = null
    this.onStopTracks()
  }

  setConnectionState(newState) {
    if (newState === this.state) {
      return
    }
    this.state = newState
    this.onStateChange(newState)
    if (this.state === STATE_CONNECTED) {
      this.events.emit('end-call')
    } else if (newState === STATE_CONNECTED) {
      this.events.emit('start-call')
    } else if (newState == STATE_RINGING || newState === STATE_RECEIVING_RING) {
      this.events.emit('ringing')
    }
    this.events.emit('state', newState)
  }

  /**
   * Start a call with another jid (user at a specific device connection).
   *
   */
  async startCall() {
    if (this.state !== STATE_READY_TO_CALL) {
      throw new Error('You can only start a call after an accepted ring')
    }
    await this.createPeerConnection()
    this.addLocalMediaTracksToPeerConnection(this.stream)
    this.setConnectionState(STATE_CONNECTING)
    this.onAddTrack('local', this.stream)
    this.startFailureTimer()
  }

  endCall() {
    this.disconnectStreams()
    if (this._failureTimer) {
      clearTimeout(this._failureTimer)
      this._failureTimer = null
    }
    if (this.pc) {
      this.pc.removeEventListener('connectionstatechange', this.handlePeerConnectionStateChange)
      this.pc.removeEventListener('iceconnectionstatechange', this.handleIceConnectionStateChange)
      this.pc.removeEventListener('negotiationneeded', this.handlePeerNegotiationNeeded)
      this.pc.removeEventListener('icecandidate', this.handlePeerIceCandidate)
      this.pc.removeEventListener('track', this.handleNewTrack)
      if (this.pc.addStream) {
        //Only for react native
        this.pc.removeEventListener('addstream', this.handleNewStream)
      }
      this.pc.removeEventListener('removetrack', this.handleRemoveTrack)
      this.pc.close()
      this.pc = null
      this.remoteMuteState = { audio: false, video: false }
      this.remoteFullJid = null
    }
    this.pcReadyForCandidates = false
    this.pendingIceCandidates = []
    this.setConnectionState(STATE_IDLE)
  }

  startFailureTimer() {
    this._failureTimer = setTimeout(() => {
      this._failureTimer = null
      if (this.state === STATE_CONNECTING || this.state === STATE_WAITING_FOR_FIRST_OFFER) {
        // eslint-disable-next-line no-console
        console.error('Call setup took too long, aborting')
        this.endCall()
      }
    }, CALL_SETUP_TIMEOUT)
  }

  /**
   * Create a new RTCPeerConnection and hook up standard event handlers.
   */
  async createPeerConnection() {
    const state = store.getState()
    const { apiUrl } = state.config
    const api = new CryphoAPI(apiUrl)
    const iceServers = (await api.get('/webrtc/ice-servers')).ice_servers
    this.pc = new RTCPeerConnection({
      iceTransportPolicy: 'all',
      iceServers,
    })
    this.pc.addEventListener('connectionstatechange', this.handlePeerConnectionStateChange)
    this.pc.addEventListener('iceconnectionstatechange', this.handleIceConnectionStateChange)
    this.pc.addEventListener('negotiationneeded', this.handlePeerNegotiationNeeded)
    this.pc.addEventListener('icecandidate', this.handlePeerIceCandidate)
    this.pc.addEventListener('track', this.handleNewTrack)
    this.pc.addEventListener('addstream', this.handleNewStream)
    this.pc.addEventListener('removetrack', this.handleRemoveTrack)
  }

  /**
   * Create and remember a local media stream.
   *
   * @param {{audio: boolean, video: boolean}} media Requested media for the call
   * @returns {Promise<void>}
   */
  async setupLocalMedia(media) {
    if (!this.stream) {
      this.stream = await this.createMediaStream(media)
      this.onAddTrack('local', this.stream)
    }
  }

  /**
   * Add local media tracks to the peer connection.
   *
   * @param {MediaStream} stream Local media stream
   */
  addLocalMediaTracksToPeerConnection(stream) {
    if (this.pc.addStream) {
      // React Native
      this.pc.addStream(stream)
    } else {
      // Browsers
      stream.getTracks().forEach((track) => this.pc.addTrack(track, stream))
    }
  }

  /**
   * Handle `connectionstatechange` event of RTCPeerConnection
   */
  handlePeerConnectionStateChange = () => {
    switch (this.pc.connectionState) {
      case 'connected':
        this.setConnectionState(STATE_CONNECTED)
        break
      case 'failed':
      case 'closed':
        this.endCall()
        this.setConnectionState(STATE_IDLE)
        break
      case 'disconnected':
        break
    }
  }

  /**
   * Handle `iceconnectionstatechange` event of RTCPeerConnection
   */
  handleIceConnectionStateChange = () => {
    switch (this.pc.iceConnectionState) {
      case 'connected':
      case 'completed':
        this.setConnectionState(STATE_CONNECTED)
        break
      case 'closed':
      case 'failed':
        this.endCall()
        this.setConnectionState(STATE_IDLE)
        break
      case 'disconnected':
        // This can be a temporary issue, so ignore it. If this becomes a
        // permanent issue the state will change to `failed` automatically.
        break
    }
  }

  /**
   * Handle `negotiationneeded` event of RTCPeerConnection
   */
  handlePeerNegotiationNeeded = async () => {
    const sessionDescription = await this.pc.createOffer()
    await this.pc.setLocalDescription(sessionDescription)
    const remote = await webrtc.sendSessionDescription(this.remoteFullJid, sessionDescription)
    if (!remote) {
      // eslint-disable-next-line no-console
      console.error('WebRTC remote returned a bad session description', this.remoteFullJid)
      return
    }
    await this.pc.setRemoteDescription(remote)
    this.enableIceCandidate()
  }

  /**
   * Handle `icecandidate` event of RTCPeerConnection
   *
   * @param {RTCPeerConnectionIceEvent} evt Incoming event
   */
  handlePeerIceCandidate = (evt) => {
    if (evt.candidate) {
      webrtc.sendIceCandidates(this.remoteFullJid, [evt.candidate])
    }
  }

  /**
   * Handle `track` event of RTCPeerConnection
   *
   * @param {RTCTrackEvent} evt Incoming event
   */
  handleNewTrack = (evt) => {
    this.onAddTrack('remote', evt.streams[0])
  }

  /**
   * Handle `addstream` event of RTCPeerConnection (in React Native)
   *
   * @param {RTCTrackEvent} evt Incoming event
   */
  handleNewStream = (evt) => {
    this.onAddTrack('remote', evt.stream)
  }

  /**
   * Handle incoming `ring-request` signal indicating someone is trying to call us
   *
   * @param {string} from JID that is calling us
   * @param {string} spaceId Space in which the call is made
   * @param {number} keyId Encryption key id to use for signal encryption
   * @param {{audio: boolean, video: boolean}} media Request media for the call
   * @returns {Promise<{reply: boolean, encryptionKey?: {id: number, key: string}, mediaConstraints: {audio: boolean, video: boolean}}>} Answer to the ring
   */
  handleRingRequest = async (from, spaceId, keyId, media, context) => {
    // Refuse an incoming call if we are already working on another call
    if (this.state !== STATE_IDLE) {
      return { answer: false }
    }

    this.remoteFullJid = from
    this.remoteBareJid = xmppJid(from).bare().toString()
    this.requestedMedia = media
    try {
      await this.setupLocalMedia(media)
    } catch (error) {
      return { answer: false }
    }
    this.setConnectionState(STATE_RECEIVING_RING)
    const answer = await this.onIncomingRing(media, context) // XXX This should return accepted media
    if (!answer) {
      this.disconnectStreams()
    }
    this.setConnectionState(answer ? STATE_WAITING_FOR_FIRST_OFFER : STATE_IDLE)
    const reply = { answer, media }
    if (answer) {
      reply.encryptionKey = await this.getEncryptionKey(spaceId, keyId)
      this.startFailureTimer()
    }
    return reply
  }

  /**
   * Handle incoming `ring-answer` signal indicating someone responded to a ring from us
   *
   * @param {string} from JID that is calling us
   * @param {'accept'|'refuse'} accept Flag indicating if the call was accepted
   * @param {{audio: boolean, video: boolean}|undefined} media Accepted media for the call
   * @returns {Promise<boolean>} Answer to the ring
   */
  handleRingAnswer = async (from, accept, media) => {
    if (this.state !== STATE_RINGING) {
      return
    }

    const remoteBareJid = xmppJid(from).bare().toString()
    if (remoteBareJid === this.remoteBareJid) {
      this.resolveRingRequest({ jid: from, accept, media })
      this.resolveRingRequest = resolveNothing
    } else {
      // eslint-disable-next-line no-console
      console.error(`Ring answer from ${from}, but we are calling ${this.remoteBareJid}`)
    }
  }

  /**
   * Handle incoming `ring-cancel` signal indicating the other party cancelled before we could answer
   *
   * @param {string} from JID that is cancelling on us
   * @returns {Promise<void>}

   */
  handleRingCancel = async (remoteFullJid) => {
    const bareJid = xmppJid(remoteFullJid).bare().toString()
    if (bareJid !== this.remoteBareJid) {
      // eslint-disable-next-line no-console
      console.error(`${remoteFullJid} tried to cancel ring with ${this.remoteBareJid}`)
      return
    }
    if (this.state !== STATE_RECEIVING_RING) {
      return
    }
    webrtc.clearEncryptionKey()
    this.disconnectStreams()
    this.setConnectionState(STATE_IDLE)
  }

  /**
   * Handle incoming `hangup` signal indicating the other party wants to disconnect
   *
   * @param {string} from JID that is cancelling on us
   * @returns {Promise<void>}
   */
  handleHangup = async (remoteFullJid) => {
    // For firefox we need to handle hangup and call endCall()
    // in order to terminate the call as FF does not receive PeerConnection
    // signals when the connection is terminated.
    // The rest of the clients might have already termincated the call.
    if (this.state !== STATE_CONNECTED) return
    if (remoteFullJid !== this.remoteFullJid) {
      // eslint-disable-next-line no-console
      console.error(`${remoteFullJid} tried to hangup conversation with ${this.remoteFullJid}`)
      return
    }
    this.endCall()
    this.setConnectionState(STATE_IDLE)
  }

  /**
   * Handle incoming session description signal. This is part of the new call setup
   * logic.
   *
   * @param {string} remoteFullJid JID that is sending the new session description
   * @param {RTCSessionDescriptionInit} sessionDescription session description from remote party
   * @return {Promise<RTCSessionDescriptionInit>}
   */
  handleNewOffer = async (remoteFullJid, sessionDescription) => {
    if (remoteFullJid !== this.remoteFullJid) {
      // eslint-disable-next-line no-console
      console.log(`${remoteFullJid} tried to interfere with offer for conversation with ${this.remoteFullJid}`)
      return
    }

    if (this.state === STATE_WAITING_FOR_FIRST_OFFER) {
      await this.createPeerConnection()
      // The media should been created here so the app is already in active state
      // It means we can have video.
      await this.setupLocalMedia(this.requestedMedia)
      this.addLocalMediaTracksToPeerConnection(this.stream)
    }

    await this.pc.setRemoteDescription(new RTCSessionDescription(sessionDescription))
    const answer = await this.pc.createAnswer()
    this.pc.setLocalDescription(answer)
    this.enableIceCandidate()

    if (this.state === STATE_WAITING_FOR_FIRST_OFFER) {
      this.setConnectionState(STATE_CONNECTING)
      this.onAddTrack('local', this.stream)
    }
    return answer
  }

  enableIceCandidate() {
    this.pcReadyForCandidates = true
    if (this.pendingIceCandidates.length > 0) {
      this.pendingIceCandidates.forEach(this.addIceCandidate)
      this.pendingIceCandidates = []
    }
  }

  /**
   * Handle incoming ICE candidate.
   * @param {string} remoteFullJid JID that is sending us an ICE candidate
   * @param {RTCIceCandidateInit[]} candidates List of ICE candidates
   */
  handleIceCandidates = (remoteFullJid, candidates) => {
    if (remoteFullJid !== this.remoteFullJid) {
      // eslint-disable-next-line no-console
      console.error(
        `${remoteFullJid} tried to interfere with ICE candidate for conversation with ${this.remoteFullJid}`,
      )
      return
    }
    if (!this.pcReadyForCandidates) {
      this.pendingIceCandidates = this.pendingIceCandidates.concat(candidates)
    } else {
      candidates.forEach(this.addIceCandidate)
    }
  }

  addIceCandidate = (candidate) => {
    try {
      this.pc.addIceCandidate(candidate)
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('Error adding ICE candidate', candidate, error)
    }
  }

  setDevices(devices) {
    this.devices = devices
  }
}
