import React, { useRef } from 'react'
import PropTypes from 'prop-types'
import { Icon } from 'semantic-ui-react'
import AddAccountDomain from './AddAccountDomain'
import classes from './AccountInfoHeader.module.css'

const AccountInfoHeader = ({ onAddDomain }) => {
  const addAccountDomainRef = useRef()

  const addAccountDomainHandler = (domain) => {
    addAccountDomainRef.current.close()
    onAddDomain(domain)
  }

  const cancelHandler = () => {
    addAccountDomainRef.current.close()
  }

  return (
    <div className={classes.header}>
      <AddAccountDomain ref={addAccountDomainRef} onAdd={addAccountDomainHandler} onCancel={cancelHandler} />
      <div className={classes.title}>
        <h3>Account info</h3>
      </div>
      <div className={classes.actions} onClick={() => addAccountDomainRef.current.open()}>
        <Icon className={`addIcon ${classes.addIcon}`} name="plus" title="Add domain" />
      </div>
    </div>
  )
}

AccountInfoHeader.propTypes = {
  onAddDomain: PropTypes.func,
}

export default AccountInfoHeader
