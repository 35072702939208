import React from 'react'
import PropTypes from 'prop-types'
import { Image, Button, List, Item, Header, Message, Divider, Grid, Reveal, Icon } from 'semantic-ui-react'
import { Form } from 'formsy-semantic-ui-react'
import { connect } from 'react-redux'
import { parsePhoneNumberFromString } from 'libphonenumber-js'
import { NotificationManager } from 'react-notifications'

import { crypho } from '../../crypho.core/xmpp'
import { PhoneInput } from '../../UI/forms'
import { errorLabel } from '../../UI/forms'
import DeleteAccountForm from './deleteaccount'
import Topbar from '../../UI/Topbar'

class MyProfile extends React.Component {
  constructor(properties) {
    super(properties)
    this.profilePictureInputReference = React.createRef()
    this.deleteAccountFormReference = React.createRef()
    this.state = {
      fullname: '',
      loading: false,
      avatar: '',
      phoneNumber: '',
      emails: null,
      email: '',
    }
  }
  isValidFullName = ({ fullname }) => {
    const regex = /^[^!#*./:;<>?@]+$/
    const isValid = regex.test(fullname)
    return isValid
  }
  saveProfile = async () => {
    this.setState({ loading: true })
    const { avatar, fullname, phoneNumber } = this.state
    let { vCard } = this.props

    try {
      if (avatar) {
        const photoRegExp = /^data:([\w/]+);base64,([\S\s]*)$/
        const photo = avatar.match(photoRegExp)
        await crypho.setAvatar({ type: photo[1], data: photo[2] })
      }

      if (fullname) {
        await crypho.setFullname(fullname)
        vCard.FN = fullname
      }

      if (phoneNumber) {
        const phoneNr = parsePhoneNumberFromString(phoneNumber)
        if (phoneNr) {
          await crypho.setMobile(phoneNr.nationalNumber, phoneNr.country)
          NotificationManager.info('Your mobile number has been set. We have sent you an SMS verification.')
        }
      }
      NotificationManager.success('Your profile has been updated')
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(error)
      NotificationManager.error('There was an error updating your profile')
    }
    this.setState({ loading: false, fullname: '' })
  }

  onAvatarChange = (ev) => {
    const Resample = ((canvas) => {
      function Resample(img, width, height, onresample) {
        let i = new window.Image()
        i.addEventListener('load', onload)

        i._onresample = onresample
        i._width = width
        i._height = height
        // eslint-disable-next-line unicorn/prevent-abbreviations
        i.src = img
      }

      function onload() {
        let img = this,
          width = img._width,
          height = img._height,
          onresample = img._onresample,
          minValue = Math.min(img.height, img.width)

        width === null && (width = round((img.width * height) / img.height))
        height === null && (height = round((img.height * width) / img.width))
        delete img._onresample
        delete img._width
        delete img._height

        canvas.width = width
        canvas.height = height
        context.drawImage(
          img,
          round((img.width - minValue) / 2),
          round((img.height - minValue) / 2),
          minValue,
          minValue,
          0,
          0,
          width,
          height,
        )
        onresample(canvas.toDataURL('image/jpeg'))
      }

      const context = canvas.getContext('2d'),
        round = Math.round

      return Resample
    })(document.createElement('canvas'))

    if (ev.target.files) {
      const file = ev.target.files[0]
      if (file.type.match('image.*')) {
        let fileTracker = new FileReader()
        const component = this
        fileTracker.addEventListener('load', function () {
          Resample(this.result, 128, 128, (avatar) => {
            component.setState({ avatar })
          })
        })
        fileTracker.readAsDataURL(file)
      }
    }
  }
  addEmail = async () => {
    const { email } = this.state
    if (!email) return
    try {
      await crypho.addEmail(email)
      NotificationManager.success(
        `We have sent you an email at ${email}. Please verify the ownership of the email address by clicking the link in the email.`,
      )
      this.setState({ email: '' })
    } catch (error) {
      NotificationManager.error(`${email} is already registered`)
    }
  }

  deleteAcount = () => {}

  render() {
    const { apiUrl, identity, vCard } = this.props
    const { loading, avatar, emails, email } = this.state
    const userId = identity.id
    if (!identity || !vCard) return null
    if (emails === null) {
      // eslint-disable-next-line promise/catch-or-return
      crypho.getEmails().then((emails) => this.setState({ emails: emails.additional }))
      return null
    }

    const phoneNr = parsePhoneNumberFromString(identity.phone.local, identity.phone.country.toUpperCase())
    const fullNumber = phoneNr ? phoneNr.format('INTERNATIONAL') : identity.phone.local

    return (
      <div className="authenticatedScene">
        <Topbar />
        <div className="accountOptions">
          <Form onValidSubmit={this.saveProfile}>
            <Grid>
              <Grid.Row>
                <Grid.Column width={12}>
                  <Form.Input
                    name="fullname"
                    type="text"
                    value={vCard.FN}
                    placeholder="Your name"
                    required
                    validations={{
                      isNonEmptyString: true,
                      isValidFullName: this.isValidFullName,
                    }}
                    onChange={(event_) => this.setState({ fullname: event_.target.value })}
                    errorLabel={errorLabel}
                    validationErrors={{
                      isDefaultRequiredValue: 'Your name is required',
                      isNonEmptyString: 'Your name is required',
                      isValidFullName:
                        'Special characters such as !, #, *, /, :, ;, <, >, ?, @, and \\ are not allowed.',
                    }}
                  />
                  <PhoneInput
                    name="phone"
                    value={fullNumber}
                    onChange={(value) => this.setState({ phoneNumber: value })}
                    required
                    errorLabel={errorLabel}
                    validations="isMobileNumber"
                    validationErrors={{
                      isDefaultRequiredValue: 'You need a phone number',
                      isMobileNumber: 'You need a valid mobile number',
                    }}
                  />
                </Grid.Column>
                <Grid.Column width={4}>
                  <Reveal id="profile-picture" animated="small fade" onClick={this.clickProfileInput}>
                    <Reveal.Content visible>
                      <Image size="small" avatar src={avatar || `${apiUrl}/avatar/${userId}`} />
                      <input
                        type="file"
                        className="avatarChange"
                        name="profile-picture"
                        onChange={(ev) => this.onAvatarChange(ev)}
                        ref={this.profilePictureInputReference}
                      />
                    </Reveal.Content>
                    <Reveal.Content hidden className="changeAvatar">
                      <Icon className="image" size="large" />
                      Update
                    </Reveal.Content>
                  </Reveal>
                </Grid.Column>
              </Grid.Row>
              <Button name="saveProfile" className="settingsButton saveProfile" type="submit" disabled={loading}>
                Save changes
              </Button>
            </Grid>
          </Form>
          <Divider />
          <Header as="h3">Linked emails</Header>
          <Item.Group divided>
            <Item className="emailListItem">
              <Item.Content>{`${vCard.EMAIL.USERID}`}</Item.Content>
              <Item.Description>(primary email)</Item.Description>
            </Item>
            {emails.map((email) => (
              <Item key={email}>
                <Item.Content className="emailListItem">{email}</Item.Content>
              </Item>
            ))}
          </Item.Group>
          <Message compact info>
            You can associate additional email addresses with your account. You can log in with any of them. Others can
            invite you at any of your registered addresses.
          </Message>

          <Form onValidSubmit={this.addEmail}>
            <Form.Input
              value={email}
              name="email"
              type="email"
              placeholder="Add another email"
              validations="isEmail"
              onChange={(event_) => this.setState({ email: event_.target.value })}
              errorLabel={errorLabel}
              validationErrors={{
                isEmail: 'This is not a valid email',
                isDefaultRequiredValue: 'Email is required',
              }}
            />
            <Button name="sendVerification" className="settingsButton" type="submit">
              Add email
            </Button>
          </Form>
          <Divider />
          <Header as="h3">Delete your account</Header>
          <Message compact warning>
            Deleting your account means:
            <List>
              <List.Item>
                All your conversations and files you have shared with your contacts will be deleted.
              </List.Item>
              <List.Item>All the groups you own and files within will be deleted.</List.Item>
              <List.Item>
                You will be removed from all the groups owned by others, but messages and files you have shared within
                those groups will remain.
              </List.Item>
            </List>
            <p>It will be impossible to recover any of the content lost.</p>
          </Message>
          <DeleteAccountForm ref={this.deleteAccountFormReference} />
          <br />
          <Button name="deleteAccount" negative onClick={() => this.deleteAccountFormReference.current.open()}>
            Delete account
          </Button>
        </div>
      </div>
    )
  }

  clickProfileInput = () => this.profilePictureInputReference.current.click()
}

MyProfile.propTypes = {
  apiUrl: PropTypes.string.isRequired,
  identity: PropTypes.shape().isRequired,
  vCard: PropTypes.shape(),
}

const mapStateToProperties = (state) => ({
  apiUrl: state.config.apiUrl,
  identity: state.identity,
  vCard: state.vcards.byId[state.identity.id],
})

const mapDispatchToProperties = {}

const connector = (container) => connect(mapStateToProperties, mapDispatchToProperties)(container)

export default connector(MyProfile)
