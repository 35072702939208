import React from 'react'
//import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import {
  CallContext,
  STATE_RECEIVING_RING,
  STATE_CONNECTED,
  STATE_RINGING,
  STATE_CONNECTING,
} from '../../crypho.core/rtc'
import InComingCall from './InComingCall'
import ActiveCall from './ActiveCall'
import OutgoingCall from './OutgoingCall'

class CallWidget extends React.Component {
  constructor(props) {
    super(props)
  }

  componentDidMount() {
    this.getContainerStyle()
  }

  getContainerStyle() {
    const interval = setInterval(() => {
      const topBar = document.querySelector('#topBar')
      if (topBar) {
        const topBarHeight = topBar.clientHeight
        this.containerStyle = {
          zIndex: 102,
          display: 'flex',
          marginTop: topBarHeight,
          flexDirection: 'column',
          cursor: 'move',
        }
        clearInterval(interval)
      }
    }, 100)
  }

  render() {
    const { callState } = this.context
    switch (callState) {
      case STATE_RECEIVING_RING:
        return <InComingCall containerStyle={this.containerStyle} />
      case STATE_CONNECTED:
        return <ActiveCall containerStyle={this.containerStyle} />
      case STATE_RINGING:
        return <OutgoingCall containerStyle={this.containerStyle} />
      case STATE_CONNECTING:
        return <ActiveCall containerStyle={this.containerStyle} />
      default:
        return null
    }
  }
}

CallWidget.contextType = CallContext

CallWidget.propTypes = {}

const connector = (container) => connect(null, null)(container)

export default withRouter(connector(CallWidget))
