import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import Dropzone from 'react-dropzone'
import { Icon } from 'semantic-ui-react'
import { uploadAndPublishFile, convertImageToJPEG } from '../../file-handling'

/**
 * This component manages file upload handling.
 *
 * To use it you must pass a single render function as child. This function
 * will be passed an object with the following items:
 *
 * - `isDragAcctive`: a boolean flag indicating the user is currently dragging a file
 * - `childPropperties`: an object with any extra properties passed to this component
 * - `open`: a function you can call to trigger the open file dialog
 *
 * ```html
<Uploader childProperties={{...}} spaceId="123123">
  {( isDragActive }) => (
    <p>User is {isDragActive && <strong>not</strong>} dragging</p>
  )}
</Uploader>
```
 */

export default class Uploader extends React.Component {
  handlePaste = async (ev) => {
    const { clipboardData } = ev
    if (clipboardData.files.length === 0 || clipboardData.getData('text/plain')) {
      return
    }

    const files = clipboardData.files
    ev.preventDefault()
    ev.stopPropagation()

    for (let file of files) {
      try {
        if (file.type.startsWith('image/')) {
          // Convert pasted images to JPEG so we do not store weird formats
          // like TIFF.
          file = await convertImageToJPEG(file)
        }
        await uploadAndPublishFile(this.props.spaceId, file)
      } catch (error) {
        // eslint-disable-next-line no-console
        console.log(error)
      }
    }
  }

  /**
   * @type {(acceptedFiles: File[]) => any}
   */
  handleDrop = (acceptedFiles) => {
    acceptedFiles.forEach((file) => uploadAndPublishFile(this.props.spaceId, file))
  }

  render() {
    const { className, children, dragNotifcation, childProperties, disabled } = this.props
    return (
      <Dropzone onDropAccepted={this.handleDrop} multiple>
        {
          // eslint-disable-next-line unicorn/prevent-abbreviations
          ({ getRootProps, getInputProps, isDragActive, open }) => {
            const rootProperties = getRootProps()
            delete rootProperties.onClick
            if (disabled) {
              rootProperties.onClick = (evt) => evt.preventDefault()
            }
            return (
              <div
                {...rootProperties}
                onPaste={this.handlePaste}
                className={classNames('dropzone', className, {
                  'dropzone-isActive': isDragActive,
                })}
              >
                {children({ isDragActive, childProperties, open })}
                <input id="c-upload" name="upload" {...getInputProps()} />
                {isDragActive
                  ? dragNotifcation || (
                      <div className="dragNotification">
                        <Icon name="cloud upload" size="massive" />
                        <p>Drop files here to upload</p>
                      </div>
                    )
                  : null}
              </div>
            )
          }
        }
      </Dropzone>
    )
  }
}

Uploader.propTypes = {
  childProperties: PropTypes.shape(),
  children: PropTypes.func.isRequired,
  className: PropTypes.string,
  dragNotifcation: PropTypes.element,
  spaceId: PropTypes.string.isRequired,
  disabled: PropTypes.bool.isRequired,
}

Uploader.defaultProps = {
  childPropperties: {},
  disabled: false,
}
