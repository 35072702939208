import React from 'react'
import PropTypes from 'prop-types'
import { List } from 'semantic-ui-react'
import { connect } from 'react-redux'
import { NavLink } from 'react-router-dom'

import InvitationListItem from './invitationlistitem'

const MAX_SHOWN_INVITATIONS = 3

class InvitationList extends React.PureComponent {
  render() {
    const { invitations } = this.props
    if (invitations.length === 0) return null
    const diplayedInvitations = invitations.slice(0, MAX_SHOWN_INVITATIONS)
    const hiddenInvitations = invitations.length - MAX_SHOWN_INVITATIONS
    return (
      <div className="invitationList">
        <List divided>
          {diplayedInvitations.map((invitation) => {
            return <InvitationListItem key={invitation.uid} invitation={invitation} />
          })}
        </List>
        {hiddenInvitations > 0 ? (
          <NavLink to={'/invitations'} className="blueTextLink">
            Full invitation list ({hiddenInvitations} more pending)
          </NavLink>
        ) : null}
      </div>
    )
  }
}

InvitationList.propTypes = {
  invitations: PropTypes.array,
}

const mapStateToProperties = (state) => {
  return {
    invitations: state.invitations.received,
  }
}

const connector = (container) => connect(mapStateToProperties, {})(container)

export default connector(InvitationList)
