import React, { Component } from 'react'
import { Header, Divider } from 'semantic-ui-react'
import Topbar from '../../UI/Topbar'

class SupportScene extends Component {
  render() {
    return (
      <div className="authenticatedScene">
        <Topbar />
        <div className="accountOptions">
          <Header as="h3">Help</Header>
          <p>
            You can find answers to most of your questions in our{' '}
            <a
              className="blueTextLink"
              href="https://www.crypho.com/documentation/"
              rel="noopener noreferrer"
              target="_blank"
            >
              Documentation
            </a>{' '}
            or in the section for{' '}
            <a className="blueTextLink" href="https://www.crypho.com/faq/" rel="noopener noreferrer" target="_blank">
              Frequently Asked Questions
            </a>
            .
          </p>
          <p>
            Feel free to contact Crypho&apos;s support team with any questions or requests for help. We&apos;ll get back
            to you as soon as possible and we&#39;ll do our best to help.
          </p>
          <Divider />
          <Header as="h3">Contact support</Header>
          <p>
            To contact support, send an email to
            <a href="mailto:support@crypho.com" className="blueTextLink">
              {' '}
              support@crypho.com
            </a>{' '}
            with a description of your problem. We are usually able to respond within same workday.
          </p>
          <p>
            You are also welcome to invite Crypho support to chat right inside the Crypho app itself. If Crypho Support
            is not already your contact, choose &quot;Add contact&quot; and invite <strong>support@crypho.com</strong>
          </p>
          <p>Our team currently operates on European time zones (CET).</p>
          <br />
        </div>
      </div>
    )
  }
}

export default SupportScene
