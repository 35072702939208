import React from 'react'
import PropTypes from 'prop-types'
import { CallManager, STATE_RECEIVING_RING, STATE_CONNECTED } from './call-manager'

export const CallContext = React.createContext()

export class CallHandler extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      localStreamCache: null,
      remoteStreamCache: null,
      state: props.manager.state,
      devices: [],
      remoteMuteState: props.manager.remoteMuteState,
    }
    this.handleAddTrack = this.handleAddTrack.bind(this)
    this.handleStopTracks = this.handleStopTracks.bind(this)
    this.handleIncomingRing = this.handleIncomingRing.bind(this)
    this.enumerateDevices = this.enumerateDevices.bind(this)
    this.setOutputDevice = this.setOutputDevice.bind(this)
    this.registerDeviceChangeHandler = this.registerDeviceChangeHandler.bind(this)
    this.unregisterDeviceChangeHandler = this.unregisterDeviceChangeHandler.bind(this)
    this.acceptIncomingCall = this.acceptIncomingCall.bind(this)
    props.manager.onIncomingRing = this.handleIncomingRing
    props.manager.onAddTrack = this.handleAddTrack
    props.manager.onStopTracks = this.handleStopTracks
  }

  componentDidMount = async () => {
    await this.setDevices()
    this.registerDeviceChangeHandler(this.setDevices)
    this.props.manager.start()
    this.props.manager.events.addListener('state', this.handleCallManagerStateChange)
  }

  componentWillUnmount() {
    this.props.manager.events.removeListener('state', this.handleCallManagerStateChange)
    this.unregisterDeviceChangeHandler(this.setDevices)
    if (this.props.manager.state === STATE_RECEIVING_RING) {
      this.refuseIncomingCall()
    }
    this.props.manager.stop()
  }

  handleCallManagerStateChange = (state) => {
    this.setState({ state })
  }

  setDevices = async () => {
    const devices = await this.enumerateDevices()
    this.setState({ devices })
    this.props.manager.setDevices(devices)
  }

  getDevicesByType = (type) => {
    const { devices } = this.state
    return devices.filter((device) => device.kind === type)
  }

  /**
   * Check if we are currently in a fully connected call
   *
   * @returns {boolean} A call is connected
   */
  isInCall = () => {
    return this.state.state === STATE_CONNECTED
  }

  /**
   * Ring another party to start a call
   *
   * @param {string} userId User ID of the person you want to call
   * @param {string} spaceId Id of the space in which the call is made
   * @param {'audio' | 'video'} media Type of call
   * @returns {Promise<'no-reply'|'refused'|'accepted'>}
   */
  ring = (userId, spaceId, media) => {
    this.targetId = userId
    return this.props.manager.ring(userId, spaceId, {
      audio: true,
      video: media === 'video',
    })
  }

  /**
   * Terminate a call that is being setup or already in progress.
   */
  hangup = () => this.props.manager.hangup()

  /**
   * Cancel a call that is being setup.
   */
  cancelRing = (userId) => this.props.manager.cancelRing(userId)

  /**
   * Accept an incoming call
   */
  acceptIncomingCall() {
    this._answerRing(true)
  }

  /**
   * Refuse an incoming call
   */
  refuseIncomingCall = () => this._answerRing(false)

  /**
   * Send mute state to other party
   *
   * @param {{audio: boolean, video: boolean}} mute video/audio mute settings
   */

  sendMuteState = (state) => {
    this.props.manager.sendMuteState(state)
  }

  //////////////////////////////////////////////
  // Internal functions

  handleIncomingRing(media) {
    this.setState({ media })
    return new Promise((resolve) => {
      this._answerRing = resolve
    })
  }

  updateLocalStreamCache = (localStreamCache) => {
    this.setState({ localStreamCache })
  }

  updateRemoteStreamCache = (remoteStreamCache) => {
    this.setState({ remoteStreamCache })
  }

  resetState = () => {
    // re-render to get new state
    this.setState({
      state: this.props.manager.state,
      localStreamCache: null,
      remoteStreamCache: null,
    })
  }

  buildProviderValue() {
    const { manager } = this.props

    return {
      callState: this.state.state,
      peerConnection: manager.pc,
      devices: this.state.devices,
      getDevicesByType: this.getDevicesByType,
      setOutputDevice: this.setOutputDevice,
      handleMuteStateChange: manager.handleMuteStateChange,
      remoteMuteState: manager.remoteMuteState,
      isInCall: this.isInCall,
      ring: this.ring,
      hangup: this.hangup,
      cancelRing: this.cancelRing,
      requestedMedia: manager.requestedMedia,
      remoteJid: manager.remoteFullJid,
      acceptIncomingCall: this.acceptIncomingCall,
      refuseIncomingCall: this.refuseIncomingCall,
      setVideoReference: this.setVideoReference,
      localStreamCache: this.state.localStreamCache,
      remoteStreamCache: this.state.remoteStreamCache,
      createMediaStream: manager.createMediaStream,
      sendMuteState: this.sendMuteState,
      resetState: this.resetState,
      targetId: this.targetId,
      manager,
    }
  }

  render() {
    const { children } = this.props
    const value = this.buildProviderValue()
    return <CallContext.Provider value={value}>{children}</CallContext.Provider>
  }
}

CallHandler.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.arrayOf(PropTypes.node)]),
  manager: PropTypes.instanceOf(CallManager).isRequired,
}
