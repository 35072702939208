import { crypho, client } from '../../../xmpp'
import { fetchUserVCard } from '../vcard'

const SET_ACCOUNT = 'SET_ACCOUNT'

export default function app(
  state = {
    title: '',
    description: '',
    members: [],
    admins: [],
    owner: '',
    validUntil: new Date(),
    invitationsToAccount: null,
    plan: {
      name: '',
      title: '',
      description: '',
      filesizeLimit: 0,
      groupLimit: 0,
      memberLimit: 0,
    },
  },
  action,
) {
  switch (action.type) {
    case SET_ACCOUNT: {
      const { account } = action
      const {
        title,
        description,
        members,
        owner,
        valid_until,
        plan,
        invitations,
        admins,
        invitationsToAccount,
      } = account

      return {
        admins,
        title,
        description,
        members,
        owner,
        invitationsToAccount,
        validUntil: new Date(valid_until * 1000),
        invitations: invitations || [],
        plan: {
          name: plan.name,
          title: plan.title,
          description: plan.description,
          filesizeLimit: plan.filesize_limit,
          groupLimit: plan.group_limit,
          memberLimit: plan.member_limit,
        },
      }
    }

    default:
      return state
  }
}

const setAccount = (account) => ({
  type: SET_ACCOUNT,
  account,
})

export const fetchAccount = () => {
  const domain = client.jid.getDomain()
  return async (dispatch) => {
    const account = await crypho.getAccount()
    dispatch(setAccount(account))
    account.members.forEach((memberId) => {
      dispatch(fetchUserVCard(`${memberId}@${domain}`))
    })
  }
}
