import React, { useState } from 'react'
import { Form } from 'formsy-semantic-ui-react'
import { Segment, Header, Button } from 'semantic-ui-react'
import PropTypes from 'prop-types'
import { NotificationManager } from 'react-notifications'
import { connect } from 'react-redux'

import { errorLabel } from '../../../UI/forms'
import { sendEmail } from '../api'
import EmailInput from './EmailInput'

import classes from './MailForm.module.css'

const extractEmailList = (emails) => {
  return emails.split(';')
}

const MailForm = ({ apiUrl }) => {
  const [sending, setSending] = useState(false)
  const [useTemplate, setUseTemplate] = useState(false)

  const submitHandler = async (formData) => {
    try {
      const { to } = formData
      const email = { ...formData, from: 'support@crypho.com', to: extractEmailList(to) }
      setSending(true)
      await sendEmail(apiUrl, email)
      NotificationManager.info('email sent successfully')
    } catch (error) {
      NotificationManager.error(error?.data?.message ?? 'Error occurred', '')
    }
    setSending(false)
  }
  const useTemplateChangeHandler = (_event, { checked }) => {
    setUseTemplate(checked)
  }

  return (
    <Segment className={classes.formContainer}>
      {sending && (
        <div className={classes.loader}>
          <div className="ui active large inverted text loader">Sending email</div>
        </div>
      )}
      <Header as="h3">Send email</Header>
      <Form onValidSubmit={submitHandler}>
        <Form.Input
          label="subject"
          type="text"
          id="subject"
          name="subject"
          className={`${classes.formInput} ${classes.subject}`}
          required
          validationErrors={{ isDefaultRequiredValue: 'subject is required' }}
          errorLabel={errorLabel}
        />
        <EmailInput label="to" name="to" id="to" className={classes.formInput} />
        <Form.Checkbox
          name="use-template"
          className={classes.formInput}
          label="Use Template"
          onChange={useTemplateChangeHandler}
        />
        {useTemplate && (
          <Form.Input
            label="template"
            type="text"
            id="template"
            name="template"
            className={classes.formInput}
            required
            validationErrors={{ isDefaultRequiredValue: 'template is required' }}
            errorLabel={errorLabel}
          />
        )}
        {!useTemplate && (
          <Form.TextArea
            label="body"
            type="text"
            id="body"
            name="body"
            className={classes.formInput}
            rows={3}
            required
            validationErrors={{ isDefaultRequiredValue: 'body is required' }}
            errorLabel={errorLabel}
          />
        )}
        <Button name="save" className={`settingsButton ${classes.sendButton}`} type="submit">
          Send
        </Button>
      </Form>
    </Segment>
  )
}

MailForm.propTypes = {
  apiUrl: PropTypes.string,
}

const mapStateToProperties = (state) => {
  const apiUrl = state.config.apiUrl
  return {
    apiUrl,
  }
}

const connector = (container) => connect(mapStateToProperties)(container)
export default connector(MailForm)
