import React from 'react'
import { List, Button, ListHeader, ListDescription } from 'semantic-ui-react'
import { connect } from 'react-redux'
import { compose } from 'recompose'
import { withRouter } from 'react-router-dom'
import PropTypes from 'prop-types'

import InviteContactForm from '../invitations/invitecontact'
import RemoveCompanyMember from './removecompanymember'
import { getContactSpaceId } from '../../crypho.core/store/modules/space/selectors'
import RosterAvatar from '../../UI/widgets/rosteravatar'

class CompanyMemberItem extends React.Component {
  constructor(properties) {
    super(properties)
    this.inviteContactFormReference = React.createRef()
    this.removeContactFormReference = React.createRef()
  }

  render() {
    const { vCard, isAdmin, contactSpaceId, history, userId, owner } = this.props
    if (!vCard) return null
    const title = `${vCard.FN} `
    const memberemail = `(${vCard.EMAIL.USERID})`

    return (
      <List.Item key="memberlist">
        <RosterAvatar noPopup userId={this.props.userId} />
        <List.Content>
          <ListHeader>{title}</ListHeader>
          <ListDescription>{memberemail}</ListDescription>
        </List.Content>
        <InviteContactForm email={vCard.EMAIL.USERID} ref={this.inviteContactFormReference} />
        <List.Content floated="right">
          {contactSpaceId ? (
            <Button
              icon="comment outline"
              title="View the conversation"
              onClick={() => history.push(`/contacts/${contactSpaceId}`)}
            />
          ) : (
            <Button icon="add" title="Add this person to your contacts" onClick={this.openContactForm} />
          )}
          {isAdmin && userId !== owner
            ? [
                <RemoveCompanyMember
                  key="removeCompanyMemberForm"
                  userId={userId}
                  vCard={vCard}
                  ref={this.removeContactFormReference}
                />,
                <Button
                  color="red"
                  circular
                  icon="remove"
                  title="Remove member"
                  key="removeCompanyMemberButton"
                  onClick={() => this.removeContactFormReference.current.open()}
                />,
              ]
            : null}
        </List.Content>
      </List.Item>
    )
  }

  openContactForm = () => this.inviteContactFormReference.current.open()
}
CompanyMemberItem.propTypes = {
  apiUrl: PropTypes.string,
  contactSpaceId: PropTypes.string,
  history: PropTypes.shape(),
  isAdmin: PropTypes.bool,
  owner: PropTypes.string,
  userId: PropTypes.string,
  vCard: PropTypes.shape(),
}

const mapStateToProperties = (state, ownProperties) => ({
  apiUrl: state.config.apiUrl,
  vCard: state.vcards.byId[ownProperties.userId],
  owner: state.account.owner,
  isAdmin: state.account.admins.includes(state.identity.id),
  contactSpaceId: getContactSpaceId(state, { contactId: ownProperties.userId }),
})

const mapDispatchToProperties = {}

const connector = (container) => compose(withRouter, connect(mapStateToProperties, mapDispatchToProperties))(container)

export default connector(CompanyMemberItem)
