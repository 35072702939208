import React from 'react'
import { Header, Item, List, Divider } from 'semantic-ui-react'
import changelog from '../../changelog'
import Topbar from '../../UI/Topbar'

function Changelog() {
  return (
    <div className="authenticatedScene">
      <Topbar />
      <div className="accountOptions">
        <Header as="h3">Changelog</Header>

        <React.Fragment>
          <Item.Group className="changelogList">
            {changelog.map((item) => (
              <Item key={item.version}>
                <Item.Content>
                  <div className="changelogHeader">
                    <h3> Crypho v{item.version}</h3>
                    <span className="description">{item.date}</span>
                  </div>
                  <Item.Description>
                    <List>
                      {item.changes.map((change, i) => (
                        <List.Item key={i}>{change}</List.Item>
                      ))}
                    </List>

                    <Divider />
                  </Item.Description>
                </Item.Content>
              </Item>
            ))}
          </Item.Group>
        </React.Fragment>
      </div>
    </div>
  )
}

export default Changelog
