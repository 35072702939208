import { createStore, applyMiddleware } from 'redux'
import reduxThunkMiddleware from 'redux-thunk'
import { composeWithDevTools } from 'redux-devtools-extension/logOnlyInProduction'
import createReducer from './create-reducer'

const enhancer = composeWithDevTools(applyMiddleware(reduxThunkMiddleware, ...[]))

// const enhancer = applyMiddleware(reduxThunkMiddleware, ...[])

export default function configureStore(initialState) {
  const store = createStore(createReducer(), initialState, enhancer)
  return store
}
