import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Route, Redirect } from 'react-router-dom'
import { useSelector } from 'react-redux'

function selectXmppStatus(state) {
  if (!sessionStorage.getItem('authSession')) {
    return 'anonymous'
  }

  switch (state.xmpp.status) {
    case 'offline':
      return 'anonymous'
    case 'online':
      return 'authenticated'
    default:
      return 'pending'
  }
}

function AuthenticatedRoute({ component: Component, children, ...rest }) {
  // We store the last known state, so we do not suddenly render to `null` when
  // XMPP is reconnecting.
  const [lastStatus, setLastStatus] = useState('pending')
  let xmppStatus = useSelector(selectXmppStatus)
  if (xmppStatus === 'pending') {
    xmppStatus = lastStatus
  }
  if (xmppStatus !== 'pending' && xmppStatus !== lastStatus) {
    setLastStatus(xmppStatus)
  }
  return React.createElement(Route, {
    ...rest,
    render: (routeProps) => {
      if (xmppStatus === 'anonymous') {
        return <Redirect to={{ pathname: '/login', state: { from: routeProps.location } }} />
      } else if (xmppStatus === 'authenticated') {
        return <Component {...routeProps}>{children}</Component>
      } else {
        return null
      }
    },
  })
}

AuthenticatedRoute.propTypes = {
  component: PropTypes.oneOfType([PropTypes.func, PropTypes.elementType]).isRequired,
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.arrayOf(PropTypes.element)]),
}

export default AuthenticatedRoute
