import React, { Component } from 'react'
import { List, Button } from 'semantic-ui-react'
import { NotificationManager } from 'react-notifications'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import { crypho } from '../../crypho.core/xmpp'
import { alphaSortedCompanyInvitations } from '../../crypho.core/store/modules/account/selectors'

class CompanyInvitationItem extends Component {
  removeInvitation = async () => {
    const { email } = this.props
    try {
      await crypho.removeAccountInvitation(email)
      NotificationManager.success(`${email} has been removed`, '')
    } catch (error) {
      NotificationManager.error('There was an error removing the user', '')
    }
  }

  render() {
    const { email } = this.props
    return (
      <List.Item key="memberlist">
        <List.Content className="unknownUser" />
        <List.Content className="newUser description">{email}</List.Content>
        <List.Content floated="right">
          <Button
            color="red"
            circular
            icon="remove"
            title="Remove member"
            key="removePendingMemberButton"
            onClick={() => this.removeInvitation()}
          />
        </List.Content>
      </List.Item>
    )
  }
}

CompanyInvitationItem.propTypes = {
  email: PropTypes.string,
}

class CompanyInvitationList extends Component {
  render() {
    const { emails } = this.props
    return (
      <List divided verticalAlign="middle">
        {emails.length > 0 ? (
          <List.Item className="newToCrypho">
            <List.Header as="h5">Invited company members</List.Header>
            <span className="description top10">
              (they will join the company account if they accept the invitation)
            </span>
          </List.Item>
        ) : null}
        {emails.map((email) => (
          <CompanyInvitationItem key={email} email={email} />
        ))}
      </List>
    )
  }
}

CompanyInvitationList.propTypes = {
  emails: PropTypes.array,
}

const mapStateToProperties = (state) => {
  return {
    emails: alphaSortedCompanyInvitations(state),
  }
}

const connector = (container) => connect(mapStateToProperties)(container)

export default connector(CompanyInvitationList)
