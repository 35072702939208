const SET_VOIP_MESSAGE = 'SET_VOIP_MESSAGE'

const INITIAL_STATE = {
  action: null,
  caller: null,
  spaceId: null,
  keyId: null,
  media: 'audio',
  title: null,
}

export default function voip(state = INITIAL_STATE, action) {
  switch (action.type) {
    case SET_VOIP_MESSAGE: {
      return {
        ...state,
        ...action.payload,
      }
    }
    default:
      return state
  }
}

/**
 * Create a redux action to persist a ring-related push message
 *
 * @param {{action: 'ring'|'ring-cancel', caller: string, keyId?: string, spaceId: string, media?: string, title?: string}} param0
 */
export const setVoipMessage = ({ action, caller, keyId, spaceId, media, title }) => ({
  type: SET_VOIP_MESSAGE,
  payload: { action, caller, keyId, spaceId, media, title },
})
