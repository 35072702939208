import React from 'react'
import { addValidationRule } from 'formsy-react'
import { Label } from 'semantic-ui-react'
import zxcvbn from 'zxcvbn'
import { validationRules } from 'formsy-react'
import { parsePhoneNumberFromString } from 'libphonenumber-js'

import { PhoneInput as OriginalPhoneInput } from './phoneinput'
import MultiSelect from './multiselect'

// Add validators
export const MIN_PASSWORD_ENTROPY = 4

addValidationRule('isMobileNumber', function (values, value) {
  if (!value) return true
  const phoneNr = parsePhoneNumberFromString(value)
  if (!phoneNr) {
    return false
  }
  if (!phoneNr.isValid()) {
    return false
  }
  const type = phoneNr.getType()
  if (type !== 'MOBILE' && type !== undefined) {
    return false
  }
  return true
})

addValidationRule('isStrongPassphrase', function (values, value) {
  const strength = zxcvbn(value)
  const minPasswordEntropy = window.minPasswordEntropy ? window.minPasswordEntropy : MIN_PASSWORD_ENTROPY
  const strongPerc = (Math.min(strength.score, minPasswordEntropy) * 100) / minPasswordEntropy
  if (strongPerc < 99) return strength.feedback.suggestions.join(' ')
  return true
})

addValidationRule('isEmailOrMobileList', function (values, value) {
  if (!value) return false
  let words = value.split(/[\s,;]+/)
  if (words.find((word) => !validationRules.isEmail(values, word) && !validationRules.isMobileNumber(values, word)))
    return false
  return true
})

addValidationRule('isFingerprint', function (values, value) {
  if (!value) return false
  let words = value.trim().split(/[\s,;]+/)
  return words.length === 6
})

addValidationRule('isTwittable', (values, value) => {
  if (!value) return true
  return value.length < 140
})

addValidationRule('isNonEmptyString', function (values, value) {
  value = value.trim()
  return (value && true) || false
})

const errorLabel = <Label className="errorLabel" />

export const PhoneInput = (properties) => {
  const flagsPath = window._FLAGS_PATH ? `${window._FLAGS_PATH}/` : `${process.env.PUBLIC_URL}/flags/`
  return <OriginalPhoneInput {...properties} flagsPath={flagsPath} />
}

export { MultiSelect, errorLabel }
