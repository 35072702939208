import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import { GoogleReCaptchaContext } from './google-recaptcha-context'
import { cleanGoogleRecaptcha, injectGoogleRecaptchaScript } from './utils'

/**
 * GoogleRecaptchaProvider
 * @param {
 *  disabled: boolean;
 *  reCaptchaKey: string;
 *  language?: string;
 *  useRecaptchaNet?: boolean;
 *  useEnterprise?: boolean;
 *  readyCallBack?: () => void;
 *  scriptProps?: {
 *    nonce?: string;
 *    defer?: boolean;
 *    async?: boolean;
 *    appendTo?: 'head' | 'body';
 *    id?: string;
 *    onLoadCallbackName?: string;
 *  };
 *  container?: {
 *    element?: string | HTMLElement;
 *    parameters: {
 *      badge?: 'inline' | 'bottomleft' | 'bottomright';
 *      theme?: 'dark' | 'light';
 *      tabindex?: number;
 *      callback?: () => void;
 *      expiredCallback?: () => void;
 *      errorCallback?: () => void;
 *    }
 *  };
 *  children: ReactNode;
 * } props
 *
 * @returns ReactNode
 */

export const GoogleRecaptchaProvider = ({
  disabled,
  reCaptchaKey,
  useEnterprise = false,
  readyCallBack,
  scriptProps,
  language,
  container,
  children,
}) => {
  const [greCaptchaInstance, setGreCaptchaInstance] = useState(null)
  const clientId = useRef(reCaptchaKey)

  useEffect(() => {
    if (disabled) {
      if (readyCallBack) readyCallBack()
      return
    }
    if (!reCaptchaKey) {
      console.warn('recaptcha site key not provided')
      return
    }

    const scriptId = scriptProps?.id || 'google-recaptcha-v3'
    const onLoadCallbackName = scriptProps?.onLoadCallbackName || 'onRecaptchaLoadCallback'

    window[onLoadCallbackName] = () => {
      const grecaptcha = useEnterprise ? window.grecaptcha.enterprise : window.grecaptcha

      const params = {
        badge: 'inline',
        size: 'invisible',
        sitekey: reCaptchaKey,
        ...(container?.parameters || {}),
      }
      try {
        clientId.current = grecaptcha.render(container?.element, params)
      } catch (error) {
        console.error('duplicate recaptcha render', error, clientId.current)
      }
    }

    const onLoad = () => {
      if (!window || !window.grecaptcha) {
        console.warn('Recaptcha script is not available')
        return
      }

      const grecaptcha = useEnterprise ? window.grecaptcha.enterprise : window.grecaptcha

      grecaptcha.ready(() => {
        setGreCaptchaInstance(grecaptcha)
        if (readyCallBack) readyCallBack()
      })
    }

    injectGoogleRecaptchaScript({
      render: container?.element ? 'explicit' : reCaptchaKey,
      onLoadCallbackName,
      useEnterprise,
      scriptProps,
      language,
      onLoad,
    })

    return () => {
      cleanGoogleRecaptcha(scriptId, container?.element)
    }
  }, [disabled, useEnterprise, language, reCaptchaKey, container?.element])

  const executeRecaptchaCallBack = useCallback(
    (action) => {
      if (!greCaptchaInstance?.execute) {
        throw new Error('Google Recaptcha is not loaded')
      }
      if (container?.element) {
        const token = greCaptchaInstance.getResponse(clientId.current)
        greCaptchaInstance.reset(clientId.current)
        return token
      }
      return greCaptchaInstance.execute(clientId.current, { action })
    },
    [greCaptchaInstance, clientId],
  )

  const goolgleRecaptchaContextValue = useMemo(
    () => ({
      executeRecaptcha: greCaptchaInstance ? executeRecaptchaCallBack : disabled ? () => 'captchaDisabled' : undefined,
      container: container?.element,
    }),
    [disabled, executeRecaptchaCallBack, greCaptchaInstance, container?.element],
  )

  return (
    <GoogleReCaptchaContext.Provider value={goolgleRecaptchaContextValue}>{children}</GoogleReCaptchaContext.Provider>
  )
}

GoogleRecaptchaProvider.propTypes = {
  disabled: PropTypes.bool,
  reCaptchaKey: PropTypes.string,
  useEnterprise: PropTypes.bool,
  readyCallBack: PropTypes.func,
  scriptProps: PropTypes.object,
  language: PropTypes.string,
  container: PropTypes.object,
  children: PropTypes.node,
}
