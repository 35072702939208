import React, { useState, useEffect, useRef } from 'react'
import { NotificationManager } from 'react-notifications'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { addPlan, getPlans } from './api'
import PlansTable from './PlansTable'
import AddPlan from './AddPlan'

const handleError = (msg) => {
  NotificationManager.error(msg, '')
}

const Plans = ({ apiUrl }) => {
  const [plans, setPlans] = useState([])
  const [loading, setLoading] = useState(true)
  const [submitting, setSubmitting] = useState(false)
  const addPlanRef = useRef(null)

  const loadPlans = async () => {
    setLoading(true)
    try {
      const plans = await getPlans(apiUrl)
      setPlans(plans)
    } catch (error) {
      handleError('Failed to get plans')
    }
    setLoading(false)
  }

  useEffect(() => {
    loadPlans()
  }, [])

  const addPlanHandler = async (plan) => {
    setSubmitting(true)
    try {
      const res = await addPlan(apiUrl, plan)
      setPlans((plans) => [res, ...plans])
      addPlanRef.current.reset()
    } catch (error) {
      NotificationManager.error(error?.data?.message ?? 'Error occurred', '')
    }
    setSubmitting(false)
  }

  return (
    <div id="plans-container" className="adminScene">
      <AddPlan ref={addPlanRef} onSave={addPlanHandler} submitting={submitting} />
      <PlansTable plans={plans} loading={loading} />
    </div>
  )
}

Plans.propTypes = {
  apiUrl: PropTypes.string,
}

const mapStateToProperties = (state) => {
  const apiUrl = state.config.apiUrl
  return {
    apiUrl,
  }
}

const connector = (container) => connect(mapStateToProperties)(container)

export default connector(Plans)
