import React, { Component, useState } from 'react'
import PropTypes from 'prop-types'
import { Button, Message, Transition, Header } from 'semantic-ui-react'
import { Form } from 'formsy-semantic-ui-react'
import { withRouter, Link } from 'react-router-dom'
import Burry from 'burry'
import { connect } from 'react-redux'
import { compose } from 'recompose'

import { CryphoAPI } from '../../crypho.core/api'
import { errorLabel } from '../../UI/forms'
import { GoogleReCaptchaContext, GoogleRecaptchaProvider } from '../../components/google-recaptcha'

class RequestPassphraseResetScene extends Component {
  constructor(properties) {
    super(properties)
    this.state = {
      email: '',
      tfaToken: '',
      emailSent: false,
      statusMessage: '',
      statusMessageType: null,
    }
    this.authTokenStore = new Burry.Store('auth.token', 1209600)
    const email = this.authTokenStore.keys()
    if (email.length > 0 && this.authTokenStore.get(email[0])) {
      this.state.email = email[0]
    }
  }

  setStatusMessage = (statusMessage, statusMessageType = 'info') => {
    this.setState({ statusMessage: '', statusMessageType })
    this.setState({ statusMessage, statusMessageType })
  }

  sendSecurityToken = async (push) => {
    const { apiUrl } = this.props
    const { email } = this.state
    const api = new CryphoAPI(apiUrl)
    this.setState({ tfaToken: '' })

    try {
      const recaptcha_token = await this.context.executeRecaptcha('LOGIN')
      if (!recaptcha_token) {
        this.setStatusMessage(`Please confirm 'I'm not a robot'`, 'warning')
        return
      }
      await api.post('/auth/sendtoken', { email, push, recaptcha_token, platform: 'web' })

      this.setStatusMessage('We have sent you a security code. It is valid for 60 seconds.')
    } catch (error) {
      this.setStatusMessage('We could not send you the security code.', 'warning')
    }
  }

  requestReset = async () => {
    const { apiUrl } = this.props
    const { email, tfaToken } = this.state
    try {
      const response = await fetch(`${apiUrl}/auth/forgot-password`, {
        method: 'POST',
        credentials: 'include',
        body: JSON.stringify({
          email,
          token: tfaToken,
        }),
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      })
      if (!response.ok) throw response
      this.setState({ emailSent: true })
    } catch (error) {
      this.setStatusMessage('The security code you have entered is incorrect or has expired', 'error')
    }
  }

  render() {
    const { statusMessage, statusMessageType, emailSent } = this.state
    if (emailSent)
      return (
        <div className="darkContainer">
          <div className="cardContainer">
            <div className="cryphoLogotype" />
            <div className="card">
              <div className="formHeader">
                <div className="cardLogo" />
                <Header as="h1">Password reset requested</Header>
              </div>
              <Form>
                <Message info>
                  We have sent you an email to verify your account. Open your email and click the link we have sent you
                  to complete your passphrase reset.
                </Message>
              </Form>
            </div>

            <a className="whiteTextLink" target="blank" href="https://www.crypho.com/contactsupport/">
              Problems? Click here for help and support.
            </a>
          </div>
        </div>
      )
    return (
      <div className="darkContainer">
        <div className="cardContainer">
          <div className="cryphoLogotype" />
          <div className="card">
            <div className="formHeader">
              <div className="cardLogo" />
              <Header as="h1">Reset passphrase</Header>
            </div>
            <Form onValidSubmit={this.requestReset}>
              <Transition animation="scale" duration={statusMessage ? 1000 : 0} visible={!!statusMessage}>
                <Message className={statusMessageType} content={statusMessage || ''} />
              </Transition>
              <Message negative>
                <p>
                  Since no one has access to your cryptographic keys, you will lose your chat history. You will have to
                  reconnect with your contacts. Groups you own will be deleted.
                </p>
                <Message.Header>
                  If you have lost <b>both your passphrase and your phone</b>, you will have to create new account.
                </Message.Header>
              </Message>

              <Form.Input
                name="email"
                type="email"
                placeholder="Email"
                required
                validations="isEmail"
                value={this.state.email}
                onChange={(event_) => this.setState({ email: event_.target.value })}
                errorLabel={errorLabel}
                validationErrors={{
                  isEmail: 'This is not a valid email',
                  isDefaultRequiredValue: 'Email is required',
                }}
              />
              <div id="g-recaptcha"></div>
              <p>
                Enter 6 digit security code. Receive the code on your mobile phone, either through the Crypho app or via
                text message.
              </p>

              <Form.Input
                width={6}
                name="token"
                className="inlineInput"
                type="number"
                placeholder="Security code"
                value={this.state.tfaToken}
                required
                errorLabel={errorLabel}
                validationErrors={{
                  isDefaultRequiredValue: 'The security code is required',
                }}
                onChange={(event_) => this.setState({ tfaToken: event_.target.value })}
              />
              <Button
                type="button"
                className="securityTokenButton"
                formNoValidate="formNoValidate"
                onClick={() => this.sendSecurityToken(false)}
                id="send-sms"
                disabled={!this.props.captchaReady}
              >
                Send SMS
              </Button>
              <Button
                type="button"
                className="securityTokenButton"
                formNoValidate="formNoValidate"
                onClick={() => this.sendSecurityToken(true)}
                id="send-push"
                disabled={!this.props.captchaReady}
              >
                Send to app
              </Button>

              <Button name="resetPassphrase" className="primaryButton" fluid type="submit">
                Request passphrase reset
              </Button>
              <Link className="blueTextLink top20" name="login" to="/login">
                Go back to Login page
              </Link>
            </Form>
          </div>

          <a className="whiteTextLink" target="blank" href="https://www.crypho.com/contactsupport/">
            Problems? Click here for help and support.
          </a>
        </div>
      </div>
    )
  }
}

RequestPassphraseResetScene.propTypes = {
  apiUrl: PropTypes.string.isRequired,
  captchaReady: PropTypes.bool,
}
const mapStateToProperties = (state) => ({
  apiUrl: state.config.apiUrl,
  recaptchaWebSiteKey: state.config.recaptchaWebSiteKey,
  recaptchaEnabled: state.config.recaptchaEnabled,
})

RequestPassphraseResetScene.contextType = GoogleReCaptchaContext

const mapDispatchToProperties = {}

const connector = (container) => compose(withRouter, connect(mapStateToProperties, mapDispatchToProperties))(container)

const requestPassphraseResetSceneWithRecaptchaProvider = (props) => {
  const { recaptchaEnabled, recaptchaWebSiteKey } = props
  const [ready, setReady] = useState(false)
  const readyHandler = () => {
    setReady(true)
  }
  return (
    <GoogleRecaptchaProvider
      reCaptchaKey={recaptchaWebSiteKey}
      useEnterprise={true}
      disabled={!recaptchaEnabled}
      readyCallBack={readyHandler}
      container={{ element: 'g-recaptcha', parameters: { size: 'normal', action: 'LOGIN' } }}
    >
      <RequestPassphraseResetScene captchaReady={ready} {...props} />
    </GoogleRecaptchaProvider>
  )
}

requestPassphraseResetSceneWithRecaptchaProvider.propTypes = {
  recaptchaWebSiteKey: PropTypes.string,
  recaptchaEnabled: PropTypes.bool,
}

export default connector(requestPassphraseResetSceneWithRecaptchaProvider)
