import React, { useEffect, useState } from 'react'
import { Grid, Image, Card, Button } from 'semantic-ui-react'
import { NotificationManager } from 'react-notifications'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import AsyncSelect from 'react-select/async'

import { getUser, searchUsers, getAdmins, addAdmin, deleteAdmin } from './api'
import SystemAdminList from './SystemAdminList'

const handleError = (msg) => {
  NotificationManager.error(msg, '')
}

const SystemAdmins = ({ apiUrl }) => {
  const [admins, setAdmins] = useState([])
  const [loading, setLoading] = useState(true)
  const [selectedUser, setSelectedUser] = useState(null)

  useEffect(() => {
    ;(async () => {
      setLoading(true)
      try {
        const admins = await getAdmins(apiUrl)
        setAdmins(admins)
      } catch (error) {
        handleError('Failed to get system admins')
      }
      setLoading(false)
    })()
  }, [])

  const selectUserHandler = async (user) => {
    const username = user.value
    try {
      const user = await getUser(apiUrl, username)
      setSelectedUser(user)
    } catch (error) {
      handleError('Failed to select a user')
    }
  }

  const unselectUserHandler = () => {
    setSelectedUser(null)
  }

  const addUserAsAdminHandler = async () => {
    setLoading(true)
    try {
      const newAdmin = await addAdmin(apiUrl, selectedUser)
      setAdmins((admins) => [...admins, newAdmin])
      setSelectedUser(null)
    } catch (error) {
      if (error.message === 'Conflict') {
        NotificationManager.error('Failed, selected system admin already exists', '')
      } else {
        NotificationManager.error('Failed to add system admin', '')
      }
    }
    setLoading(false)
  }

  const deleteAdminHandler = async (admin) => {
    setLoading(true)
    try {
      await deleteAdmin(apiUrl, admin.id)
      const admins = await getAdmins(apiUrl)
      setAdmins(admins)
    } catch (error) {
      NotificationManager.error('Failed to delete system admin', '')
    }
    setLoading(false)
  }

  return (
    <div id="system-admin-container" className="adminScene">
      <Grid stackable divided="vertically">
        <Grid.Row columns={2}>
          <Grid.Column>
            <h2>Select user to add as a new Admin</h2>
            <AsyncSelect
              className="admin-select"
              placeholder="Search..."
              icon="search"
              cacheOptions
              onChange={selectUserHandler}
              loadOptions={(searchTerm) => searchUsers(apiUrl, searchTerm)}
              theme={(theme) => ({
                ...theme,
                colors: {
                  ...theme.colors,
                  primary: '#ccc',
                },
              })}
            />
          </Grid.Column>
          <Grid.Column>
            {selectedUser && (
              <Card>
                <Card.Content>
                  <div className="buttons-container">
                    <div className="ui two buttons vertical">
                      <Button
                        color="red"
                        circular
                        icon="remove"
                        title="Discard"
                        key="removeSystemAdminButton"
                        onClick={unselectUserHandler}
                      />
                      <Button
                        color="green"
                        circular
                        icon="check"
                        title="Add"
                        key="addSystemAdminButton"
                        onClick={addUserAsAdminHandler}
                      />
                    </div>
                  </div>
                  <Card.Header>
                    {selectedUser.fullName}
                    <Image circular size="mini" src={`${apiUrl}/avatar/${selectedUser.id}`} />
                  </Card.Header>
                  <Card.Meta>{selectedUser.email}</Card.Meta>
                </Card.Content>
              </Card>
            )}
          </Grid.Column>
        </Grid.Row>
      </Grid>
      <SystemAdminList admins={admins} loading={loading} onDelete={deleteAdminHandler} />
    </div>
  )
}

SystemAdmins.propTypes = {
  apiUrl: PropTypes.string,
}

const mapStateToProperties = (state) => {
  const apiUrl = state.config.apiUrl
  return {
    apiUrl,
  }
}

const connector = (container) => connect(mapStateToProperties)(container)

export default connector(SystemAdmins)
