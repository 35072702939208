import * as Sentry from '@sentry/core'
import { Severity as SentrySeverity } from '@sentry/types'
import { xmppClient, cryphoPlugin, pubsubPlugin, syncPresence } from './client'
import store from '../store'
import { fetchUserVCards, fetchUserVCard, updateVCards } from '../store/modules/vcard'
import { showSpinner, hideSpinner } from '../store/modules/spinner'
import {
  fetchSpacesFromServer,
  getUnreadMessageCount,
  fetchSpaceFromServer,
  fetchSpaceMetadata,
  updateSpace,
  deleteSpace,
  updateSpaceOrder,
} from '../store/modules/space'
import { fetchStreams } from 'crypho.core/store/modules/pubsub'
import { crypho } from 'crypho.core/xmpp'
import { fetchInvitations } from '../store/modules/invitations'
import { fetchAccount } from '../store/modules/account'
import { userOnline, userOffline, resetOnlineState } from '../store/modules/online'
import { addPubsubItem, deletePubsubItem, clearPubSub } from '../store/modules/pubsub'
import { fetchRosterFromServer, fetchUserVerificationsFromServer } from '../store/modules/roster'
import { setTFASecret, setPhone, setIdentityUserId, setIdentityUserJID } from '../store/modules/identity'
import { setLoadedStateStatus } from '../store/modules/app'
import { setEjabberdVersion } from '../store/modules/config'
import { fetchUserSettings } from '../store/modules/settings'
import { setServerTimeOffset } from '../utils'

export function setupEvents() {
  const state = store.getState()
  const { xmppPubsub } = state.config

  xmppClient.on('status', updateSpinner)
  xmppClient.on('online', () => {
    syncPresence()
    try {
      updateClientState()
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('Error updating client state', error)
    }
  })

  pubsubPlugin.on(`item-published:${xmppPubsub}`, onPubSubItemPublished)
  pubsubPlugin.on(`item-deleted:${xmppPubsub}`, onPubSubItemDeleted)

  cryphoPlugin.on('invitations-updated', () => {
    store.dispatch(fetchInvitations())
  })

  cryphoPlugin.on('spaces-updated', async (spaceIds) => {
    const oldContacts = store.getState().roster.allIds
    const updated = await store.dispatch(fetchRosterFromServer())
    spaceIds.forEach((spaceId) => {
      store.dispatch(fetchSpaceFromServer(spaceId))
    })
    if (updated) {
      store.dispatch(fetchUserVCards())
      // Send our presence information to any new contacts. This is necessary
      // because the backend bypasses the XMPP server when updating the server,
      // so no presence information is send automatically for new users.
      const state = store.getState()
      const newContacts = new Set(state.roster.allIds)
      oldContacts.forEach((username) => {
        newContacts.delete(username)
      })
      newContacts.forEach((username) => {
        syncPresence(`${username}@${state.config.xmppDomain}`)
      })
    }
  })

  cryphoPlugin.on('space-deleted', (spaceId) => {
    store.dispatch(deleteSpace(spaceId))
  })

  cryphoPlugin.on('space-read', (spaceId) => {
    store.dispatch(
      updateSpace(spaceId, {
        last_seen: new Date().toISOString(),
        unread: 0,
      }),
    )
  })

  cryphoPlugin.on('space-key-request', (spaceId) => {
    cryphoPlugin.addSpaceKey(spaceId)
  })

  cryphoPlugin.on('vcards', (cards) => {
    store.dispatch(updateVCards(cards))
  })

  cryphoPlugin.on('account-updated', () => {
    store.dispatch(fetchAccount())
  })

  cryphoPlugin.on('error', (err) => {
    Sentry.captureMessage('XMPP error: ${err}', SentrySeverity.Error)
    // eslint-disable-next-line no-console
    console.error('🗣 XMPP error', err)
  })

  xmppClient.on('stanza', (stanza) => {
    if (!stanza.is('presence')) return
    const { from } = stanza.attrs
    if (
      stanza.attrs.type === 'unavailable' ||
      (stanza.getChild('show') && ['away', 'dnd'].includes(stanza.getChildText('show')))
    ) {
      store.dispatch(userOffline(from))
    } else if (!stanza.attrs.type) store.dispatch(userOnline(from))
  })

  xmppClient.reconnect.on('reconnected', () => {
    // eslint-disable-next-line no-console
    console.log('🗣 XMPP reconnected')
  })
}

export const onPubSubItemPublished = (item) => {
  store.dispatch(addPubsubItem(item))
}

export const onPubSubItemDeleted = (item) => {
  store.dispatch(deletePubsubItem(item.id, item.node))
}

/**
 * Update the spinner status in redux based on XMPP status
 *
 * @param {string} state XMPP client status
 */
function updateSpinner(state) {
  switch (state) {
    case 'offline': // We stopped the XMPP client
      store.dispatch(hideSpinner())
      break
    case 'online': // Fully online and authenticated
      store.dispatch(hideSpinner())
      break
    case 'closing':
    case 'disconnecting':
    case 'disconnect':
    case 'connecting':
    case 'connect':
    case 'opening':
    case 'open':
      store.dispatch(showSpinner())
      break
  }
}

async function updateClientState() {
  store.dispatch(clearPubSub())
  const state = store.getState()
  cryphoPlugin
    .getServerVersion()
    .then(({ version }) => store.dispatch(setEjabberdVersion(version)))
    .catch((error) => {
      // eslint-disable-next-line no-console
      console.error('Error fetching server version', error)
    })

  cryphoPlugin
    .syncTime()
    .then((offset) => {
      cryphoPlugin.emit('clock-sync-offset', offset)
      return setServerTimeOffset(offset)
    })
    // eslint-disable-next-line no-console
    .catch(console.error)

  store.dispatch(resetOnlineState())
  store.dispatch(setIdentityUserId(xmppClient.jid.local))
  store.dispatch(setIdentityUserJID(xmppClient.jid.bare().toString()))

  await store.dispatch(fetchRosterFromServer())
  await store.dispatch(fetchSpacesFromServer())

  // If we have all the vcards already, do not wait on fetching them.
  const rosterUserIds = Object.keys(state.roster.byId)
  const vcardUserIds = Object.keys(state.vcards.byId)

  if (state.navigation) {
    const { route, params } = state.navigation
    if (['space'].includes(route)) {
      const { spaceId } = params
      await store.dispatch(fetchSpaceMetadata(spaceId))
      // Fetch items that were added after we went offline
      await store.dispatch(fetchStreams(spaceId))
      store.dispatch(updateSpace(spaceId, { unread: 0, last_seen: new Date().toISOString() }))
      crypho.ping(spaceId).catch(console.error)
    }
  }

  if (!rosterUserIds.every((userId) => vcardUserIds.includes(userId))) {
    await Promise.all([
      store.dispatch(fetchUserVCards()),
      store.dispatch(fetchUserVCard(xmppClient.jid.bare().toString())),
      store.dispatch(fetchUserVerificationsFromServer()),
    ])
  } else {
    store.dispatch(fetchUserVCards())
    store.dispatch(fetchUserVCard(xmppClient.jid.bare().toString()))
    store.dispatch(fetchUserVerificationsFromServer())
  }

  await store.dispatch(fetchAccount())
  xmppClient.emit('ready')

  cryphoPlugin.getUpdates()
  store.dispatch(fetchInvitations())

  const twoFactorData = await cryphoPlugin.getTwoFactorData()
  store.dispatch(setTFASecret(twoFactorData.secret))
  store.dispatch(setPhone(twoFactorData.local, twoFactorData.country))
  store.dispatch(getUnreadMessageCount())
  store.dispatch(updateSpaceOrder())
  store.dispatch(setLoadedStateStatus(true))
  store.dispatch(fetchUserSettings())
}
