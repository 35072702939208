import createCachedSelector from 're-reselect'

const accountMemberIds = (state) => state.account.members
const vcards = (state) => state.vcards.byId
const invitations = (state) => state.account.invitations

export const alphaSortedCompanyMemberIds = createCachedSelector(
  [accountMemberIds, vcards],
  (accountMemberIds, vcards) =>
    accountMemberIds.slice().sort((idA, idB) => {
      const FNA = (vcards[idA] && vcards[idA].FN && vcards[idA].FN.toLowerCase()) || ''
      const FNB = (vcards[idB] && vcards[idB].FN && vcards[idB].FN.toLowerCase()) || ''
      return FNA > FNB ? 1 : -1
    }),
)(() => 'alphaSortedCompanyMemberIds')

export const alphaSortedCompanyInvitations = createCachedSelector([invitations], (invitations) =>
  invitations.slice().sort(),
)(() => 'alphaSortedCompanyInvitations')
