import React from 'react'
import PropTypes from 'prop-types'
import { Icon, Form, TextArea, Button } from 'semantic-ui-react'
import { NotificationManager } from 'react-notifications'
import { connect } from 'react-redux'

import InfoStreamNode from './infostreamnode'
import { COMPOSING_IDLE_TIMEOUT } from './infostreamnode'
import { getSpaceOnlineMembersUserIds } from 'crypho.core/store/modules/space/selectors'
import { crypho } from 'crypho.core/xmpp'
import { serverTime, isEmojiLine } from 'crypho.core/utils'
import store from 'crypho.core/store'
import { publishToPubsubNode } from 'crypho.core/infostream'

const NEW_POST_INTERVAL = 60 * 1000

class InfoStream extends React.Component {
  constructor(properties) {
    super(properties)
    this.streamNodeReference = React.createRef()
    this.state = {
      text: '',
    }
    this.typing = false
    this.typingTimeout = null
  }

  componentDidMount() {
    const { spaceId } = this.props
    const text = sessionStorage.getItem(`savedText-${spaceId}`)
    if (text) this.setState({ text })
  }

  componentDidUpdate(previousProperties, previousState) {
    const { spaceId } = this.props
    const previousSpaceId = previousProperties.spaceId

    if (previousSpaceId !== spaceId) {
      // Persist any text available
      const previousText = previousState.text
      if (previousText) sessionStorage.setItem(`savedText-${previousSpaceId}`, previousText)
      else sessionStorage.removeItem(`savedText-${previousSpaceId}`)
      // Restore text or set to ''
      const text = sessionStorage.getItem(`savedText-${spaceId}`) || ''
      this.setState({ text })
    }
  }

  componentWillUnmount() {
    const { text } = this.state
    const { spaceId } = this.props
    if (text) sessionStorage.setItem(`savedText-${spaceId}`, text)
    else sessionStorage.removeItem(`savedText-${spaceId}`)
  }

  _onStartTyping = () => {
    const { xmppDomain, spaceId, onlineMemberIds } = this.props
    onlineMemberIds.forEach((id) => {
      crypho.composing(`${id}@${xmppDomain}`, spaceId)
    })
  }

  _onEndTyping = () => {
    const { xmppDomain, spaceId, onlineMemberIds } = this.props
    onlineMemberIds.forEach((id) => {
      crypho.composing(`${id}@${xmppDomain}`, spaceId, 'paused')
    })
  }

  _onInputValueChange = (event_) => {
    this.setState({ text: event_.target.value })
    clearTimeout(this.typingTimeout)

    if (!this.typing) {
      this._onStartTyping()
    }

    this.typing = true
    this.typingTimeout = setTimeout(() => {
      this._onEndTyping()
      this.typing = false
    }, COMPOSING_IDLE_TIMEOUT)
  }

  _handleKeyPress = (target) => {
    if (target.charCode === 13 && target.shiftKey === false) {
      this.onPost()
      target.preventDefault()
    }
  }

  onPost = async () => {
    if (!this.props.isXmppOnline) {
      return false
    }
    const text = this.state.text.trimEnd()
    // Note that we do include leading whitespace in the infostream item. This allows
    // indenting in preformatted blocks.
    if (!text) {
      return false
    }

    this.setState({ text: '' })

    const now = serverTime()
    const { jid, spaceId, nodeId } = this.props

    // Get last item published on the node
    const state = store.getState().pubsub
    const lastItemId = state.nodeItemIds[nodeId].slice(0, 1)
    const lastItem = state.itemsById[lastItemId]

    // Update the previous item if it's from the same author and posted
    // within the NEW_POST_INTERVAL
    let data,
      updated = false
    if (
      lastItem &&
      !lastItem.type &&
      lastItem.author === jid &&
      now - new Date(lastItem.updated) < NEW_POST_INTERVAL &&
      !isEmojiLine(text) &&
      !isEmojiLine(lastItem.content)
    ) {
      data = {
        content: `${lastItem.content}\n${text}`,
        author: jid,
        created: new Date(lastItem.created),
        updated: now,
        expires: lastItem.expires || undefined,
      }
      updated = true
    } else {
      data = {
        content: text,
        author: jid,
        created: now,
        updated: now,
      }
    }

    try {
      await publishToPubsubNode(spaceId, nodeId, data, updated ? lastItemId : undefined, updated)
      // eslint-disable-next-line no-console
      crypho.ping(spaceId).catch(console.error)
      this.typing = false
      clearTimeout(this.typingTimeout)
      this._onEndTyping()
      this.streamNodeReference.current.scrollToBottom(true)
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error)
      NotificationManager.error('There was an error sending the message, please try again', '')
      return
    }
  }

  render() {
    const { spaceId, spaceType, openUploadDialog, isXmppOnline } = this.props
    return (
      <div className="infostreamContainer">
        <InfoStreamNode
          ref={this.streamNodeReference}
          nodeId={this.props.nodeId}
          spaceId={spaceId}
          spaceType={spaceType}
        />
        <Form className="infostreamFooter" onSubmit={this.onPost}>
          <Icon onClick={openUploadDialog} title="Share a file" className="attachmentIcon" size="large" />
          <TextArea
            style={{ minHeight: 60, maxHeight: 250 }}
            autoHeight
            rows={1}
            name="infostream-message"
            placeholder="Your message"
            type="text"
            value={this.state.text}
            onChange={this._onInputValueChange}
            onKeyPress={this._handleKeyPress}
            onPaste={this.onPaste}
          />
          <Button
            color="blue"
            circular
            icon="send"
            name="post-infostream-message"
            className="sendIcon"
            title="Send"
            onClick={this.onPost}
            disabled={!isXmppOnline}
          />
        </Form>
      </div>
    )
  }
}

InfoStream.propTypes = {
  jid: PropTypes.string,
  nodeId: PropTypes.string,
  onlineMemberIds: PropTypes.array,
  openUploadDialog: PropTypes.func.isRequired,
  spaceId: PropTypes.string.isRequired,
  spaceType: PropTypes.string.isRequired,
  xmppDomain: PropTypes.string,
  isXmppOnline: PropTypes.bool.isRequired,
}

const mapStateToProperties = (state, ownProperties) => {
  const { spaceId } = ownProperties
  const space = state.spaces.byId[spaceId]
  if (!space) return {}
  return {
    xmppDomain: state.config.xmppDomain,
    jid: state.identity.jid,
    onlineMemberIds: getSpaceOnlineMembersUserIds(state, {
      spaceId,
    }),
    spaceId,
    spaceType: space.type,
    isXmppOnline: state.xmpp.status === 'online',
  }
}

const mapDispatchToProperties = {}

const connector = (container) => connect(mapStateToProperties, mapDispatchToProperties)(container)

export default connector(InfoStream)
