import React, { useRef } from 'react'
import { Button, Table } from 'semantic-ui-react'
import PropTypes from 'prop-types'
import moment from 'moment'
import RemoveConfirm from '../RemoveConfirm'
import { UserLink } from '../AdminUsers'

const SpaceTableRow = ({ space, fullNames, onMembersLinkClick, onRemove }) => {
  const removeConfirmRef = useRef()
  const cancelRemoveSpaceHandler = () => {
    removeConfirmRef.current.close()
  }
  const removeSpaceHandler = () => {
    removeConfirmRef.current.close()
    onRemove(space.id)
  }
  return (
    <Table.Row key={`space-${space.id}`}>
      <Table.Cell>{space.id}</Table.Cell>
      <Table.Cell>{space.title}</Table.Cell>
      <Table.Cell>
        {space.members.map((member) => [
          <UserLink
            key={`members-${member}`}
            username={member}
            fullname={fullNames[member]}
            callback={onMembersLinkClick}
            isGroupOwner={!!(space.roles[member] && space.roles[member].includes('op'))}
          />,
          <span key={`span-${member}`}>, </span>,
        ])}
      </Table.Cell>
      <Table.Cell>{moment(space.lastActivity).format('YYYY-MM-DD HH:MM')}</Table.Cell>
      <Table.Cell>
        <RemoveConfirm ref={removeConfirmRef} onDelete={removeSpaceHandler} onCancel={cancelRemoveSpaceHandler} />
        <Button
          color="red"
          circular
          icon="remove"
          title="Remove space"
          onClick={() => removeConfirmRef.current.open(space.id, 'space', 'spaces')}
        />
      </Table.Cell>
    </Table.Row>
  )
}

SpaceTableRow.propTypes = {
  space: PropTypes.object,
  fullNames: PropTypes.object,
  onMembersLinkClick: PropTypes.func,
  onRemove: PropTypes.func,
}

export default SpaceTableRow
