import React, { Component } from 'react'
import { List } from 'semantic-ui-react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import CompanyMemberItem from './companymemberitem'
import { alphaSortedCompanyMemberIds } from '../../crypho.core/store/modules/account/selectors'
class CompanyMemberList extends Component {
  render() {
    const { memberIds } = this.props
    return (
      <List divided verticalAlign="middle">
        {memberIds.length === 0 ? (
          <div className="pageInfo">
            <span className="empty">There are no members in your company.</span>
          </div>
        ) : null}

        {memberIds.map((memberId) => (
          <CompanyMemberItem key={memberId} userId={memberId} />
        ))}
      </List>
    )
  }
}

CompanyMemberList.propTypes = {
  memberIds: PropTypes.array,
  vcards: PropTypes.shape(),
  membersSpaces: PropTypes.shape(),
}

const mapStateToProperties = (state) => {
  const userId = state.identity.id

  return {
    memberIds: alphaSortedCompanyMemberIds(state).filter((memberId) => memberId !== userId),
  }
}

const connector = (container) => connect(mapStateToProperties, {})(container)

export default connector(CompanyMemberList)
