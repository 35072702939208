import React, { Component } from 'react'
import { connect } from 'react-redux'
import { compose } from 'recompose'
import PropTypes from 'prop-types'
import Select from 'react-select'
import { withRouter } from 'react-router-dom'

import {
  getContactSpacesFullnameValueLabelPairs,
  getGroupSpacesFullnameValueLabelPairs,
} from '../../crypho.core/store/modules/space/selectors'

class SpaceSearch extends Component {
  render() {
    const { spaceValueLabels, history, baseUrl, placeholder } = this.props

    return (
      <Select
        name="c-search-space"
        placeholder={placeholder}
        options={spaceValueLabels}
        icon="search"
        onChange={(item) => {
          history.push(`${baseUrl}/${item.value}`)
        }}
        theme={(theme) => ({
          ...theme,
          colors: {
            ...theme.colors,
            primary: '#ccc',
          },
        })}
        className="c-spaceSearch"
      />
    )
  }
}

SpaceSearch.propTypes = {
  baseUrl: PropTypes.string,
  history: PropTypes.shape(),
  placeholder: PropTypes.string,
  spaceValueLabels: PropTypes.array,
  type: PropTypes.string,
}

const mapStateToProperties = (state, ownProperties) => {
  return ownProperties.type === 'contacts'
    ? {
        spaceValueLabels: getContactSpacesFullnameValueLabelPairs(state),
        placeholder: 'Search contacts',
        baseUrl: '/contacts',
      }
    : {
        spaceValueLabels: getGroupSpacesFullnameValueLabelPairs(state),
        placeholder: 'Search groups',
        baseUrl: '/groups',
      }
}

const mapDispatchToProperties = {}

const connector = (container) => compose(withRouter, connect(mapStateToProperties, mapDispatchToProperties))(container)

export default connector(SpaceSearch)
