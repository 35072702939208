import React from 'react'
import uuid from 'uuid/v4'
import { NotificationManager } from 'react-notifications'
import PropTypes from 'prop-types'
import filesize from 'filesize'
import LRU from 'lru-cache'
import 'blueimp-canvas-to-blob' // Polyfill canvas.toBlob for MS Edge
import store from './crypho.core/store'
import { startUpload, updateUploadProgress, uploadComplete } from './crypho.core/store/modules/upload'
import { getSpaceCurrentKey } from './crypho.core/store/modules/space/selectors'
import { createPubsubItem, publishToPubsubNode } from './crypho.core/infostream'
import {
  markItemAsDownloaded,
  deleteFile as coreDeleteFile,
  setFileName as coreSetFileName,
  setFileTitle as coreSetFileTitle,
} from './crypho.core/vault'
import { getKeyById } from './crypho.core/space'
import { CryphoAPI, ApiError } from './crypho.core/api'
import { downloadAsBlob, downloadFile, canDownload } from './api'
import { uploadFile } from './api/upload'
import CryptoHandler from './crypto'

const ObjectURLCache = new LRU({ max: 500, dispose: URL.revokeObjectURL })

export const PREVIEW_SIZE = 200

export const InfostreamFileContentPropType = PropTypes.exact({
  node_id: PropTypes.string.isRequired,
  vault_item: PropTypes.string.isRequired,
})

export const VaultItemPropType = PropTypes.shape({
  fkid: PropTypes.string.isRequired,
  title: PropTypes.string,
  name: PropTypes.string.isRequired,
  size: PropTypes.number.isRequired,
  type: PropTypes.string.isRequired,
  params: PropTypes.exact({
    ocb2: PropTypes.bool.isRequired,
    iv: PropTypes.string.isRequired,
    adata: PropTypes.string.isRequired,
  }),
  thumbnail: PropTypes.exact({
    uid: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    size: PropTypes.number.isRequired,
    type: PropTypes.string.isRequired,
    params: PropTypes.exact({
      ocb2: PropTypes.bool.isRequired,
      iv: PropTypes.string.isRequired,
      adata: PropTypes.string.isRequired,
    }),
  }),
})

/**
 * Convert an image to JPEG
 *
 * @param {File} file File convert to JPEG
 * @returns {Promise<File>} New file with JPEG version of the image
 */
export function convertImageToJPEG(file) {
  return new Promise((resolve, reject) => {
    if (!file.type.startsWith('image/')) {
      reject(new Error('Only images can be converted to JPEG'))
      return
    }

    if (file.type.startsWith('image/jpeg')) {
      resolve(file)
      return
    }

    const imgUrl = URL.createObjectURL(file)
    const image = new Image()
    image.addEventListener('error', (error) => {
      URL.revokeObjectURL(imgUrl)
      reject(error)
    })
    image.addEventListener('load', () => {
      URL.revokeObjectURL(imgUrl)
      try {
        const canvas = document.createElement('canvas')
        canvas.width = image.width
        canvas.height = image.height
        const context = canvas.getContext('2d')
        context.drawImage(image, 0, 0, canvas.width, canvas.height)

        canvas.toBlob((blob) =>
          resolve(new File([blob], file.name, { type: file.type, lastModified: file.lastModified })),
        )
      } catch (error) {
        reject(error)
      }

      // eslint-disable-next-line unicorn/prevent-abbreviations
    })
    image.src = imgUrl
  })
}

/**
 * Create a preview/thumbnail for an image
 *
 * @param {Blob} blob Blob with image data
 * @returns {Promise<File>} data URL for jpeg thumbnail
 */
export function createPreview(blob) {
  const maxSize = PREVIEW_SIZE
  return new Promise((resolve, reject) => {
    const img = new Image()
    const canvas = document.createElement('canvas')

    const objectURL = URL.createObjectURL(blob)

    img.addEventListener('error', (error) => {
      URL.revokeObjectURL(objectURL)
      reject(error)
    })

    img.addEventListener('load', () => {
      URL.revokeObjectURL(objectURL)
      try {
        if (img.height > img.width) {
          canvas.height = maxSize
          canvas.width = maxSize * (img.width / img.height)
        } else {
          canvas.width = maxSize
          canvas.height = maxSize * (img.height / img.width)
        }
        const context = canvas.getContext('2d')
        context.fillStyle = '#fff'
        context.fillRect(0, 0, canvas.width, canvas.height)
        context.drawImage(img, 0, 0, canvas.width, canvas.height)

        canvas.toBlob(
          (blob) => resolve(new File([blob], 'image.jpeg', { type: 'image/jpeg', lastModified: new Date() })),
          'image/jpeg',
        )
      } catch (error) {
        reject(error)
      }
    })

    // eslint-disable-next-line unicorn/prevent-abbreviations
    img.src = objectURL
  })
}

/**
 * Encrypt a file and upload it to the space vault.
 *
 * The vault item id and the encryption parameters are returned.
 * @param {CryphoAPI} api Crypho API wrapper
 * @param {CryptoHandler} cryptoHandler Encryption handler
 * @param {string} spaceId Identifier for the space
 * @param {File} file File to upload
 * @param {(string, int) => void} onProgress Optional progress handler
 * @returns {Promise<{uid: string, name: string, size: number, type: string, params: object}>}
 */
async function encryptAndUpload(api, cryptoHandler, spaceId, file, onProgress) {
  const { task: encryptTask, params } = await cryptoHandler.startEncryption()
  const uid = await uploadFile(api, spaceId, file, encryptTask, onProgress ? onProgress : () => {})
  return {
    uid,
    name: file.name,
    size: file.size,
    type: file.type || 'application/octet-stream',
    params,
  }
}

/**
 * Upload a file and publish it in the space.
 *
 * On failure the user is notified via a notification message.
 *
 * @param {string} spaceId The ID for the space
 * @param {File} file The file to upload
 * @param {string|undefined} title File title (optional)
 * @returns {Promise<void>}
 */
export async function uploadAndPublishFile(spaceId, file, title) {
  const state = store.getState()
  const spaceKey = getSpaceCurrentKey(state, { spaceId })
  const jid = state.identity.jid
  const api = new CryphoAPI(state.config.apiUrl)
  const cryptoHandler = new CryptoHandler(spaceKey.key, jid)
  const uploadId = uuid()

  store.dispatch(startUpload(uploadId, spaceId, file.name))
  NotificationManager.create({
    type: 'info',
    message: `${file.name} is being encrypted and uploaded...`,
    id: uploadId,
    timeOut: 1000 * 60 * 60,
  })

  const promises = []
  const handleProgress = (status, progress) => {
    if (status === 'complete') {
      store.dispatch(uploadComplete(uploadId))
    } else {
      store.dispatch(updateUploadProgress(uploadId, progress, status))
    }
  }

  let vaultItem = {
    ...createPubsubItem(undefined, undefined),
    title,
    fkid: spaceKey.id,
  }

  promises.push(
    // eslint-disable-next-line promise/always-return
    encryptAndUpload(api, cryptoHandler, spaceId, file, handleProgress).then((file) => {
      vaultItem = { ...vaultItem, ...file }
    }),
  )

  if (file.type.startsWith('image/')) {
    promises.push(
      createPreview(file)
        .then((thumbnail) => encryptAndUpload(api, cryptoHandler, spaceId, thumbnail))
        .then((file) => (vaultItem.thumbnail = file))
        // If a preview can’t be generated, for example due to an unsupported image format,
        // just upload the image as a plain file.
        .catch(() => {}),
    )
  }

  try {
    await Promise.all(promises)
    await publishToPubsubNode(spaceId, `/spaces/${spaceId}/vault`, vaultItem, uploadId)

    NotificationManager.remove({ id: uploadId })
    NotificationManager.success(`${file.name} uploaded successfully.`)
  } catch (error) {
    NotificationManager.remove({ id: uploadId })
    let genericError = false
    if (error instanceof ApiError || error.apiError) {
      switch (error.error) {
        case 'unsupported':
          NotificationManager.error('Upload protocol not supported by server. You may need to upgrade Crypho')
          break
        case 'not-a-member':
          NotificationManager.error('You are not allowed to upload files here')
          break
        case 'upload-too-big': {
          const maxSize = filesize(error.data['max-size'])
          NotificationManager.error(
            `${file.name} is too large. Maximum size for this conversation is ${maxSize}. Upgrade your account to allow larger files.`,
          )
          break
        }
        default:
          genericError = true
          break
      }
    } else {
      genericError = true
    }
    if (genericError) {
      if (navigator.onLine !== undefined && !navigator.Online) {
        NotificationManager.error(`${file.name} upload failed. Please check your internet connection.`)
      } else {
        NotificationManager.error(`${file.name} upload failed.`)
      }
    }
  } finally {
    store.dispatch(uploadComplete(uploadId))
  }
}

/**
 * Download and save a file item
 *
 * This will also mark the item as downloaded by the current user.
 *
 * @param {string} spaceId Identifier for the space
 * @param {string} vaultItemId Identifier of the vault pubsub item
 * @param {any} vaultItem Item from the vault pubsub node with file information
 * @returns {Promise<void>} Promise which resolves when the download is complete
 */
export async function downloadAndSaveFile(spaceId, vaultItemId, vaultItem) {
  if (!canDownload(vaultItem)) {
    /* eslint-disable react/jsx-no-target-blank */
    NotificationManager.error(
      <>
        <p>Your browser does not support decrypting large files.</p>
        <p>
          We recommend using Chrome or the{' '}
          <a target="_blank" href="https://www.crypho.com/download/">
            Crypho desktop app
          </a>
          .
        </p>
      </>,
      null,
      10000,
    )
  }

  const notificationId = Math.floor(Math.random() * 32767)
  NotificationManager.create({
    type: 'info',
    message: `Decrypting ${vaultItem.name}…`,
    id: notificationId,
    timeOut: 1000 * 60 * 60,
  })

  const state = store.getState()
  const jid = state.identity.jid
  const key = getKeyById(spaceId, vaultItem.fkid)
  const cryptoHandler = new CryptoHandler(key, jid)
  const api = new CryphoAPI(state.config.apiUrl)
  const { task: decryptTask } = await cryptoHandler.startDecryption(vaultItem.params)

  try {
    const fileUrl = await downloadFile(api, state.config.siteUrl, spaceId, vaultItem, decryptTask)
    markItemAsDownloaded(spaceId, vaultItemId)
    if (fileUrl && process.env.REACT_APP_DESKTOP) {
      NotificationManager.success(
        <React.Fragment>
          <a target="_blank" rel="noopener noreferrer" href={fileUrl}>
            {vaultItem.name}
          </a>{' '}
          downloaded.
        </React.Fragment>,
        null,
        10000,
      )
    }
  } catch (error) {
    if (error.message === 'download-in-progress') {
      NotificationManager.error('You are already downloading the file')
    } else {
      NotificationManager.error('There was an error downloading the file')
    }
  }
  NotificationManager.remove({ id: notificationId })
}

/**
 * Download a file and an image element showing the file contents.
 *
 * @param {string} spaceId Space id where file must be downloaded from
 * @param {string} keyID The (space) key id needed to decrypt the file
 * @param {FileItem} fileContent Item to download
 * @returns {Promise<string>}
 */
export async function getObjectURLForFile(spaceId, keyId, fileContent) {
  const cacheKey = `${spaceId}-${fileContent.uid}`
  let objectURL = ObjectURLCache.get(cacheKey)

  if (!objectURL) {
    const state = store.getState()
    const jid = state.identity.jid
    const key = getKeyById(spaceId, keyId)
    const cryptoHandler = new CryptoHandler(key, jid)
    const api = new CryphoAPI(state.config.apiUrl)
    const { task: decryptTask } = await cryptoHandler.startDecryption(fileContent.params)

    const blob = await downloadAsBlob(api, spaceId, fileContent, decryptTask)

    objectURL = URL.createObjectURL(blob)
    ObjectURLCache.set(cacheKey, objectURL)
  }
  return objectURL
}

/**
 * Change the title for a file
 *
 * @param {string} spaceId Space id where file must be downloaded from
 * @param {string} vaultItemId Pubsub item id for the vault data
 * @param {*} vaultItem  Pubsub item in the vault node
 * @param {string} title The new file title
 */
export async function setFileName(spaceId, vaultItemId, vaultItem, name) {
  const success = await coreSetFileName(spaceId, vaultItemId, vaultItem, name)
  if (!success) {
    NotificationManager.error('There was an error renaming this file', '')
  }
}
/**
 * Change the title for a file
 *
 * @param {string} spaceId Space id where file must be downloaded from
 * @param {string} vaultItemId Pubsub item id for the vault data
 * @param {*} vaultItem  Pubsub item in the vault node
 * @param {string} title The new file title
 */
export async function setFileTitle(spaceId, vaultItemId, vaultItem, title) {
  const success = await coreSetFileTitle(spaceId, vaultItemId, vaultItem, title)
  if (!success) {
    NotificationManager.error('There was an error changing the file title', '')
  }
}

/**
 * Delete a file
 *
 * @param {string} spaceId Space id where file is located
 * @param {*} item Infostream item or vault item
 */
export async function deleteFile(spaceId, itemId, item) {
  const { filename, success } = await coreDeleteFile(spaceId, itemId, item)
  if (success) {
    NotificationManager.success(`${filename} has been deleted`, '')
  } else {
    NotificationManager.error('There was an error deleting this file', '')
  }
}
