import dotProp from 'dot-prop-immutable'
import without from 'lodash/without'
import {
  SET_SPACES,
  ADD_SPACE,
  UPDATE_SPACE,
  UPDATE_SPACES,
  DELETE_SPACE,
  UPDATE_SPACE_KEY,
  UPDATE_SPACE_ORDER,
  SET_UNREAD,
} from './constants'

const epochToDate = (d) => {
  d = d ? new Date(parseInt(d, 10) * 1000) : new Date()
  return d.toISOString()
}

const _updateSpaceState = (id, data, state) => {
  let { last_seen, lastActivity, unread, keys, keySignatures, ...spaceData } = data
  last_seen = epochToDate(last_seen)
  let newState = { ...state }
  if (unread !== undefined) {
    newState = dotProp.set(state, `unread.${id}`, unread)
  } else if (lastActivity && last_seen < lastActivity && state.unread[id] === 0) {
    newState = dotProp.set(state, `unread.${id}`, 1)
  }

  newState = dotProp.set(newState, `lastSeen.${id}`, last_seen)
  newState = lastActivity ? dotProp.set(newState, `lastActivity.${id}`, lastActivity) : newState

  // Only merge new keys as keys do not change only get decrypted
  if (keys) {
    const oldKeyIds = state.keys[id] ? Object.keys(state.keys[id]) : []
    const newKeyIds = Object.keys(keys).filter((keyId) => !oldKeyIds.includes(keyId))
    const newKeysAndSignatures = newKeyIds.reduce(
      (res, keyId) => {
        res.keys[keyId] = keys[keyId]
        res.keySignatures[keyId] = keySignatures[keyId]
        return res
      },
      { keys: {}, keySignatures: {} },
    )
    newState = dotProp.merge(newState, `keys.${id}`, newKeysAndSignatures.keys)
    newState = dotProp.merge(newState, `keySignatures.${id}`, newKeysAndSignatures.keySignatures)
  }

  const spaceStatus = spaceData.status

  if (spaceStatus) {
    const { kid, payload } = JSON.parse(spaceStatus)
    const keys = newState.keys[id]
    let key = keys[kid]
    if (key.includes('adata')) {
      key = global.husher.decrypt(key)
      newState = dotProp.set(newState, `keys.${id}.${kid}`, key)
    }
    spaceData.status = global.husher.decrypt(payload, key)
  }

  return dotProp.merge(newState, `byId.${id}`, spaceData)
}

const _addSpaceState = (id, data, state) => {
  let { last_seen, lastActivity, keys, keySignatures, ...spaceData } = data
  last_seen = epochToDate(last_seen)
  const unread = last_seen < lastActivity ? 1 : 0
  const updatedWithUnreadTable = dotProp.set(state, `unread.${id}`, unread)
  const updatedWithLastSeenTable = dotProp.set(updatedWithUnreadTable, `lastSeen.${id}`, last_seen)
  const updatedWithLastActivityTable = dotProp.set(updatedWithLastSeenTable, `lastActivity.${id}`, lastActivity)
  const updatedWithSpaceTable = dotProp.set(updatedWithLastActivityTable, `byId.${id}`, spaceData)
  const updatedWithSpaceList = dotProp.set(updatedWithSpaceTable, 'allIds', (allIds) => [id, ...allIds])
  const updatedWithKeys = dotProp.set(updatedWithSpaceList, `keys.${id}`, keys)
  const updatedWithKeySignatures = dotProp.set(updatedWithKeys, `keySignatures.${id}`, keySignatures)

  return updatedWithKeySignatures
}

const _orderSpaceIds = (lastActivity) =>
  Object.keys(lastActivity).sort((a, b) => (lastActivity[a] < lastActivity[b] ? 1 : -1))

export default function spaces(
  state = {
    byId: {},
    allIds: [],
    lastSeen: {},
    lastActivity: {},
    unread: {},
    keys: {},
    keySignatures: {},
  },
  action,
) {
  switch (action.type) {
    case SET_SPACES: {
      const { spaces } = action
      let newState = state
      spaces.forEach((space) => {
        newState = _addSpaceState(space.id, space, newState)
      })
      newState = dotProp.set(newState, 'allIds', _orderSpaceIds(newState.lastActivity))
      return newState
    }

    case ADD_SPACE: {
      const { space } = action
      return _addSpaceState(space.id, space, state)
    }

    case UPDATE_SPACE: {
      const { id, data } = action
      return _updateSpaceState(id, data, state)
    }

    case UPDATE_SPACES: {
      let { data } = action
      let newState = { ...state }
      Object.keys(data).forEach((spaceId) => {
        newState = _updateSpaceState(spaceId, data[spaceId], newState)
      })
      return newState
    }

    case DELETE_SPACE: {
      const { id } = action
      const updatedWithLastSeenTable = dotProp.delete(state, `lastSeen.${id}`)
      const updatedWithLastActivityTable = dotProp.delete(updatedWithLastSeenTable, `lastActivity.${id}`)
      const updatedWithUnreadTable = dotProp.delete(updatedWithLastActivityTable, `unread.${id}`)
      const updatedWithKeys = dotProp.delete(updatedWithUnreadTable, `keys.${id}`)
      const updatedWithKeySignatures = dotProp.delete(updatedWithKeys, `keySignatures.${id}`)
      const updatedWithSpaceTable = dotProp.delete(updatedWithKeySignatures, `byId.${id}`)
      const updatedWithSpaceList = dotProp.set(updatedWithSpaceTable, 'allIds', (allIds) => without(allIds, id))
      return updatedWithSpaceList
    }

    case UPDATE_SPACE_KEY: {
      const { spaceId, keyId, key } = action
      return dotProp.set(state, `keys.${spaceId}.${keyId}`, key)
    }

    case UPDATE_SPACE_ORDER: {
      const ordered = _orderSpaceIds(state.lastActivity)
      return dotProp.set(state, 'allIds', ordered)
    }

    case SET_UNREAD: {
      let { unread } = action
      const newUnread = Object.keys(state.unread).reduce((d, spaceId) => {
        d[spaceId] = unread[spaceId] || 0
        return d
      }, unread)
      return dotProp.set(state, 'unread', newUnread)
    }

    default:
      return state
  }
}
