import React from 'react'
import PropTypes from 'prop-types'
import ActionWrapper from './ActionWrapper'

function UnsuportedItem({ showFullName, authorVCard }) {
  return (
    <ActionWrapper>
      {showFullName ? <em>Unavailable content from {authorVCard.FN}</em> : <em>Unavailable content</em>}
    </ActionWrapper>
  )
}

UnsuportedItem.propTypes = {
  showFullName: PropTypes.bool.isRequired,
  authorVCard: PropTypes.shape({
    FN: PropTypes.string.isRequired,
  }).isRequired,
}

export default UnsuportedItem
