import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import Mnemonic from 'mnemonic.js'
import { Button, Icon, Header } from 'semantic-ui-react'
import { Form } from 'formsy-semantic-ui-react'
import { useHistory } from 'react-router-dom'
import { NotificationManager } from 'react-notifications'

import { errorLabel } from '../../UI/forms'
import { saveSession } from '../../session'
import { crypho } from '../../crypho.core/xmpp'
import { husher } from '../../crypho.core'
import Topbar from '../../UI/Topbar'
import PassphraseStrengthMeter from '../../UI/widgets/passphrasestrength'

const ChangePassphrase = () => {
  const mnemonic = new Mnemonic(32)
  const history = useHistory()

  const [newPassword, setNewPassword] = useState('')
  const [passwordConfirmation, setPasswordConfirmation] = useState('')
  const [showPassword, setShowPassword] = useState(false)
  const [processing, setProcessing] = useState(false)
  const [examplePassphrase, setExamplePassphrase] = useState(mnemonic.toWords().join(' '))

  const identity = useSelector((state) => state.identity)

  const resetExamplePassphrase = () => {
    const mnemonic = new Mnemonic(32)
    setExamplePassphrase(mnemonic.toWords().join(' '))
  }

  const onSubmit = async () => {
    const { email, scryptSalt } = identity
    setProcessing(true)
    try {
      // Generate the new encrypted password
      const strengthened = await husher._strengthenScrypt(newPassword, {
        salt: husher._b64.toBits(scryptSalt),
      })
      global.husher.macKey = strengthened.key // The strengthened key used to encrypt the private El Gamal keys
      global.husher.authKey = strengthened.key2 // The strengthened key used (hashed) for authentication
      global.husher.scryptSalt = strengthened.salt
      global.husher.pN = strengthened.N
      global.husher.pr = strengthened.r
      global.husher.pp = strengthened.p
      await crypho.setPassword(global.husher.toJSON(email))
      await saveSession()
    } catch (error) {
      setProcessing(false)
      // eslint-disable-next-line no-console
      console.error(error)
      NotificationManager.error('There was an error, please try again.', '')
      return
    }
    setProcessing(false)
    NotificationManager.success('Your passphrase has been changed.', '')
    history.push('/')
  }

  return (
    <div className="authenticatedScene">
      <Topbar />
      <div className="accountOptions">
        <Header as="h3">Change passphrase</Header>
        <p>Use a few words, avoid common phrases. No need for symbols, digits, or uppercase letters.</p>

        <Form onValidSubmit={onSubmit} className="changePassword">
          <div className="changePasswordExample">
            <h4 className="examplePassphrase">Example</h4>
            <span>{examplePassphrase}</span>
            <Icon onClick={() => resetExamplePassphrase()} className="refresh" />
          </div>
          <Form.Input
            action={
              <Icon
                disabled
                name="eye"
                title={showPassword ? 'Hide passphrase' : 'Show passphrase'}
                onClick={() => setShowPassword(!showPassword)}
              />
            }
            name="newPassword"
            type={showPassword ? 'text' : 'password'}
            autoComplete="new-password"
            placeholder="Passphrase"
            required
            value={newPassword}
            onChange={(event_) => setNewPassword(event_.target.value)}
            errorLabel={errorLabel}
            validations="isStrongPassphrase"
            validationErrors={{
              isDefaultRequiredValue: 'Your passphrase is required',
            }}
          />
          <Form.Input
            name="passwordConfirmation"
            type={showPassword ? 'text' : 'password'}
            autoComplete="new-password"
            placeholder="Repeat passphrase"
            validations="equalsField:newPassword"
            value={passwordConfirmation}
            required={true}
            onChange={(event_) => setPasswordConfirmation(event_.target.value)}
            errorLabel={errorLabel}
            validationErrors={{
              isDefaultRequiredValue: 'Please repeat your passphrase',
              equalsField: 'Passphrases do not match',
            }}
          />
          <PassphraseStrengthMeter passphrase={newPassword} className="changePassProgress" />
          <Button name="change-passphrase" className="settingsButton" type="submit" disabled={processing}>
            Save changes
          </Button>
        </Form>
      </div>
    </div>
  )
}

export default ChangePassphrase
