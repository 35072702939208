import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Button, Modal, Divider } from 'semantic-ui-react'
import { Form } from 'formsy-semantic-ui-react'
import { NotificationManager } from 'react-notifications'
import PropTypes from 'prop-types'
import { PhoneInput } from '../../UI/forms'

import { errorLabel } from '../../UI/forms'
import { crypho } from '../../crypho.core/xmpp'

const smsInvitaion = Number(process.env[`REACT_APP_SMS_INVITATION`])

class InviteContactForm extends Component {
  constructor(properties) {
    super(properties)
    const email = properties.email || ''
    const mobile = properties.mobile || ''
    this.state = {
      open: false,
      email,
      mobile,
      processing: false,
    }
  }

  open = () => this.setState({ open: true })
  close = () => this.setState({ open: false, email: '', mobile: '' })

  invite = async () => {
    const { email, mobile, processing } = this.state
    if (processing) return
    if (!email && !mobile) return
    this.setState({ processing: true })
    try {
      await crypho.invite(email ? [email] : [], mobile ? [mobile] : [], '')
      this.setState({ email: '', mobile: '' })
      NotificationManager.success('Your invitation have been sent', '')
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error)
      NotificationManager.error('There was an error sending your invitation', '')
    }
    this.setState({ processing: false })
  }

  render() {
    const { open, processing, email, mobile } = this.state
    const { country } = this.props
    return (
      <Modal size="tiny" closeIcon open={open} closeOnEscape={true} closeOnRootNodeClick={false} onClose={this.close}>
        <Modal.Header>Add contacts</Modal.Header>
        <Modal.Content>
          {!!smsInvitaion && (
            <p>
              Invite contacts by adding their email address or mobile phone number. They will receive an email/sms
              invitation to be your contact (and to join Crypho if they are not already a member). Once they accept the
              invitation, you will be in each others contact lists.
            </p>
          )}
          {!smsInvitaion && (
            <p>
              Invite contacts by adding their email address. They will receive an email invitation to be your contact
              (and to join Crypho if they are not already a member). Once they accept the invitation, you will be in
              each others contact lists.
            </p>
          )}

          <Divider />
          <Form className="invite" onValidSubmit={this.invite}>
            <Form.Input
              name="inviteeEmail"
              onChange={(event_) => {
                this.setState({ email: event_.target.value })
              }}
              validations="isEmail"
              errorLabel={errorLabel}
              validationErrors={{
                isEmail: 'You provided an invalid email address',
              }}
              placeholder="Email address"
              rows={1}
              label="Invite via email"
              value={email}
            />

            {!!smsInvitaion && (
              <PhoneInput
                label="Or via SMS (include country code)"
                name="phone"
                defaultCountry={country}
                onChange={(value) => this.setState({ mobile: value })}
                autoComplete="tel"
                errorLabel={errorLabel}
                validations="isMobileNumber"
                validationErrors={{
                  isMobileNumber: 'You provided an invalid mobile number',
                }}
                value={mobile}
              />
            )}

            <Divider />
            <Modal.Actions>
              <Button floated="right" name="send-invitations" disabled={processing}>
                Send
              </Button>
            </Modal.Actions>
          </Form>
        </Modal.Content>
      </Modal>
    )
  }
}

InviteContactForm.propTypes = {
  email: PropTypes.string,
  mobile: PropTypes.string,
  country: PropTypes.string.isRequired,
}

const mapStateToProperties = (state) => ({
  country: (state.identity.phone && state.identity.phone.country.toUpperCase()) || 'NO',
})
const connector = (container) =>
  connect(
    mapStateToProperties,
    {},
    null,
    // eslint-disable-next-line unicorn/prevent-abbreviations
    { forwardRef: true },
  )(container)

export default connector(InviteContactForm)
