import without from 'lodash/without'
import dotProp from 'dot-prop-immutable'

const START_DOWNLOAD = 'START_DOWNLOAD'
const DOWNLOAD_COMPLETE = 'DOWNLOAD_COMPLETE'

export default function downloads(
  state = {
    downloadsInProgress: [],
  },
  action,
) {
  switch (action.type) {
    case START_DOWNLOAD: {
      const { downloadId } = action
      return dotProp.set(state, 'downloadsInProgress', (downloadsInProgress) =>
        downloadsInProgress.includes(downloadId) ? downloadsInProgress : downloadsInProgress.concat(downloadId),
      )
    }

    case DOWNLOAD_COMPLETE: {
      const { downloadId } = action
      return dotProp.set(state, 'downloadsInProgress', (downloadsInProgress) =>
        without(downloadsInProgress, downloadId),
      )
    }

    default:
      return state
  }
}

export const startDownload = (downloadId) => ({
  type: START_DOWNLOAD,
  downloadId,
})

export const downloadComplete = (downloadId) => ({
  type: DOWNLOAD_COMPLETE,
  downloadId,
})
