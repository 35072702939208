import React from 'react'
import { Form, Header } from 'semantic-ui-react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

import { saveEmailNotifications, saveInactivityTimeout } from '../../crypho.core/store/modules/settings'
import Topbar from '../../UI/Topbar'

class Settings extends React.Component {
  constructor(properties) {
    super(properties)
    this.state = {
      processing: false,
    }
  }

  handleSetInactivityAwayTimeout = async (ev, option) => {
    const { saveInactivityTimeout } = this.props
    this.setState({ processing: true })
    await saveInactivityTimeout(option.value)
    this.setState({ processing: false })
  }

  handleSetEmailNotifications = async () => {
    const { emailNotifications, saveEmailNotifications } = this.props
    this.setState({ processing: true })
    await saveEmailNotifications(!emailNotifications)
    this.setState({ processing: false })
  }

  render() {
    const { inactivityAwayTimeout, emailNotifications } = this.props
    const { processing } = this.state
    return (
      <div className="authenticatedScene">
        <Topbar />
        <div className="accountOptions">
          <Header as="h3">Inactivity timeout</Header>
          <p>
            This settings determines how long you can be inactive before Crypho considers you to be offline. While
            offline you will still receive activity notifications on your other devices.
          </p>

          <Form.Select
            options={[
              { text: '5 minutes', value: 5 * 60000 },
              { text: '15 minutes', value: 15 * 60000 },
              { text: '1 hour', value: 60 * 60000 },
              { text: 'Never', value: 0 },
            ]}
            value={inactivityAwayTimeout}
            disabled={processing}
            onChange={this.handleSetInactivityAwayTimeout}
          />
          <Header as="h3">Email notifications</Header>
          <p>
            If you are not using the mobile app, you will receive email notifications when you have new messages. Crypho
            will not send you more than one message in 24 hours until you log in. You can turn email notifications off
            in the checkbox below.
          </p>
          <Form.Checkbox
            label="Receive email notifications"
            checked={emailNotifications || undefined}
            onClick={this.handleSetEmailNotifications}
          />
        </div>
      </div>
    )
  }
}

Settings.propTypes = {
  inactivityAwayTimeout: PropTypes.number.isRequired,
  emailNotifications: PropTypes.bool.isRequired,
  saveUserSettings: PropTypes.func.isRequired,
  saveEmailNotifications: PropTypes.func.isRequired,
  saveInactivityTimeout: PropTypes.func.isRequired,
}

const mapStateToProperties = (state) => ({
  inactivityAwayTimeout: state.settings.inactivityAwayTimeout,
  emailNotifications: state.settings.emailNotifications,
})

const mapDispatchToProperties = {
  saveEmailNotifications,
  saveInactivityTimeout,
}

const connector = (container) => connect(mapStateToProperties, mapDispatchToProperties)(container)

export default connector(Settings)
