import React from 'react'
import PropTypes from 'prop-types'
import { Item } from 'semantic-ui-react'
import { downloadAndSaveFile, VaultItemPropType } from '../../../../../file-handling'
import { mimeTypeToIcon } from '../../../../../UI/utils'

function FileWithoutThumbnail({ authorVCard, spaceId, itemId, item, showFullName }) {
  const handleDownload = () => downloadAndSaveFile(spaceId, itemId, item)
  return (
    <Item.Group key="infostream-file">
      <Item>
        <Item.Image className={`${mimeTypeToIcon(item.type)} fileIcons`} onClick={handleDownload} />
        <Item.Content verticalAlign="middle">
          {showFullName ? <Item.Meta className="authorName">{`${authorVCard.FN}`}</Item.Meta> : null}
          <Item.Meta className="fileInfo" onClick={handleDownload}>
            {item.title || item.name}
          </Item.Meta>
        </Item.Content>
      </Item>
    </Item.Group>
  )
}

FileWithoutThumbnail.propTypes = {
  itemId: PropTypes.string.isRequired,
  showFullName: PropTypes.bool.isRequired,
  authorVCard: PropTypes.object.isRequired,
  spaceId: PropTypes.string.isRequired,
  item: VaultItemPropType.isRequired,
}

export default FileWithoutThumbnail
