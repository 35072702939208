import React, { Component } from 'react'
import { Modal, Divider } from 'semantic-ui-react'
import PropTypes from 'prop-types'
import { crypho } from '../../crypho.core/xmpp'
import { ColorSelect } from '../../UI/widgets/color'

class ChangeSpaceColor extends Component {
  state = { open: false, color: null }
  open = () => this.setState({ open: true })
  close = () => this.setState({ open: false })

  render() {
    const { open } = this.state
    const { space } = this.props

    return (
      <Modal
        size="tiny"
        className="info"
        closeIcon
        open={open}
        closeOnEscape={true}
        closeOnRootNodeClick={false}
        onClose={this.close}
      >
        <Modal.Header>Change color</Modal.Header>
        <Modal.Content>
          <p> Choose the color you want to set for this conversation:</p>
          <Divider />
          <ColorSelect
            selected={space.color}
            onUpdate={(color) => {
              crypho.updateSpace(space.id, { color })
              this.close()
            }}
          />
        </Modal.Content>
      </Modal>
    )
  }
}

ChangeSpaceColor.propTypes = {
  space: PropTypes.shape(),
}

export default ChangeSpaceColor
