import * as Sentry from '@sentry/core'
import { Severity as SentrySeverity } from '@sentry/types'
import xml from '@xmpp/xml'
import { createXmppClient, xmppClient, cryphoPlugin, startClient, pubsubPlugin, rtcPlugin } from './client'
import { promise } from '@xmpp/events'
import { setXmppPresence } from '../store/modules/xmpp'
import { setupEvents, onPubSubItemPublished, onPubSubItemDeleted } from './events'

/**
 * Reconfigure the XMPP client. This allows switching to another XMPP server,
 * or changing the XMPP domain.
 *
 * Note that we do *not* start the XMPP client as part of this process, since
 * you must set new credentials as well.
 *
 * @param {{websocketUrl: string, xmppDomain: string, xmppPubsub: string, xmppCrypho: string}} config Server configuration
 */
export function reconfigure(config) {
  xmppClient.stop()
  ;[cryphoPlugin, rtcPlugin].forEach((plugin) => {
    plugin.xmppDomain = config.xmppDomain
    plugin.xmppCrypho = config.xmppCrypho
  })

  pubsubPlugin.removeAllListeners()
  pubsubPlugin.on(`item-published:${config.xmppPubsub}`, onPubSubItemPublished)
  pubsubPlugin.on(`item-deleted:${config.xmppPubsub}`, onPubSubItemDeleted)

  xmppClient.options.service = config.xmppWebsocketUrl
  xmppClient.options.domain = config.xmppDomain
}

/**
 * Start the XMPP client.
 *
 * @returns {Promise<void>}
 */
export function start() {
  return startClient()
}

/**
 * Stop the XMPP client.
 *
 * This should only be called when logging out, or credentials have become
 * invalid.
 *
 * @returns {Promise<void}>
 */
export async function stop() {
  Sentry.addBreadcrumb({
    category: 'xmpp',
    message: 'Stoppping XMPP client',
    level: SentrySeverity.Info,
  })
  return xmppClient.stop()
}

/**
 * Restart the XMPP client completely.
 *
 * This should only be called during error recovery. Normal usage should never
 * invoke this function.
 *
 * @returns {Promise<void>}
 */
export async function restart() {
  if (xmppClient.status !== 'offline') {
    await xmppClient.stop()
  }
  await xmppClient.start()
}

/**
 * Reload the credentials in the XMPP client.
 *
 * The new credentials must already be in the redux store when this function is called.
 * The user will also automatically be marked as online.
 *
 * @returns {Promise<void>} Promise which resolves when updating the XMPP client is
 *      complete. The connection will have finished authentication at that point.
 */
export async function reloadCredentials() {
  if (xmppClient.status !== 'offline') {
    await xmppClient.stop()
  }
  await xmppClient.start()
  await onceOnline()
}

/**
 * Wait for XMPP to finish connecting and authentication
 *
 * @returns {Promise<void>}
 */
export const onceOnline = () => (xmppClient.status === 'online' ? Promise.resolve() : promise(xmppClient, 'online'))

/**
 * Wait for XMPP to finish disconnecting
 *
 * @returns {Promise<void>}
 */
export const onceOffline = () => (xmppClient.status === 'offline' ? Promise.resolve() : promise(xmppClient, 'offline'))

export function createClient() {
  createXmppClient()
  setupEvents()
}

export { default as jid } from '@xmpp/jid'
export { xml, setXmppPresence }
export {
  xmppClient as client,
  cryphoPlugin as crypho,
  pubsubPlugin as pubsub,
  rtcPlugin as webrtc,
  rosterPlugin as roster,
  privatePlugin as privateStorage,
} from './client'
