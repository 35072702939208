const changelog = [
  {
    version: '4.27.2',
    date: '2023-11-18',
    changes: ['Some bug fixes', 'Security improvement'],
  },
  {
    version: '4.27.1',
    date: '2023-11-06',
    changes: ['Some bug fixes', 'Security improvement'],
  },
  {
    version: '4.27.0',
    date: '2023-08-23',
    changes: ['Disable sms invitation', 'Update UI coloring'],
  },
  {
    version: '4.25.0',
    date: '2020-06-23',
    changes: ['Invitations to company accounts.'],
  },
  {
    version: '4.24.0',
    date: '2020-05-28',
    changes: [
      'Improved gallery view',
      'Faster file/gallery view loading',
      'In response to growing demand for secure communications during the Covid-19 pandemic, Crypho makes encrypted voice and video calls free to all accounts for the next 3 months.',
    ],
  },
  {
    version: '4.22.5',
    date: '2020-04-03',
    changes: [
      'In response to growing demand for secure communications during the Covid-19 pandemic, Crypho makes encrypted voice and video calls free to all accounts for the next 3 months.',
    ],
  },
  {
    version: '4.19.2',
    date: '2020-03-13',
    changes: ['You can now set a time after which messages and files expire.'],
  },
  {
    version: '4.18.1',
    date: '2020-02-14',
    changes: ['You can now view images in "Gallery" view.'],
  },
  {
    version: '4.12.0',
    date: '2019-11-14',
    changes: ['Screen sharing during video calls.'],
  },
  {
    version: '4.9.3',
    date: '2019-10-29',
    changes: ['You can now select which camera/microphone or speaker you prefer on video/audio calls.', 'UI tweaks.'],
  },
  {
    version: '4.5.1',
    date: '2019-10-07',
    changes: ['Group conversations now have an editable "status" visible to all members.'],
  },
  {
    version: '4.5.0',
    date: '2019-10-04',
    changes: ['Video/audio calls'],
  },
  {
    version: '4.2.1',
    date: '2019-06-26',
    changes: [
      'Welcome screen for new users.',
      'Fix duplicate message counts in badges.',
      'Smart zoom in image viewer.',
      'Faster authentication.',
    ],
  },
  {
    version: '4.2.0',
    date: '2019-06-14',
    changes: [
      'Improved layout.',
      'Invitations are shown in contact list.',
      'Messages you enter in a conversation are saved as draft until you post them.',
      'Authentication and key recovery bug fixes.',
    ],
  },
  {
    version: '4.1.11',
    date: '2019-05-29',
    changes: ['Crypho now remembers unsent messages when you navigate between conversations.', 'UI Fixes.'],
  },
  {
    version: '4.1.10',
    date: '2019-05-29',
    changes: ['Display changelog on version updates.', 'Correctly paste text from rich text sources.', 'UI tweaks.'],
  },
]
export default changelog
