import React from 'react'
import { Table } from 'semantic-ui-react'
import PropTypes from 'prop-types'
import SpaceTableRow from './SpaceTableRow'

const SpacesTable = ({ groupSpaces, fullNames, onMembersLinkClick, onRemove }) => {
  return (
    <div>
      <h3>Groups</h3>
      <Table celled>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Space Id</Table.HeaderCell>
            <Table.HeaderCell>Title</Table.HeaderCell>
            <Table.HeaderCell>Members</Table.HeaderCell>
            <Table.HeaderCell>Last activity</Table.HeaderCell>
            <Table.HeaderCell></Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {groupSpaces.map((space) => (
            <SpaceTableRow
              key={space.id}
              space={space}
              fullNames={fullNames}
              onMembersLinkClick={onMembersLinkClick}
              onRemove={onRemove}
            />
          ))}
        </Table.Body>
      </Table>
    </div>
  )
}

SpacesTable.propTypes = {
  groupSpaces: PropTypes.array,
  fullNames: PropTypes.object,
  onMembersLinkClick: PropTypes.func,
  onRemove: PropTypes.func,
}

export default SpacesTable
