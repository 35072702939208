import { EventEmitter } from '@xmpp/events'
import xml from '@xmpp/xml'
import jid from '@xmpp/jid'
import xid from '@xmpp/id'
import husher from '../husher'
import { encryptSpaceKeysToKey } from '../space'

const xmlns = 'http://crypho.com/ns/crypho'
const CHATSTATES = 'http://jabber.org/protocol/chatstates'
const version = '1.1'

class CryphoPlugin extends EventEmitter {
  constructor(client, xmppCrypho, xmppDomain) {
    super()
    this.client = client
    this.xmppCrypho = xmppCrypho
    this.xmppDomain = xmppDomain
  }

  /**
   * Retrieve the server version information
   *
   * @returns {Promise<{name: string, version: string, os: string}>} Server version information
   */
  async getServerVersion() {
    const response = await this.get(xml('query', { xmlns: 'jabber:iq:version' }), { to: this.xmppDomain })
    const result = response.getChild('query')
    return {
      nanme: result.getChildText('name'),
      version: result.getChildText('version'),
      os: result.getChildText('os'),
    }
  }

  MediaQueryList(child, params = {}) {
    const { iqCaller } = this.client
    return iqCaller.request(xml('iq', { type: 'get', to: this.xmppCrypho, ...params }, child))
  }

  get(child, params = {}) {
    const { iqCaller } = this.client
    return iqCaller.request(xml('iq', { type: 'get', to: this.xmppCrypho, ...params }, child))
  }

  set(child, params) {
    const { iqCaller } = this.client
    return iqCaller.request(xml('iq', { type: 'set', to: this.xmppCrypho, ...params }, child))
  }

  /**
   * Process an XMPP headline message
   *
   * @param {XMPPContext} context
   */
  onHeadlineMessage(context) {
    const message = context.stanza
    if (message.getChild('invitationsupdated')) {
      this.emit('invitations-updated')
    } else if (message.getChild('spacesupdated')) {
      const spaces = JSON.parse(message.getChildText('spacesupdated'))
      this.emit('spaces-updated', spaces)
    } else if (message.getChild('spacedeleted')) {
      const spaceId = message.getChildText('spacedeleted')
      this.emit('space-deleted', spaceId)
    } else if (message.getChild('spaceread')) {
      const spaceId = message.getChildText('spaceread')
      this.emit('space-read', spaceId)
    } else if (message.getChild('spacekeyrequest')) {
      const spaceId = message.getChildText('spacekeyrequest')
      this.emit('space-key-request', spaceId)
    } else if (message.getChild('vcardupdated')) {
      const userId = message.getChildText('vcardupdated')
      this.emit('vcard-updated', userId)
    } else if (message.getChild('accountupdated')) {
      this.emit('account-updated')
    } else if (message.getChild('userverification')) {
      const msg = message.getChildText('userverification')
      this.emit('user-verification', JSON.parse(msg))
    } else if (message.getChild('vcards')) {
      this.emit('vcards', JSON.parse(message.getChildText('vcards')))
    }
  }

  onChatMessage(context) {
    const body = context.stanza.getChild('body')
    if (body) {
      this.emit('service-message', body.text())
    }
  }

  getInvitations() {
    return this.get(xml('invitations', { xmlns })).then((response) => JSON.parse(response.getChildText('invitations')))
  }

  getSentInvitations() {
    return this.get(xml('sentinvitations', { xmlns })).then((response) =>
      JSON.parse(response.getChildText('invitations')),
    )
  }

  invite(emails, mobiles, message) {
    return this.set(xml('invite', { xmlns }, JSON.stringify({ emails, mobiles, message })))
  }

  /**
   * Accept a pending invitation from another user.
   *
   * @param {string} uid  Identifier for the invitation
   * @returns Promise<string> ID for the new contact space
   */
  async acceptInvitation(uid) {
    let response = await this.set(xml('acceptinvitation', { xmlns }, uid))
    const invitor = response.getChild('invitor')
    const invitorUsername = invitor.getChildText('uid')
    const invitorPubkey = invitor.getChildText('pubkey')
    const publicKeys = {}
    publicKeys[invitorUsername] = invitorPubkey
    publicKeys[this.client.entity.jid.local] = global.husher.encryptionKey.pub
    const { keys, signature } = global.husher.generateKeyAndEncryptToPublicKeys(publicKeys)
    response = await this.set(xml('spacekeys', { xmlns, signature }, JSON.stringify(keys)), {
      id: response.root().attrs.id,
    })
    return response.getChildText('space')
  }

  rejectInvitation(uid) {
    return this.set(xml('rejectinvitation', { xmlns }, JSON.stringify({ uid })))
  }

  retractInvitation(uid) {
    return this.set(xml('retractinvitation', { xmlns }, JSON.stringify({ uid })))
  }

  discoverContacts(hashes) {
    return this.get(
      xml(
        'discovercontacts',
        { xmlns },
        hashes.map((hash) => xml('item', {}, hash)),
      ),
    ).then((response) => JSON.parse(response.getChildText('matches')))
  }

  getVerifiedUsers() {
    return this.get(xml('verified', { xmlns })).then((response) => JSON.parse(response.getChildText('verified')))
  }

  verifyUser(uid, signature) {
    return this.set(xml('verifyUser', { xmlns }, JSON.stringify({ uid, signature })))
  }

  clearUserVerifications() {
    return this.set(xml('clearUserVerifications', { xmlns }))
  }

  getSpace(id) {
    return this.get(xml('space', { xmlns, version, id })).then((response) => JSON.parse(response.getChildText('space')))
  }

  getSpaces() {
    return this.get(xml('spaces', { xmlns, version })).then((response) => JSON.parse(response.getChildText('spaces')))
  }

  getSpaceMeta(id) {
    return this.get(xml('spacemeta', { xmlns, version, id })).then((response) =>
      JSON.parse(response.getChildText('spacemeta')),
    )
  }

  /**
   * Return the last-seen timestamp for all users for a space
   *
   * @params {string} id Space id
   * @returns {Promise<{[jid: string]: string}} Last-seen timestamp for each user
   */
  getSpaceLastSeen(id) {
    return this.get(xml('spacelastseen', { xmlns, version, id })).then((response) =>
      JSON.parse(response.getChildText('lastseen')),
    )
  }

  deleteSpace(uid) {
    return this.set(xml('deletespace', { xmlns, uid }))
  }

  leaveSpace(spaceid) {
    return this.set(xml('leavespace', { xmlns, spaceid }))
  }

  /**
   * Create a new group space
   *
   * @param {string[]} members List of usernames of members for the space
   * @param {string} title Title for the new space
   * @returns {Promise<string>} id for the new space
   */
  async createGroupSpace(members, title) {
    const response = await this.set(xml('createspace', { xmlns }, JSON.stringify({ members, title })))

    const publicKeys = JSON.parse(response.getChildText('keys'))
    publicKeys[this.client.entity.jid.local] = global.husher.encryptionKey.pub
    const { keys, signature } = global.husher.generateKeyAndEncryptToPublicKeys(publicKeys)

    const res = await this.set(xml('spacekeys', { xmlns, signature }, JSON.stringify(keys)), {
      id: response.root().attrs.id,
    })
    return res.getChildText('space')
  }

  updateSpace(uid, changes) {
    return this.set(xml('spaceupdate', { xmlns, uid }, JSON.stringify(changes)))
  }

  /**
   * Generate a new encryption key and add it to a space
   *
   * @param {string} spaceid Identified for the space
   * @returns {Promise<void>}
   */
  async addSpaceKey(spaceid) {
    const response = await this.set(xml('addspacekey', { xmlns, spaceid }))
    const publicKeys = JSON.parse(response.getChildText('keys'))
    const { keys, signature } = global.husher.generateKeyAndEncryptToPublicKeys(publicKeys)
    await this.set(xml('spacekeys', { xmlns, signature }, JSON.stringify(keys)), { id: response.root().attrs.id })
  }

  getEmailNotifications() {
    return this.get(xml('emailnotifications', { xmlns })).then((res) =>
      JSON.parse(res.getChildText('emailnotifications')),
    )
  }

  setEmailNotifications(enabled) {
    return this.set(xml('emailnotifications', { xmlns }, JSON.stringify(enabled)))
  }

  setSpaceNotificationSettings(spaceid, settings) {
    return this.set(xml('notificationsettings', { xmlns, spaceid, settings }))
  }

  getUnreadMessageCount() {
    return this.get(xml('unread', { xmlns })).then((response) => JSON.parse(response.getChildText('unread')))
  }

  getAccount() {
    return this.get(xml('account', { xmlns })).then((response) => JSON.parse(response.getChildText('account')))
  }

  updateAccount(diff) {
    return this.set(xml('account', { xmlns }, xml('details', {}, JSON.stringify(diff))))
  }

  addAccountMember(email) {
    return this.set(xml('account', { xmlns }, xml('addmember', {}, email)))
  }

  removeAccountMember(uid) {
    return this.set(xml('account', { xmlns }, xml('removemember', {}, uid)))
  }

  removeAccountInvitation(email) {
    return this.set(xml('account', { xmlns }, xml('removeinvitation', {}, email)))
  }

  respondToAccountInvitation(accountId, response) {
    return this.set(xml('account', { xmlns }, xml('invitationresponse', {}, JSON.stringify({ accountId, response }))))
  }

  deleteAccount(authHash) {
    return this.set(xml('account', { xmlns }, xml('delete', {}, authHash)))
  }

  getTwoFactorData() {
    return this.get(xml('twofactor', { xmlns })).then((response) => JSON.parse(response.getChildText('twofactor')))
  }

  setAvatar(data) {
    return this.set(xml('setavatar', { xmlns }, JSON.stringify(data)))
  }

  ping(spaceid) {
    return this.get(xml('ping', { xmlns, spaceid }), { id: `ping-${xid()}` })
  }

  getUpdates() {
    return this.get(xml('updates', { xmlns })).then((response) => JSON.parse(response.getChildText('updates')))
  }

  setMobile(local, country) {
    return this.set(xml('mobile', { xmlns }, JSON.stringify({ local, country })))
  }

  setFullname(fullname) {
    return this.set(xml('setfullname', { xmlns }, fullname))
  }

  setPassword(keypair) {
    return this.set(xml('changepassword', { xmlns }, JSON.stringify(keypair)))
  }

  /**
   * Add a user to an existing group space
   *
   * @param {string} spaceid Identifier for the group space
   * @param {string} memberid Username for the user to add to the space
   * @returns {Promise<void>}
   */
  async addSpaceMember(spaceid, memberid) {
    const response = await this.set(xml('addmember', { xmlns, version, spaceid, memberid }))
    const publicKey = husher.buildPublicKey(response.getChildText('key'))
    const keys = encryptSpaceKeysToKey(spaceid, publicKey)

    await this.set(xml('spacekeys', { xmlns }, JSON.stringify(keys)), {
      id: response.root().attrs.id,
    })
  }

  /**
   * Remove a member from a group space
   *
   * @param {string} spaceid Identifier for the group space
   * @param {string} memberid Username for the user to remove fromthe space
   * @returns {Promise<void>}
   */
  async removeSpaceMember(spaceid, memberid) {
    const response = await this.set(xml('removemember', { xmlns, version, spaceid, memberid }))
    const publicKeys = JSON.parse(response.getChildText('keys'))
    const { keys, signature } = global.husher.generateKeyAndEncryptToPublicKeys(publicKeys)
    await this.set(xml('spacekeys', { xmlns, signature }, JSON.stringify(keys)), { id: response.root().attrs.id })
  }

  /**
   * Set the default expiry time for a group.
   *
   * @param {string} spaceId Identifier for the group space
   * @param {number|null} ttl Expiry time in seconds, or null if no default expiry
   * @returns {Promise<void>}
   */
  setGroupDefaultTtl(spaceId, ttl) {
    return this.set(xml('set-group-default-ttl', { xmlns, group: spaceId, ttl }))
  }

  /**
   * Set the expiry time for a pubsub item.
   *
   * @param {string} node Pubsub node id (for example `/spaces/19su2k1289109/infostream`)
   * @param {string} itemId The pubsub item id to update
   * @param {number|null} ttl Expiry time in seconds, or null if no default expiry
   * @returns {Promise<void>}
   */
  setPubsubItemTtl(node, itemId, ttl) {
    return this.set(xml('set-pubsub-item-ttl', { xmlns, node, item: itemId, ttl }))
  }

  setUserRolesInSpace(spaceid, userid, diff) {
    return this.set(
      xml(
        'userrole',
        { xmlns, version },
        JSON.stringify({
          spaceid,
          userid: jid(userid, this.xmppDomain).toString(),
          diff,
        }),
      ),
    )
  }

  getEmails() {
    return this.get(xml('emails', { xmlns })).then((response) => JSON.parse(response.getChildText('emails')))
  }

  addEmail(email) {
    return this.set(xml('emails', { xmlns }, xml('add', {}, email)))
  }

  setRecoveryKey(key, authHash) {
    return this.set(xml('recoverykey', { xmlns }, [xml('key', {}, key), xml('authHash', {}, authHash)]))
  }

  async syncTime() {
    const clientRequestTransmission = Date.now()
    const res = await this.get(xml('syncTime', { xmlns, version }, xml('timestamp', {}, clientRequestTransmission)))
    const clientResponseReception = Date.now()
    const serverRequestReception = parseInt(res.getChildText('timestamp'), 10)
    return serverRequestReception - (clientRequestTransmission + clientResponseReception) / 2
  }

  getDevices() {
    return this.get(xml('devices', { xmlns })).then((response) => JSON.parse(response.getChildText('devices')))
  }

  unlinkDevice(deviceId) {
    return this.set(xml('devices', { xmlns }, xml('unlink', {}, deviceId)))
  }

  composing(to, thread, state = 'composing') {
    const msg = xml('message', { to, type: 'chat' }, [xml('thread', {}, thread), xml(state, { xmlns: CHATSTATES })])
    this.client.entity.send(msg)
  }
}

export default function setupCrypho(client, xmppCrypho, xmppDomain) {
  const plugin = new CryphoPlugin(client, xmppCrypho, xmppDomain)
  const { middleware } = client

  const isHeadlineMessage = ({ stanza }) =>
    stanza.is('message') && stanza.attrs.from === xmppCrypho && stanza.attrs.type === 'headline'
  const isChatMessage = ({ stanza }) =>
    stanza.is('message') && stanza.attrs.from === xmppCrypho && stanza.attrs.type === 'chat'

  middleware.use((context, next) => {
    if (isHeadlineMessage(context)) {
      return plugin.onHeadlineMessage(context)
    } else if (isChatMessage(context)) {
      return plugin.onChatMessage(context)
    }
    return next()
  })
  return plugin
}
