import _ from 'lodash'

/**
 * Adds a single filestream to the top of the infostream by creating a virtual infostream item of type `file`
 *
 * Returns the updated redux state
 *
 * @param {*} item
 * @param {string} itemId
 * @param {string} spaceId
 * @param {object} state
 * @returns {object}
 */
export function syncFileStreamItem(item, itemId, spaceId, state) {
  const infostreamNodeId = `/spaces/${spaceId}/infostream`
  const infostreamItemId = `${itemId}-infostream`

  if (!state.nodeIds.includes(infostreamNodeId)) {
    state = {
      ...state,
      nodeIds: [...state.nodeIds, infostreamNodeId],
      nodeItemIds: {
        ...state.nodeItemIds,
        [infostreamNodeId]: [],
      },
    }
  }

  const infostreamItem = {
    type: 'file',
    author: item.author,
    created: item.created,
    updated: item.updated,
    expires: item.expires,
    content: {
      node_id: `/spaces/${spaceId}/vault`,
      vault_item: itemId,
    },
    seenBy: item.downloadedBy || [],
  }

  state = {
    ...state,
    itemsById: {
      ...state.itemsById,
      [infostreamItemId]: infostreamItem,
    },
  }

  if (!state.nodeItemIds[infostreamNodeId].includes(infostreamItemId)) {
    state = {
      ...state,
      nodeItemIds: {
        ...state.nodeItemIds,
        [infostreamNodeId]: [infostreamItemId, ...state.nodeItemIds[infostreamNodeId]],
      },
    }
  }

  return state
}

/**
 * Syncs the entire filestream to infostream by creating virtual infostream items of type "file"
 *
 * Returns the updated redux state
 *
 * @param {string} spaceId
 * @param {object} state
 * @returns {object}
 */
export function syncInfoStream(spaceId, state) {
  const infostreamNodeId = `/spaces/${spaceId}/infostream`
  const fileStreamNodeId = `/spaces/${spaceId}/vault`
  const infoStreamItemIds = state.nodeItemIds[infostreamNodeId]

  if (!infoStreamItemIds || infoStreamItemIds.length === 0) {
    return state
  }

  // Build a list of filestream item ids that we need to add to the infostream.
  const firstCreatedInInfostream = state.itemsById[infoStreamItemIds[infoStreamItemIds.length - 1]].created

  let fileStreamItemIds = (state.nodeItemIds[fileStreamNodeId] || [])
    // Only add new items
    .filter((itemId) => !infoStreamItemIds[itemId])
    // Do not add items before the oldest infostream item, to prevent them from
    // spamming the top of the infostream.
    .filter((itemId) => state.itemsById[itemId].created > firstCreatedInInfostream)

  if (fileStreamItemIds.length === 0) {
    return state
  }

  let syncedState = state
  fileStreamItemIds.forEach((itemId) => {
    syncedState = syncFileStreamItem(syncedState.itemsById[itemId], itemId, spaceId, syncedState)
  })

  // Sort items so the newest comes first
  const sortedIds = syncedState.nodeItemIds[infostreamNodeId].sort(
    (a, b) => (syncedState.itemsById[a].created < syncedState.itemsById[b].created && 1) || -1,
  )

  return {
    ...syncedState,
    nodeItemIds: {
      ...syncedState.nodeItemIds,
      [infostreamNodeId]: sortedIds,
    },
  }
}

/**
 * merges downloaded by arrays from source object to target object and returns a new object
 *
 * @param {object} target
 * @param {object} source
 * @returns {object}
 */
export function mergeDownloadedByById(target, source) {
  if (!source) return target
  const result = _.cloneDeep(target)
  Object.keys(source).forEach((key) => {
    if (target[key]) {
      result[key] = [...new Set(source[key].concat(target[key]))]
    } else {
      result[key] = [...source[key]]
    }
  })
  return result
}

/**
 * filters all downloadedBy items and merges all of the to a single object and returns a new action
 *
 * @param {object} action
 * @returns {object}
 */
export function filterDownloadedByItems(action) {
  const items = { ...action.items }
  let itemIds = [...action.itemIds]
  let downloadedByById = {}
  if (!items) return action
  Object.keys(items).forEach((key) => {
    if (items[key].type === 'downloadedBy') {
      downloadedByById = mergeDownloadedByById(downloadedByById, { [items[key].itemId]: items[key].downloadedBy })
      delete items[key]
      itemIds = itemIds.filter((item) => item !== key)
    }
  })
  return { ...action, items, itemIds, downloadedByById }
}

/**
 * merges all downloadedBy from the state to appropriate item in the state
 *
 * @param {object} state
 */
export function syncDownloadedByById(state) {
  Object.keys(state.itemsById).forEach((key) => {
    if (state.downloadedByById[key]) {
      state.itemsById[key].downloadedBy = [
        ...new Set(state.downloadedByById[key].concat(state.itemsById[key].downloadedBy ?? [])),
      ]
    }
  })
}
