import React from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { Icon, Form } from 'semantic-ui-react'

const selectVaultItemIds = (spaceId) => (state) => state.pubsub.nodeItemIds[`/spaces/${spaceId}/vault`] || []

function FileStreamContainer({ spaceId, openUploadDialog, children: render }) {
  const itemIds = useSelector(selectVaultItemIds(spaceId))

  return (
    <div className="filestreamContainer">
      {render(itemIds)}
      <Form className="filestreamFooter" type="text" style={{ minHeight: 61, maxHeight: 250 }}>
        <Icon onClick={openUploadDialog} className="attachmentIcon" title="Share a file" size="large" />
      </Form>
    </div>
  )
}

FileStreamContainer.propTypes = {
  spaceId: PropTypes.string.isRequired,
  children: PropTypes.func.isRequired,
  openUploadDialog: PropTypes.func.isRequired,
}

export default FileStreamContainer
