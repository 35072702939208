import { size } from 'lodash'
import emojiRegex from 'emoji-regex'
import { emojify } from 'react-emojione'

let serverTimeOffset = 0
const DEFAULT_FETCH_TIMEOUT = 5000

export const setServerTimeOffset = function (offset) {
  serverTimeOffset = offset
}

export const getServerTimeOffset = function () {
  return serverTimeOffset
}

/**
 * Return the current server time. This takes the difference in clock time
 * between the client and the server into account.
 *
 * @param {int} offset Extra offset (in seconds) to apply
 * @returns {Date}
 */
export function serverTime(offset = 0) {
  let now = new Date()
  offset = 1000 * offset + serverTimeOffset
  if (offset) {
    now = new Date(now.getTime() + offset)
  }
  return now
}

export async function fetchWithTimeout(url, params = {}) {
  const { timeout, ...fetchParams } = params
  const controller = new AbortController()

  const result = await Promise.race([
    pause(timeout || DEFAULT_FETCH_TIMEOUT, 'timeout'),
    fetch(url, { ...fetchParams, signal: controller.signal }),
  ])
  if (result === 'timeout') {
    controller.abort()
    throw new Error('timeout')
  }
  return result
}

/**
 * Check if a string is a valid emoji line: at most three emoji characters,
 * possibly containing whitespace.
 *
 * @param {string} txt Text to check
 * @returns {boolean} true if this is a valid emojiline
 */
export const isEmojiLine = (txt) => {
  const emojified = toEmoji(txt)
  const noSpace = emojified.replace(/\s/gm, '')
  if (size(noSpace) > 3) return false
  return !noSpace.replace(emojiRegex(), '')
}

export const toEmoji = (txt) => emojify(txt, { output: 'unicode' })

/**
 * A very minimal lock that starts of as locked, and can only be unlocked once.
 *
 * This is useful to delay until an initialisation process is complete.
 */
export function OnetimeLock() {
  let r
  const l = new Promise((resolve) => {
    r = resolve
  })
  l.unlock = r
  return l
}
/*
 * Promise wrapper for setTimeout
 *
 * @param {number} time Time in milliseconds to wait
 * @param {any} result Value to return from a resolved promise
 * @return {Promise<any>} Result value
 */
export function pause(time, result) {
  return new Promise((resolve) => {
    setTimeout(() => resolve(result), time)
  })
}

/**
 * Wait a period of time before a network request should be retried.
 *
 * This uses a capped exponential backoff approach.
 *
 * @param {number} attempt The number of (failed) attempts already made.
 * @return {Promise<void>}
 */
export function backoff(attempt) {
  const delay = 1000 * 2 ** (Math.min(attempt, 5) - 1)
  return pause(delay)
}
