import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Button, Modal, Divider, Header, Icon } from 'semantic-ui-react'
import { Form } from 'formsy-semantic-ui-react'
import Mnemonic from 'mnemonic.js'
import { connect, Provider, ReactReduxContext } from 'react-redux'
import { __RouterContext as RouterContext } from 'react-router'
import { NotificationManager } from 'react-notifications'

import { getSpaceTitle, getSpaceMembersUserIds } from '../../../crypho.core/store/modules/space/selectors'
import { errorLabel } from '../../../UI/forms'

import { husher } from '../../../crypho.core'
import { crypho } from '../../../crypho.core/xmpp'
import { updateUser } from '../../../crypho.core/store/modules/roster'
import RosterAvatar from '../../../UI/widgets/rosteravatar'

class VerificationForm extends Component {
  state = { open: false, fingerprint: '' }
  open = () => this.setState({ open: true })
  close = () => this.setState({ open: false })

  verify = async () => {
    const { vCard, userId, updateUser } = this.props

    try {
      const words = this.state.fingerprint
        .trim()
        .toLowerCase()
        .split(/[\s,;]+/)
      const fingerprint = husher._hash([
        ...husher._b64.toBits(vCard.KEYS.ENCRYPTIONPUB),
        ...husher._b64.toBits(vCard.KEYS.SIGNINGPUB),
      ])

      const signature = global.husher.sign(fingerprint)
      const validation = Mnemonic.fromWords(words).toHex()
      if (husher._hex.fromBits(fingerprint).slice(0, 16) === validation) {
        await crypho.verifyUser(userId, signature)
        updateUser(userId, { verified: signature })
        this.close()
        NotificationManager.success('Your contact has been verified', '')
      } else {
        NotificationManager.error('Could not verify the contact', '')
      }
    } catch (error) {
      NotificationManager.error('There was an error verifying your contact', '')
    }
  }

  render() {
    const { title, userId, vCard } = this.props
    const { open } = this.state
    const email = vCard && vCard.EMAIL && vCard.EMAIL.USERID
    return (
      <RouterContext.Consumer>
        {((routeCtx) => (
          <ReactReduxContext.Consumer>
            {((ctx) => (
              <Modal
                className="warning"
                closeIcon
                open={open}
                closeOnEscape={true}
                closeOnRootNodeClick={false}
                onClose={this.close}
              >
                <RouterContext.Provider value={routeCtx}>
                  <Provider store={ctx.store}>
                    <Modal.Header>Verify contact</Modal.Header>
                    <Modal.Content>
                      <Header>
                        <RosterAvatar noPopup userId={userId} />
                        {title}
                      </Header>
                      <Modal.Description>{email}</Modal.Description>

                      <Divider />
                      <p>
                        Verifying a contact signs their encryption keys and guarantees that you are always talking to
                        the right person. Confirm your contact&#39;s true identity by meeting in person or via telephone
                        before validating their fingerprint. (Out-of-band verification).
                      </p>
                      <p className="description">
                        If someone resets their passphrase, they will have to be re-verified.
                      </p>
                      <p>
                        Only verify contacts when you really know who they are. It is also possible to verify keys by
                        scanning the QR code on their phone when you meet.
                      </p>
                      <p>You have not yet verified this contact. Ask for their key fingerprint and enter it here:</p>

                      <Form onValidSubmit={this.verify}>
                        <Form.Input
                          icon={<Icon className="keyIcon" />}
                          iconPosition="left"
                          name="fingerprint"
                          type="text"
                          placeholder="Key fingerprint (6 words)"
                          required
                          onChange={(event_) => {
                            this.setState({ fingerprint: event_.target.value })
                          }}
                          validations="isFingerprint"
                          errorLabel={errorLabel}
                          validationErrors={{
                            isDefaultRequiredValue: 'Fingerprint is required',
                            isFingerprint: 'Fingerprints are 6 words',
                          }}
                        />
                        <p className="description">
                          Your contact should find their fingerprint in their application menu - My fingerprint.
                        </p>
                        <Modal.Actions>
                          <Button floated="right" name="verify-contact">
                            Verify contact
                          </Button>
                        </Modal.Actions>
                      </Form>
                    </Modal.Content>
                  </Provider>
                </RouterContext.Provider>
              </Modal>
            )).bind(this)}
          </ReactReduxContext.Consumer>
        )).bind(this)}
      </RouterContext.Consumer>
    )
  }
}

VerificationForm.propTypes = {
  apiUrl: PropTypes.string,
  title: PropTypes.string,
  updateUser: PropTypes.func,
  userId: PropTypes.string,
  vCard: PropTypes.shape(),
}

const mapStateToProperties = (state, ownProperties) => {
  const space = ownProperties.space
  if (!space) return {}
  const userId = getSpaceMembersUserIds(state, ownProperties)[0]

  return {
    space,
    title: getSpaceTitle(state, { space }),
    userId,
    vCard: state.vcards.byId[userId],
    apiUrl: state.config.apiUrl,
  }
}

const mapDispatchToProperties = {
  updateUser,
}

const connector = (container) =>
  connect(
    mapStateToProperties,
    mapDispatchToProperties,
    null,
    // eslint-disable-next-line unicorn/prevent-abbreviations
    { forwardRef: true },
  )(container)

export default connector(VerificationForm)
