import React, { useRef } from 'react'
import PropTypes from 'prop-types'
import { Icon } from 'semantic-ui-react'
import RemoveConfirm from './RemoveConfirm'
import classes from './UserInfoHeader.module.css'

const UserInfoHeader = ({ onDelete, fullName }) => {
  const removeConfirmRef = useRef()

  const deleteUserHandler = () => {
    removeConfirmRef.current.close()
    onDelete()
  }

  const cancelDeleteHandler = () => {
    removeConfirmRef.current.close()
  }

  return (
    <div className={classes['user-info-header']}>
      <RemoveConfirm
        ref={removeConfirmRef}
        onDelete={deleteUserHandler}
        onCancel={cancelDeleteHandler}
        key="RemoveUserConfirm"
      />
      <div className={classes['user-info-title']}>
        <h3>User info</h3>
      </div>
      <div
        className={classes['user-info-actions']}
        key="deleteAction"
        onClick={() => removeConfirmRef.current.open(fullName, 'user', 'users')}
      >
        <Icon className={`deleteIcon ${classes['user-info-delete']}`} id="c-delete" name="trash" title="Delete user" />
      </div>
    </div>
  )
}

UserInfoHeader.propTypes = {
  fullName: PropTypes.string,
  onDelete: PropTypes.func,
}

export default UserInfoHeader
