import localforage from 'localforage'
import throttle from 'lodash/throttle'
import store from '../crypho.core/store'
import { loadRosterFromCache } from '../crypho.core/store/modules/roster'

// Observable pattern on store. Will trigger a throttled save cache when the state
// dictated by the selector is changed.
function observeStore(store, select, name) {
  let currentState

  const _cacheState = throttle(
    (state) => {
      let payload
      payload = state
      return localforage.setItem(`state.${name}`, payload)
    },
    5000,
    { leading: false, trailing: true },
  )

  function handleChange() {
    let nextState = select(store.getState())
    if (nextState !== currentState) {
      currentState = nextState
      _cacheState(currentState)
    }
  }

  let unsubscribe = store.subscribe(handleChange)
  handleChange()
  return unsubscribe
}

export const initCacheStorage = () => {
  observeStore(store, (state) => state.roster, 'roster')
}

export const loadStateFromCache = () => {
  return async (dispatch) => {
    await localforage.getItem('state.roster').then((cache) => {
      return cache ? dispatch(loadRosterFromCache(cache)) : undefined
    })
  }
}

export const wipeStorage = async () => {
  return localforage.multiRemove(['state.roster', 'state.vcards'])
}
