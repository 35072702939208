import max from 'lodash/max'
import moment from 'moment'

import { getSpaceKey } from './store/modules/space'
import { fetchPubsubItemsFromServer } from './store/modules/pubsub'
import store from './store'

export const getCurrentKey = (space) => {
  const { spaces } = store.getState()
  const spaceKeys = spaces.keys[space.id]

  const id = max(Object.keys(spaceKeys).map((k) => parseInt(k, 10))).toString()
  return {
    id,
    key: getSpaceKey(space.id, id),
  }
}

export const getKeyById = (spaceId, keyId) => {
  keyId = parseInt(keyId, 10)
  return getSpaceKey(spaceId, keyId)
}

export const encryptSpaceKeysToKey = (spaceId, key) => {
  const { spaces } = store.getState()
  const spaceKeys = spaces.keys[spaceId]
  return Object.keys(spaceKeys).reduce((accumulator, k) => {
    accumulator[k] = global.husher.encrypt(getKeyById(spaceId, k), key)
    return accumulator
  }, {})
}

const _renderContent = (item, authorVCard) => {
  const { content } = item
  const updated = moment(item.updated).format('YYYY-MM-DD HH:mm')

  switch (item.type) {
    case 'spaceCreated':
      return `${updated}\n${authorVCard.FN} started the conversation`

    case 'fileDeleted':
      return `${updated}\n${authorVCard.FN} deleted the file ${content.title}`

    case 'messageDeleted':
      return `${updated}\n${authorVCard.FN} deleted this message`

    case 'geolocation':
      return `${updated}\n${authorVCard.FN} shared a location near ${content.latitude}, ${content.longitude}, ${content.address}`

    default:
      return `${authorVCard.FN} ${updated}\n${content}`
  }
}

export const chatToText = async (spaceId) => {
  await store.dispatch(fetchPubsubItemsFromServer(`/spaces/${spaceId}/infostream`, spaceId, true, 1000))
  const { pubsub, vcards } = store.getState()
  const itemIds = [...pubsub.nodeItemIds[`/spaces/${spaceId}/infostream`]].reverse()
  const items = itemIds.map((itemId) => pubsub.itemsById[itemId])
  const text = items.reduce((chat, item) => {
    const vcard = vcards.byId[item.author.split('@')[0]]
    return chat.concat(`${_renderContent(item, vcard)}\n\n`)
  }, '')
  return text
}
