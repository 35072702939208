import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Button, Modal, Loader, Checkbox, Icon, Table, Header, Message } from 'semantic-ui-react'
import { Form } from 'formsy-semantic-ui-react'
import { NotificationManager } from 'react-notifications'
import Select from 'react-select'
import { connect, Provider, ReactReduxContext } from 'react-redux'
import { __RouterContext as RouterContext } from 'react-router'
import store from '../../../crypho.core/store'
import { crypho } from '../../../crypho.core/xmpp'
import { getContactFullnameValueLabelPairs } from '../../../crypho.core/store/modules/roster/selectors'
import {
  getSpaceMembersUserIds,
  getSpaceOnlineMembersUserIds,
} from '../../../crypho.core/store/modules/space/selectors'
import RosterAvatar from '../../../UI/widgets/rosteravatar'
import { getSpaceTitle } from '../../../crypho.core/store/modules/space/selectors'

class PureGroupMemberItem extends Component {
  state = { processing: false }
  toggleOp = async (opStatus) => {
    const { space, userId } = this.props

    try {
      await crypho.setUserRolesInSpace(space.id, userId, { op: opStatus })
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error)
      NotificationManager.error('There was an error changing the user roles', '')
    }
  }

  removeMember = async () => {
    this.setState({ processing: true })
    const { space, userId } = this.props
    try {
      await crypho.removeSpaceMember(space.id, userId)
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error)
      NotificationManager.error('There was an error removing the user', '')
      this.setState({ processing: false })
    }
  }

  render() {
    const { processing } = this.state
    const { vcard, isOperator, isOnline, amIOperator, isOwner, myId, userId } = this.props
    const isMe = userId === myId
    const company = (vcard.ORG && vcard.ORG.ORGNAME) || ''
    return (
      <Table.Row>
        <Table.Cell>
          <Header as="h4" image>
            <RosterAvatar noPopup userId={this.props.userId} />
            <Header.Content as="p">
              {vcard && vcard.FN} {isOnline ? <Icon color="green" name="circle" size="tiny" /> : null}
              <Header.Subheader> {company}</Header.Subheader>
            </Header.Content>
          </Header>
        </Table.Cell>
        <Table.Cell collapsing textAlign="right">
          {!isOwner && !isMe ? (
            <Checkbox
              disabled={isOwner || !amIOperator}
              checked={isOperator}
              onChange={() => {
                this.toggleOp(!isOperator)
              }}
            />
          ) : null}
          {isOwner ? <p className="ownerLabel">Owner</p> : null}
        </Table.Cell>
        <Table.Cell collapsing textAlign="right">
          {amIOperator && !isOwner && !isMe ? (
            <Icon className="remove" onClick={this.removeMember} disabled={processing} />
          ) : null}
        </Table.Cell>
      </Table.Row>
    )
  }
}
PureGroupMemberItem.propTypes = {
  amIOperator: PropTypes.bool,
  apiUrl: PropTypes.string,
  isOnline: PropTypes.bool,
  isOperator: PropTypes.bool,
  isOwner: PropTypes.bool,
  onlineMemberIds: PropTypes.array,
  space: PropTypes.shape(),
  userId: PropTypes.string,
  vcard: PropTypes.shape(),
  myId: PropTypes.string,
}

const GroupMemberItem = connect((state, ownProperties) => {
  const space = ownProperties.space
  const userId = ownProperties.memberId
  const roles = ownProperties.roles || []
  return {
    apiUrl: state.config.apiUrl,
    space,
    userId,
    vcard: state.vcards.byId[userId],
    amIOperator: ownProperties.amIOperator,
    isOperator: roles.includes('op'),
    isOwner: roles.includes('owner'),
    isOnline: getSpaceOnlineMembersUserIds(state, ownProperties).includes(userId),
    myId: state.identity.id,
  }
}, {})(PureGroupMemberItem)

class GroupMemberList extends Component {
  state = { open: false, title: '', members: [], processing: false, openOwnershipModal: false, newOwner: '' }

  open = () => {
    const state = store.getState()
    const { memberIds } = this.props
    const contactValueLabels = getContactFullnameValueLabelPairs(state).filter((item) => {
      return !memberIds.includes(item.value)
    })
    this.setState({ open: true, contactValueLabels })
  }

  close = () => this.setState({ open: false })

  openChangeOwnerPopup = () => {
    const state = store.getState()
    const { memberIds } = this.props
    const memberValueLabels = []
    for (const userId of memberIds) {
      const vcard = state.vcards.byId[userId]
      memberValueLabels.push({ value: userId, label: vcard.FN })
    }
    this.setState({ openOwnershipModal: true, memberValueLabels })
  }

  closeChangeOwnerPopup = () => {
    this.setState({ openOwnershipModal: false, newOwner: '' })
  }

  applyNewOwner = async () => {
    const { newOwner } = this.state
    const { space } = this.props

    if (!newOwner) {
      NotificationManager.error('You need to select the new owner.')
      return
    }
    try {
      await crypho.setUserRolesInSpace(space.id, newOwner, { owner: true })
      this.closeChangeOwnerPopup()
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error)
      NotificationManager.error('There was an error changing ownership.', '')
    }
  }

  addMembers = async () => {
    const { members } = this.state
    const { space } = this.props
    this.setState({ processing: true })
    try {
      members
        .map((pair) => pair.value)
        .forEach(async (memberId) => {
          await crypho.addSpaceMember(space.id, memberId)
        })
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error)
      NotificationManager.error('There was an error adding members to the conversation', '')
      this.setState({ processing: false })
    }
    this.setState({ members: [], processing: false })
  }

  render() {
    const { open, processing, members, contactValueLabels, openOwnershipModal, memberValueLabels } = this.state
    const { space, memberIds, myId, title } = this.props
    const count = memberIds.length
    if (!space || !space.roles) return null

    const amIOperator = !!(space.roles[myId] && space.roles[myId].includes('op'))
    const amIOwner = space.roles[myId] && space.roles[myId].includes('owner')

    return (
      <RouterContext.Consumer>
        {((routeCtx) => (
          <ReactReduxContext.Consumer>
            {((ctx) => (
              <Modal
                size="small"
                closeIcon
                open={open}
                closeOnEscape={true}
                closeOnRootNodeClick={false}
                onClose={this.close}
              >
                <RouterContext.Provider value={routeCtx}>
                  <Provider store={ctx.store}>
                    <Modal.Header>
                      {title}
                      <span className="modalSubheader">
                        ({count + 1}
                        {count < 1 ? ' member' : ' members'})
                      </span>
                    </Modal.Header>
                    <Modal.Content>
                      <Loader disabled={!processing} />
                      <Table basic="very" unstackable className="groupMembersTable">
                        {memberIds.length !== 0 || [...[myId]] ? (
                          <Table.Header>
                            {amIOperator
                              ? [
                                  <Table.Row key="select-member-widget">
                                    <Table.HeaderCell colSpan="3">
                                      <p key="select-member-description">
                                        Select from your contacts to add people to the group. New members get access to
                                        the group&apos;s chat history and files. An operator can invite and remove
                                        members.
                                      </p>
                                    </Table.HeaderCell>
                                  </Table.Row>,
                                  <Table.Row key="select-members">
                                    <Table.HeaderCell colSpan="1">
                                      <Select
                                        value={members}
                                        name="members"
                                        isMulti
                                        placeholder="Select"
                                        onChange={(value) => this.setState({ members: value })}
                                        options={contactValueLabels}
                                        theme={(theme) => ({
                                          ...theme,
                                          colors: {
                                            ...theme.colors,
                                            primary: '#ccc',
                                          },
                                        })}
                                        className="c-groupMembers"
                                      />
                                    </Table.HeaderCell>
                                    <Table.HeaderCell colSpan="2">
                                      <Form onValidSubmit={this.addMembers}>
                                        <Modal.Actions>
                                          <Button floated="right" name="save-changes" disabled={processing}>
                                            Add members
                                          </Button>
                                        </Modal.Actions>
                                      </Form>
                                    </Table.HeaderCell>
                                  </Table.Row>,
                                ]
                              : null}
                            <Table.Row>
                              <Table.HeaderCell />
                              <Table.HeaderCell collapsing textAlign="right">
                                Operator
                              </Table.HeaderCell>
                              {amIOperator ? (
                                <Table.HeaderCell collapsing textAlign="right">
                                  Remove
                                </Table.HeaderCell>
                              ) : null}
                            </Table.Row>
                          </Table.Header>
                        ) : null}
                        {memberIds.length !== 0 || [...[myId]] ? (
                          <Table.Body>
                            {[...[myId], ...memberIds].map((memberId) => (
                              <GroupMemberItem
                                space={space}
                                key={memberId}
                                memberId={memberId}
                                roles={space.roles[memberId]}
                                amIOperator={amIOperator}
                              />
                            ))}
                          </Table.Body>
                        ) : null}
                        {amIOwner && (
                          <Button onClick={this.openChangeOwnerPopup} negative>
                            Change group ownership
                          </Button>
                        )}
                        <Modal
                          closeOnRootNodeClick={false}
                          className="destructive"
                          closeIcon
                          closeOnEscape={true}
                          open={openOwnershipModal}
                          key="set-new-owner"
                          size="small"
                          onClose={this.closeChangeOwnerPopup}
                        >
                          <Modal.Header>Set the new owner</Modal.Header>
                          <Modal.Content>
                            <Message negative compact>
                              Assigning the ownership to a different person means you will no longer be the owner of the
                              group and will be demoted to an operator.
                            </Message>
                            <Select
                              name="members"
                              placeholder="New owner"
                              theme={(theme) => ({
                                ...theme,
                                colors: {
                                  ...theme.colors,
                                  primary: '#ccc',
                                },
                              })}
                              onChange={(option) => this.setState({ newOwner: option.value })}
                              options={memberValueLabels}
                              className="c-groupmembers-owner"
                            />
                            <Form onValidSubmit={this.addMembers}>
                              <Modal.Actions>
                                <Button
                                  floated="right"
                                  name="save-changes"
                                  disabled={processing}
                                  onClick={this.applyNewOwner}
                                >
                                  Apply
                                </Button>
                              </Modal.Actions>
                            </Form>
                          </Modal.Content>
                        </Modal>
                      </Table>
                    </Modal.Content>
                  </Provider>
                </RouterContext.Provider>
              </Modal>
            )).bind(this)}
          </ReactReduxContext.Consumer>
        )).bind(this)}
      </RouterContext.Consumer>
    )
  }
}

GroupMemberList.propTypes = {
  memberIds: PropTypes.array,
  myId: PropTypes.string,
  space: PropTypes.shape(),
  title: PropTypes.string,
}

const mapStateToProperties = (state, ownProperties) => {
  const space = ownProperties.space

  return {
    space,
    memberIds: getSpaceMembersUserIds(state, { spaceId: space.id }),
    myId: state.identity.id,
    title: getSpaceTitle(state, { space }),
  }
}

const mapDispatchToProperties = {}

const connector = (container) =>
  connect(
    mapStateToProperties,
    mapDispatchToProperties,
    null,
    // eslint-disable-next-line unicorn/prevent-abbreviations
    { forwardRef: true },
  )(container)

export default connector(GroupMemberList)
