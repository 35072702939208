import React from 'react'
import PropTypes from 'prop-types'
import { List, Button, ListDescription } from 'semantic-ui-react'
import classes from './EmailListItem.module.css'

const EmailListItem = ({ email, onDelete, showRemoveButton }) => {
  const deleteEmailHandler = () => {
    onDelete(email)
  }

  return (
    <List.Item key={email} className={classes['list-item-container']}>
      <List.Content>
        <ListDescription as="div" className={classes.description}>
          {email}
        </ListDescription>
      </List.Content>
      <List.Content className={classes['remove-button-container']}>
        {showRemoveButton && [
          <Button
            color="red"
            circular
            icon="remove"
            title="Remove email"
            key={`${email}_removeButton`}
            className={classes['remove-button']}
            onClick={deleteEmailHandler}
          />,
        ]}
      </List.Content>
    </List.Item>
  )
}

EmailListItem.propTypes = {
  email: PropTypes.string,
  onDelete: PropTypes.func,
  showRemoveButton: PropTypes.bool,
}

export default EmailListItem
