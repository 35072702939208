import xml from '@xmpp/xml'

const xmlns = 'jabber:iq:private'
const KEY_NAMESPACE = 'crypho'

class PrivateStoragePlugin {
  constructor(client) {
    this.client = client
  }

  async get(key) {
    const { iqCaller } = this.client
    const res = await iqCaller.request(
      xml('iq', { type: 'get' }, xml('query', { xmlns }, xml(key, { xmlns: KEY_NAMESPACE }))),
    )
    return res.getChild('query').getChildText(key)
  }

  async set(key, value) {
    const { iqCaller } = this.client
    await iqCaller.request(
      xml('iq', { type: 'set' }, xml('query', { xmlns }, xml(key, { xmlns: KEY_NAMESPACE }, value))),
    )
  }
}

export default function setupPrivateStorage(client) {
  const plugin = new PrivateStoragePlugin(client)
  return plugin
}
