import dotProp from 'dot-prop-immutable'

import { crypho } from '../../../xmpp'

const FETCH_RECEIVED_INVITATIONS = 'FETCH_RECEIVED_INVITATIONS'
const FETCH_SENT_INVITATIONS = 'FETCH_SENT_INVITATIONS'

export default function invitations(
  state = {
    sent: [],
    received: [],
  },
  action,
) {
  switch (action.type) {
    case FETCH_RECEIVED_INVITATIONS: {
      const { invitations } = action
      const updatedWithInvitationTable = dotProp.set(state, 'received', invitations)
      return updatedWithInvitationTable
    }

    case FETCH_SENT_INVITATIONS: {
      const { invitations } = action
      const updatedWithInvitationTable = dotProp.set(state, 'sent', invitations)
      return updatedWithInvitationTable
    }

    default:
      return state
  }
}

export const fetchInvitations = () => {
  return async function (dispatch) {
    return Promise.all([
      crypho.getInvitations().then((invitations) => {
        return dispatch({
          type: FETCH_RECEIVED_INVITATIONS,
          invitations,
        })
      }),
      crypho.getSentInvitations().then((invitations) => {
        return dispatch({
          type: FETCH_SENT_INVITATIONS,
          invitations,
        })
      }),
    ])
  }
}
