import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { compose } from 'recompose'
import PropTypes from 'prop-types'
import { pause } from '../crypho.core/utils'

class SplashScene extends Component {
  constructor(properties) {
    super(properties)
    this.state = {
      splashText: 'Authenticating',
    }

    this.delayResolve = pause(2000)
    this.componentDidUpdate({})
  }

  componentDidUpdate(prevProps) {
    if (this.props.xmppStatus === 'online' && prevProps.xmppStatus !== 'online') {
      this.delayResolve.then(() => this.props.history.push('/contacts')).catch(() => {})
    }
  }

  render() {
    const { splashText } = this.state

    return (
      <section className="splash-container">
        <div className="splash">
          <div id="outer-circle" />
          <div id="mid-circle" />
          <div id="inner-circle" />
        </div>
        <div className="cryphoLogotype" />
        <p>
          {splashText}
          <span />
        </p>
      </section>
    )
  }
}

SplashScene.propTypes = {
  history: PropTypes.shape().isRequired,
  xmppStatus: PropTypes.string.isRequired,
}

const mapStateToProperties = (state) => ({
  xmppStatus: state.xmpp.status,
})

const connector = (container) => compose(withRouter, connect(mapStateToProperties))(container)

export default connector(SplashScene)
