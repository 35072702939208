import React from 'react'
import PropTypes from 'prop-types'
import { Table } from 'semantic-ui-react'
import classes from './PlansTable.module.css'

const PlanTable = ({ plans, loading }) => {
  if (loading) {
    return (
      <div className={classes.loader}>
        <div className="ui active large inverted text loader">Loading</div>
      </div>
    )
  }

  return (
    <div className={classes.tableContainer}>
      <Table>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell className={classes.tableHeaderCells} textAlign="center">
              Name
            </Table.HeaderCell>
            <Table.HeaderCell className={classes.tableHeaderCells} textAlign="center">
              Title
            </Table.HeaderCell>
            <Table.HeaderCell className={classes.tableHeaderCells} textAlign="center">
              Description
            </Table.HeaderCell>
            <Table.HeaderCell className={classes.tableHeaderCells} textAlign="center">
              Interval
            </Table.HeaderCell>
            <Table.HeaderCell className={classes.tableHeaderCells} textAlign="center">
              Member Limit
            </Table.HeaderCell>
            <Table.HeaderCell className={classes.tableHeaderCells} textAlign="center">
              File Size Limit
            </Table.HeaderCell>
            <Table.HeaderCell className={classes.tableHeaderCells} textAlign="center">
              Group Limit
            </Table.HeaderCell>
            <Table.HeaderCell className={classes.tableHeaderCells} textAlign="center">
              Special Offer
            </Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body className={classes.tableRowGroup}>
          {plans.map((plan) => (
            <Table.Row className={classes.tableRow} key={plan.name}>
              <Table.Cell textAlign="center" className={classes.tableCells}>
                {plan.name}
              </Table.Cell>
              <Table.Cell textAlign="center" className={classes.tableCells}>
                {plan.title}
              </Table.Cell>
              <Table.Cell textAlign="left" className={`${classes.tableCells} ${classes.tableCellsDescription}`}>
                {plan.description}
              </Table.Cell>
              <Table.Cell textAlign="center" className={classes.tableCells}>
                {plan.interval}
              </Table.Cell>
              <Table.Cell textAlign="center" className={classes.tableCells}>
                {plan.member_limit}
              </Table.Cell>
              <Table.Cell textAlign="center" className={classes.tableCells}>
                {plan.filesize_limit}
              </Table.Cell>
              <Table.Cell textAlign="center" className={classes.tableCells}>
                {plan.group_limit}
              </Table.Cell>
              <Table.Cell textAlign="center" className={classes.tableCells}>
                {plan.special_offer ? 'True' : 'False'}
              </Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
      </Table>
    </div>
  )
}

PlanTable.propTypes = {
  loading: PropTypes.bool,
  plans: PropTypes.array,
}

export default PlanTable
