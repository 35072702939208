import React from 'react'
import PropTypes from 'prop-types'
import { Header, Icon, Popup } from 'semantic-ui-react'

function OnlineMembers({ space, onlineMemberIds, members, onOpenGroupMemberList }) {
  const count = onlineMemberIds.length

  if (!space) return null

  if (space.type === 'contact') {
    return count != 0 ? <Icon color="green" name="circle" size="tiny" title="online" /> : null
  }

  if (count >= 1)
    return (
      <Popup
        className="memberInfo"
        on="click"
        position="bottom left"
        trigger={
          <Header.Subheader className="description">
            <Icon name="user" />
            {members.length + 1}
          </Header.Subheader>
        }
        wide="very"
      >
        {members.length + 1} members, {count}
        {count === 1 ? ' other online' : ' others online'}
        <p className="blueTextLink" onClick={onOpenGroupMemberList} title="">
          View all
        </p>
      </Popup>
    )
  else
    return (
      <Popup
        on="click"
        position="bottom left"
        trigger={
          <Header.Subheader className="description">
            <Icon name="user" />
            {members.length + 1}
          </Header.Subheader>
        }
        wide="very"
      >
        {members.length + 1} members, {'only you online'}
      </Popup>
    )
}

OnlineMembers.propTypes = {
  space: PropTypes.shape({
    type: PropTypes.string.isRequired,
  }),
  onlineMemberIds: PropTypes.arrayOf(PropTypes.string).isRequired,
  members: PropTypes.array.isRequired,
  onOpenGroupMemberList: PropTypes.func.isRequired,
}

export default OnlineMembers
