import * as defaultReducers from './reducers'
import { combineReducers } from 'redux'

export default function createReducer(reducers) {
  const appReducer = combineReducers({ ...defaultReducers, ...reducers })

  // https://stackoverflow.com/questions/35622588/how-to-reset-the-state-of-a-redux-store/35641992#35641992
  const rootReducer = (state, action) => {
    if (action.type === 'UNLINK') {
      state = undefined
    }

    return appReducer(state, action)
  }

  return rootReducer
}
