import React, { forwardRef, useImperativeHandle, useState } from 'react'
import PropTypes from 'prop-types'
import { Confirm } from 'semantic-ui-react'

const RemoveConfirm = ({ onCancel, onDelete }, ref) => {
  const [open, setOpen] = useState(false)
  const [name, setName] = useState('')
  const [sourcePluralName, setSourcePluralName] = useState('')
  const [sourceSingularName, setSourceSingularName] = useState('')

  useImperativeHandle(ref, () => ({
    open: (instanceName, resourceSingularName, resourcePluralName) => {
      setName(instanceName)
      setOpen(true)
      setSourcePluralName(resourcePluralName)
      setSourceSingularName(resourceSingularName)
    },
    close: () => setOpen(false),
  }))

  return (
    <Confirm
      size="mini"
      className="destructive"
      closeIcon
      open={open}
      closeOnRootNodeClick={false}
      content={[
        <h5 key="p1">{`${name}`}</h5>,
        <p key="p2">will be removed from {sourcePluralName}.</p>,
        <p key="p3">Are you sure?</p>,
      ]}
      header={`Delete ${sourceSingularName}`}
      confirmButton="Delete"
      cancelButton={null}
      onCancel={onCancel}
      onConfirm={onDelete}
    />
  )
}

RemoveConfirm.propTypes = {
  onCancel: PropTypes.func,
  onDelete: PropTypes.func,
}

export default forwardRef(RemoveConfirm)
