import React, { Component } from 'react'
import { Button, Modal } from 'semantic-ui-react'
import { Form } from 'formsy-semantic-ui-react'
import { NotificationManager } from 'react-notifications'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { crypho } from '../../../crypho.core/xmpp'
import { updateSpace as updateStore } from '../../../crypho.core/store/modules/space'
import { getSpaceTitle } from '../../../crypho.core/store/modules/space/selectors'

class RenameGroup extends Component {
  state = { open: false, title: '' }
  open = () => this.setState({ open: true })
  close = () => this.setState({ open: false })

  onRename = async () => {
    const { spaceId, updateStore } = this.props
    const { title } = this.state
    try {
      await crypho.updateSpace(spaceId, { title })
      await updateStore(spaceId, { title })
      await NotificationManager.success('The group has been renamed', '')
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error)
      NotificationManager.error('There was an error renaming this group', '')
    }
    this.close()
  }

  render() {
    const { title } = this.props
    const { open } = this.state
    return (
      <Modal
        size="tiny"
        className="info"
        closeIcon
        open={open}
        closeOnEscape={true}
        closeOnRootNodeClick={false}
        onClose={this.close}
      >
        <Modal.Header>Rename conversation</Modal.Header>
        <Modal.Content>
          <p>{title}</p>
          <Form>
            <Form.Input
              name="rename"
              type="text"
              required
              placeholder="New title"
              onChange={(event_) => this.setState({ title: event_.target.value })}
            />
            <Modal.Actions>
              <Button floated="right" name="rename" onClick={this.onRename}>
                Rename
              </Button>
            </Modal.Actions>
          </Form>
        </Modal.Content>
      </Modal>
    )
  }
}

RenameGroup.propTypes = {
  spaceId: PropTypes.string,
  title: PropTypes.string,
  updateStore: PropTypes.func,
}

const mapStateToProperties = (state, ownProperties) => {
  const space = ownProperties.space
  if (!space) return {}

  return {
    spaceId: space.id,
    title: getSpaceTitle(state, { space }),
  }
}

const mapDispatchToProperties = {
  updateStore,
}

const connector = (container) =>
  connect(
    mapStateToProperties,
    mapDispatchToProperties,
    null,
    // eslint-disable-next-line unicorn/prevent-abbreviations
    { forwardRef: true },
  )(container)

export default connector(RenameGroup)
