import React, { Component, useState, useEffect } from 'react'
import { Header, List, Button, Divider, Message, Icon, Grid } from 'semantic-ui-react'
import { NotificationManager } from 'react-notifications'
import { withRouter } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { connect } from 'react-redux'
import { compose } from 'recompose'
import PropTypes from 'prop-types'
import * as Sentry from '@sentry/browser'

import { crypho } from '../../crypho.core/xmpp'
import store from '../../crypho.core/store'
import { fetchSpaceMetadata } from '../../crypho.core/store/modules/space'
import { createActionItem } from '../../crypho.core/infostream'
import Topbar from '../../UI/Topbar'

export class BaseIncomingInvitation extends Component {
  state = { processing: false }

  accept = async () => {
    this.setState({ processing: true })
    const { history } = this.props
    let spaceId = null

    // Subscribe to the store so that we can fetch space meta
    // and post an action item.
    let actionItemCreated = false
    const unsubscribe = store.subscribe(async () => {
      const state = store.getState()
      if (state.spaces.allIds.includes(spaceId) && !actionItemCreated) {
        actionItemCreated = true
        unsubscribe()
        await createActionItem({}, 'spaceCreated', spaceId)
        history.push(`/contacts/${spaceId}`)
      }
    })

    try {
      spaceId = await crypho.acceptInvitation(this.props.invitation.uid)
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error)
      this.setState({ processing: false })
      NotificationManager.error('There was an error accepting the invitation.')
      return
    }
  }

  reject = async () => {
    const { invitation } = this.props
    this.setState({ processing: true })

    try {
      await crypho.rejectInvitation(invitation.uid)
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error)
      this.setState({ processing: false })
      NotificationManager.error('There was an error rejecting the invitation.')
    }

    this.setState({ processing: false })
  }
}

BaseIncomingInvitation.propTypes = {
  history: PropTypes.shape().isRequired,
  invitation: PropTypes.shape().isRequired,
}

class IncomingInvitation extends BaseIncomingInvitation {
  render() {
    const { from_name, from_email, message } = this.props.invitation
    const { processing } = this.state
    return (
      <List.Item>
        <List.Content floated="right" verticalAlign="middle">
          <Button
            color="green"
            icon="check"
            className="c-acceptInvitation"
            onClick={this.accept}
            disabled={processing}
            title="Accept invitation"
          />
          <Button
            color="red"
            icon="remove"
            className="c-rejectInvitation"
            title="Reject invitation"
            onClick={this.reject}
            disabled={processing}
          />
        </List.Content>
        <List.Content>
          <List.Header>
            {`${from_name} (${from_email}) wants to be your
            contact`}
          </List.Header>
          <List.Description>{`${message}`}</List.Description>
        </List.Content>
        <Divider />
      </List.Item>
    )
  }
}

class OutgoingInvitation extends Component {
  state = { processing: false }

  retract = async () => {
    const { invitation } = this.props
    this.setState({ processing: true })
    try {
      await crypho.retractInvitation(invitation.uid)
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error)
      NotificationManager.error('There was an error cancelling the invitation.')
      this.setState({ processing: false })
    }
  }

  render() {
    const { invitee, message } = this.props.invitation
    const { processing } = this.state
    return (
      <List.Item>
        <List.Content floated="right" verticalAlign="middle">
          <Button
            color="red"
            circular
            icon="remove"
            title="Cancel invitation"
            onClick={this.retract}
            disabled={processing}
          />
        </List.Content>
        <List.Content>
          <List.Header>{`You have invited ${invitee} `}</List.Header>
          {message.length > 0 ? <List.Description>{`${message}`}</List.Description> : null}
        </List.Content>
        <Divider />
      </List.Item>
    )
  }
}

OutgoingInvitation.propTypes = {
  invitation: PropTypes.shape(),
}

const InvitationScene = ({ incomingInvitations, outgoingInvitations, history, fetchSpaceMetadata }) => {
  const [openInvitationLink, setOpenInvitationLink] = useState('')
  const apiUrl = useSelector((state) => state.config.apiUrl)

  useEffect(() => {
    if (!openInvitationLink) {
      fetch(`${apiUrl}/open-invitation-token`, {
        method: 'GET',
        credentials: 'include',
      })
        .then((r) => r.json())
        .then((json) => {
          setOpenInvitationLink(json.link)
          return
        })
        .catch((error) => {
          Sentry.captureException(error)
          // eslint-disable-next-line no-console
          console.error(error)
        })
    }
  })

  const hasClipboard = navigator.clipboard && navigator.clipboard.writeText

  return (
    <div id="invitations-scene" className="authenticatedScene ">
      <Topbar />
      <div className="accountOptions">
        <Header as="h3" className="invitationType">
          Open invitation link
        </Header>
        <Message warning className="invitationToken">
          <p>
            You can share this link for people to contact you in Crypho. For example on your website or in your email
            signature. When they join, they will automatically be invited to become your contact in Crypho.
          </p>
          <Divider />
          <Grid columns={2}>
            <Grid.Row>
              <Grid.Column width={12}>
                <span id="c-open-invitation-link" className="copyLink">
                  {openInvitationLink}
                </span>
              </Grid.Column>
              {hasClipboard ? (
                <Grid.Column width={4}>
                  <Button
                    content="Copy"
                    className="copyButton"
                    icon={<Icon name="copy" />}
                    labelPosition="left"
                    floated="right"
                    onClick={async () => {
                      await navigator.clipboard.writeText(openInvitationLink)
                      NotificationManager.info('Link copied to the clipboard')
                    }}
                  />
                </Grid.Column>
              ) : null}
            </Grid.Row>
          </Grid>
        </Message>
        {(incomingInvitations.length === 0) & (outgoingInvitations.length === 0) ? (
          <div className="pageInfo">
            <h3 className="empty">You have no invitations.</h3>
          </div>
        ) : null}

        {incomingInvitations.length !== 0 ? (
          <div>
            <Header as="h3" className="invitationType">
              Received Invitations
            </Header>
            <List
              id="incomingInvitations"
              items={incomingInvitations.map((invitation) => (
                <IncomingInvitation
                  key={invitation.uid}
                  invitation={invitation}
                  fetchSpaceMetadata={fetchSpaceMetadata}
                  history={history}
                />
              ))}
            />
          </div>
        ) : null}
        {outgoingInvitations.length !== 0 ? (
          <div>
            <Header as="h3" className="invitationType">
              Sent Invitations
            </Header>
            <List
              id="outgoingInvitations"
              items={outgoingInvitations.map((invitation) => (
                <OutgoingInvitation key={invitation.uid} invitation={invitation} />
              ))}
            />
          </div>
        ) : null}
      </div>
    </div>
  )
}

InvitationScene.propTypes = {
  fetchSpaceMetadata: PropTypes.func,
  history: PropTypes.shape(),
  incomingInvitations: PropTypes.array,
  outgoingInvitations: PropTypes.array,
}

const mapStateToProperties = (state) => ({
  incomingInvitations: state.invitations.received,
  outgoingInvitations: state.invitations.sent,
})

const mapDispatchToProperties = { fetchSpaceMetadata }

const connector = (container) => compose(withRouter, connect(mapStateToProperties, mapDispatchToProperties))(container)

export default connector(InvitationScene)
