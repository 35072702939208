export const mimeTypeToIcon = (mimeType) => {
  const type = mimeType.split('/')
  const mediaType = type[0]
  const subtype = type[1]

  switch (mediaType) {
    case 'image':
      if (subtype === 'svg+xml') return 'vector'
      return 'images'
    case 'audio':
      return 'audio'
    case 'video':
      return 'video'
    case 'text':
      if (['html', 'css', 'x-java', 'x-java-source', 'x-python', 'x-script.phyton'].includes(subtype)) return 'code'
      if (['richtext', 'rtf'].includes(subtype)) return 'document'
      return 'text'
    case 'application':
      if (subtype === 'pdf') return 'pdf'
      if (['msword', 'x-iwork-pages-sffpages', 'vnd.oasis.opendocument.text', 'rtf', 'x-rtf'].includes(subtype))
        return 'document'
      if (['x-compressed', 'x-zip-compressed', 'zip', 'x-gzip', 'x-tar', 'x-rar-compressed'].includes(subtype))
        return 'archive'
      if (
        [
          'vnd.openxmlformats-officedocument.spreadsheetml.sheet',
          'excel',
          'x-excel',
          'x-msexcel',
          'vnd.ms-excel',
          'x-msexcel',
          'x-iwork-numbers-sffnumbers',
        ].includes(subtype)
      )
        return 'spreadsheet'
      if (
        [
          'vnd.openxmlformats-officedocument.presentationml.presentation',
          'mspowerpoint',
          'vnd.ms-powerpoint',
          'powerpoint',
          'x-mspowerpoint',
          'x-iwork-keynote-sffkey',
          'key',
        ].includes(subtype)
      )
        return 'presentation'
      if (['javascript', 'ecmascript', 'x-ecmascript', 'x-javascript', 'json', 'xml'].includes(subtype)) return 'code'
      if (subtype === 'postscript') return 'vector'
      return 'system'
    default:
      return 'default'
  }
}
