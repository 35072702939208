import { sanitizeFilename, decryptStream } from './generic'
const pathToFileURL = window.require('file-url')
const fs = window.require('fs')
const path = window.require('path')
const { remote } = require('electron')
/** @typedef {import('../../crypto/sjcl').Task} Task */
/** @typedef {import('.generic/FileItem} FileItem */

/**
 * Download and save a file using.
 *
 * @param {CryphoAPI} api CryphoAPI instance
 * @param {string} siteUrl Base URL for the website
 * @param {string} spaceId Space id where file must be downloaded from
 * @param {FileItem} item Item to download
 * @param {Task} decryptTask Decryption task
 * @returns {Promise<string>}
 */
export async function downloadFile(api, siteUrl, spaceId, item, decryptTask) {
  const response = await fetch(`${api.baseUrl}/vault/${spaceId}/${item.uid}`, {
    credentials: 'include',
  })

  const decryptor = new decryptStream(response.body, decryptTask)
  const [filename, outputFd] = createOutputFd(item)

  // XXX Use url.pathToFileURL if we upgrade to a recent node
  const fileUrl = pathToFileURL(filename)

  const reader = decryptor.getReader()

  return new Promise((resolve) => {
    const pump = async () => {
      const { value, done } = await reader.read()
      if (done) {
        fs.close(outputFd, () => resolve(fileUrl))
      } else {
        fs.write(outputFd, value, pump)
      }
    }

    pump()
  })
}

/**
 * Generate an output file with a new filename to store a download
 *
 * @param {FileItem} item
 * @returns {[string, number]}
 */
function createOutputFd(item) {
  const targetPath = path.join(remote.app.getPath('downloads'), sanitizeFilename(item.name))
  const parts = path.parse(targetPath)
  delete parts.base // This fucks up path.format

  let index = 0
  // eslint-disable-next-line no-constant-condition
  while (true) {
    const p = { ...parts }
    if (index) {
      p.name = `${parts.name} (${index})`
    }
    const fn = path.format(p)
    try {
      const fd = fs.openSync(fn, 'wx')
      return [fn, fd]
    } catch (error) {
      if (error.code !== 'EEXIST') {
        // eslint-disable-next-line no-console
        console.error('Error creating output file', error)
        throw error
      }
    }
    index += 1
  }
}
