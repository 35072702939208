import dotProp from 'dot-prop-immutable'
import { LOAD_VCARD, LOAD_VCARD_COMPLETE, ADD_VCARD, DELETE_VCARD, UPDATE_VCARDS } from './constants'

export default function vcards(
  state = {
    byId: {},
    loading: {},
  },
  action,
) {
  switch (action.type) {
    case LOAD_VCARD:
      return dotProp.set(state, `loading.${action.payload}`, true)

    case LOAD_VCARD_COMPLETE:
      return dotProp.delete(state, `loading.${action.payload}`)

    case ADD_VCARD: {
      const { jid, vCard } = action
      const id = jid.split('@')[0]
      return dotProp.set(state, `byId.${id}`, vCard)
    }

    case DELETE_VCARD: {
      const { id } = action
      return dotProp.delete(state, `byId.${id}`)
    }

    case UPDATE_VCARDS: {
      const { data } = action
      return dotProp.merge(state, `byId`, data)
    }

    default:
      return state
  }
}
