import React from 'react'
import PropTypes from 'prop-types'
import { Grid } from 'semantic-ui-react'
import Linkify from 'linkifyjs/react'
import { isEmojiLine, toEmoji } from '../../../../crypho.core/utils'
import { allowLink } from '../../../../utils'
import ActionWrapper from './ActionWrapper'
import { deleteMessage, splitMessage } from './utils'

function ChatMessageItem({ id, item, spaceId, isDeleting, showFullName, authorVCard, content }) {
  let bubbleClass, richContent
  if (isEmojiLine(content)) {
    richContent = <div className="emojiLine">{toEmoji(content)}</div>
    bubbleClass = 'emojiBubble'
  } else {
    bubbleClass = 'chatBubble'
    const messages = splitMessage(content)
    richContent = messages.map((msg, ix) =>
      msg.type === 'code' ? (
        <code key={ix}>{msg.content}</code>
      ) : (
        <React.Fragment key={ix}>{toEmoji(msg.content)}</React.Fragment>
      ),
    )
  }

  return (
    <ActionWrapper
      className={bubbleClass}
      onDelete={() => deleteMessage(spaceId, id, item, spaceId)}
      isDeleting={isDeleting}
    >
      <div className="bubbleText">
        <Linkify
          tagName={React.Fragment}
          options={{
            attributes: {
              rel: 'nofollow noopener noreferrer',
            },
            nl2br: true,
            validate: allowLink,
          }}
        >
          {showFullName ? <Grid.Row className="authorName">{authorVCard.FN}</Grid.Row> : null}
          {richContent}
        </Linkify>
      </div>
    </ActionWrapper>
  )
}

ChatMessageItem.propTypes = {
  id: PropTypes.string.isRequired,
  isDeleting: PropTypes.bool.isRequired,
  showFullName: PropTypes.bool.isRequired,
  authorVCard: PropTypes.shape({
    FN: PropTypes.string.isRequired,
  }).isRequired,
  content: PropTypes.string.isRequired,
  spaceId: PropTypes.string.isRequired,
  item: PropTypes.shape({
    author: PropTypes.string.isRequired,
    created: PropTypes.any.isRequired,
  }),
}

export default ChatMessageItem
