import PropTypes from 'prop-types'
import React from 'react'
import { Button } from 'semantic-ui-react'
import { Form } from 'formsy-semantic-ui-react'
import classes from './AddAccountAdmin.module.css'
import { errorLabel } from '../../UI/forms'

const AddAdmin = ({ onCancel, onSave }) => {
  const submitHandler = (data) => {
    onSave(data)
  }

  return (
    <Form onValidSubmit={submitHandler} className={classes.form}>
      <Form.Input
        name="email"
        type="email"
        placeholder="Email"
        required
        validations="isEmail"
        errorLabel={errorLabel}
        validationErrors={{
          isEmail: 'This is not a valid email',
          isDefaultRequiredValue: 'Email is required',
        }}
      />
      <Button name="save" className="settingsButton" type="submit">
        Save
      </Button>
      <Button name="cancel" className={`settingsButton ${classes['cancel-button']}`} onClick={onCancel}>
        Cancel
      </Button>
    </Form>
  )
}

AddAdmin.propTypes = {
  onCancel: PropTypes.func,
  onSave: PropTypes.func,
}

export default AddAdmin
