import dotProp from 'dot-prop-immutable'

const SET_AWAY_STATUS = 'SET_AWAY_STATUS'
const SET_STATE_LOADED_STATUS = 'SET_STATE_LOADED_STATUS'
const SET_LATEST_VERSION = 'SET_LATEST_VERSION'

export default function app(
  state = {
    away: false,
    stateLoaded: false,
    latestVersion: null,
  },
  action,
) {
  switch (action.type) {
    case SET_AWAY_STATUS: {
      const { status } = action
      return dotProp.set(state, 'away', status)
    }

    case SET_STATE_LOADED_STATUS: {
      const { status } = action
      return dotProp.set(state, 'stateLoaded', status)
    }

    case SET_LATEST_VERSION: {
      const { version } = action
      return dotProp.set(state, 'latestVersion', version)
    }

    default:
      return state
  }
}

export const setAwayStatus = (status) => ({
  type: SET_AWAY_STATUS,
  status,
})

export const setLoadedStateStatus = (status) => ({
  type: SET_STATE_LOADED_STATUS,
  status,
})

export const setLatestVersion = (version) => ({
  type: SET_LATEST_VERSION,
  version,
})
