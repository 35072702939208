import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { Popup, Icon } from 'semantic-ui-react'

export const colorPallette = ['#EC5f67', '#F99157', '#FAC863', '#99C794', '#5FB3B3', '#6699CC', '#C594C5', '#AB7967']

export const defaultColor = 3

export class ColorSelect extends PureComponent {
  render() {
    const { onUpdate, selected } = this.props
    const color = selected !== null ? selected : defaultColor

    return (
      <div className="colorSelect">
        <label className={`color0 ${color === 0 && 'selected'}`} name="space-color-0" onClick={() => onUpdate(0)} />
        <label className={`color1 ${color === 1 && 'selected'}`} name="space-color-1" onClick={() => onUpdate(1)} />
        <label className={`color2 ${color === 2 && 'selected'}`} name="space-color-2" onClick={() => onUpdate(2)} />
        <label className={`color3 ${color === 3 && 'selected'}`} name="space-color-3" onClick={() => onUpdate(null)} />
        <label className={`color4 ${color === 4 && 'selected'}`} name="space-color-4" onClick={() => onUpdate(4)} />
        <label className={`color5 ${color === 5 && 'selected'}`} name="space-color-5" onClick={() => onUpdate(5)} />
        <label className={`color6 ${color === 6 && 'selected'}`} name="space-color-6" onClick={() => onUpdate(6)} />
        <label className={`color7 ${color === 7 && 'selected'}`} name="space-color-7" onClick={() => onUpdate(7)} />
      </div>
    )
  }
}

ColorSelect.propTypes = {
  onUpdate: PropTypes.func,
  selected: PropTypes.number,
}

export class ColorFilter extends PureComponent {
  render() {
    const { onUpdate, selected } = this.props
    const colorIndex = selected !== null ? selected : defaultColor
    const color = selected === -1 ? '#777777' : colorPallette[colorIndex]

    return (
      <Popup
        basic
        on="click"
        hoverable
        position="bottom center"
        trigger={
          <Icon
            style={{ color }}
            link
            title="Filter by color"
            name="filter"
            className="colorFilterIcon"
            onClick={(ev) => {
              ev.preventDefault()
            }}
          />
        }
      >
        <ColorSelect selected={selected} onUpdate={onUpdate} />
      </Popup>
    )
  }
}

ColorFilter.propTypes = {
  onUpdate: PropTypes.func,
  selected: PropTypes.number,
}
