import React from 'react'
import { withRouter } from 'react-router-dom'
import { List, Grid, Button } from 'semantic-ui-react'

import { BaseIncomingInvitation } from '.'

class InvitationListItem extends BaseIncomingInvitation {
  render() {
    const { invitation } = this.props
    const { processing } = this.state

    return (
      <List.Item>
        <Grid>
          <Grid.Column width={11} className="invitationHeader">
            <List.Header>{`Invitation from ${invitation.from_name}`}</List.Header>
            <List.Description>{`${invitation.from_email}`}</List.Description>
          </Grid.Column>
          <Grid.Column floated="right" width={5}>
            <Button
              color="green"
              circular
              icon="check"
              className="c-acceptInvitation"
              onClick={this.accept}
              disabled={processing}
              title="Accept invitation"
            />
            <Button
              color="red"
              circular
              icon="remove"
              className="c-rejectInvitation"
              title="Reject invitation"
              onClick={this.reject}
              disabled={processing}
            />
          </Grid.Column>
        </Grid>
        {invitation.message ? <List.Content className="invitationMessage">{invitation.message}</List.Content> : null}
      </List.Item>
    )
  }
}

export default withRouter(InvitationListItem)
