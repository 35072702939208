const SHOW_SPINNER = 'SHOW_SPINNER'
const HIDE_SPINNER = 'HIDE_SPINNER'

export default function spinner(
  state = {
    visible: false,
    text: '',
  },
  action,
) {
  switch (action.type) {
    case SHOW_SPINNER: {
      const { text } = action
      return { visible: true, text }
    }

    case HIDE_SPINNER: {
      return { visible: false }
    }

    default:
      return state
  }
}

export const showSpinner = (text) => ({
  type: SHOW_SPINNER,
  text,
})

export const hideSpinner = () => ({
  type: HIDE_SPINNER,
})
