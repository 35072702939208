import parseFilePath from 'parse-filepath'

/**
 * List of reserved filenames on windows.
 *
 * This is based on https://docs.microsoft.com/en-gb/windows/desktop/FileIO/naming-a-file
 */
export const windowsReservedNames = [
  'CON',
  'PRN',
  'AUX',
  'NUL',
  'COM1',
  'COM2',
  'COM3',
  'COM4',
  'COM5',
  'COM6',
  'COM7',
  'COM8',
  'COM9',
  'LPT1',
  'LPT2',
  'LPT3',
  'LPT4',
  'LPT5',
  'LPT6',
  'LPT7',
  'LPT8',
  'LPT9',
]

/**
 * @typedef {Object} FileItem - a downloadable file
 * @property {string} author - XMPP identifier for the author
 * @property {string} created - creation timestamp in RFC3339 format
 * @property {string} fkid - Encryption key identifier
 * @property {string} name - filename (without path)
 * @property {{iv: string, adata: string, ocb2: boolean}} adata - AES additional data (base64 encoded)
 * @property {number} size - filesize in bytes
 * @property {string} type - MIME type
 * @property {string} uid - unique identifier for the file
 * @property {string} updated - last modification timestamp in RFC3339 format
 */

/**
 * Filesize above which we must use streaming downloads to prevent
 * out-of-memory errors.
 */
export const MUST_STREAM_FROM_SIZE = 2 ** 20 * 100 // 5 megabytes

export class FileTooLarge extends Error {
  constructor(message) {
    super(message)
    this.downloadTooLarge = true
  }
}

/**
 * Sanitise a received filename.
 *
 *
 * @param {string} filename
 * @returns {string} Safe filename
 */
export function sanitizeFilename(filename) {
  const fp = parseFilePath(filename)
  let safePath = fp.base.trim()

  if (windowsReservedNames.includes(fp.name.toUpperCase())) {
    safePath = `_${safePath}`
  }

  // Remove characters that are not allowed in windows filenames
  safePath = safePath.replace(/["*/:<>?\\|]/g, '')

  // Windows shell and user interface do not support names ending in a
  // dot or space.
  safePath = safePath.replace(/\.+$/, '')

  // Replace leading dots to prevent creation of config files
  safePath = safePath.replace(/^\.*/, '')

  return safePath
}

/**
 * Download and decrypt a file, and return it as a blob
 *
 * @param {CryphoAPI} api CryphoAPI instance
 * @param {string} spaceId Space id where file must be downloaded from
 * @param {FileItem} item Item to download
 * @param {Task} decryptTask Decryption task
 * @returns {Promise<Blob>}
 */
export async function downloadAsBlob(api, spaceId, item, decryptTask) {
  if (item.size > MUST_STREAM_FROM_SIZE) {
    throw new FileTooLarge('too-large')
  }
  const response = await fetch(`${api.baseUrl}/vault/${spaceId}/${item.uid}`, {
    credentials: 'include',
  })
  return responseToBlob(response, item, decryptTask)
}

export async function responseToBlob(response, item, decryptTask) {
  const readStream = decryptStream(response.body, decryptTask)
  const decryptedResponse = new Response(readStream)
  return decryptedResponse.blob()
}

/**
 *
 * @param {ReadableStream} stream
 * @param {Task} decryptTask
 * @returns {ReadableStream<any>}
 */
export function decryptStream(stream, decryptTask, readableStreamPony) {
  const reader = stream.getReader()
  const streamFactory = readableStreamPony || ReadableStream

  return new streamFactory({
    pull(controller) {
      return reader.read().then(({ done, value }) => {
        /* eslint-disable promise/always-return */
        /* eslint-disable promise/no-nesting */
        if (done) {
          return decryptTask.finish().then((data) => {
            controller.enqueue(data)
            controller.close()
          })
        } else {
          return decryptTask
            .update(value)
            .then((data) => {
              controller.enqueue(data)
            })
            .catch((error) => {
              // eslint-disable-next-line no-console
              console.error('Error processing download stream', error)
            })
        }
      })
    },
  })
}
