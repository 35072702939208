import { setIdentityEmail, setScryptSalt, setPhone } from '../crypho.core/store/modules/identity'

export const fetchIdentity = () => {
  return async (dispatch) => {
    const identity = JSON.parse(sessionStorage.getItem('identity'))
    const { email, scryptSalt, phone } = identity
    dispatch(setIdentityEmail(email))
    dispatch(setScryptSalt(scryptSalt))
    dispatch(setPhone(phone.local, phone.country))
  }
}

export function saveIdentity() {
  return (dispatch, getState) => {
    const identity = getState().identity
    sessionStorage.setItem('identity', JSON.stringify(identity))
    return Promise.resolve()
  }
}
