import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { NavLink } from 'react-router-dom'
import { connect } from 'react-redux'
import { compose } from 'recompose'
import { Grid, Image, Icon, Label, Popup, Item } from 'semantic-ui-react'
import moment from 'moment'
import { withRouter } from 'react-router-dom'
import { crypho } from '../../crypho.core/xmpp'

import {
  getSpaceMembersUserIds,
  getSpaceTitle,
  getSpaceCompany,
  getSpaceOnlineMembersUserIds,
} from '../../crypho.core/store/modules/space/selectors'
import ChangeSpaceColor from './changecolor'
import { defaultColor, colorPallette, ColorSelect } from '../../UI/widgets/color'
import './space.css'

import VerifiedIcon from '../../UI/widgets/verifiedicon'
import RosterAvatar from '../../UI/widgets/rosteravatar'

const TODAY = moment(new Date()).format('YYYYMMDD')

class BaseSpaceListItem extends PureComponent {
  lastActivityOn() {
    let { lastActivity } = this.props
    lastActivity = moment(lastActivity)

    if (lastActivity.format('YYYYMMDD') === TODAY) return lastActivity.format('HH:mm')
    else if (moment().year() === lastActivity.year()) return lastActivity.format('MMM DD')
    return lastActivity.format('MMM YYYY')
  }

  render() {
    const { title, space, onlineMemberIds, unread } = this.props
    const { type, id } = space
    const badge = unread ? <Label className="badge">{unread}</Label> : null
    const count = onlineMemberIds.length

    const spaceColor = (
      <React.Fragment>
        <Popup
          basic
          on="click"
          hoverable
          position="right center"
          trigger={
            <Grid.Column
              style={{
                backgroundColor: space.color !== null ? colorPallette[space.color] : colorPallette[defaultColor],
                zIndex: 1,
              }}
              className="spaceColor"
              onClick={(ev) => {
                ev.preventDefault()
              }}
            />
          }
        >
          <ColorSelect
            selected={space.color}
            onUpdate={(color) => {
              crypho.updateSpace(space.id, { color })
            }}
          />
        </Popup>
        <ChangeSpaceColor space={space} />
      </React.Fragment>
    )

    return (
      <Item as={NavLink} to={`/${type === 'contact' ? 'contacts' : 'groups'}/${id}`} className="spaceListItem">
        {spaceColor}
        <Image>{this.avatars()}</Image>
        {unread > 0 ? <Label circular /> : null}
        <Item.Content>
          <Item.Header className="spaceTitle">{title}</Item.Header>
          {space.type === 'contact' && onlineMemberIds.length > 0 ? (
            <Icon color="green" name="circle" size="tiny" title={`${title} is online `} />
          ) : null}
          {space.type === 'group' && onlineMemberIds.length > 0 ? (
            <Icon
              color="green"
              name="circle"
              size="tiny"
              title={
                count === 1
                  ? `${onlineMemberIds.length} other member online `
                  : `${onlineMemberIds.length} other members online `
              }
            />
          ) : null}
          {this.subtitle()}
          <Item.Description>
            {this.lastActivityOn()}
            {badge}
          </Item.Description>
        </Item.Content>
      </Item>
    )
  }
}

class _ContactSpaceListItem extends BaseSpaceListItem {
  subtitle() {
    const { company } = this.props
    return company ? (
      <Item.Description title={company} className="companyName">
        {company}
      </Item.Description>
    ) : null
  }
  avatars() {
    const { spaceMembersUserIds } = this.props
    return (
      <div>
        <VerifiedIcon noPopup noUnverified userId={spaceMembersUserIds[0]} />
        <RosterAvatar noPopup userId={spaceMembersUserIds[0]} />
      </div>
    )
  }
}
class _GroupSpaceListItem extends BaseSpaceListItem {
  subtitle() {
    const { memberIds } = this.props
    const count = memberIds.length
    return (
      <Item.Description>
        {count + 1}
        {count < 1 ? ' member' : ' members'}
      </Item.Description>
    )
  }
  avatars() {
    const { apiUrl, space } = this.props
    return <Image className="spaceAvatar" circular avatar src={`${apiUrl}/identicon/${space.id}`} />
  }
}

BaseSpaceListItem.propTypes = {
  apiUrl: PropTypes.string,
  company: PropTypes.string,
  onlineMemberIds: PropTypes.array,
  space: PropTypes.shape(),
  lastActivity: PropTypes.string,
  spaceMembersUserIds: PropTypes.array,
  title: PropTypes.string,
  memberIds: PropTypes.array,
  unread: PropTypes.number,
}

const mapStateToPropertiesContact = (state, ownProperties) => ({
  apiUrl: state.config.apiUrl,
  unread: state.spaces.unread[ownProperties.space.id],
  spaceMembersUserIds: getSpaceMembersUserIds(state, ownProperties),
  title: getSpaceTitle(state, ownProperties),
  company: getSpaceCompany(state, ownProperties),
  onlineMemberIds: getSpaceOnlineMembersUserIds(state, ownProperties),
  lastActivity: state.spaces.lastActivity[ownProperties.space.id],
})

const mapStateToPropertiesGroup = (state, ownProperties) => ({
  apiUrl: state.config.apiUrl,
  unread: state.spaces.unread[ownProperties.space.id],
  title: getSpaceTitle(state, ownProperties),
  memberIds: getSpaceMembersUserIds(state, ownProperties),
  onlineMemberIds: getSpaceOnlineMembersUserIds(state, ownProperties),
  lastActivity: state.spaces.lastActivity[ownProperties.space.id],
})

const contactSpaceListItemConnector = (container) =>
  compose(withRouter, connect(mapStateToPropertiesContact, null))(container)

const groupSpaceListItemConnector = (container) =>
  compose(withRouter, connect(mapStateToPropertiesGroup, null))(container)

const ContactSpaceListItem = contactSpaceListItemConnector(_ContactSpaceListItem)
const GroupSpaceListItem = groupSpaceListItemConnector(_GroupSpaceListItem)

const SpaceListItem = (properties) => {
  if (properties.space.type === 'contact') return <ContactSpaceListItem {...properties} />
  return <GroupSpaceListItem {...properties} />
}

export default SpaceListItem
