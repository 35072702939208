import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Button, Icon, Message, Header, Loader, Transition } from 'semantic-ui-react'
import { Form } from 'formsy-semantic-ui-react'
import Mnemonic from 'mnemonic.js'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { compose } from 'recompose'

import { setIdentityEmail, setScryptSalt } from '../../crypho.core/store/modules/identity'
import PassphraseStrengthMeter from '../../UI/widgets/passphrasestrength'
import { husher } from '../../crypho.core'
import { saveSession } from '../../session'
import { saveIdentity } from '../../store/identity'
import { errorLabel } from '../../UI/forms'

class RegisterScene extends Component {
  constructor(properties) {
    super(properties)
    const mnemonic = new Mnemonic(32)
    this.state = {
      email: '',
      fullname: '',
      password: '',
      passwordConfirmation: '',
      processing: false,
      showPassword: false,
      statusMessage: '',
      statusMessageType: null,
      examplePassphrase: mnemonic.toWords().join(' '),
    }
  }

  async componentDidMount() {
    const { apiUrl, token, history } = this.props
    try {
      let response = await fetch(`${apiUrl}/register/email-from-token/${token}`)
      const { email } = await response.json()
      this.setState({ email })
      response = await fetch(`${apiUrl}/register/${token}`)
      const { error } = await response.json()
      if (response.status !== 200) {
        if (error === 'already-registered') history.push(`/login?email=${email}`)
        else if (error === 'redirect-twofactor') history.push(`/register/twofactor/${token}`)
      }
    } catch (error) {
      this.setStatusMessage('Connection error', 'error')
    }
  }

  setStatusMessage = (statusMessage, statusMessageType = 'info') => {
    this.setState({ statusMessage: '', statusMessageType })
    this.setState({ statusMessage, statusMessageType })
  }

  resetExamplePassphrase() {
    const mnemonic = new Mnemonic(32)
    this.setState({ examplePassphrase: mnemonic.toWords().join(' ') })
  }

  isValidFullName = ({ fullname }) => {
    const regex = /^[^!#*./:;<>?@\\]+$/
    const isValid = regex.test(fullname)
    return isValid
  }
  onRegister = async () => {
    const { email, password, fullname } = this.state
    const { apiUrl, history, token, setIdentityEmail, setScryptSalt, saveIdentity } = this.props

    this.setState({ processing: true })
    const h = new husher.Husher()
    await h.generate(password, email)
    const authData = h.toJSON(email)

    try {
      const response = await fetch(`${apiUrl}/register/${token}`, {
        method: 'POST',
        credentials: 'include',
        body: JSON.stringify({ authData, fullname }),
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      })
      this.setState({ processing: false })
      if (response.status === 200) {
        global.husher = h
        await setIdentityEmail(email)
        await setScryptSalt(authData.scrypt.scryptSalt)
        saveSession()
        saveIdentity()
        history.push(`/register/twofactor/${token}`)
      } else {
        this.setStatusMessage('There was an error registering your account', 'error')
      }
    } catch (error) {
      this.setState({ processing: false })
      this.setStatusMessage('There was an error registering your account', 'error')
      return
    }
  }

  render() {
    const { email, processing, examplePassphrase, statusMessage, statusMessageType, password } = this.state

    return (
      <div className="darkContainer">
        <div className="cardContainer">
          <div className="cryphoLogotype" />
          <div className="card">
            <div className="formHeader">
              <div className="cardLogo" />
              <Header as="h1">Register 1/2</Header>
            </div>
            <Loader disabled={!processing} />
            <Form onValidSubmit={this.onRegister}>
              <Transition animation="scale" duration={statusMessage ? 1000 : 0} visible={!!statusMessage}>
                <Message className={statusMessageType} content={statusMessage || ''} />
              </Transition>
              <Header as="h4">{`Registering account for ${email}`}</Header>
              <p className="description">
                Your name as others will see it. It is best if you go with your full real name.
              </p>
              <Form.Input
                name="fullname"
                type="text"
                autoComplete="name"
                placeholder="Your name"
                required
                onChange={(event_) => this.setState({ fullname: event_.target.value })}
                validations={{ isValidFullName: this.isValidFullName }}
                errorLabel={errorLabel}
                validationErrors={{
                  isDefaultRequiredValue: 'Your name is required',
                  isValidFullName: 'Special characters such as !, #, *, /, :, ;, <, >, ?, @, and \\ are not allowed.',
                }}
              />

              <Form.Input
                action={
                  <Icon
                    disabled
                    name="eye"
                    title={this.state.showPassword ? 'Hide passphrase' : 'Show passphrase'}
                    onClick={() =>
                      this.setState({
                        showPassword: !this.state.showPassword,
                      })
                    }
                  />
                }
                name="password"
                type={this.state.showPassword ? 'text' : 'password'}
                autoComplete="new-password"
                placeholder="Passphrase"
                required
                value={this.state.password}
                onChange={(event_) => this.setState({ password: event_.target.value })}
                errorLabel={errorLabel}
                validations="isStrongPassphrase"
                validationErrors={{
                  isDefaultRequiredValue: 'Your passphrase is required',
                }}
              />
              <Form.Input
                name="passwordConfirmation"
                type={this.state.showPassword ? 'text' : 'password'}
                autoComplete="new-password"
                placeholder="Repeat passphrase"
                validations="equalsField:password"
                required={true}
                onChange={(event_) => this.setState({ passwordConfirmation: event_.target.value })}
                errorLabel={errorLabel}
                validationErrors={{
                  isDefaultRequiredValue: 'Please repeat your passphrase',
                  equalsField: 'Passphrases do not match',
                }}
              />
              <PassphraseStrengthMeter passphrase={password} />

              <h4 className="examplePassphrase">Example</h4>
              <span>{examplePassphrase}</span>
              <Icon onClick={() => this.resetExamplePassphrase()} className="refresh" />

              <Form.Checkbox
                id="termsCheckbox"
                name="termsofservice"
                validations="isTrue"
                errorLabel={errorLabel}
                validationErrors={{
                  isTrue: "You need to agree to Crypho's terms and conditions",
                }}
                label={
                  <label htmlFor="termsCheckbox">
                    <span> I agree to {''}</span>
                    <a target="blank" className="termsLink" href="https://www.crypho.com/tos/">
                      Crypho’s terms of service
                    </a>
                  </label>
                }
              />
              <Message negative>
                <Message.Header>
                  Please take good care of your passphrase.
                  <br />
                  <br />
                </Message.Header>
                <p>
                  Crypho is different from other services. We can not see, store or recover your passphrase. If you lose
                  your passphrase, you will lose your content.
                </p>
              </Message>
              <Button name="register" className="primaryButton" fluid type="submit" disabled={processing}>
                Next
              </Button>
            </Form>
          </div>
          <a className="whiteTextLink" target="blank" href="https://www.crypho.com/contactsupport/">
            Problems? Click here for help and support.
          </a>
        </div>
      </div>
    )
  }
}

RegisterScene.propTypes = {
  apiUrl: PropTypes.string.isRequired,
  token: PropTypes.string.isRequired,
  history: PropTypes.shape().isRequired,
  setIdentityEmail: PropTypes.func.isRequired,
  setScryptSalt: PropTypes.func.isRequired,
  saveIdentity: PropTypes.func.isRequired,
}
const mapStateToProperties = (state, ownProperties) => {
  const token = ownProperties.match.params.token
  return {
    token,
    apiUrl: state.config.apiUrl,
  }
}

const mapDispatchToProperties = {
  setIdentityEmail,
  setScryptSalt,
  saveIdentity,
}

const connector = (container) => compose(withRouter, connect(mapStateToProperties, mapDispatchToProperties))(container)

export default connector(RegisterScene)
