import PropTypes from 'prop-types'
import React, { useRef, useImperativeHandle, forwardRef } from 'react'
import { Button, Grid, Segment, Header } from 'semantic-ui-react'
import { Form } from 'formsy-semantic-ui-react'
import classes from './AddPlan.module.css'
import { errorLabel } from 'UI/forms'
import AddConfirm from './AddConfirm'

const AddPlan = ({ onSave, submitting }, ref) => {
  const formRef = useRef(null)
  const confirmRef = useRef(null)
  let plan = {}

  useImperativeHandle(ref, () => ({
    reset: () => formRef.current.reset(),
  }))

  const submitHandler = (data) => {
    plan = data
    confirmRef.current.open(data.name, 'plan', 'plans')
  }

  const addPlanHandler = () => {
    confirmRef.current.close()
    onSave(plan)
  }

  const cancelHandler = () => {
    confirmRef.current.close()
  }

  return (
    <Segment className={classes.formContainer}>
      <Header as="h3">Create new Plan</Header>
      <Form ref={formRef} onValidSubmit={submitHandler} className={classes.form}>
        {submitting && (
          <div className={classes.loader}>
            <div className="ui active large inverted text loader">Submitting</div>
          </div>
        )}
        <AddConfirm ref={confirmRef} onAdd={addPlanHandler} onCancel={cancelHandler} />
        <Grid columns={3}>
          <Grid.Row>
            <Grid.Column>
              <Form.Input
                label="Title"
                type="text"
                id="title"
                name="title"
                className={classes.formInput}
                required
                validationErrors={{ isDefaultRequiredValue: 'Title is required' }}
                errorLabel={errorLabel}
              />
              <Form.Input
                label="Name"
                type="text"
                id="name"
                name="name"
                className={classes.formInput}
                required
                validationErrors={{ isDefaultRequiredValue: 'Name is required' }}
                errorLabel={errorLabel}
              />
              <Form.Input
                label="Group Limit"
                name="group_limit"
                type="number"
                min={0}
                id="group_limit"
                className={classes.formInput}
                required
                validationErrors={{
                  isDefaultRequiredValue: 'Group Limit is required',
                }}
                errorLabel={errorLabel}
              />
            </Grid.Column>
            <Grid.Column>
              <Form.Input
                label="Interval"
                name="interval"
                type="text"
                id="interval"
                className={classes.formInput}
                errorLabel={errorLabel}
              />
              <Form.Input
                label="Member Limit"
                name="member_limit"
                type="number"
                min={0}
                id="member_limit"
                className={classes.formInput}
                required
                validationErrors={{
                  isDefaultRequiredValue: 'Member Limit is required',
                }}
                errorLabel={errorLabel}
              />
              <Form.Input
                label="File Size Limit"
                name="filesize_limit"
                type="number"
                min={0}
                id="filesize_limit"
                className={classes.formInput}
                required
                validationErrors={{
                  isDefaultRequiredValue: 'File Size Limit is required',
                }}
                errorLabel={errorLabel}
              />
            </Grid.Column>
            <Grid.Column>
              <Form.TextArea
                label="Description"
                type="text"
                id="description"
                name="description"
                className={classes.formInput}
                rows={3}
                required
                validationErrors={{ isDefaultRequiredValue: 'description is required' }}
                errorLabel={errorLabel}
              />
              <Form.Checkbox name="special_offer" className={classes.formInput} label="Special Offer" />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column>
              <Button name="save" className={`settingsButton ${classes.saveButton}`} type="submit">
                Save
              </Button>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Form>
    </Segment>
  )
}

AddPlan.propTypes = {
  onSave: PropTypes.func,
  submitting: PropTypes.bool,
}

export default forwardRef(AddPlan)
