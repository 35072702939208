import React, { forwardRef, useImperativeHandle, useState } from 'react'
import PropTypes from 'prop-types'
import { Confirm } from 'semantic-ui-react'

const SystemAdminRemoveConfirm = ({ onCancel, onDelete }, ref) => {
  const [open, setOpen] = useState(false)
  const [fullName, SetFullName] = useState('')

  useImperativeHandle(ref, () => ({
    open: (fullName) => {
      SetFullName(fullName)
      setOpen(true)
    },
    close: () => setOpen(false),
  }))

  return (
    <Confirm
      size="mini"
      className="destructive"
      closeIcon
      open={open}
      closeOnRootNodeClick={false}
      content={[
        <h5 key="p1">{`${fullName}`}</h5>,
        <p key="p2">will be removed from system admins.</p>,
        <p key="p3">Are you sure?</p>,
      ]}
      header={`Delete System Admin`}
      confirmButton="Delete"
      cancelButton={null}
      onCancel={onCancel}
      onConfirm={onDelete}
    />
  )
}

SystemAdminRemoveConfirm.propTypes = {
  onCancel: PropTypes.func,
  onDelete: PropTypes.func,
}

export default forwardRef(SystemAdminRemoveConfirm)
