import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Button, Header, Message, Item, Modal, Icon } from 'semantic-ui-react'
import moment from 'moment'
import { connect } from 'react-redux'
import { crypho } from '../../crypho.core/xmpp'
import QRCode from 'qrcode.react'
import Mnemonic from 'mnemonic.js'
import { husher } from '../../crypho.core'
import { NotificationManager } from 'react-notifications'
import {
  isExternallyVerified,
  externallyVerifiedFullname,
  externallyVerifiedBirthDate,
} from '../../crypho.core/store/modules/roster/selectors'
import Topbar from '../../UI/Topbar'

class Verification extends Component {
  constructor(properties) {
    super(properties)
  }

  componentDidMount = async () => {
    crypho.on('user-verification', this.onVerificationUpdate)
    const { location } = this.props
    const queryParams = new URLSearchParams(location.search)
    if (queryParams.get('code')) {
      this.signicatVerifyCallback(this.props.location.search)
      this.props.history.push('/verification')
    }
  }

  componentWillUnmount() {
    crypho.removeListener('user-verification', this.onVerificationUpdate)
  }

  onVerificationUpdate = (message) => {
    if (message.error) {
      NotificationManager.error('Verification did not complete.')
    } else {
      NotificationManager.success(`You have successfully verified yourself as ${message.name}.`)
    }
  }

  signicatVerify = async (method) => {
    const { apiUrl } = this.props
    try {
      let response = await fetch(`${apiUrl}/user-verification/url`, {
        method: 'POST',
        body: JSON.stringify({ method, 'app-type': 'web' }),
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        credentials: 'include',
      })
      if (!response.ok) {
        const error = await response.text()
        throw error
      }
      response = await response.json()
      window.location = response.url
    } catch (error) {
      const json = JSON.parse(error)
      switch (json.error) {
        case 'already-verified':
          NotificationManager.error(`You are already verified with ${method}.`)
          break
        default:
          NotificationManager.error(`There was an error in verifying you.`)
          break
      }
    }
  }

  signicatVerifyCallback = async (queryString) => {
    const { apiUrl } = this.props
    try {
      let response = await fetch(`${apiUrl}/user-verification/redirect${queryString}`, {
        method: 'GET',
      })
      if (!response.ok) {
        const error = await response.text()
        throw error
      }
    } catch (error) {
      try {
        const json = JSON.parse(error)
        NotificationManager.error(`There was an error in verifying you ${json.error}.`)
      } catch {
        NotificationManager.error(`There was an error in verifying you.`)
      }
    }
  }

  removeBankId = async () => {
    try {
      await crypho.clearUserVerifications()
    } catch (error) {
      NotificationManager.error(`There was an error clearing your verifications.`)
    }
  }

  render() {
    const { vCard, isExternallyVerified, externallyVerifiedFullname, externallyVerifiedBirthDate } = this.props
    if (!vCard) return null
    const fingerprint = husher._hex.fromBits(global.husher.fingerprint()).slice(0, 16)
    const words = Mnemonic.fromHex(fingerprint).toWords().join(' ')
    return (
      <div className="authenticatedScene">
        <Topbar />
        <div className="accountOptions">
          <Header as="h3">Identity verification</Header>
          <p>Prove your identity to your contacts. This way they can know they are talking to the right person.</p>
          <React.Fragment>
            <Item.Group divided className="bankIdSection">
              <Item
                as={!isExternallyVerified ? 'a' : ''}
                onClick={() => {
                  !isExternallyVerified && this.signicatVerify('bankid')
                }}
              >
                <Item.Image
                  className="bankID"
                  label={
                    isExternallyVerified
                      ? {
                          icon: <Icon size="mini" className="verifiedIcon" />,
                          floating: true,
                          className: 'verifiedLabel',
                        }
                      : null
                  }
                />
                <Item.Content>
                  <Item.Header>
                    {isExternallyVerified ? 'Verified with BankID (Norway)' : 'BankID (Norway)'}
                  </Item.Header>
                  <Item.Description>
                    {isExternallyVerified ? (
                      <React.Fragment>
                        <p>
                          You have been verified as <b>{`${externallyVerifiedFullname}`}</b> born on{' '}
                          <b>{`${moment(externallyVerifiedBirthDate).format('MMMM Do YYYY')}`}</b>.
                        </p>
                        <p>All your contacts can see your identity when they chat with you.</p>
                        <Button floated="right" negative onClick={this.removeBankId}>
                          Remove BankID verification
                        </Button>
                      </React.Fragment>
                    ) : (
                      <p>
                        Verify your identity with BankID. Your real name and date of birth will be visible to your
                        contacts.
                      </p>
                    )}
                  </Item.Description>
                </Item.Content>
              </Item>
              <Modal
                closeIcon
                trigger={
                  <Item as="a">
                    <Item.Image className="fingerprint" />
                    <Item.Content>
                      <Item.Header>Key fingerprint</Item.Header>
                      <Item.Description>
                        <p>Share your key fingerprint with others, by meeting in person or talking on the phone.</p>
                      </Item.Description>
                    </Item.Content>
                  </Item>
                }
              >
                <Modal.Header>Key fingerprint for {vCard.FN}</Modal.Header>
                <Modal.Content>
                  <p>
                    <strong>The six words are your key fingerprint</strong>. They identify your cryptographic keys and
                    are used for contact verification. They can not be used for anything else than indicating trust in
                    you. <strong>You do not have to keep your fingerprint secret.</strong>
                    <br /> <br />
                    Share your fingerprint with your Crypho contacts through an out-of-band channel (i.e not inside
                    Crypho). The best way is meeting in person or talking on the phone. If you meet in person, your
                    contacts can scan the fingerprint QR-code with their mobile app instead of typing.{' '}
                    <strong>The QR-code is equivalent to the 6 words.</strong>
                  </p>
                  <Message compact>
                    <h3 id="c-fingerprint-token" className="fingerprint-token">
                      {words}
                    </h3>
                    <QRCode value={fingerprint} level="H" />
                  </Message>
                </Modal.Content>
              </Modal>
            </Item.Group>
          </React.Fragment>
        </div>
      </div>
    )
  }
}

Verification.propTypes = {
  apiUrl: PropTypes.string.isRequired,
  isExternallyVerified: PropTypes.bool,
  externallyVerifiedFullname: PropTypes.string,
  externallyVerifiedBirthDate: PropTypes.string,
  userId: PropTypes.string.isRequired,
  vCard: PropTypes.shape().isRequired,
  location: PropTypes.object,
  history: PropTypes.object,
}

const mapStateToProperties = (state) => {
  const userId = state.identity.id
  return {
    apiUrl: state.config.apiUrl,
    externallyVerifiedFullname: externallyVerifiedFullname(state, { userId }),
    externallyVerifiedBirthDate: externallyVerifiedBirthDate(state, { userId }),
    isExternallyVerified: isExternallyVerified(state, {
      userId,
    }),
    userId,
    vCard: state.vcards.byId[userId],
  }
}
const connector = (container) => connect(mapStateToProperties)(container)

export default connector(Verification)
