import React, { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import { Icon, Table, Label, Form, Popup } from 'semantic-ui-react'
import { basename, extname } from 'path'
import filesize from 'filesize'
import { fetchUserVCard } from '../../../../crypho.core/store/modules/vcard'
import { mimeTypeToIcon } from '../../../../UI/utils'
import ImageViewer from '../../../../UI/imageviewer'
import { formatTimestamp } from '../../../../utils'
import { deleteFile, downloadAndSaveFile, setFileName } from '../../../../file-handling'

function FileStreamItem({ id, spaceId }) {
  const vaultItem = useSelector((state) => state.pubsub.itemsById[id])
  if (!vaultItem) {
    return null
  }
  const isImage = vaultItem.type.startsWith('image/')
  const dispatch = useDispatch()
  const myJID = useSelector((state) => state.identity.jid)
  const vcards = useSelector((state) => state.vcards.byId)
  const authorId = vaultItem.author.split('@')[0]
  const authorVCard = vcards[authorId]
  const extension = vaultItem.name ? extname(vaultItem.name) : ''
  const [filename, setFilename] = useState(vaultItem.name ? basename(vaultItem.name, extension) : 'unnamed')
  const [isRenaming, setIsRenaming] = useState(false)
  const [isDeleting, setIsDeleting] = useState(false)
  const imageViewerReference = useRef()
  const canOperateOnFile = vaultItem.author === myJID
  const handleDownload = () => downloadAndSaveFile(spaceId, id, vaultItem)
  const handleOpen = isImage ? () => imageViewerReference.current.open() : handleDownload
  const handleDelete = async () => {
    await deleteFile(spaceId, id, vaultItem)
    setIsDeleting(false)
  }
  const handleRename = async () => {
    await setFileName(spaceId, id, vaultItem, `${filename}${extension}`)
    setIsRenaming(false)
  }
  const handleKeyDown = (evt) => {
    if (evt.key === 'Enter') {
      return handleRename()
    }
  }
  useEffect(() => {
    if (!authorVCard) {
      dispatch(fetchUserVCard(authorId))
    }
  }, [authorId])

  if (!authorVCard) {
    return null
  }

  const downloadedByText =
    vaultItem.downloadedBy && vaultItem.downloadedBy.length > 0
      ? vaultItem.downloadedBy
          .map((userId) => (vcards[userId] && vcards[userId].FN) || '')
          .sort()
          .join(', ')
      : ''

  return (
    <Table.Row className={!isDeleting ? null : 'destroyBubble'}>
      {isImage ? <ImageViewer spaceId={spaceId} item={vaultItem} itemId={id} ref={imageViewerReference} /> : null}
      <Table.Cell width={1}>
        <Icon link className={`${mimeTypeToIcon(vaultItem.type)} fileIcons`} onClick={handleOpen} />
      </Table.Cell>
      <Table.Cell width={4} className="fileInfo">
        {isRenaming ? (
          <Form.Input
            icon={<Icon name="check" color="green" link onClick={handleRename} />}
            name="rename"
            type="text"
            placeholder="New file name"
            required
            value={filename}
            onChange={(event) => setFilename(event.target.value)}
            onKeyDown={handleKeyDown}
          />
        ) : (
          <div onClick={handleOpen}>
            {vaultItem.name ? vaultItem.name : 'unnamed'}
            <br /> <span className="description">{formatTimestamp(vaultItem.updated)}</span>
          </div>
        )}
      </Table.Cell>
      <Table.Cell width={2}>
        <span className="description">{authorVCard.FN}</span>
      </Table.Cell>
      <Table.Cell singleLine width={2} textAlign="right">
        {!isDeleting ? (
          <span className="filesize"> {filesize(vaultItem.size)}</span>
        ) : (
          <Label as="a" onClick={handleDelete}>
            Delete <Icon color="red" name="trash" />
          </Label>
        )}
      </Table.Cell>
      <Table.Cell singleLine width={1} className="fileOptions">
        {downloadedByText ? (
          <Popup
            hoverable={true}
            on="click"
            trigger={<Icon id="c-seen" name="eye" title={isImage ? `Viewed by` : `Downloaded by`} />}
            content={isImage ? `Viewed by : ${downloadedByText}` : `Downloaded by: ${downloadedByText}`}
            basic
          />
        ) : null}
        {canOperateOnFile
          ? [
              <div key="deleteAction" onClick={() => setIsDeleting(!isDeleting)}>
                {isDeleting ? (
                  <Icon name="remove" />
                ) : (
                  <Icon key="file-delete" className="c-delete-file" id="c-delete" name="trash" title="Delete file" />
                )}
              </div>,
              <div key="renameAction">
                {isRenaming ? (
                  <Icon
                    name="remove"
                    onClick={() => {
                      setIsRenaming(false)
                    }}
                  />
                ) : (
                  <Icon
                    key="file-rename"
                    className="edit c-rename-file"
                    title="Rename file"
                    onClick={() => setIsRenaming(true)}
                  />
                )}
              </div>,
            ]
          : null}
        <Icon className="download" onClick={handleDownload} title="Download file" />
      </Table.Cell>
    </Table.Row>
  )
}

FileStreamItem.propTypes = {
  id: PropTypes.string.isRequired,
  spaceId: PropTypes.string.isRequired,
}

export default FileStreamItem
