import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import { Icon, Popup, Item } from 'semantic-ui-react'
import moment from 'moment'
import PropTypes from 'prop-types'

import { fetchUserVCard } from '../../crypho.core/store/modules/vcard'
import {
  isFingerprintVerified,
  isExternallyVerified,
  externallyVerifiedFullname,
  externallyVerifiedBirthDate,
} from '../../crypho.core/store/modules/roster/selectors'

class VerifiedIcon extends PureComponent {
  componentDidMount() {
    const { vcard, fetchUserVCard, userId } = this.props
    if (!vcard) {
      fetchUserVCard(userId)
    }
  }

  renderExternalVerification() {
    const { externallyVerifiedBirthDate, externallyVerifiedFullname } = this.props

    return (
      <Item>
        <div className="bankID symbol" />
        <Item.Content>
          Verified with <b>BankID</b> as <b>{` ${externallyVerifiedFullname}`}</b> born on{' '}
          <b>{`${moment(externallyVerifiedBirthDate).format('MMMM Do YYYY')}`}</b>.
        </Item.Content>
      </Item>
    )
  }

  render() {
    const { vcard, isExternallyVerified, isFingerPrintVerified, noPopup, noUnverified } = this.props

    const isVerified = isFingerPrintVerified || isExternallyVerified

    if (!isVerified && noUnverified) return null

    if (noPopup) {
      return isVerified ? <Icon className="verifiedIcon" /> : <Icon className="unverifiedIcon" />
    }

    if (!isVerified) {
      return (
        <Popup hoverable trigger={<Icon className="unverifiedIcon" />} id="unverifiedPopup">
          <Popup.Header>{`${vcard.FN} is not verified`}</Popup.Header>
        </Popup>
      )
    }

    return (
      <Popup wide="very" hoverable trigger={<Icon className="verifiedIcon" />} id="verifiedPopup">
        <Popup.Header>{`${vcard.FN} is verified`}</Popup.Header>
        <Popup.Content>
          <Item.Group>
            {isFingerPrintVerified ? (
              <Item>
                <div className="fingerprint symbol" />
                <Item.Content>{'Verified by you in person.'}</Item.Content>
              </Item>
            ) : null}
            {isExternallyVerified ? this.renderExternalVerification() : null}
          </Item.Group>
        </Popup.Content>
      </Popup>
    )
  }
}

VerifiedIcon.propTypes = {
  externallyVerifiedFullname: PropTypes.string,
  externallyVerifiedBirthDate: PropTypes.string,
  fetchUserVCard: PropTypes.func.isRequired,
  isExternallyVerified: PropTypes.bool.isRequired,
  isFingerPrintVerified: PropTypes.bool.isRequired,
  noPopup: PropTypes.bool,
  noUnverified: PropTypes.bool,
  userId: PropTypes.string.isRequired,
  vcard: PropTypes.shape(),
}

const mapStateToProperties = (state, ownProperties) => {
  const { vcards } = state
  const { userId } = ownProperties
  let vcard = vcards.byId[userId]
  if (!vcard) {
    vcard = {}
  }
  return {
    externallyVerifiedBirthDate: externallyVerifiedBirthDate(state, { userId }),
    externallyVerifiedFullname: externallyVerifiedFullname(state, { userId }),
    isExternallyVerified: isExternallyVerified(state, { userId }),
    isFingerPrintVerified: isFingerprintVerified(state, { userId }),
    vcard,
  }
}

const connector = (container) => connect(mapStateToProperties, { fetchUserVCard })(container)

export default connector(VerifiedIcon)
