import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import * as Sentry from '@sentry/browser'
import { Image, Item } from 'semantic-ui-react'
import { getObjectURLForFile, VaultItemPropType } from '../../../../../file-handling'
import ImageViewer from '../../../../../UI/imageviewer'
import FileWithoutThumbnail from './FileWithoutThumbnail'

function FileWithThumbnail({ showFullName, authorVCard, spaceId, itemId, item }) {
  const [thumbnail, setThumbnail] = useState()
  const imageViewerRef = React.useRef(null)

  useEffect(() => {
    getObjectURLForFile(spaceId, item.fkid, item.thumbnail).then(setThumbnail).catch(Sentry.captureException)
  }, [spaceId, item])

  if (!thumbnail) {
    return (
      <FileWithoutThumbnail
        showFullName={showFullName}
        authorVCard={authorVCard}
        spaceId={spaceId}
        itemId={itemId}
        item={item}
      />
    )
  }

  return (
    <div key="action" className="fileThumbnail">
      {showFullName ? <div className="authorName">{authorVCard.FN}</div> : null}
      <ImageViewer spaceId={spaceId} item={item} itemId={itemId} ref={imageViewerRef} />
      <Image draggable={false} className="thumbnail" src={thumbnail} onClick={() => imageViewerRef.current.open()} />
      <Item className="fileName" title={item.title || item.name}>
        <p>{item.title || item.name}</p>
      </Item>
    </div>
  )
}

FileWithThumbnail.propTypes = {
  showFullName: PropTypes.bool.isRequired,
  authorVCard: PropTypes.object.isRequired,
  spaceId: PropTypes.string.isRequired,
  itemId: PropTypes.string.isRequired,
  item: VaultItemPropType.isRequired,
}

export default FileWithThumbnail
