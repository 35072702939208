/* eslint-disable unicorn/prefer-query-selector */
import React from 'react'
import PropTypes from 'prop-types'
import { Container, Image } from 'semantic-ui-react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { CallContext } from '../../crypho.core/rtc'

import { Button } from 'semantic-ui-react'
import Draggable from 'react-draggable'
import { getCallerName } from './utils'
import './call.css'

class OutgoingCall extends React.Component {
  constructor(props) {
    super(props)
    this.localVideoReference = React.createRef()
  }

  componentDidMount() {
    if (this.context.requestedMedia.video) {
      this.context.setVideoReference(this.localVideoReference, null)
    }
  }

  async componentWillUnmount() {
    if (this.context.requestedMedia.video) {
      this.context.setVideoReference(null, null)
    }
  }

  cancelRing = () => {
    const { cancelRing, targetId } = this.context
    cancelRing(targetId)
  }

  renderVideoView() {
    const { targetId } = this.context
    return (
      <Draggable bounds="parent">
        <Container id="wrtc" style={this.props.containerStyle}>
          <div className="callControls">
            <Button id="cancel-call" circular icon="call" onClick={() => this.cancelRing()} color="red" />
          </div>

          <div className="callHeader">
            Calling <p className="inlineName">{getCallerName(targetId)}…</p>
          </div>
          <video id="remote-video" className="mirrored" ref={this.localVideoReference} autoPlay muted />
        </Container>
      </Draggable>
    )
  }

  renderAudioView() {
    const { targetId } = this.context
    const { apiUrl } = this.props

    return (
      <Draggable bounds="parent">
        <Container
          id="wrtc"
          style={{
            ...this.props.containerStyle,
            backgroundColor: 'white',
          }}
        >
          <div className="callControls">
            <Button id="cancel-call" circular icon="call" onClick={() => this.cancelRing()} color="red" />
          </div>
          <div className="callHeader">
            Calling <p className="contactsName">{getCallerName(targetId)}…</p>
          </div>
          <Image className="audioCallerAvatar" size="small" avatar src={`${apiUrl}/avatar/${targetId.split('@')[0]}`} />
        </Container>
      </Draggable>
    )
  }

  render() {
    const { requestedMedia } = this.context
    if (requestedMedia.video) {
      return this.renderVideoView()
    }
    return this.renderAudioView()
  }
}

OutgoingCall.contextType = CallContext

OutgoingCall.propTypes = {
  containerStyle: PropTypes.shape(),
  apiUrl: PropTypes.string.isRequired,
}

const mapStateToProperties = (state) => ({
  apiUrl: state.config.apiUrl,
})

const connector = (container) => connect(mapStateToProperties, null)(container)

export default withRouter(connector(OutgoingCall))
