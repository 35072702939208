import intersection from 'lodash/intersection'
import createCachedSelector from 're-reselect'

const spaces = (state) => state.spaces.byId
const spaceIds = (state) => state.spaces.allIds
const roster = (state) => state.roster.byId
const rosterIds = (state) => state.roster.allIds
const vcards = (state) => state.vcards.byId
const userId = (_state, props) => props.userId
const userVCard = (state, props) => state.vcards.byId[props.userId]

export const alphaSortedRosterIds = createCachedSelector([rosterIds, vcards], (rosterIds, vcards) =>
  rosterIds.slice().sort((idA, idB) => {
    const FNA = (vcards[idA] && vcards[idA].FN && vcards[idA].FN.toLowerCase()) || ''
    const FNB = (vcards[idB] && vcards[idB].FN && vcards[idB].FN.toLowerCase()) || ''
    return FNA > FNB ? 1 : -1
  }),
)(() => 'alphaSortedRosterIds')

export const getContacts = createCachedSelector(
  [spaces, spaceIds, roster, alphaSortedRosterIds],
  (spaces, spaceIds, roster, alphaSortedRosterIds) => {
    const contactSpaceIds = Object.values(spaces)
      .filter((space) => space && space.type === 'contact')
      .map((space) => space.id)

    /* eslint-disable no-unused-vars */
    return Object.entries(roster)
      .filter(([rosterItemId, rosterItem]) => intersection(rosterItem.groups, contactSpaceIds).length > 0)
      .sort(([rosterItemIdA, rosterItemA], [rosterItemIdB, rosterItemB]) => {
        if (alphaSortedRosterIds.indexOf(rosterItemIdA) > alphaSortedRosterIds.indexOf(rosterItemIdB)) return 1
        return -1
      })
      .map(([rosterItemId, rosterItem]) => rosterItem)
  },
)(() => 'contacts')

export const getContactFullnameValueLabelPairs = createCachedSelector([vcards, getContacts], (vcards, contacts) => {
  return contacts.map((contact) => {
    const id = contact.jid.split('@')[0]
    return {
      value: id,
      label: (vcards[id] && vcards[id].FN) || '',
    }
  })
})(() => 'alphaSortedRosterFullnameValueLabels')

export const isFingerprintVerified = createCachedSelector([roster, userId], (roster, userId) => {
  const user = roster[userId]
  return !!(user && user.verified)
})(userId)

export const getFingerprintVerifiedContacts = createCachedSelector([roster], (roster) => {
  return Object.entries(roster).reduce((verified, [userId, roster]) => {
    if (roster.verified) {
      verified[userId] = roster.verified
    }
    return verified
  }, {})
})(() => 'verifiedContacts')

export const isExternallyVerified = createCachedSelector([userVCard], (userVCard) => {
  return !!(userVCard && userVCard.VERIFIED)
})(userId)

export const externallyVerifiedFullname = createCachedSelector([userVCard], (userVCard) => {
  return (userVCard && userVCard.VERIFIED && userVCard.VERIFIED.BANKID && userVCard.VERIFIED.BANKID.FN) || ''
})(userId)

export const externallyVerifiedBirthDate = createCachedSelector([userVCard], (userVCard) => {
  return (userVCard && userVCard.VERIFIED && userVCard.VERIFIED.BANKID && userVCard.VERIFIED.BANKID.BIRTHDATE) || ''
})(userId)
