import React, { Component, cloneElement } from 'react'
import { withFormsy } from 'formsy-react'
import { Form } from 'semantic-ui-react'
import PropTypes from 'prop-types'
import Phone from 'react-phone-number-input'
import { filterSuirElementPropperties } from './utils'

import 'react-phone-number-input/style.css'

class FormsyPhoneInput extends Component {
  static propTypes = {
    id: PropTypes.string,
    name: PropTypes.string.isRequired,
    as: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    width: PropTypes.number,
    className: PropTypes.string,
    inputClassName: PropTypes.string,
    disabled: PropTypes.bool,
    inline: PropTypes.bool,
    passRequiredToField: PropTypes.bool,
    errorLabel: PropTypes.element,
    required: PropTypes.bool,
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    instantValidation: PropTypes.bool,
    defaultValue: PropTypes.string,
    onBlur: PropTypes.func,
    isValid: PropTypes.func.isRequired,
    setValue: PropTypes.func.isRequired,
    getValue: PropTypes.func.isRequired,
    onChange: PropTypes.func,
    isPristine: PropTypes.func.isRequired,
    getErrorMessage: PropTypes.func.isRequired,
    validationError: PropTypes.string,
    validationErrors: PropTypes.object,
    validations: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  }

  static defaultProps = {
    passRequiredToField: true,
  }

  state = { allowError: false }

  componentDidMount() {
    const { defaultValue, setValue } = this.props
    if (defaultValue) setValue(defaultValue)
  }

  handleChange = (event, data) => {
    this.props.setValue(event)
    if (this.props.onChange) {
      this.props.onChange(event, data)
    }
    if (this.props.instantValidation) {
      this.showError()
    }
  }

  handleBlur = (event, data) => {
    this.showError()
    if (this.props.onBlur) {
      this.props.onBlur(event, data)
    }
  }

  showError = () => this.setState({ allowError: true })

  render() {
    const {
      id,
      inputClassName,
      required,
      label,
      defaultValue,
      getValue,
      isValid,
      isPristine,
      getErrorMessage,
      errorLabel,
      // Form.Field props
      as,
      width,
      className,
      disabled,
      inline,
      passRequiredToField,
      name,
    } = this.props
    const { allowError } = this.state
    const error = !isPristine() && !isValid() && allowError

    const inputProperties = {
      countrySelectProps: {
        unicodeFlags: true,
      },
      ...filterSuirElementPropperties(this.props),
      value: getValue() || (isPristine() && defaultValue) || 'ZZ',
      onChange: this.handleChange,
      onBlur: this.handleBlur,
      className: inputClassName,
      label,
      id,
      name,
    }

    return (
      <Form.Field
        as={as}
        className={className}
        required={required && passRequiredToField}
        error={!disabled && error}
        width={width}
        inline={inline}
        disabled={disabled}
      >
        {<label htmlFor={id}> {label} </label>}
        <Phone {...inputProperties} />
        {!disabled && error && errorLabel && cloneElement(errorLabel, {}, getErrorMessage())}
      </Form.Field>
    )
  }
}

const PhoneInput = withFormsy(FormsyPhoneInput)

export { PhoneInput }
