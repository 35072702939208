import React from 'react'
import PropTypes from 'prop-types'
import { List } from 'semantic-ui-react'
import EmailListItem from './EmailListItem'
import classes from './EmailList.module.css'

const EmailList = ({ emails, primaryEmail, onDelete }) => {
  const isPrimaryEmail = (email) => {
    return email === primaryEmail
  }
  return (
    <List divided verticalAlign="middle" className={classes['email-list']}>
      <span></span>
      {emails.length === 0 && (
        <div className="pageInfo">
          <span className="empty">There is no emails.</span>
        </div>
      )}
      {emails.map((email) => (
        <EmailListItem
          key={email}
          showRemoveButton={!isPrimaryEmail(email)}
          email={email}
          onDelete={() => onDelete(email)}
        />
      ))}
    </List>
  )
}

EmailList.propTypes = {
  emails: PropTypes.array,
  loading: PropTypes.bool,
  onDelete: PropTypes.func,
  primaryEmail: PropTypes.string,
}

export default EmailList
