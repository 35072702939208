import React from 'react'
import SMSForm from './SMSForm'
import MailForm from './MailForm'
import AnnouncementForm from './AnnouncementForm'

const Communications = () => {
  return (
    <div id="communications-container" className="adminScene">
      <SMSForm />
      <MailForm />
      <AnnouncementForm />
    </div>
  )
}

export default Communications
